<template>
    <div>
        <form v-if="page == 'form'" ref="form">
            <input type="hidden" name="instId" v-model="formValues.installation">
            <input type="hidden" name="accId1" v-model="formValues.merchant">
            <input type="hidden" name="cartId" v-model="formValues.reference">
            <input type="hidden" name="amount" v-model="formValues.amount">
            <input type="hidden" name="currency" v-model="formValues.currency.code">
            <input type="hidden" name="desc" v-model="formValues.description">
            <input type="hidden" name="signature" v-model="formValues.signature">
            <input type="hidden" name="lang" value="en-GB">
            <input type="hidden" name="noLanguageMenu" value="1">
            <input type="hidden" name="fixContact" value="1">
            <input type="hidden" name="hideCurrency" value="1">

            <div class="mb-5">
                <label for="name" class="form-label is-required">Name</label>
                <div class="inline-block relative w-full">
                    <input type="text" name="name" id="name" class="form-control"
                        placeholder="Name" v-model="name">
                </div>
                <span class="invalid-input" v-if="error.has('name')">{{ error.get('name') }}</span>
            </div>

            <div class="mb-5">
                <label for="address1" class="form-label is-required">Address Line 1</label>
                <div class="inline-block relative w-full">
                    <input type="text" name="address1" id="address1" class="form-control"
                        placeholder="Address Line 1" v-model="address1">
                </div>
                <span class="invalid-input" v-if="error.has('address1')">{{ error.get('address1') }}</span>
            </div>

            <div class="mb-5">
                <label for="address2" class="form-label">Address Line 2</label>
                <div class="inline-block relative w-full">
                    <input type="text" name="address2" id="address2" class="form-control"
                        placeholder="Address Line 2" v-model="address2">
                </div>
                <span class="invalid-input" v-if="error.has('address2')">{{ error.get('address2') }}</span>
            </div>

            <div class="mb-5">
                <label for="town" class="form-label is-required">Town</label>
                <div class="inline-block relative w-full">
                    <input type="text" name="town" id="town" class="form-control"
                        placeholder="Town" v-model="town">
                </div>
                <span class="invalid-input" v-if="error.has('town')">{{ error.get('town') }}</span>
            </div>

            <div class="mb-5">
                <label for="region" class="form-label is-required">Region</label>
                <div class="inline-block relative w-full">
                    <input type="text" name="region" id="region" class="form-control"
                        placeholder="Region" v-model="region">
                </div>
                <span class="invalid-input" v-if="error.has('region')">{{ error.get('region') }}</span>
            </div>

            <div class="mb-5">
                <label for="postcode" class="form-label is-required">Postcode</label>
                <div class="inline-block relative w-full">
                    <input type="text" name="postcode" id="postcode" class="form-control"
                        placeholder="Postcode" v-model="postcode">
                </div>
                <span class="invalid-input" v-if="error.has('postcode')">{{ error.get('postcode') }}</span>
            </div>

            <div class="mb-5">
                <label for="country" class="form-label is-required">Country</label>
                <div class="inline-block relative w-full">
                    <select name="country" id="country" class="form-control" :value="country">
                        <option v-for="(countryL, index) in formValues.countries" :key="index" :value="countryL.code">{{ countryL.name }}</option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg class="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                        </svg>
                    </div>
                </div>
                <span class="invalid-input" v-if="error.has('country')">{{ error.get('country') }}</span>
            </div>

            <div class="mb-5">
                <label for="tel" class="form-label is-required">Telephone</label>
                <div class="inline-block relative w-full">
                    <input type="text" name="tel" id="tel" class="form-control"
                        placeholder="Telephone" v-model="tel">
                </div>
                <span class="invalid-input" v-if="error.has('tel')">{{ error.get('tel') }}</span>
            </div>

            <div class="mb-5">
                <label for="email" class="form-label is-required">Email Address</label>
                <div class="inline-block relative w-full">
                    <input type="text" name="email" id="email" class="form-control"
                        placeholder="Email Address" v-model="email">
                </div>
                <span class="invalid-input" v-if="error.has('email')">{{ error.get('email') }}</span>
            </div>

            <button type="button" class="button is-primary" @click="paymentPage">
                Pay Now
            </button>
        </form>

        <keep-alive>
            <subscription-payment
                v-if="page == 'payment'"
                :page="page"
                :user="user"
                :user-address="userAddress"
                :user-country="userCountry"
                :form-values="formValues"
                :stripe-params="stripeParams"
                :sub-year="subYear"
                :address1="address1"
                :address2="address2"
                :town="town"
                :region="region"
                :postcode="postcode"
                :country="country"
                v-on:subGoBack="goBack"
            />
        </keep-alive>
    </div>
</template>

<script>
    import SubscriptionPayment from './SubscriptionPayment'
    import Error from '../Error';

    export default {
        name: 'UserPaymentFormComponent',

        components: {
            SubscriptionPayment
        },

        props: {
            formValues: {
                type: Object,
                required: true
            },
            user: {
                type: Object
            },
            userAddress: {
                type: Object|Array
            },
            userCountry: {
                type: Object|Array
            },
            stripeParams: {
                type: Object,
                required: true
            },
            subYear: {
                type: String
            }
        },

        data() {
            return {
                page: 'form',
                name: this.user.title + ' ' + this.user.first_name + ' ' + this.user.last_name,
                house_number: this.userAddress.house_number,
                address1: this.userAddress.address_1,
                address2: this.userAddress.locality,
                town: this.userAddress.town,
                region: this.userAddress.county,
                postcode: this.userAddress.postcode,
                country: this.userCountry.code,
                tel: this.userAddress.primary_telephone,
                email: this.user.email,

                error: new Error()
            }
        },

        methods: {
            paymentPage() {
                axios.post('/api/account/payment/validate', {
                    name: this.name,
                    house_number: this.house_number,
                    address1: this.address1,
                    address2: this.address2,
                    town: this.town,
                    region: this.region,
                    postcode: this.postcode,
                    country: this.country,
                    tel: this.tel,
                    email: this.email
                }).then(() => {
                    // this.$refs.form.submit()
                    this.page = 'payment'
                }).catch(error => {
                    this.error.errors = error.response.data.errors;
                })
            },
            goBack() {
                this.page = 'form'
            }
        },
    }
</script>
