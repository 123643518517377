<style>
</style>

<template>
    <div>
        <h4 class="font-bold text-gray-700">Additional CPD</h4>
        <p class="text-gray-700 mb-4">Append CPD to the certificate of the user completing the evaluation, optionally included regardless of response.</p>

        <div class="flex flex-wrap -m-2">
            <div class="w-full p-2">
                <label class="block text-gray-700 w-full">
                    <label class="font-bold inline-block mb-2">CPD amount</label>
                    <DatetimePopup v-if="cpdOpen" type="time" :datetime="cpdLocal" title="Select the CPD amount" @confirm="changeDuration" @cancel="cancelDuration"></DatetimePopup>
                    <div class="relative w-full">
                        <button class="w-full justify-center text-left" :class="{ 'bg-white border border-gray-400 px-3 py-1 rounded pr-8': options.cpd, 'button is-primary px-3 py-1': !options.cpd }" @click.prevent="triggerDuration" v-html="cpd ? cpd : 'Select'"></button>
                        <button class="absolute inset-y-0 right-0 py-1 px-3" @click.prevent="clearDuration" v-if="options.cpd">&times;</button>
                    </div>
                </label>
            </div>

            <div class="w-full p-2">
                <label class="flex items-center">
                    <Toggle :value="options.force_cpd" @change="(event) => handleChange('force_cpd', event)"></Toggle>
                    <div class="ml-2 mb-0 form-label">Include regardless of response</div>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import { Duration, DateTime } from 'luxon'

    import Toggle from "../../../GenericToggleComponent"
    import DatetimePopup from '../../../datetime/DatetimePopup'

    export default {
        components: {
            Toggle,
            DatetimePopup
        },

        props: {
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        data() {
            return {
                cpdOpen: false,
                cpdTemp: null,

                cpdLocal: this.options.cpd
            }
        },

        methods: {
            handleChange(key, value) {
                this.$emit('change', key, value)
            },

            triggerDuration() {
                this.cpdTemp = this.cpdLocal
                if (!this.cpdLocal) {
                    this.cpdLocal = DateTime.fromObject({
                        hour: 0,
                        minute: 0
                    })
                } else {
                    let duration = Duration.fromObject({
                        minutes: this.cpdTemp
                    })

                    let format = duration.toFormat("h m").split(' ')

                    this.cpdLocal = DateTime.fromObject({
                        hour: parseInt(format[0]),
                        minute: parseInt(format[1])
                    })
                }
                this.cpdOpen = true
            },

            changeDuration(value) {
                let duration = Duration.fromObject({
                    hours: value.hour,
                    minutes: value.minute
                })

                this.$emit('change', 'cpd', (duration.hours * 60) + duration.minutes)

                this.cpdTemp = null
                this.cpdOpen = false
            },

            cancelDuration() {
                this.cpdLocal = this.cpdTemp
                this.cpdTemp = null
                this.cpdOpen = false
            },

            clearDuration() {
                if (!this.cpdOpen) {
                    this.cpdLocal = null
                    this.$emit('change', 'cpd', null)
                }
            },
        },

        computed: {
            cpd() {
                return this.options.cpd ? this.options.cpd + 'm' : null
            }
        }
    }
</script>
