<template>
    <div>
        <div class="mb-4">
            <label class="form-label">Title</label>
            <input type="text" class="form-control" :value="data.title" @keyup="(event) => handleChange('title', event.target.value)">
        </div>

        <label class="form-label">Talks</label>

        <table class="border rounded w-full mb-4">
            <tbody>
                <tr v-if="selectedTalks.length === 0">
                    <td class="p-2 text-center text-gray-500">No Talks</td>
                </tr>
                <tr v-for="talk in selectedTalks" :key="talk.id">
                    <td class="border-t p-2 px-4">
                        <span class="block font-bold">{{ talk.title }}</span>
                        <span v-if="talk.speaker !== null">{{ talk.speaker }}</span>
                    </td>
                    <td class="border-t p-4" width="1">
                        <button class="button is-danger" @click.prevent="() => handleRemoveTalk(talk.id)"><i class="fa fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="flex">
            <div class="relative flex-1">
                <div class="bg-smoke-light absolute rounded inset-0 z-50" v-if="talksLoading"></div>
                <GenericSelectComponent :options="talks" :value="selected" placeholder="Search Talks..." keyColumn="id" valueColumn="title" descriptionColumn="speaker" :loading="talksLoading" @change="(value) => selected = value"></GenericSelectComponent>
            </div>
            <button class="button is-primary flex-0 ml-4" @click="handleAddTalk"><i class="fa fa-plus mr-2"></i> Add Talk</button>
        </div>
    </div>
</template>

<script>
    import GenericSelectComponent from '../../GenericSelectComponent'

    export default {
        components: {
            GenericSelectComponent
        },

        props: {
            event: {
                type: Object,
                required: true
            },
            data: {
                type: Object,
                required: true
            },
            talks: {
                type: Array,
                default: () => {
                    return []
                }
            },
            talksLoading: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                selected: null,

                slot: Object.assign({}, {
                    title: this.data.title,
                    talk_ids: []
                }, this.data)
            }
        },

        methods: {
            handleAddTalk() {
                if (!this.slot.talk_ids.includes(this.selected)) {
                    this.slot.talk_ids.push(this.selected)
                    this.$emit('change', 'talk_ids', this.slot.talk_ids)
                }

                this.selected = null
            },

            handleRemoveTalk(id) {
                let index = this.slot.talk_ids.indexOf(id)
                if (index > -1) {
                    this.slot.talk_ids.splice(index, 1)
                    this.$emit('change', 'talk_ids', this.slot.talk_ids)
                }
            },

            handleChange(name, value) {
                this.$emit('change', name, value)
            }
        },

        computed: {
            selectedTalks() {
                let talks = []
                for (let talk in this.talks) {
                    if (this.slot.talk_ids.includes(this.talks[talk].id)) {
                        talks.push(this.talks[talk])
                    }
                }

                return talks
            }
        }
    }
</script>
