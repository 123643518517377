<template>
    <div>
        <div class="mb-5">
            <div class="flex items-center">
                <h2 class="font-bold text-2xl text-gray-700"><span class="font-normal">CPD</span> {{ cpd }}</h2>

                <div class="flex ml-auto">
                    <span class="font-bold text-gray-700 mr-3">Admin</span>
                    <Toggle :value="meta.admin" @change="(value) => meta.admin = value"></Toggle>
                </div>
            </div>

            <div class="mt-4 flex items-center w-full">
                <div class="w-full">
                    <div class="relative">
                        <div class="bg-smoke-light absolute rounded inset-0 z-50" v-if="loading">
                            <div class="flex items-center pr-2 absolute inset-y-0 right-0">
                                <i class="fa fa-spinner fa-spin"></i>
                            </div>
                        </div>
                        <Select :options="fields" :value="field" placeholder="Search Fields..." keyColumn="id" valueColumn="title" descriptionColumn="description" :loading="loading" @change="(value) => handleFieldChange('field', value)"></Select>
                    </div>
                </div>

                <div class="ml-4">
                    <label class="flex items-center">
                        <span class="font-bold text-gray-700 mr-3">Filled</span>
                        <toggle :value="filled" @change="(event) => handleFieldChange('filled', event)"></toggle>
                    </label>
                </div>
            </div>
        </div>

        <keep-alive v-for="(child, index) in meta.layout" :key="index">
            <component
                :is="meta.objects[child.internal].component"
                :field="child"

                :form="name"

                :admin="meta.admin"

                :user="meta.user"
                :event="event"
                :bookingValues="meta.booking"

                :map="meta.map"
                :object="meta.objects[child.internal]"
                :layout="meta.layout"
                :objects="meta.objects"
                :values="meta.values"
            ></component>
        </keep-alive>
    </div>
</template>

<script>
    import {debounce} from 'lodash'

    import GroupField from './Fields/Group'
    import InputField from './Fields/Input'
    import TextField from './Fields/Text'
    import ToggleField from './Fields/Toggle'
    import RatingField from './Fields/Rating'
    import ListField from './Fields/List'

    import Select from '../../../GenericSelectComponent'
    import Toggle from '../../../GenericToggleComponent'

    export default {
        name: 'EvaluationFormPreview',

        components: {
            Select,
            Toggle,

            GroupField,
            InputField,
            TextField,
            ToggleField,
            RatingField,
            ListField
        },

        props: {
            name: {
                type: String,
                required: true,
            },

            event: {
                type: Object,
                required: true
            },

            layout: {
                type: Array,
                required: true
            },
            objects: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                loading: false,
                fields: [],
                field: '',
                filled: false,

                meta: {
                    map: {},
                    flipped: {},

                    user: {},
                    booking: {},

                    admin: false,

                    objects: this.objects,
                    layout: this.layout,
                    values: {}
                }
            }
        },

        created() {
            window.EventBus.$on(this.name + '_value', (field, name, value) => {
                this.handleChange(field, name, value)
            })

            window.EventBus.$on(this.name + '_visible', (field, state) => {
                this.handleVisibleChange(field, state)
            })

            window.EventBus.$on(this.name + '_enabled', (field, state) => {
                this.handleEnabledChange(field, state)
            })

            debounce(() => {
                this.loading = true

                axios.get('/api/events/' + this.event.id + '/booking').then(response => {
                    this.fields = []
                    for (let i = 0; i < response.data.objects.length; i++) {
                        let object = response.data.objects[i]
                        if (object.type != 'text' && object.type != 'group') {
                            this.fields.push({
                                id: object.options.name,
                                title: object.options.name,
                                description: object.type
                            })
                        }
                    }
                    this.loading = false
                })
            }, 200)()
        },

        mounted() {
            this.init()
        },

        methods: {
            handleFieldChange(key, value) {
                this.$set(this, key, value)

                if (key == 'field') {
                    if (this.field != '') {
                        this.$set(this.meta, 'booking', {
                            [this.field]: {
                                value: this.filled ? '1' : '0'
                            }
                        })
                    } else {
                        this.$set(this.meta, 'booking', {})
                    }
                } else if (key == 'filled') {
                    if (this.field != '') {
                        this.$set(this.meta, 'booking', {
                            [this.field]: {
                                value: this.filled ? '1' : '0'
                            }
                        })
                    }
                }
            },

            init() {
                let parents = {}
                let children = {}

                let recurse = (tree, parent) => {
                    for (let i = 0; i < tree.length; i++) {
                        parents[tree[i].internal] = []
                        if (parent !== null) {
                            parents[tree[i].internal] = [
                                parent,
                                ...parents[parent]
                            ]
                        }

                        children[tree[i].internal] = []
                        if (parent !== null) {
                            children[parent] = [
                                tree[i].internal,
                                ...children[parent]
                            ]
                        }

                        if (tree[i].children.length !== 0) {
                            recurse(tree[i].children, tree[i].internal)
                        }
                    }
                }

                recurse(this.layout, null)

                for (let i = 0; i < this.meta.objects.length; i++) {
                    this.$set(this.meta.map, this.meta.objects[i].options.name, this.meta.objects[i].internal)

                    if (typeof this.meta.values[this.meta.objects[i].options.name] === 'undefined') {
                        this.$set(this.meta.values, this.meta.objects[i].options.name, {
                            value: null,
                            visible: null,
                            enabled: null
                        })
                    }

                    if (typeof this.meta.objects[i].options.has_default !== 'undefined' && this.meta.objects[i].options.has_default) {
                        if ((typeof this.meta.objects[i].options.default_compulsory !== 'undefined' && this.meta.objects[i].options.default_compulsory) || typeof this.meta.values[this.meta.objects[i].options.name] === 'undefined' || this.meta.values[this.meta.objects[i].options.name].value === null) {
                            this.$set(this.meta.values[this.meta.objects[i].options.name], 'value', this.meta.objects[i].options.default_value)
                        }
                    }

                    this.$set(this.meta.objects[i], 'touched', [])
                    this.$set(this.meta.objects[i], 'visible', null)

                    this.$set(this.meta.objects[i], 'fields', [
                        this.meta.objects[i].options.name
                    ])

                    this.$set(this.meta.objects[i], 'rules', {})
                    this.$set(this.meta.objects[i], 'errors', {})
                    this.$set(this.meta.objects[i], 'parents', parents[this.meta.objects[i].internal])
                    this.$set(this.meta.objects[i], 'children', children[this.meta.objects[i].internal])

                    switch (this.meta.objects[i].type) {
                        case 'list':
                            this.$set(this.meta.objects[i], 'component', 'ListField')

                            this.$set(this.meta.objects[i].rules, 'default', [
                                (value) => {
                                    let total = 0

                                    let check = this.meta.objects.filter(object => object.parents.includes(this.meta.objects[i].internal))
                                    for (let e = 0; e < check.length; e++) {
                                        if (check[e].type == 'toggle') {
                                            if (this.meta.values[check[e].options.name].value == true) {
                                                total++
                                            }
                                        }
                                    }

                                    if (this.meta.objects[i].options.group_rule == 'one' && total !== 1) {
                                        return 'Please choose exactly one option'
                                    } else if (this.meta.objects[i].options.group_rule == 'gte_one' && total < 1) {
                                        return 'Please choose at least one option'
                                    } else if (this.meta.objects[i].options.group_rule == 'lte_one' && total > 1) {
                                        return 'Please choose at most one option'
                                    }

                                    return true
                                }
                            ])

                            break
                        case 'group':
                            this.$set(this.meta.objects[i], 'component', 'GroupField')
                            break
                        case 'input':
                            this.$set(this.meta.objects[i], 'component', 'InputField')

                            this.$set(this.meta.objects[i].rules, 'default', [
                                (value) => {
                                    if (this.meta.objects[i].options.required && (value == null || value.trim() == '')) {
                                        return 'This field is required'
                                    }

                                    return true
                                }
                            ])

                            break
                        case 'rating':
                            this.$set(this.meta.objects[i], 'component', 'RatingField')

                            this.$set(this.meta.objects[i].rules, 'default', [
                                (value) => {
                                    if (this.meta.objects[i].options.required && (value == null || value.trim() == '')) {
                                        return 'This field requires a rating'
                                    }

                                    return true
                                }
                            ])

                            break
                        case 'text':
                            this.$set(this.meta.objects[i], 'component', 'TextField')
                            break
                        case 'toggle':
                            this.$set(this.meta.objects[i], 'component', 'ToggleField')

                            this.meta.objects[i].fields.push('additional')

                            this.$set(this.meta.objects[i].rules, 'additional', [
                                (value) => {
                                    if (this.meta.values[this.meta.objects[i].options.name].value === true && this.meta.objects[i].options.request_additional && this.meta.objects[i].options.additional_mandatory && (value == null || value.trim() == '')) {
                                        return 'This field is required'
                                    }

                                    return true
                                }
                            ])

                            break
                    }
                }
            },

            handleChange(field, name, value) {
                let child = null
                if (name.indexOf('::') !== -1) {
                    child = name.split('::', 2)[1]
                }

                name = name.replace('::', '_')

                if (typeof this.meta.values[name] == 'undefined') {
                    this.$set(this.meta.values, name, {
                        'value': value,
                        'visible': null,
                        'enabled': null,
                    })
                } else {
                    this.$set(this.meta.values[name], 'value', value)
                }

                if (this.meta.objects[this.meta.map[field]].touched.includes(child !== null ? child : 'default') === false) {
                    this.meta.objects[this.meta.map[field]].touched.push(child !== null ? child : 'default')
                }

                let parents = this.meta.objects[this.meta.map[field]].parents
                if (parents.length !== 0) {
                    let parent = parents[0]

                    if (this.meta.objects[parent].type == 'list') {
                        if (this.meta.objects[parent].touched.includes('default') === false) {
                            this.meta.objects[parent].touched.push('default')
                        }

                        this.handleValidate(this.meta.objects[parent].options.name, 'default', null)
                    }
                }

                this.handleValidate(field, child !== null ? child : 'default', value)
            },

            handleVisibleChange(field, state) {
                if (typeof this.meta.values[field] == 'undefined') {
                    this.$set(this.meta.values, field, {
                        'value': null,
                        'visible': state,
                        'enabled': null,
                    })
                } else {
                    this.$set(this.meta.values[field], 'visible', state)
                }
            },
            handleEnabledChange(field, state) {
                if (typeof this.meta.values[field] == 'undefined') {
                    this.$set(this.meta.values, field, {
                        'value': null,
                        'visible': null,
                        'enabled': state,
                    })
                } else {
                    this.$set(this.meta.values[field], 'enabled', state)
                }
            },

            handleValidate(field, name, value) {
                this.$set(this.meta.objects[this.meta.map[field]].errors, name, [])

                if (this.meta.objects[this.meta.map[field]].touched.includes(name) && typeof this.meta.objects[this.meta.map[field]].rules !== 'undefined' && typeof this.meta.objects[this.meta.map[field]].rules[name] !== 'undefined') {
                    for (let i = 0; i < this.meta.objects[this.meta.map[field]].rules[name].length; i++) {
                        let result = this.meta.objects[this.meta.map[field]].rules[name][i](value)
                        if (result !== true) {
                            this.meta.objects[this.meta.map[field]].errors[name].push(result)
                        }
                    }
                }
            },

            validateForm() {
                let ok = true

                for (let i = 0; i < this.meta.objects.length; i++) {
                    if (this.meta.objects[i].visible === true && Object.keys(this.meta.objects[i].rules).length !== 0) {
                        for (let key in this.meta.objects[i].rules) {
                            this.$set(this.meta.objects[i].errors, key, [])

                            for (let j = 0; j < this.meta.objects[i].rules[key].length; j++) {
                                let field = this.meta.objects[i].options.name
                                if (key !== 'default') {
                                    field += '_' + key
                                }

                                let result = this.meta.objects[i].rules[key][j](typeof this.meta.values[field] !== 'undefined' ? this.meta.values[field].value : null)
                                if (result !== true) {
                                    this.meta.objects[i].errors[key].push(result)

                                    ok = false
                                }
                            }
                        }
                    }
                }

                return ok;
            }
        },

        computed: {
            cpd() {
                let cpd = 0
                if (this.event !== null && typeof this.event.initial_cpd !== 'undefined' && this.event.initial_cpd != null) {
                    cpd = this.event.initial_cpd
                }

                for (let i = 0; i < this.meta.objects.length; i++) {
                    if (this.meta.objects[i].visible && typeof this.meta.objects[i].options.cpd !== 'undefined' && this.meta.objects[i].options.cpd > 0) {
                        if ((typeof this.meta.objects[i].options.force_cpd !== 'undefined' && (this.meta.objects[i].options.force_cpd == true || this.meta.objects[i].options.force_cpd == 1)) || (typeof this.meta.values[this.meta.objects[i].options.name] !== 'undefined' && ((this.meta.objects[i].type == 'input' || this.meta.objects[i].type == 'rating') && this.meta.values[this.meta.objects[i].options.name].value != null) || (this.meta.objects[i].type == 'toggle' && (this.meta.values[this.meta.objects[i].options.name].value == 1 || this.meta.values[this.meta.objects[i].options.name].value === true)))) {
                            cpd += this.meta.objects[i].options.cpd
                        }
                    }
                }

                if (this.event !== null && typeof this.event.maximum_cpd !== 'undefined' && this.event.maximum_cpd != null && cpd > this.event.maximum_cpd) {
                    cpd = this.event.maximum_cpd
                }

                if (this.meta.forceCpd !== true) {
                    this.meta.forcedCpd = (cpd < 0 ? 0 : cpd)
                }

                if (cpd < 0) {
                    return 0
                }

                return cpd
            }
        }
    }
</script>
