<template>
    <div>
        <input type="hidden" :name="name" :id="name" :value="rating" :disabled="disabled">
        <div class="flex -ml-2">
            <button v-for="(option, index) in options" :key="index" :class="defaults.class.concat(option.class(rating == option.id))" :title="option.name" @click.prevent="() => rate(option.id)">
                <i :class="option.icon"></i>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String
            },

            disabled: {
                type: Boolean,
                default: false
            },

            value: {
                type: String
            }
        },

        data() {
            return {
                rating: this.value,

                defaults: {
                    class: [
                        'transition duration-500 ease-in-out',
                        this.disabled ? 'cursor-default bg-gray-200' : 'cursor-pointer',
                        'button m-0 ml-2 px-2 text-2xl rounded-full border-2'
                    ]
                },

                options: [
                    {
                        id: 'awful',
                        name: 'Awful',
                        class: (active) => {
                            return [
                                this.disabled ? (active ? 'border-gray-500' : 'border-gray-400') : 'border-red',
                                this.disabled ? (active ? 'border-gray-500' : 'text-gray-400') : (active ? 'text-white bg-red' : 'text-red')
                            ]
                        },
                        icon: 'far fa-tired',
                    },
                    {
                        id: 'bad',
                        name: 'Bad',
                        class: (active) => {
                            return [
                                this.disabled ? (active ? 'border-gray-500' : 'border-gray-400') : 'border-orange-500',
                                this.disabled ? (active ? 'border-gray-500' : 'text-gray-400') : (active ? 'text-white bg-orange-500' : 'text-orange-500')
                            ]
                        },
                        icon: 'far fa-frown',
                    },
                    {
                        id: 'neutral',
                        name: 'Neutral',
                        class: (active) => {
                            return [
                                this.disabled ? (active ? 'border-gray-500' : 'border-gray-400') : 'border-yellow-500',
                                this.disabled ? (active ? 'border-gray-500' : 'text-gray-400') : (active ? 'text-white bg-yellow-500' : 'text-yellow-500')
                            ]
                        },
                        icon: 'far fa-meh',
                    },
                    {
                        id: 'good',
                        name: 'Good',
                        class: (active) => {
                            return [
                                this.disabled ? (active ? 'border-gray-500' : 'border-gray-400') : 'border-green-light',
                                this.disabled ? (active ? 'border-gray-500' : 'text-gray-400') : (active ? 'text-white bg-green-light' : 'text-green-light')
                            ]
                        },
                        icon: 'far fa-smile',
                    },
                    {
                        id: 'great',
                        name: 'Great',
                        class: (active) => {
                            return [
                                this.disabled ? (active ? 'border-gray-500' : 'border-gray-400') : 'border-green',
                                this.disabled ? (active ? 'border-gray-500' : 'text-gray-400') : (active ? 'text-white bg-green' : 'text-green')
                            ]
                        },
                        icon: 'far fa-grin',
                    }
                ]
            }
        },

        watch: {
            value (value) {
                this.rating = value
            }
        },

        methods: {
            rate(value) {
                if (!this.disabled) {
                    if (this.rating == value) {
                        this.rating = null
                    } else {
                        this.rating = value
                    }

                    this.$emit('change', this.rating)
                }
            }
        }
    }
</script>
