<template>
    <div>
        <div class="flex flex-wrap">
            <div class="w-1/2 px-4" v-for="(type, key) in options" :key="key">
                <label class="form-label font-normal">
                    <input type="checkbox" name="membership_types[]" class="mr-2" :value="type.id" :checked="type.selected" v-on:input="handleSelect(type.id, type.name)">
                    <span class="text-base">{{ type.name }}</span>
                </label>
            </div>
        </div>
        <input type="hidden" name="ecg_committee_position" value="">
        <div class="mx-2 mt-2 p-2 pt-1 border rounded" v-if="ecg">
            <label for="ecg_committee_position" class="form-label">ECG Committee Position</label>
            <input type="text" class="form-control" id="ecg_committee_position" name="ecg_committee_position" :value="ecg_committee_position">
        </div>
        <div class="mx-2 mt-2 p-2 pt-1 border rounded" v-if="council">
            <label class="form-label">Council Positions</label>

            <input type="hidden" name="council_positions" :value="councillor_positions_json">

            <table class="table data-table">
                <thead>
                    <tr>
                        <th>Position</th>
                        <th>Year</th>
                        <th width="1" nowrap />
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(position, key) in positions">
                        <tr v-if="!position.deleted" :key="key">
                            <td>
                                <generic-select-component :value="position.position_id" :options="councillor_positions" @change="(value) => handleChangeExistingSelectedPosition(key, value)"></generic-select-component>
                            </td>
                            <td>
                                <generic-select-component :value="position.year" :options="years" @change="(value) => handleChangeExistingSelectedYear(key, value)"></generic-select-component>
                            </td>
                            <td>
                                <button class="button is-primary w-full whitespace-no-wrap block text-center" @click.prevent="() => handleRemovePosition(key)">&times; Delete</button>
                            </td>
                        </tr>
                    </template>
                    <tr>
                        <td>
                            <generic-select-component :value="selectedPosition" :options="councillor_positions" @change="(value) => handleChangeSelectedPosition(value)"></generic-select-component>
                        </td>
                        <td>
                            <generic-select-component :value="selectedYear" :options="years" @change="(value) => handleChangeSelectedYear(value)"></generic-select-component>
                        </td>
                        <td>
                            <button class="button is-primary w-full whitespace-no-wrap block text-center" @click.prevent="() => handleAddPosition()">&plus; Add</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import GenericSelectComponent from './GenericSelectComponent.vue';
import YearSelectComponent from './YearSelectComponent.vue';
    export default {
  components: { GenericSelectComponent, YearSelectComponent },
        props: {
            types: {
                type: Array,
                required: true
            },

            selected: {
                type: Array,
                required: false
            },

            ecg_committee_position: {
                type: String,
                required: false
            },

            councillor_positions: {
                type: Array,
                required: true
            },

            councillors: {
                type: Array,
                required: true
            }
        },

        data() {
            let ecg = false;
            let council = false;

            let choices = this.selected.map(element => {
                if (element.name === 'ECG Committee Representative') {
                    ecg = true;
                }

                if (element.name === 'Council Member') {
                    council = true;
                }

                return element.id
            });

            let positions = this.councillors;

            let year = new Date().getFullYear();
            let years = [];
            for (let i = year - 30; i <= year + 10; i++ ) {
                years.push({
                    id: i,
                    name: i,
                    value: i,
                })
            }

            return {
                ecg: ecg,
                council: council,
                choices: choices,
                years: years,

                selectedPosition: null,
                selectedYear: null,

                positions: positions,
            }
        },

        methods: {
            handleSelect(id, name) {
                if (this.choices.includes(id)) {
                    this.choices.splice(this.choices.indexOf(id), 1)
                    if (name === 'ECG Committee Representative') {
                        this.ecg = false;
                    } else if (name === 'Council Member') {
                        this.council = false;
                    }
                } else {
                    this.choices.push(id)
                    if (name === 'ECG Committee Representative') {
                        this.ecg = true;
                    } else if (name === 'Council Member') {
                        this.council = true;
                    }
                }
            },

            handleRemovePosition(key) {
                this.$set(this.positions, key, {
                    ...this.positions[key],
                    deleted: true
                })
            },

            handleChangeSelectedPosition(value) {
                this.selectedPosition = value;
            },
            handleChangeSelectedYear(value) {
                this.selectedYear = value;
            },

            handleChangeExistingSelectedPosition(key, value) {
                this.positions[key].changed = true;
                this.positions[key].position_id = value;
            },
            handleChangeExistingSelectedYear(key, value) {
                this.positions[key].changed = true;
                this.positions[key].year = value;
            },

            handleAddPosition() {
                this.positions.push({
                    created: true,
                    position_id: this.selectedPosition,
                    year: this.selectedYear,
                })

                this.selectedPosition = null;
                this.selectedYear = null;
            }
        },

        computed: {
            councillor_positions_json: {
                get: function () {
                    return JSON.stringify(this.positions)
                }
            },
            options: {
                get: function () {
                    return this.types.map(element => {
                        element.selected = this.choices.includes(element.id)
                        return element
                    })
                }
            }
        },
    }
</script>
