<style>
</style>

<template>
    <Field @change="(key, value) => handleChange(key, value)" @duplicate="(object) => this.$emit('duplicate', object)" @delete="(object) => this.$emit('delete', object)" @error="(element, valid) => this.$emit('error', object, element, valid)" :objects="objects" :object="object" :definition="definition">
        <div class="flex flex-wrap text-gray-700 -m-2">
            <div class="w-full p-2">
                <label for="caption" class="font-bold inline-block mb-2">Caption</label>
                <input type="text" id="caption" class="form-control" :value="options.caption" @keyup="(event) => handleChange('caption', event.target.value)">
            </div>
            <div class="w-full p-2" v-if="!this.object.options.toggled">
                <label for="description" class="font-bold inline-block mb-2">Description</label>
                <textarea id="description" class="form-control" :value="options.description" @keyup="(event) => handleChange('description', event.target.value)"></textarea>
            </div>
        </div>
        <div class="-m-4 mt-4" v-if="!this.object.options.toggled">
            <div class="w-full p-4 border-solid border-t border-gray-300">
                <div>
                    <h4 class="font-bold text-gray-700">Option Rule</h4>
                    <p class="text-gray-700 mb-4">Choose the ruleset that will be followed by this toggle group.</p>

                    <div class="flex flex-wrap -m-2">
                        <div class="w-full p-2">
                            <Select :nullable="false" :options="rules" :value="options.group_rule" description-column="description" placeholder="Search Rules..." @change="(value) => handleChange('group_rule', value)"></Select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300">
                <FilterMixin type="text" :options="this.options" :object="this.object" :objects="this.objects" @change="(key, value) => handleChange(key, value)"></FilterMixin>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'booking'">
                <MemberLimit :options="this.options" @change="(key, value) => handleChange(key, value)"></MemberLimit>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'booking'">
                <DateLimit :options="this.options" @change="(key, value) => handleChange(key, value)"></DateLimit>
            </div>
        </div>
    </Field>
</template>

<script>
    import Field from '../Field'

    import FilterMixin from './Mixins/Filter'
    import DateLimit from './Mixins/DateLimit'
    import MemberLimit from './Mixins/MemberLimit'

    import Select from '../../GenericSelectComponent'

    export default {
        components: {
            Field,

            FilterMixin,
            DateLimit,
            MemberLimit,

            Select
        },

        props: {
            type: {
                type: String,
                required: true
            },
            objects: {
                type: Array,
                default: () => {
                    return []
                }
            },
            object: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            event: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        data() {
            return {
                definition: Definition,

                rules: [
                    {
                        id: 'one',
                        name: 'Only one',
                        description: 'The member must always select one toggle option'
                    },
                    {
                        id: 'lte_one',
                        name: 'One or zero',
                        description: 'The member can select one toggle option, or none'
                    },
                    {
                        id: 'gte_one',
                        name: 'One or more',
                        description: 'The member must select at least one toggle option'
                    },
                    {
                        id: 'any',
                        name: 'Any number',
                        description: 'The member can select any toggle options, or none'
                    },
                ]
            }
        },

        methods: {
            handleChange(key, value) {
                this.$emit('change', this.object.internal, key, value)
            }
        },

        computed: {
            options() {
                const defaults = {
                    caption: '',
                    description: '',
                    group_rule: 'any',
                    groups: {
                        member: true,
                        non_member: true
                    },
                }

                return Object.assign({}, defaults, this.object.options)
            }
        }
    }

    export const Definition = {
        name: 'list',
        title: 'Group',
        icon: 'tasks'
    }
</script>
