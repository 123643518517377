<template>
    <div>
        <data-table-component :url="`/api/subscriptions/${subscription.id}/variants`" :columns="columns" :params="params">
            <template slot-scope="{ row }" slot="filters">
                <slot v-bind="row"></slot>
            </template>
            <template slot-scope="{ row }" slot="actions">
                <td class="text-right">
                    <a :href="`/admin/subscriptions/${subscription.id}/variants/${row.id}/edit`" class="button is-small is-secondary inline-block">
                        <i class="fas fa-edit"></i>
                    </a>
                    <a :href="`/admin/subscriptions/${subscription.id}/variants/${row.id}/duplicate`" class="button is-small is-tertiary inline-block">
                        <i class="fas fa-copy"></i>
                    </a>
                </td>
            </template>
        </data-table-component>
    </div>
</template>

<script>
    import DataTableComponent from '../DataTableComponent';

    export default {
        components: {
            DataTableComponent,
        },

        props: {
            subscription: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                columns: [
                    {
                        key: 'code',
                        label: 'Code',
                    },
                    {
                        key: 'name',
                        label: 'Name',
                    },
                    {
                        key: 'has_printed_journal',
                        label: 'Printed Journal',
                        html: function (x, y) {
                            if (y == 1) {
                                return '<i class="fas fa-check text-green"></i>';
                            }

                            return '<i class="fas fa-times text-red"></i>';
                        }
                    },
                    {
                        key: 'year',
                        label: 'Year',
                    },
                    {
                        key: 'net_price',
                        label: 'Price',
                        html: (key, value, row) => {
                            return row.symbol + row.net_price;
                        }
                    },
                    {
                        key: 'is_taxable',
                        label: 'Taxed',
                        html: function (x, y) {
                            if (y == 1) {
                                return '<i class="fas fa-check text-green"></i>';
                            }

                            return '<i class="fas fa-times text-red"></i>';
                        }
                    },
                ],

                currentRow: {},
                isDeleteModalVisible: false,

                params: {},
            }
        },

        methods: {

        }
    }
</script>
