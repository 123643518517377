<template>
    <div>
        <data-table-component :url="url" :columns="columns" :keyword="keyword" order-by="4">
            <template slot="filters">
                <a href="/admin/events/certificates/create" class="button is-primary ml-auto">
                    <i class="fas fa-plus mr-3"></i> Create New Certificate
                </a>
            </template>
            <template slot-scope="{ row }" slot="actions">
                <td class="text-right whitespace-no-wrap">
                    <a :href="`/admin/events/certificates/${row.id}/view`" class="button is-primary is-small inline-block">
                        <i class="fas fa-eye"></i>
                    </a>
                    <a :href="`/admin/events/certificates/${row.id}/print`" target="_blank" class="button is-small is-tertiary inline-block">
                        <i class="fas fa-print"></i>
                    </a>
                    <a href="#" class="button is-small is-danger inline-block" @click.prevent="showDeleteModal(row)">
                        <i class="fas fa-trash"></i>
                    </a>
                </td>
            </template>
        </data-table-component>

        <modal :is-visible="isDeleteModalVisible" @hide="isDeleteModalVisible = !isDeleteModalVisible">
            <template slot="modal-title">
                Confirm Delete
            </template>
            <div slot="modal-body">
                <p>Are you sure you wish to delete this certificate?</p>
            </div>
            <div slot="modal-footer" class="flex justify-end">
                <button type="button" class="button is-secondary mr-2" @click.prevent="isDeleteModalVisible = false">
                    Cancel
                </button>
                <button type="button" class="button is-danger" @click="onDelete">
                    <i class="fas fa-trash mr-3"></i> Delete
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
    import moment from 'moment'

    import DataTableComponent from '../DataTableComponent'
    import Modal from "../Modal";

    export default {
        components: {
            DataTableComponent,
            Modal
        },

        props: {
            booking: {
                type: Object,
                required: false
            },
            user: {
                type: Object,
                required: false
            },
            event: {
                type: Object,
                required: false
            },
        },

        data() {
            return {
                keyword: '',

                columns: [
                    {
                        key: 'certificate_id',
                        label: 'Reference',
                    },
                    {
                        key: 'event_title',
                        label: 'Event',
                    },
                    {
                        key: 'booking_fullname',
                        label: 'Recipient',
                    },
                    {
                        key: 'cpd',
                        label: 'CPD',
                    },
                    {
                        key: 'created_at',
                        label: 'Issued',
                        html: (key, value, row) => {
                            return moment(row.created_at).format('DD-MM-YYYY');
                        }
                    }
                ],

                currentRow: {},
                isDeleteModalVisible: false,
            }
        },

        methods: {
            showDeleteModal(row) {
                this.currentRow = row;
                this.isDeleteModalVisible = true;
            },

            onDelete() {
                axios.delete('/api/events/certificates/' + this.currentRow.id + '/delete').then(() => {
                    window.EventBus.$emit('onTableRefresh');
                });

                this.isDeleteModalVisible = false;
            }
        },

        computed: {
            url() {
                let url = '/api/events/certificates/datatable?'

                let params = {}

                if (typeof this.booking !== 'undefined' && this.booking !== null) {
                    params.booking_id = this.booking.id
                }

                if (typeof this.user !== 'undefined' && this.user !== null) {
                    params.user_id = this.user.id
                }

                if (typeof this.event !== 'undefined' && this.event !== null) {
                    params.event_id = this.event.id
                }

                let query = Object.keys(params).map(function(k) {
                    return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
                });

                return url + query.join('&')
            }
        }
    }
</script>
