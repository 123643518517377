<template>
    <table class="nested-table">
        <thead>
            <tr>
                <th>Variant</th>
                <th>Status</th>
                <th>Changes</th>
                <th>Date</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <template v-for="(row, i) in rows">
                <tr :key="i + '_a'">
                    <td class="text-sm">{{ row.variant.code }} {{ row.variant.name }}</td>
                    <td class="text-sm">{{ row.status }}</td>
                    <td class="text-sm">{{ row.items_count }}</td>
                    <td class="text-sm">{{ row.created_at }}</td>
                    <td width="1"><button @click.prevent="toggle(row.id)"><i :class="'fas ' + (active.indexOf(row.id) !== -1 ? 'fa-minus-square' : 'fa-plus-square')"></i></button></td>
                </tr>
                <tr :key="i + '_b'" :class="active.indexOf(row.id) !== -1 ? '' : 'hidden'">
                    <td colspan="5">
                        <div class="inside mb-2">
                            <table>
                                <thead>
                                    <tr>
                                        <th>User</th>
                                        <th>Previous</th>
                                        <th>New</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, j) in row.items" :key="i + '_b_' + j">
                                        <td>
                                            <a target="_blank" :href="'/admin/users/' + item.user_id + '/edit'">{{ item.user.member_id }}</a>
                                        </td>
                                        <td>
                                            <a target="_blank" :href="'/admin/users/' + item.user_id + '/subscriptions/' + item.previous_subscription_id + '/edit'"><strong>{{ item.previous_subscription_id }}</strong></a>
                                            <br>
                                            £{{ item.previous_amount }} &mdash; {{ item.previous_subscription.year }}
                                        </td>
                                        <td v-if="item.subscription !== null">
                                            <a target="_blank" :href="'/admin/users/' + item.user_id + '/subscriptions/' + item.subscription_id + '/edit'"><strong>{{ item.subscription_id }}</strong></a>
                                            <br>
                                            £{{ item.amount }} &mdash; {{ item.subscription.year }}
                                            <br>
                                        </td>
                                        <td v-if="item.subscription === null">&mdash;</td>
                                        <td>{{ item.status }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: {
            rows: {
                type: Array
            },
        },

        data() {
            return {
                active: [],
            }
        },

        methods: {
            toggle(id) {
                let index = this.active.indexOf(id);
                if (index !== -1) {
                    this.active.splice(index, 1);
                } else {
                    this.active.push(id);
                }
            }
        }
    }
</script>
