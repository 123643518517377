<style>
</style>

<template>
    <Field @change="(key, value) => handleChange(key, value)" @duplicate="(object) => this.$emit('duplicate', object)" @delete="(object) => this.$emit('delete', object)" @error="(element, valid) => this.$emit('error', object, element, valid)" :objects="objects" :object="object" :definition="definition">
        <div class="flex flex-wrap text-gray-700 -m-2">
            <div class="w-full p-2 pt-2">
                <label for="caption" class="font-bold inline-block mb-2">Caption</label>
                <input type="text" id="caption" class="form-control mb-4" placeholder="Heading" :value="options.caption" @keyup="(event) => handleChange('caption', event.target.value)">
                <Editor v-if="!this.object.options.toggled" :value="this.object.options.description" @change="(event) => handleChange('description', event)"></Editor>
            </div>
        </div>
        <div class="-m-4 mt-4" v-if="!this.object.options.toggled">
            <div class="w-full p-4 border-solid border-t border-gray-300">
                <FilterMixin type="text" :options="this.options" :object="this.object" :objects="this.objects" @change="(key, value) => handleChange(key, value)"></FilterMixin>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'survey'">
                <FieldLimit :options="this.options" :event="this.event" @change="(key, value) => handleChange(key, value)"></FieldLimit>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'booking'">
                <MemberLimit :options="this.options" @change="(key, value) => handleChange(key, value)"></MemberLimit>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'booking'">
                <DateLimit :options="this.options" @change="(key, value) => handleChange(key, value)"></DateLimit>
            </div>
        </div>
    </Field>
</template>

<script>
    import Field from '../Field'

    import FilterMixin from './Mixins/Filter'
    import DateLimit from './Mixins/DateLimit'
    import MemberLimit from './Mixins/MemberLimit'
    import FieldLimit from './Mixins/FieldLimit'
    import DidNotAttend from './Mixins/DidNotAttend'
    import AppendCpd from './Mixins/AppendCpd'

    import Editor from './Mixins/Editor'

    export default {
        components: {
            Field,

            FilterMixin,
            DateLimit,
            MemberLimit,
            FieldLimit,
            DidNotAttend,
            AppendCpd,

            Editor
        },

        props: {
            type: {
                type: String,
                required: true
            },
            objects: {
                type: Array,
                default: () => {
                    return []
                }
            },
            object: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            event: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        data() {
            return {
                definition: Definition
            }
        },

        methods: {
            handleChange(key, value) {
                this.$emit('change', this.object.internal, key, value)
            }
        },

        computed: {
            options() {
                const defaults = {
                    caption: '',
                    description: '',
                    groups: {
                        member: true,
                        non_member: true
                    },
                }

                return Object.assign({}, defaults, this.object.options)
            }
        }
    }

    export const Definition = {
        name: 'text',
        title: 'Text',
        icon: 'paragraph'
    }
</script>
