<template>
    <div class="generic-select" v-click-outside="collapse">
        <input type="hidden" :name="name" :id="name" :value="choice">
        <input type="text"
            :class="[ 'form-control', isExpanded ? 'rounded-b-none outline-none' : '', isCompact ? 'px-3 py-1' : '' ]"
            :value="selection"
            :placeholder="placeholder"
            :disabled="disabled"
            @focus="expand"
            @keyup="onKeyUp"
            @keydown="onKeyDown"
            autocomplete="off">
        <div class="absolute inset-y-0 right-0 flex items-center" :class="[ isCompact ? 'px-3' : 'px-4' ]" v-show="nullable && !disabled && selection != ''">
            <span class="cursor-pointer" @click.prevent="clear">&times;</span>
        </div>
        <div style="border-color:#cbd5e0" class="absolute bg-white border border-t-0 rounded rounded-t-none overflow-auto w-full max-h-40 z-50" v-show="isExpanded">
            <ul>
                <li v-show="!hasResults">
                    <div class="p-2 text-sm px-4 cursor-pointer w-full" :class="[ isCompact ? 'p-1 px-3' : '' ]">
                        <span class="block">No Results</span>
                    </div>
                </li>
                <li class="generic-select-result" v-show="hasResults" v-for="(item, key) in filtered" v-bind:key="key" @click.prevent="select(item, key)">
                    <div class="text-sm cursor-pointer w-full" :class="[ (currentIndex != -1 && key === currentIndex) || (currentIndex == -1 && item[keyColumn] === choice) ? 'bg-gray-300' : '', isCompact ? 'p-1 px-3' : 'p-2 px-4' ]">
                        <span class="block" :class="[ descriptionColumn ? 'font-bold' : '' ]">{{ item[valueColumn] }}</span>
                        <span class="block" v-if="idColumn">{{ item[idColumn] }}</span>
                        <span class="block" v-if="descriptionColumn">{{ item[descriptionColumn] }}</span>
                        <span class="block" v-if="fullnameColumn">{{ item[fullnameColumn] }}</span>
                        <span class="block" v-if="memberIdColumn">{{ item[memberIdColumn] }}</span>
                        <span class="block" v-if="emailColumn">{{ item[emailColumn] }}</span>
                        <span class="block" v-if="dateColumn">{{ item[dateColumn] }}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String
            },

            loading: {
                type: Boolean,
                default: false
            },

            disabled: {
                type: Boolean,
                default: false
            },

            nullable: {
                type: Boolean,
                default: true
            },

            options: {
                type: Array|Object,
                required: true
            },

            compact: {
                type: Boolean,
                default: false
            },

            value: {
                type: Number|String
            },

            placeholder: {
                type: String,
                default: "Search..."
            },

            keyColumn: {
                type: String,
                default: 'id'
            },

            valueColumn: {
                type: String,
                default: 'name'
            },

            descriptionColumn: {
                type: String
            },

            dateColumn: {
                type: String
            },

            fullnameColumn: {
                type: String
            },

            idColumn: {
                type: String
            },

            emailColumn: {
                type: String
            },

            memberIdColumn: {
                type: String
            }
        },

        data() {
            let selection = ''
            let choices = []

            if (typeof this.options == 'object' && !(this.options instanceof Array)) {
                for (var key in this.options) {
                    if (this.options.hasOwnProperty(key)) {
                        choices.push({
                            [this.keyColumn]: key,
                            [this.valueColumn]: this.options[key]
                        })
                    }
                }
            } else {
                choices = this.options
            }

            if (this.value) {
                let keyColumn = this.keyColumn;
                let value = this.value;

                let item = choices.find(function (element) {
                    return element[keyColumn] == value;
                });

                if (item) {
                    selection = item[this.valueColumn];
                }
            }

            return {
                choice: this.value,
                currentIndex: -1,
                selection: selection,
                edited: false,
                hasResults: false,
                isCompact: this.compact,
                isExpanded: false,
                choices: choices,
            }
        },

        watch: {
            value (value) {
                this.choice = value

                let keyColumn = this.keyColumn;
                let choice = this.choice;

                let item = this.choices.find(function (element) {
                    return element[keyColumn] == choice;
                });

                if (item) {
                    this.selection = item[this.valueColumn];
                } else {
                    this.currentIndex = -1
                    this.selection = ''
                    this.edited = true
                    this.hasResults = false
                }
            },
            options (values) {
                let selection = ''
                let choices = []

                if (typeof this.options == 'object' && !(this.options instanceof Array)) {
                    for (var key in this.options) {
                        if (this.options.hasOwnProperty(key)) {
                            choices.push({
                                [this.keyColumn]: key,
                                [this.valueColumn]: this.options[key]
                            })
                        }
                    }
                } else {
                    choices = this.options
                }

                if (this.value) {
                    let keyColumn = this.keyColumn;
                    let value = this.value;

                    let item = choices.find(function (element) {
                        return element[keyColumn] == value;
                    });

                    if (item) {
                        selection = item[this.valueColumn];
                    }
                }

                this.selection = selection
                this.choices = choices
            }
        },

        methods: {
            collapse() {
                this.isExpanded = false;

                if (!this.selection && this.choice) {
                    let keyColumn = this.keyColumn;
                    let choice = this.choice;

                    let item = this.choices.find(function (element) {
                        return element[keyColumn] == choice;
                    });

                    if (item) {
                        this.selection = item[this.valueColumn];
                        this.edited = false
                    }
                }
            },

            expand() {
                this.isExpanded = true;
            },

            clear() {
                if (this.nullable) {
                    this.isExpanded = false;
                    this.choice = '';
                    this.currentIndex = -1;
                    this.selection = '';
                    this.edited = true;
                    this.hasResults = false;

                    this.$emit('change', '');
                }
            },

            select(item, index) {
                let keyColumn = this.keyColumn;

                // Doesn't work perfectly, loses position on filter
                this.currentIndex = this.choices.findIndex(function (element) {
                    return element[keyColumn] == item[keyColumn];
                });

                this.choice = item[this.keyColumn];
                this.selection = item[this.valueColumn];
                this.edited = false;

                this.collapse();

                this.$emit('change', this.choice);
                this.$emit('resetSelect')
            },

            onKeyDown(event) {
                if (this.isExpanded) {
                    if (event.key === 'ArrowDown') {
                        if ((this.currentIndex + 1) !== this.choices.length) {
                            return this.currentIndex++;
                        }
                    } else if (event.key === 'ArrowUp') {
                        if (this.currentIndex > 0) {
                            return this.currentIndex--;
                        }
                    } else if (event.key === 'Enter') {
                        event.preventDefault();

                        let item = this.choices[this.currentIndex];

                        return this.select(item, this.currentIndex);
                    }
                } else {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                    }

                    return this.expand();
                }
            },

            onKeyUp(event) {
                let old = this.selection;
                this.selection = event.target.value;

                if (this.selection != old) {
                    this.edited = true;
                }

                this.$emit('keyup', event.target.value);
            }
        },

        computed: {
            filtered() {
                if (this.edited) {
                    let valueColumn = this.valueColumn;
                    let descriptionColumn = this.descriptionColumn;
                    let selection = this.selection;
                    let fullnameColumn = this.fullnameColumn;
                    let idColumn = this.idColumn;
                    let memberIdColumn = this.memberIdColumn;
                    let emailColumn = this.emailColumn;
                    let dateColumn = this.dateColumn;

                    let results = this.choices.filter(function (element) {
                        let valueFilter = element[valueColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());

                        if (descriptionColumn && element[descriptionColumn] && fullnameColumn && element[fullnameColumn] && idColumn && element[idColumn] && emailColumn && element[emailColumn] != null && dateColumn && element[dateColumn]) {
                            let descriptionFilter = element[descriptionColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());
                            let fullnameFilter = element[fullnameColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());
                            let idFilter = element[idColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());
                            let emailFilter = element[emailColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());
                            let dateFilter = element[dateColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());
                            let memberIdFilter = element[memberIdColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());

                            return valueFilter || descriptionFilter || fullnameFilter || idFilter || emailFilter || dateFilter || memberIdFilter;
                        }

                        if (descriptionColumn && element[descriptionColumn] && fullnameColumn && element[fullnameColumn] && idColumn && element[idColumn] != null) {
                            let descriptionFilter = element[descriptionColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());
                            let fullnameFilter = element[fullnameColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());
                            let idFilter = element[idColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());
                            let memberIdFilter = element[memberIdColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());

                            return valueFilter || descriptionFilter || fullnameFilter || idFilter || memberIdFilter;
                        }


                        if (descriptionColumn && element[descriptionColumn] && fullnameColumn && element[fullnameColumn] != null) {
                            let descriptionFilter = element[descriptionColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());
                            let fullnameFilter = element[fullnameColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());

                            return valueFilter || descriptionFilter || fullnameFilter
                        }

                        if( memberIdColumn && element[memberIdColumn] != null ) {
                            let memberIdFilter = element[memberIdColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());

                            return valueFilter || memberIdFilter;
                        }

                        if(dateColumn && element[dateColumn] != null) {
                            let dateFilter = element[dateColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());

                            return valueFilter || dateFilter;
                        }

                        if (descriptionColumn && element[descriptionColumn] != null) {
                            let descriptionFilter = element[descriptionColumn].toString().trim().toLowerCase().includes(selection.toString().toLowerCase().trim());

                            return valueFilter || descriptionFilter
                        }


                        return valueFilter;
                    });

                    if (results.length > 0) {
                        this.hasResults = true;
                    } else {
                        this.hasResults = false;
                    }

                    return results;
                } else {
                    if (this.choices.length > 0) {
                        this.hasResults = true;
                    } else {
                        this.hasResults = false;
                    }

                    return this.choices;
                }
            }
        }
    }
</script>
