<template>
    <div class="tooltip-container">
        <span @click="isVisible = !isVisible">
            <i class="fas fa-question-circle"></i>
        </span>

        <transition name="fade-fast">
            <div class="" v-if="isVisible">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isVisible: false
            }
        },

        methods: {
            closeIfClickedOutside(event) {
                if (!event.target.closest('.tooltip-container')) {
                    this.isVisible = false;
                    document.removeEventListener('click', this.closeIfClickedOutside);
                }
            }
        },

        watch: {
            isVisible(visible) {
                if (visible) {
                    document.addEventListener('click', this.closeIfClickedOutside);
                }
            }
        },
    }
</script>
