<style>
</style>

<template>
    <div class="flex flex-wrap -m-2">
        <div class="p-2">
            <h4 class="font-bold text-gray-700">Default Value</h4>
            <p class="text-gray-700 mb-4">Enable this option if you want to specify a default value, and mark it compulsory to prevent the user from choosing another value.</p>

            <div class="flex flex-wrap">
                <label class="flex items-center">
                    <Toggle :value="options.has_default" @change="(event) => handleChange('has_default', event)"></Toggle>
                    <div class="ml-2 mb-0 form-label">Use a default value</div>
                </label>

                <label class="flex items-center" v-if="typeof options.has_default !== 'undefined' && options.has_default != ''">
                    <Toggle :value="options.default_compulsory" @change="(event) => handleChange('default_compulsory', event)"></Toggle>
                    <div class="ml-2 mb-0 form-label">Mark as compulsory</div>
                </label>
            </div>
        </div>

        <div class="w-full p-2" v-if="typeof options.has_default !== 'undefined' && options.has_default != ''">
            <div class="mb-0 form-label">Default value</div>

            <Toggle v-if="type == 'toggle'" class="mt-2" :value="options.default_value" @change="(event) => handleChange('default_value', event)"></Toggle>
            <input v-if="type == 'text'" type="text" id="default_value" class="form-control" :value="options.default_value" @keyup="(event) => handleChange('default_value', event.target.value)">
        </div>
    </div>
</template>

<script>
    import Toggle from "../../../GenericToggleComponent"
    import DateTime from "../../../DateTimeComponent"

    export default {
        components: {
            Toggle,
            DateTime,
        },

        props: {
            type: {
                type: String,
                default: 'text'
            },
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        methods: {
            handleChange(key, value) {
                this.$emit('change', key, value)
            }
        }
    }
</script>
