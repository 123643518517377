<template>
    <div>
        <VueApexCharts width="100%" type="line" :options="options" :series="series"></VueApexCharts>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        components: {
            VueApexCharts,
        },

        props: {
            data: {
                type: Array,
                required: true
            }
        },

        data() {
            this.data.reverse();

            return {
                options: {},
                series: [{
                    name: 'Subscriptions',
                    data: this.data
                }]
            }
        }
    };
</script>
