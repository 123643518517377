<template>
    <div class="toggle-component">
        <input type="hidden" :name="name" :value="status" />
        <div class="toggle">
            <span class="on" @click="status = '1'"></span>
            <span class="neutral" @click="status = null"></span>
            <span class="off" @click="status = '0'"></span>

            <span class="indicator" v-bind:class="{ 'on': status == '1', 'off': status == '0', 'neutral': status == null }" />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                required: false
            },
            value: {
                type: Boolean|null,
                default: null
            }
        },

        data() {
            return {
                status: this.value
            }
        }
    };
</script>
