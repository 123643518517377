<style>
</style>

<template>
    <div>
        <h4 class="font-bold text-gray-700">Display Date Range</h4>
        <p class="text-gray-700 mb-4">Choose a date range for displaying this field. If an end date is specified, you can choose what happens after that date.</p>

        <div class="flex flex-wrap -m-2">
            <div class="w-1/2 p-2">
                <label for="display_from" class="form-label">Display From</label>
                <DateTime id="display_from" name="display_from" type="datetime" :datetime="options.display_from" @input="(event) => handleChange('display_from', event)"></DateTime>
            </div>
            <div class="w-1/2 p-2">
                <label for="display_until" class="form-label">Display Until</label>
                <DateTime id="display_until" name="display_until" type="datetime" :datetime="options.display_until" @input="(event) => handleChange('display_until', event)"></DateTime>
            </div>

            <div class="w-full p-2" v-if="options.display_until != ''">
                <div class="flex flex-wrap -m-2">
                    <div class="p-2">
                        <label class="flex items-center">
                            <toggle :value="options.hide_after" @change="(event) => handleChange('hide_after', event)"></toggle>
                            <div class="ml-2 mb-0 form-label">Don't display after this date</div>
                        </label>
                    </div>
                    <div class="p-2">
                        <label class="flex items-center">
                            <toggle :value="options.notice_after" @change="(event) => handleChange('notice_after', event)"></toggle>
                            <div class="ml-2 mb-0 form-label">Display a notice after this date</div>
                        </label>
                    </div>
                </div>
                <div class="mt-4" v-if="options.notice_after">
                    <Editor :value="options.notice" @change="(event) => handleChange('notice', event)"></Editor>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Toggle from "../../../GenericToggleComponent"
    import DateTime from "../../../DateTimeComponent"
    import Editor from './Editor'

    export default {
        components: {
            Toggle,
            DateTime,
            Editor
        },

        props: {
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        methods: {
            handleChange(key, value) {
                this.$emit('change', key, value)

                if (key == 'notice_after' && value) {
                    this.$emit('change', 'hide_after', false)
                } else if (key == 'hide_after' && value) {
                    this.$emit('change', 'notice_after', false)
                }
            }
        }
    }
</script>
