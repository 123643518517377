<template>
    <div class="relative h-full">
        <div class="absolute w-full h-full z-10 flex justify-center items-center" v-if="loading">
            <div class="fa-2x">
                <i class="fas fa-spinner fa-spin text-gray-500"></i>
            </div>
        </div>
        <div class="mt-2 pb-10">
            <table class="-my-2 table data-table w-full">
                <thead>
                    <th class="pl-10 pr-5 text-sm">Event</th>
                    <th class="px-5 text-sm">Pending</th>
                    <th class="px-5 text-sm">Approved</th>
                    <th class="px-5 text-sm">Cancelled</th>
                    <th class="pl-5 pr-10"></th>
                </thead>
                <tbody>
                    <tr v-for="(event, key) in events" :key="key">
                        <td class="pl-10 pr-5 w-full align-middle">
                            <h4 class="leading-none font-bold text-l my-0">{{ event.title }}</h4>
                            <h5 v-if="event.start_at" class="leading-none mt-2 text-sm">{{ event.start_at.format('YYYY-MM-DD') + (event.end_at ? ' – ' + event.end_at.format('YYYY-MM-DD') : '') }}</h5>
                            <div>
                                <span class="leading-none mt-2 inline-block bg-gray-400 rounded px-2 py-1 text-xs capitalize">{{ event.type }}</span>
                                <span v-if="event.online" class="leading-none mt-2 inline-block bg-green-lighter rounded px-2 py-1 text-xs">Online</span>
                                <span v-if="!event.online" class="leading-none mt-2 inline-block bg-red-lighter rounded px-2 py-1 text-xs">Offline</span>
                            </div>
                        </td>
                        <td class="px-5 text-center align-middle">
                            <h3 class="leading-none text-3xl font-bold" :class="{ 'text-orange': event.pending_bookings_count > 0 }">{{ event.pending_bookings_count }}</h3>
                        </td>
                        <td class="px-5 align-middle text-center">
                            <h3 class="leading-none text-3xl font-bold" :class="{ 'text-green': event.approved_bookings_count > 0 }">{{ event.approved_bookings_count }}</h3>
                        </td>
                        <td class="px-5 align-middle text-center">
                            <h3 class="leading-none text-3xl font-bold" :class="{ 'text-red': event.cancelled_bookings_count > 0 }">{{ event.cancelled_bookings_count }}</h3>
                        </td>
                        <td class="pl-5 pr-10 align-middle text-center">
                            <a :href="[ '/admin/events/' + event.id + '/edit' ]" class="button is-small is-tertiary inline-block"><i class="fas fa-edit"></i></a>
                        </td>
                    </tr>
                    <tr v-if="events.length == 0">
                        <td class="px-10 text-center" style="padding-top:1.25rem" colspan="4">
                            <div>Sorry, no results were returned from this table</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        data() {
            return {
                loading: true,
                events: []
            }
        },

        created() {
            this.fetch();
        },

        methods: {
			fetch() {
                this.loading = true;

                axios.get('/api/events/dashboard', {
                    params: Object.assign({
                        limit: 5
                    }, this.params)
                }).then(response => {
                    this.events = []

                    response.data.forEach((data) => {
                        this.events.push({
                            id: data.id,
                            title: data.title,
                            type: data.type,
                            online: data.is_online,
                            start_at: data.start_at ? moment(data.start_at) : null,
                            end_at: data.end_at ? moment(data.end_at) : null,
                            url: '/admin/events/' + data.id,
                            pending_bookings_count: data.pending_bookings_count,
                            approved_bookings_count: data.approved_bookings_count,
                            cancelled_bookings_count: data.cancelled_bookings_count,
                        })
                    });

                    this.loading = false;
                })
            }
        }
    }
</script>
