<style>
</style>

<template>
    <div>
        <h4 class="font-bold text-gray-700">Capacity Limit</h4>
        <p class="text-gray-700 mb-4">Choose if you want to set a maximum capacity for this option, and what to do if that capacity is exceeded.</p>

        <div class="flex flex-wrap -m-2">
            <div class="flex flex-wrap p-2">
                <label class="flex items-center">
                    <Toggle :value="options.has_capacity" @change="(event) => handleChange('has_capacity', event)"></Toggle>
                    <div class="ml-2 mb-0 form-label">Use a capacity limit</div>
                </label>
            </div>

            <div class="w-full flex flex-wrap" v-if="typeof options.has_capacity !== 'undefined' && options.has_capacity != ''">
                <div class="w-1/2 p-2">
                    <label for="used_capacity" class="mb-0 form-label">Used Capacity</label>
                    <input type="number" min="1" id="used_capacity" class="form-control" :value="options.used_capacity" @keyup="(event) => handleChange('used_capacity', event.target.value)">
                </div>
                <div class="w-1/2 p-2">
                    <label for="max_capacity" class="mb-0 form-label">Maximum Capacity</label>
                    <input type="number" min="1" id="max_capacity" class="form-control" :value="options.max_capacity" @keyup="(event) => handleChange('max_capacity', event.target.value)">
                </div>
            </div>

            <div class="flex flex-wrap -m-2 p-2" v-if="typeof options.has_capacity !== 'undefined' && options.has_capacity != ''">
                <div class="p-2">
                    <label class="flex items-center">
                        <toggle :value="options.max_capacity_disable" @change="(event) => handleChange('max_capacity_disable', event)"></toggle>
                        <div class="ml-2 mb-0 form-label">Disable this option once reached</div>
                    </label>
                </div>
                <div class="p-2">
                    <label class="flex items-center">
                        <toggle :value="options.max_capacity_notice_enable" @change="(event) => handleChange('max_capacity_notice_enable', event)"></toggle>
                        <div class="ml-2 mb-0 form-label">Display a notice once reached</div>
                    </label>
                </div>
                <div class="w-full p-2" v-if="options.max_capacity_notice_enable">
                    <Editor :value="options.max_capacity_notice" @change="(event) => handleChange('max_capacity_notice', event)"></Editor>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Toggle from "../../../GenericToggleComponent"
    import Editor from './Editor'

    export default {
        components: {
            Toggle,
            Editor
        },

        props: {
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        methods: {
            handleChange(key, value) {
                this.$emit('change', key, value)
            }
        }
    }
</script>
