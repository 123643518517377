<template>
    <div>
        <div class="mb-5" v-if="showCalendar">
            <div class="flex flex-col lg:flex-row">
                <div class="flex flex-col lg:flex-row mr-5">
                    <a href="" class="button rounded-r-none is-tertiary" @click.prevent="showTable = true; showCalendar = false">
                        <i class="fas fa-list mr-3"></i> List
                    </a>
                    <a href="" class="button rounded-l-none is-primary" @click.prevent="showTable = false; showCalendar = true; calendarFetch()">
                        <i class="fas fa-calendar mr-3"></i> Calendar
                    </a>
                    <div class="flex flex-row items-center ml-5">
                        <a href="" class="button is-primary rounded-r-none h-full" @click.prevent="previousMonth"><i class="fas fa-chevron-left"></i></a>
                        <a href="" class="button is-tertiary rounded-none h-full" @click.prevent="currentMonth">Today</a>
                        <a href="" class="button is-primary rounded-l-none h-full" @click.prevent="nextMonth"><i class="fas fa-chevron-right"></i></a>
                        <div class="block text-xl ml-5"><span class="font-bold">{{ this.calendarDate.format('MMMM') }}</span> {{ this.calendarDate.format('YYYY') }}</div>
                    </div>
                </div>
                <div class="flex flex-col lg:flex-row lg:justify-between flex-grow">
                    <a href="/admin/events/create" class="button is-primary ml-auto">
                        <i class="fas fa-plus mr-3"></i> Create New Event
                    </a>
                </div>
            </div>
        </div>
        <div>
            <div v-if="showTable">
                <data-table-component url="/api/events/datatable" :columns="columns" :keyword="keyword" order-by="3" sort-by="desc">
                    <template slot="prepend">
                        <a href="" class="button rounded-r-none is-primary" @click.prevent="showTable = true; showCalendar = false">
                            <i class="fas fa-list mr-3"></i> List
                        </a>
                        <a href="" class="button rounded-l-none mr-5 is-tertiary" @click.prevent="showTable = false; showCalendar = true; calendarFetch()">
                            <i class="fas fa-calendar mr-3"></i> Calendar
                        </a>
                    </template>
                    <template slot="filters">
                        <a href="/admin/events/create" class="button is-primary ml-auto">
                            <i class="fas fa-plus mr-3"></i> Create New Event
                        </a>
                    </template>
                    <template slot-scope="{ row }" slot="actions">
                        <td class="text-right">
                            <a :href="`/admin/events/${row.id}/edit`" class="button is-small is-tertiary inline-block">
                                <i class="fas fa-edit"></i>
                            </a>
                        </td>
                    </template>
                </data-table-component>
            </div>
            <div class="relative bg-white rounded shadow" v-if="showCalendar">
                <div class="absolute w-full h-full z-10 flex justify-center items-center" v-if="calendarLoading">
                    <div class="fa-2x">
                        <i class="fas fa-spinner fa-spin text-gray-500"></i>
                    </div>
                </div>
                <calendar-view :show-date="calendarDatetime" class="theme-default theme-tall theme-borderless" :events="calendarEvents" @click-event="onClickItem"></calendar-view>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    import DataTableComponent from '../DataTableComponent'
    import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'

    export default {
        components: {
            DataTableComponent,
            CalendarView,
            CalendarViewHeader
        },

        data() {
            return {
                showTable: true,
                showCalendar: false,

                columns: [
                    {
                        key: 'id',
                        label: 'ID',
                        wrap: false
                    },
                    {
                        key: 'title',
                        label: 'Title',
                    },
                    {
                        key: 'type',
                        label: 'Type',
                        html: function (x, y) {
                            if (y !== null && y) {
                                return y.charAt(0).toUpperCase() + y.slice(1);
                            }

                            return '-';
                        }
                    },
                    {
                        key: 'start_at',
                        label: 'Starts',
                        html: function (x, y) {
                            if (y !== null && y) {
                                let date = moment(y);

                                if (date.hour() == 0 && date.minute() == 0 && date.second() == 0) {
                                    return date.format('YYYY-MM-DD');
                                }

                                return date.format('YYYY-MM-DD HH:mm')
                            }

                            return '–';
                        }
                    },
                    {
                        key: 'end_at',
                        label: 'Ends',
                        html: function (x, y) {
                            if (y !== null && y) {
                                let date = moment(y);

                                if (date.hour() == 0 && date.minute() == 0 && date.second() == 0) {
                                    return date.format('YYYY-MM-DD');
                                }

                                return date.format('YYYY-MM-DD HH:mm')
                            }

                            return '–';
                        }
                    },
                    {
                        key: 'close_at',
                        label: 'Registration Closes',
                        wrap: false,
                        html: function (x, y) {
                            if (y !== null && y) {
                                let date = moment(y);

                                if (date.hour() == 0 && date.minute() == 0 && date.second() == 0) {
                                    return date.format('YYYY-MM-DD');
                                }

                                return date.format('YYYY-MM-DD HH:mm')
                            }

                            return '–';
                        }
                    },
                    {
                        key: 'is_online',
                        label: 'Online',
                        html: function (x, y) {
                            if (y !== null && y) {
                                return '<i class="fas fa-check text-green"></i>';
                            }

                            return '<i class="fas fa-times text-red"></i>';
                        }
                    }
                ],

                keyword: '',

                currentRow: {},

                calendarDate: moment(),
                calendarDatetime: new Date(),
                calendarLoading: true,
                calendarEvents: []
            }
        },

        created() {
            this.calendarFetch();
        },

        methods: {
			calendarFetch() {
                this.calendarLoading = true;

                axios.get('/api/events/calendar', {
                    params: Object.assign({
                        year: this.calendarDate.year(),
                        month: this.calendarDate.month() + 1,
                    }, this.params)
                }).then(response => {
                    this.calendarEvents = []

                    response.data.forEach((data) => {
                        this.calendarEvents.push({
                            id: data.id,
                            startDate: data.start_at,
                            endDate: data.end_at,
                            title: data.title,
                            url: '/admin/events/' + data.id + '/edit'
                        })
                    });

                    this.calendarDatetime = this.calendarDate.toDate();

                    this.calendarLoading = false;
                })
            },

            previousMonth() {
                this.calendarDate = this.calendarDate.subtract(1, 'month');

                this.calendarFetch();
            },
            currentMonth() {
                this.calendarDate = moment();

                this.calendarFetch();
            },
            nextMonth() {
                this.calendarDate = this.calendarDate.add(1, 'month');

                this.calendarFetch();
            },

            onClickItem(e) {
                window.location.href = e.originalEvent.url;
            }
        }
    }
</script>
