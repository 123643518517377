<style>
</style>

<template>
    <Field @change="(key, value) => handleChange(key, value)" @duplicate="(object) => this.$emit('duplicate', object)" @delete="(object) => this.$emit('delete', object)" @error="(element, valid) => this.$emit('error', object, element, valid)" :objects="objects" :object="object" :definition="definition">
        <div class="flex flex-wrap text-gray-700 -m-2">
            <div class="w-full p-2">
                <label for="caption" class="font-bold inline-block mb-2">Caption</label>
                <input type="text" id="caption" class="form-control" :value="options.caption" @keyup="(event) => handleChange('caption', event.target.value)">
            </div>
            <div class="w-full p-2" v-if="!this.object.options.toggled">
                <label for="description" class="font-bold inline-block mb-2">Description</label>
                <textarea id="description" class="form-control" :value="options.description" @keyup="(event) => handleChange('description', event.target.value)"></textarea>
            </div>
            <div class="w-full p-2" v-if="!this.object.options.toggled">
                <label for="placeholder" class="font-bold inline-block mb-2">Placeholder</label>
                <input type="text" id="placeholder" class="form-control" :value="options.placeholder" @keyup="(event) => handleChange('placeholder', event.target.value)">
            </div>
            <div class="w-full p-2" v-if="!this.object.options.toggled">
                <label class="flex items-center">
                    <Toggle :value="options.multiline" @change="(value) => handleChange('multiline', value)"></Toggle>
                    <div class="ml-2 mb-0 form-label">Accept multiline text</div>
                </label>
            </div>
        </div>
        <div class="-m-4 mt-4" v-if="!this.object.options.toggled">
            <div class="w-full p-4 border-solid border-t border-gray-300">
                <RequiredMixin :options="this.options" @change="(key, value) => handleChange(key, value)"></RequiredMixin>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300">
                <FilterMixin type="text" :options="this.options" :object="this.object" :objects="this.objects" @change="(key, value) => handleChange(key, value)"></FilterMixin>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300">
                <DefaultValue :options="this.options" @change="(key, value) => handleChange(key, value)"></DefaultValue>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'survey'">
                <FieldLimit :options="this.options" :event="this.event" @change="(key, value) => handleChange(key, value)"></FieldLimit>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'survey'">
                <DidNotAttend :options="this.options" @change="(key, value) => handleChange(key, value)"></DidNotAttend>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'survey'">
                <AppendCpd :options="this.options" @change="(key, value) => handleChange(key, value)"></AppendCpd>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'booking'">
                <MemberLimit :options="this.options" @change="(key, value) => handleChange(key, value)"></MemberLimit>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'booking'">
                <DateLimit :options="this.options" @change="(key, value) => handleChange(key, value)"></DateLimit>
            </div>
        </div>
    </Field>
</template>

<script>
    import Toggle from '../../GenericToggleComponent'
    import Field from '../Field'

    import RequiredMixin from './Mixins/Required'
    import FilterMixin from './Mixins/Filter'
    import DateLimit from './Mixins/DateLimit'
    import MemberLimit from './Mixins/MemberLimit'
    import DefaultValue from './Mixins/DefaultValue'
    import FieldLimit from './Mixins/FieldLimit'
    import DidNotAttend from './Mixins/DidNotAttend'
    import AppendCpd from './Mixins/AppendCpd'

    export default {
        components: {
            Toggle,

            Field,

            RequiredMixin,
            FilterMixin,
            DateLimit,
            MemberLimit,
            DefaultValue,
            FieldLimit,
            DidNotAttend,
            AppendCpd
        },

        props: {
            type: {
                type: String,
                required: true
            },
            objects: {
                type: Array,
                default: () => {
                    return []
                }
            },
            object: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            event: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        data() {
            return {
                definition: Definition
            }
        },

        methods: {
            handleChange(key, value) {
                this.$emit('change', this.object.internal, key, value)
            }
        },

        computed: {
            options() {
                const defaults = {
                    caption: '',
                    description: '',
                    allow_did_not_attend: true,
                    groups: {
                        member: true,
                        non_member: true
                    },
                }

                return Object.assign({}, defaults, this.object.options)
            }
        }
    }

    export const Definition = {
        name: 'input',
        title: 'Input',
        icon: 'comment-alt'
    }
</script>
