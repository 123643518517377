<template>
    <div class="border border-gray-300 p-4 mt-4" v-if="visible || this.admin">
        <div v-if="this.admin && !visible" class="bg-red font-bold text-white px-4 py-2 -mt-4 -mx-4 mb-4">
            Only visible to administrators
        </div>

        <div class="border border-red px-4 py-3 mb-4" v-if="notice" v-html="notice"></div>

        <h1 class="text-xl font-normal text-black leading-none m-0 block" v-if="this.object.options.caption" :class="[ 'form-label', this.object.options.required ? 'is-required' : null ]">{{ this.object.options.caption }}</h1>
        <p v-if="this.object.options.description" v-html="this.object.options.description"></p>

        <div class="mt-4" v-if="getChildValue('did_not_attend') != true">
            <input v-if="!this.object.options.multiline" type="text" class="form-control" :value="getValue()" :placeholder="this.object.options.placeholder" @input="(event) => handleChange(event.target.value)" :disabled="disabled || getChildValue('did_not_attend') == true">
            <textarea :ref="`textarea-${this.object.options.name}`" v-if="this.object.options.multiline" class="form-control resize-none" :value="getValue()" :placeholder="this.object.options.placeholder" @input="(event) => handleChange(event.target.value)" :disabled="disabled || getChildValue('did_not_attend') == true"></textarea>
            <span class="invalid-input" v-if="getError()">{{ getError() }}</span>
        </div>

        <div class="mt-4" v-if="this.object.options.allow_did_not_attend">
            <label class="flex items-center">
                <Toggle :value="getChildValue('did_not_attend')" @change="(value) => interceptDidNotAttend(value)" :disabled="disabled"></Toggle>
                <div class="ml-2 mb-0 form-label">I did not take part in or attend this part of the event</div>
            </label>
        </div>
    </div>
</template>

<script>
    import autosize from 'autosize'

    import Toggle from "../../../../GenericToggleComponent"

    import { default as field } from '../Mixins/Field'

    export default {
        mixins: [
            field
        ],

        components: {
            Toggle
        },

        mounted() {
            if (this.object.options.multiline) {
                autosize(this.$refs['textarea-' + this.object.options.name])
            }
        },

        updated() {
            if (this.object.options.multiline) {
                autosize(this.$refs['textarea-' + this.object.options.name])
            }
        },

        methods: {
            interceptDidNotAttend(value) {
                this.handleChildChange('did_not_attend', value)
                if (value == true) {
                    if (this.object.options.has_default == true && this.object.options.default_compulsory == true) {
                        this.handleChange(this.object.options.default_value)
                    } else {
                        this.handleChange(null)
                    }
                }
            }
        }
    }
</script>
