<template>
    <input type="text" :name="name" class="form-control pl-10" v-model="formattedPrice" step="0.00" @focus="isActive = true" @blur="isActive = false">
</template>

<script>
    import accounting from "accounting";

    export default {
        props: {
            name: {
                type: String,
                default: 'price'
            },
            price: {
                type: Number|String,
                required: true
            }
        },

        data() {
            return {
                isActive: false,
                value: this.price
            }
        },

        computed: {
            formattedPrice: {
                get: function () {
                    if (this.isActive) {
                        return this.value.toString();
                    } else {
                        return accounting.formatMoney(this.value, '')
                    }
                },
                set: function(value) {
                    value = parseFloat(value);

                    if (isNaN(value)) {
                        value = 0;
                    }

                    this.value = value;
                }
            }
        },

        watch: {
            price: function(n, o) {
                this.value = this.price
            },
            value: function(n, o) {
                this.$emit('onChange', n);
            }
        }
    }
</script>
