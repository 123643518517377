<style>
</style>

<template>
    <div>
        <h4 class="font-bold text-gray-700">Linked Booking Field</h4>
        <p class="text-gray-700 mb-4">If this field is linked to a booking field, it is possible to only show this field if a user has or has not selected the field while booking.</p>

        <div class="flex items-center border rounded border-gray-400 mb-2 p-3" v-for="(item, index) in selections" :key="index">
            <div class="flex-1">
                <h4 class="font-bold text-gray-700 leading-none">{{ item.title }}</h4>
            </div>
            <button class="button is-danger text-sm ml-auto" @click.prevent="() => removeSelected(index)"><i class="fa fa-trash"></i></button>
        </div>

        <div class="flex flex-wrap -m-2 mb-4">
            <div class="w-full p-2">
                <label class="form-label">Field</label>
                <div class="relative">
                    <div class="bg-smoke-light absolute rounded inset-0 z-50" v-if="loading">
                        <div class="flex items-center pr-2 absolute inset-y-0 right-0">
                            <i class="fa fa-spinner fa-spin"></i>
                        </div>
                    </div>
                    <Select :options="selectLinkedFields" value="" placeholder="Search Fields..." keyColumn="id" valueColumn="title" descriptionColumn="description" :loading="loading" @change="handleSelect"></Select>
                </div>
            </div>
        </div>

        <div class="flex flex-wrap -m-2">
            <div class="p-2">
                <label class="flex items-center">
                    <toggle :value="options.show_filled" @change="(event) => handleChange('show_filled', event)"></toggle>
                    <div class="ml-2 mb-0 form-label">Show if filled</div>
                </label>
            </div>
            <div class="p-2 pl-0">
                <label class="flex items-center">
                    <toggle :value="options.show_empty" @change="(event) => handleChange('show_empty', event)"></toggle>
                    <div class="ml-2 mb-0 form-label">Show if empty</div>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import {debounce} from 'lodash'

    import Select from '../../../GenericSelectComponent'
    import Toggle from "../../../GenericToggleComponent"

    export default {
        components: {
            Select,
            Toggle
        },

        props: {
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            event: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        data() {
            return {
                loading: false,
                fields: [],
                selections: []
            }
        },

        created() {
            debounce(() => {
                this.loading = true

                axios.get('/api/events/' + this.event.id + '/booking').then(response => {
                    this.fields = []

                    let selections = [];

                    for (let i = 0; i < response.data.objects.length; i++) {
                        let object = response.data.objects[i]
                        this.fields.push({
                            id: object.options.name,
                            title: object.options.name,
                            description: object.type
                        })
                    }

                    if( typeof this.options.linked_fields !== 'undefined' && this.options.linked_fields !== null ) {
                        for( let i = 0; i < this.options.linked_fields.length; i++ ) {
                            for( let j = 0; j < this.fields.length; j++ ) {
                                if( this.fields[j].title == this.options.linked_fields[i].title ) {
                                    selections.push( this.options.linked_fields[i] );
                                }
                            }
                        }
                    }

                    this.selections = selections;

                    this.loading = false
                })
            }, 200)()
        },

        methods: {
            getLinkedFields(selections) {
                var out = [];
                var excluded = [];

                for( var key in this.fields ) {
                    for( var k in selections ) {
                        if( selections[k].title == this.fields[key].title ) {
                            excluded.push(this.fields[key]);
                        }
                    }
                }

                for( var key in this.fields ) {
                    if( !excluded.includes( this.fields[key] ) ) {
                        out.push( {
                            id: this.fields[key].id,
                            title: this.fields[key].title,
                            description: this.fields[key].description
                        } );
                    }
                }

                return out;
            },
            handleChange(key, value) {
                if (key == 'show_filled') {
                    this.$emit('change', 'show_empty', false)
                } else if (key == 'show_empty') {
                    this.$emit('change', 'show_filled', false)
                }

                this.$emit('change', key, value)
            },
            handleSelect(value) {
                this.selections.push({
                    title: value
                });

                this.handleChange('linked_fields', this.selections);
            },
            removeSelected(index) {
                this.selections.splice(index, 1);

                this.handleChange('linked_fields', this.selections);
            }
        },

        computed: {
            selectLinkedFields() {
                return this.getLinkedFields(this.selections);
            }
        }
    }
</script>
