<template>
    <li v-click-outside="handleClickOutside" :class="this.className">
        <a :href="this.url" @click.prevent="handleClick" :class="[ this.depth == 0 ? 'py-1 md:py-5 font-bold text-gray-600 flex items-center text-base cursor-pointer hover:text-green block w-full' : 'text-white hover:text-gray-300 flex items-center inline cursor-pointer block w-full', this.depth > 1 ? 'py-3' : 'py-5', 'px-5' ]">
            <i :class="[ 'mr-3', this.depth == 0 ? 'text-gray-400' : '', this.icon ]" v-if="this.icon"></i>
            {{ this.name }}
            <i :class="['fas fa-chevron-down text-xs ml-3' ]" v-if="this.routes.length !== 0"></i>
        </a>
        <transition name="slide">
        <div :class="[ 'w-full block md:flex md:flex-col relative md:absolute left-0 right-0 z-50', this.depth == 0 ? 'bg-blue' : 'bg-blue-lighter' ]" v-if="routes.length !== 0 && open">
            <div class="w-full container mx-auto">
                <ul class="flex flex-col md:flex-row">
                    <MegaMenuItemComponent v-for="(route, index) in this.routes" :key="index" :depth="depth + 1" :name="route.name" :url="typeof route.url !== 'undefined' ? route.url : null" :icon="typeof route.icon !== 'undefined' ? route.icon : null" :className="typeof route.class !== 'undefined' ? route.class : null" :routes="typeof route.children !== 'undefined' ? route.children : []"></MegaMenuItemComponent>
                </ul>
            </div>
        </div>
        </transition>
    </li>
</template>

<script>
    export default {
        name: 'MegaMenuItemComponent',

        props: {
            name: {
                type: String,
                required: true
            },
            url: {
                type: String,
                required: false
            },
            icon: {
                type: String,
                required: false
            },
            className: {
                type: String,
                default: ''
            },
            depth: {
                type: Number,
                default: 0
            },
            routes: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },

        data() {
            return {
                open: false
            }
        },

        methods: {
            handleClick(url) {
                if (this.routes.length !== 0) {
                    this.open = !this.open
                } else if (this.url !== null) {
                    window.location.href = this.url
                }
            },
            handleClickOutside() {
                this.open = false
            }
        }
    }
</script>
