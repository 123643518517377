<template>
    <div>
        <div>
            <label class="form-label">Talk</label>
            <div class="relative">
                <div class="bg-smoke-light absolute rounded inset-0 z-50" v-if="talksLoading"></div>
                <GenericSelectComponent :options="talks" :value="typeof slot.talk_ids[0] !== undefined ? slot.talk_ids[0] : null" placeholder="Search Talks..." keyColumn="id" valueColumn="title" descriptionColumn="speaker" :loading="talksLoading" @change="(value) => handleChange('talk_ids', [value])"></GenericSelectComponent>
            </div>
        </div>
    </div>
</template>

<script>
    import GenericSelectComponent from '../../GenericSelectComponent'

    export default {
        components: {
            GenericSelectComponent
        },

        props: {
            event: {
                type: Object,
                required: true
            },
            data: {
                type: Object,
                required: true
            },
            talks: {
                type: Array,
                default: () => {
                    return []
                }
            },
            talksLoading: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                slot: Object.assign({}, {
                    talk_ids: []
                }, this.data)
            }
        },

        methods: {
            handleChange(name, value) {
                this.$emit('change', name, value)
            }
        },

        computed: {}
    }
</script>
