import { DateTime } from 'luxon'

export default {
    props: {
        form: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        admin: {
            type: Boolean,
            default: false
        },

        user: {
            type: Object,
            required: false
        },
        userGroup: {
            type: String,
            required: false
        },
        event: {
            type: Object,
            required: true
        },

        field: {
            type: Object,
            required: true
        },
        object: {
            type: Object,
            required: true
        },

        map: {
            type: Object,
            required: true
        },
        layout: {
            type: Array,
            required: true
        },
        objects: {
            type: Array,
            required: true
        },
        values: {
            type: Object,
            required: true
        }
    },

    data() {
        let flipped = {}

        Object.keys(this.map).forEach(key => {
            flipped[this.map[key]] = key
        })

        return {
            flipped: flipped,
            membertype: null
        }
    },

    created() {
        this.$nextTick(function () {
            this.object.visible = this.$el.nodeName !== "#comment" && this.visible
        })

        window.EventBus.$on('preview_membertype', (name) => {
            this.membertype = name;
        })
    },

    updated() {
        this.$nextTick(function () {
            this.object.visible = this.$el.nodeName !== "#comment" && this.visible
        })
    },

    methods: {
        handleChange(value) {
            if (!this.readonly) {
                window.EventBus.$emit(this.form + '_value', this.object.options.name, this.object.options.name, value)
            }
        },
        handleChildChange(name, value) {
            if (!this.readonly) {
                window.EventBus.$emit(this.form + '_value', this.object.options.name, this.object.options.name + '::' + name, value)
            }
        },

        getValue() {
            // if (this.object.type == 'toggle' && typeof this.object.options.display_from !== 'undefined' && this.object.options.display_from !== null && this.object.options.display_from != '') {
            //     let display_from = DateTime.fromFormat(this.object.options.display_from, 'yyyy-MM-dd TT')
            //     if (display_from > DateTime.local()) {
            //         return null
            //     }
            // }

            // if (this.object.type == 'toggle' && typeof this.object.options.hide_after !== 'undefined' && this.object.options.hide_after && typeof this.object.options.display_until !== 'undefined' && this.object.options.display_until !== null && this.object.options.display_until != '') {
            //     let display_until = DateTime.fromFormat(this.object.options.display_until, 'yyyy-MM-dd TT')
            //     if (DateTime.local() >= display_until) {
            //         return null
            //     }
            // }

            if (this.object.type == 'toggle' && typeof this.values[this.object.options.name] !== 'undefined' && this.values[this.object.options.name].value == 0) {
                return false
            } else if (this.object.type == 'toggle' && typeof this.values[this.object.options.name] !== 'undefined' && this.values[this.object.options.name].value == 1) {
                return true
            }

            return typeof this.values[this.object.options.name] !== 'undefined' ? this.values[this.object.options.name].value : null
        },
        getChildValue(name) {
            return typeof this.values[this.object.options.name + '_' + name] !== 'undefined' ? this.values[this.object.options.name + '_' + name].value : null
        },

        getError() {
            if (!this.readonly) {
                if (typeof this.object.errors['default'] !== 'undefined' && this.object.errors['default'].length !== 0) {
                    return this.object.errors['default'][0]
                }
            }

            return null
        },
        getChildError(name) {
            if (!this.readonly) {
                if (typeof this.object.errors[name] !== 'undefined' && this.object.errors[name].length !== 0) {
                    return this.object.errors[name][0]
                }
            }

            return null
        }
    },

    watch: {
        // visible: {
        //     handler: function(n, o) {
        //         window.EventBus.$emit(this.form + '_visible', this.object.options.name, n)
        //     },
        // },
        disabled: {
            handler: function(n, o) {
                window.EventBus.$emit(this.form + '_enabled', this.object.options.name, !n)
            },
        }
    },

    computed: {
        visible() {
            if (this.object.options.enabled == false) {
                window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                return false
            }

            if (typeof this.object.options.filter_fields !== 'undefined' && this.object.options.filter_fields.length !== 0) {
                let options_met = false;

                for (let i = 0; i < this.object.options.filter_fields.length; i++) {
                    let field_internal = this.objects.find(obj => obj.options.name == this.object.options.filter_fields[i].name).internal
                    let field_visible = this.objects[field_internal].visible
                    let field_has_value = typeof this.values[this.flipped[field_internal]] !== 'undefined' && this.values[this.flipped[field_internal]].value !== null && this.values[this.flipped[field_internal]].value !== false && this.values[this.flipped[field_internal]].value !== 0 && this.values[this.flipped[field_internal]].value.toString().trim() !== ''

                    if (this.object.options.filter_fields[i].filled == true) {
                        if (field_visible && field_visible != null && field_has_value) {
                            options_met = true;
                        }
                    } else {
                        if (!field_visible || field_visible == null || !field_has_value) {
                            options_met = true;
                        }
                    }
                }

                
                if (!options_met) {
                    window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                    
                    let children = this.object.children
                    var _self = this
                    
                    // children.forEach((el) => {
                    //     let child = _self.objects[el].options.name
                    //     window.EventBus.$emit(_self.form + '_visible', child, false)
                    //     window.EventBus.$emit(_self.form + '_value', child, child, false)
                    // })

                    return false
                }
            }

            if( typeof this.object.options.filter_member_types !== 'undefined' && this.object.options.filter_member_types.length !== 0 ) {
                let options_met = false;

                for( let i = 0; i < this.object.options.filter_member_types.length; i++ ) {
                    if( this.userGroup == this.object.options.filter_member_types[i].name || this.object.options.filter_member_types[i].name == this.membertype ) {
                        options_met = true;
                    }
                }

                if (!options_met) {
                    window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)

                    let children = this.object.children
                    var _self = this

                    // children.forEach((el) => {
                    //     let child = _self.objects[el].options.name
                    //     window.EventBus.$emit(_self.form + '_visible', child, false)
                    // })
                    
                    return false
                }
            }

            if (typeof this.object.options.groups !== 'undefined') {
                let member = this.object.options.groups.member !== 'undefined' && this.object.options.groups.member ? true : false
                let non_member = this.object.options.groups.non_member !== 'undefined' && this.object.options.groups.non_member ? true : false

                // Check the user's current subscription is in date, allowing for a one month grace period at the start of a new year
                let checkSubYear = true

                let currentDate = new Date()
                let currentYear = currentDate.getFullYear()
                let currentMonth = currentDate.getMonth()

                let subYear = 0

                if (typeof this.user !== 'undefined' && this.user !== null && typeof this.user.subscription !== 'undefined') {
                    subYear = this.user.subscription.year
                } else if (typeof this.user !== 'undefined' && this.user !== null && typeof this.user.subscription_year !== 'undefined') {
                    subYear = this.user.subscription_year
                }

                if (currentYear - subYear > 1 || (currentYear - subYear == 1 && currentMonth != 0)) checkSubYear = false

                let is_user_member = typeof this.user !== 'undefined' && this.user !== null && typeof this.user.active_subscription_id !== 'undefined' && this.user.active_subscription_id !== null && checkSubYear

                if (member && !non_member && !is_user_member) {
                    window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                    return false
                } else if (!member && non_member && is_user_member) {
                    window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                    return false
                } else if (!member && !non_member) {
                    window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                    return false;
                }
            }

            if (typeof this.object.options.display_from !== 'undefined' && this.object.options.display_from !== null && this.object.options.display_from != '') {
                let display_from = DateTime.fromFormat(this.object.options.display_from, 'yyyy-MM-dd TT')
                if (display_from > DateTime.local()) {
                    window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                    return false
                }
            }

            if (typeof this.object.options.hide_after !== 'undefined' && this.object.options.hide_after && typeof this.object.options.display_until !== 'undefined' && this.object.options.display_until !== null && this.object.options.display_until != '') {
                let display_until = DateTime.fromFormat(this.object.options.display_until, 'yyyy-MM-dd TT')
                if (DateTime.local() >= display_until) {
                    window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                    return false
                }
            }

            window.EventBus.$emit(this.form + '_visible', this.object.options.name, true)
            return true
        },

        notice() {
            if (!this.readonly) {
                if (typeof this.object.options.notice_after !== 'undefined' && this.object.options.notice_after && typeof this.object.options.display_until !== 'undefined' && this.object.options.display_until !== null && this.object.options.display_until != '') {
                    let display_until = DateTime.fromFormat(this.object.options.display_until, 'yyyy-MM-dd TT')
                    if (DateTime.local() >= display_until) {
                        return this.object.options.notice
                    }
                }

                if (typeof this.object.options.max_capacity_notice_enable !== 'undefined' && this.object.options.max_capacity_notice_enable && this.capacity !== null && this.capacity === 0) {
                    return this.object.options.max_capacity_notice
                }
            }

            return null
        },

        cost() {
            if (typeof this.object.options.price !== 'undefined' || typeof this.object.options.discount !== 'undefined') {
                let cost = 0

                if (typeof this.object.options.price !== 'undefined') {
                    cost += this.object.options.price
                }

                if (typeof this.object.options.discount !== 'undefined') {
                    cost -= this.object.options.discount
                }

                return cost
            }

            return null
        },

        capacity() {
            if (typeof this.object.options.has_capacity !== 'undefined' && this.object.options.has_capacity && typeof this.object.options.max_capacity !== 'undefined' && this.object.options.max_capacity !== null && !isNaN(this.object.options.max_capacity) && this.object.options.max_capacity > -1) {
                let max_capacity = this.object.options.max_capacity
                let used_capacity = typeof this.object.options.used_capacity !== 'undefined' && this.object.options.used_capacity !== null || this.object.options.used_capacity >= 0 ? this.object.options.used_capacity : 0

                let remaining_capacity = max_capacity - used_capacity
                if (remaining_capacity < 0) {
                    return 0
                }

                return remaining_capacity
            }

            return null
        },

        usedCapacity() {
            if (typeof this.object.options.has_capacity !== 'undefined' && this.object.options.has_capacity && typeof this.object.options.max_capacity !== 'undefined' && this.object.options.max_capacity !== null && !isNaN(this.object.options.max_capacity) && this.object.options.max_capacity > -1) {
                let used_capacity = typeof this.object.options.used_capacity !== 'undefined' && this.object.options.used_capacity !== null || this.object.options.used_capacity >= 0 ? this.object.options.used_capacity : 0

                return used_capacity
            }

            return null
        },

        disabled() {
            if (this.object.options.enabled == false) {
                window.EventBus.$emit(this.form + '_enabled', this.object.options.name, false)
                return true
            }

            if (this.readonly) {
                window.EventBus.$emit(this.form + '_enabled', this.object.options.name, false)
                return true
            }

            if (typeof this.object.options.has_default !== 'undefined' && this.object.options.has_default && typeof this.object.options.default_compulsory !== 'undefined' && this.object.options.default_compulsory) {
                window.EventBus.$emit(this.form + '_enabled', this.object.options.name, false)
                return true
            }

            if (this.object.type == 'toggle' && typeof this.object.options.max_capacity_disable !== 'undefined' && this.object.options.max_capacity_disable && this.capacity !== null && this.capacity === 0) {
                window.EventBus.$emit(this.form + '_enabled', this.object.options.name, false)
                return true
            }

            if (typeof this.object.options.display_from !== 'undefined' && this.object.options.display_from !== null && this.object.options.display_from != '') {
                let display_from = DateTime.fromFormat(this.object.options.display_from, 'yyyy-MM-dd TT')
                if (display_from > DateTime.local()) {
                    window.EventBus.$emit(this.form + '_enabled', this.object.options.name, false)
                    return true
                }
            }

            if (typeof this.object.options.hide_after !== 'undefined' && this.object.options.hide_after && typeof this.object.options.display_until !== 'undefined' && this.object.options.display_until !== null && this.object.options.display_until != '') {
                let display_until = DateTime.fromFormat(this.object.options.display_until, 'yyyy-MM-dd TT')
                if (DateTime.local() >= display_until) {
                    window.EventBus.$emit(this.form + '_enabled', this.object.options.name, false)
                    return true
                }
            }

            window.EventBus.$emit(this.form + '_enabled', this.object.options.name, true)
            return false
        }
    }
}
