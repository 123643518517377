export default class Error {
    constructor() {
        this.errors = {};
    }

    has(field) {
        return this.errors.hasOwnProperty(field);
    }

    get(field) {
        if (this.has(field)) {
            return this.errors[field][0];
        }
    }

    put(field, message) {
      this.errors[field] = message;
    }

    clear(field) {
        if (this.has(field)) {
            this.errors[field] = '';
        }
    }
}
