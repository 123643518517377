import { DateTime } from 'luxon'

export default {
    props: {
        form: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        admin: {
            type: Boolean,
            default: false
        },

        user: {
            type: Object,
            required: false
        },
        event: {
            type: Object,
            required: true
        },
        booking: {
            type: Object
        },
        bookingValues: {
            type: Object,
            required: true
        },

        field: {
            type: Object,
            required: true
        },
        object: {
            type: Object,
            required: true
        },

        map: {
            type: Object,
            required: true
        },
        layout: {
            type: Array,
            required: true
        },
        objects: {
            type: Array,
            required: true
        },
        values: {
            type: Object,
            required: true
        }
    },

    data() {
        let flipped = {}

        Object.keys(this.map).forEach(key => {
            flipped[this.map[key]] = key
        })

        return {
            flipped: flipped,
            actualBookingValues: {}
        }
    },

    created() {
        // if( typeof this.booking != 'undefined' ) {
        //     for( let i = 0; i < this.booking.values.length; i++ ) {
        //         let value = this.booking.values[i];

        //         this.actualBookingValues[value.key] = value.value;
        //     }
        // }

        this.$nextTick(function () {
            this.object.visible = this.$el.nodeName !== "#comment" && this.visible
        })
    },

    updated() {
        this.$nextTick(function () {
            this.object.visible = this.$el.nodeName !== "#comment" && this.visible
        })
    },

    methods: {
        handleChange(value) {
            if (!this.readonly) {
                window.EventBus.$emit(this.form + '_value', this.object.options.name, this.object.options.name, value)
            }
        },
        handleChildChange(name, value) {
            if (!this.readonly) {
                window.EventBus.$emit(this.form + '_value', this.object.options.name, this.object.options.name + '::' + name, value)
            }
        },

        getValue() {
            if (this.object.type == 'toggle' && typeof this.values[this.object.options.name] !== 'undefined' && this.values[this.object.options.name].value == 0) {
                return false
            } else if (this.object.type == 'toggle' && typeof this.values[this.object.options.name] !== 'undefined' && this.values[this.object.options.name].value == 1) {
                return true
            }

            return typeof this.values[this.object.options.name] !== 'undefined' ? this.values[this.object.options.name].value : null
        },
        getChildValue(name) {
            return typeof this.values[this.object.options.name + '_' + name] !== 'undefined' ? this.values[this.object.options.name + '_' + name].value : null
        },

        getError() {
            if (!this.readonly) {
                if (typeof this.object.errors['default'] !== 'undefined' && this.object.errors['default'].length !== 0) {
                    return this.object.errors['default'][0]
                }
            }

            return null
        },
        getChildError(name) {
            if (!this.readonly) {
                if (typeof this.object.errors[name] !== 'undefined' && this.object.errors[name].length !== 0) {
                    return this.object.errors[name][0]
                }
            }

            return null
        }
    },

    watch: {
        visible: {
            handler: function(n, o) {
                window.EventBus.$emit(this.form + '_visible', this.object.options.name, n)
            },
        },
        disabled: {
            handler: function(n, o) {
                window.EventBus.$emit(this.form + '_enabled', this.object.options.name, !n)
            },
        }
    },

    computed: {
        visible() {
            if (this.object.options.enabled == false) {
                window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                return false
            }

            if (typeof this.object.options.filter_fields !== 'undefined' && this.object.options.filter_fields.length !== 0) {
                let options_met = false;

                for (let i = 0; i < this.object.options.filter_fields.length; i++) {
                    let field_internal = this.objects.find(obj => obj.options.name == this.object.options.filter_fields[i].name).internal
                    let field_visible = this.objects[field_internal].visible
                    let field_has_value = typeof this.values[this.flipped[field_internal]] !== 'undefined' && this.values[this.flipped[field_internal]].value !== null && this.values[this.flipped[field_internal]].value !== false && this.values[this.flipped[field_internal]].value.toString().trim() !== ''

                    if (this.object.options.filter_fields[i].filled == true) {
                        if (field_visible && field_has_value) {
                            options_met = true;
                        }
                    } else {
                        if (!field_visible || !field_has_value) {
                            options_met = true;
                        }
                    }
                }

                if (!options_met) {
                    window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                    return false
                }
            }

            if (typeof this.object.options.field !== 'undefined' && this.object.options.field !== null) {
                let value = typeof this.bookingValues[this.object.options.field] !== 'undefined' ? this.bookingValues[this.object.options.field].value : null
                if (typeof this.object.options.show_filled !== 'undefined' && (this.object.options.show_filled === true || this.object.options.show_filled == 1)) {
                    if (value == '0' || value == '' || value === null) {
                        window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                        return false
                    }
                } else if (typeof this.object.options.show_empty !== 'undefined' && (this.object.options.show_empty === true || this.object.options.show_empty == 1)) {
                    if (value != '1' && value != '' && value !== null) {
                        window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                        return false
                    }
                }
            }

            if( typeof this.object.options.linked_fields !== 'undefined' && this.object.options.linked_fields.length !== 0 ) {
                let actualBookingValues = {};

                for( let i = 0; i < this.booking.values.length; i++ ) {
                    let value = this.booking.values[i];
    
                    actualBookingValues[value.key] = value.value;
                }
                
                let excluded = [];

                for( let i = 0; i < this.object.options.linked_fields.length; i++ ) {
                    let linked_field = this.object.options.linked_fields[i];
                    let title = linked_field.title;

                    let value = typeof actualBookingValues[title] !== 'undefined' ? actualBookingValues[title] : null;

                    if( typeof this.object.options.show_filled !== 'undefined' && ( this.object.options.show_filled === true || this.object.options.show_filled == 1 ) ) {
                        if( value == '0' || value == '' || value === null ) {
                            excluded.push({
                                title: title,
                                value: value
                            });
                        }
                    } else if( typeof this.object.options.show_empty !== 'undefined' && ( this.object.options.show_empty === true || this.object.options.show_empty == 1 ) ) {
                        if( value != '1' && value != '' && value !== null ) {
                            excluded.push({
                                title: title,
                                value: value
                            });
                        }
                    }
                }

                if( excluded.length == this.object.options.linked_fields.length ) {
                    window.EventBus.$emit( this.form + '_visible', this.object.options.name, false );
                    return false;
                }
            }

            if (typeof this.object.options.groups !== 'undefined') {
                let member = this.object.options.groups.member !== 'undefined' && this.object.options.groups.member ? true : false
                let non_member = this.object.options.groups.non_member !== 'undefined' && this.object.options.groups.non_member ? true : false

                // Check the user's current subscription is in date, allowing for a one month grace period at the start of a new year
                let checkSubYear = true

                let currentDate = new Date()
                let currentYear = currentDate.getFullYear()
                let currentMonth = currentDate.getMonth()

                let subYear = typeof this.user !== 'undefined' && this.user !== null ? this.userthis.user.subscription.year : false

                if (currentYear - subYear > 1 || (currentYear - subYear == 1 && currentMonth != 0)) checkSubYear = false

                let is_user_member = this.user !== null && typeof this.user.active_subscription_id !== 'undefined' && this.user.active_subscription_id !== null && checkSubYear

                if (member && !non_member && !is_user_member) {
                    window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                    return false
                } else if (!member && non_member && is_user_member) {
                    window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                    return false
                }
            }

            if (this.readonly === false) {
                if (typeof this.object.options.display_from !== 'undefined' && this.object.options.display_from !== null && this.object.options.display_from != '') {
                    let display_from = DateTime.fromFormat(this.object.options.display_from, 'yyyy-MM-dd TT')
                    if (display_from > DateTime.local()) {
                        window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                        return false
                    }
                }

                if (typeof this.object.options.hide_after !== 'undefined' && this.object.options.hide_after && typeof this.object.options.display_until !== 'undefined' && this.object.options.display_until !== null && this.object.options.display_until != '') {
                    let display_until = DateTime.fromFormat(this.object.options.display_until, 'yyyy-MM-dd TT')
                    if (DateTime.local() >= display_until) {
                        window.EventBus.$emit(this.form + '_visible', this.object.options.name, false)
                        return false
                    }
                }
            }

            window.EventBus.$emit(this.form + '_visible', this.object.options.name, true)
            return true
        },

        notice() {
            if (!this.readonly) {
                if (typeof this.object.options.notice_after !== 'undefined' && this.object.options.notice_after && typeof this.object.options.display_until !== 'undefined' && this.object.options.display_until !== null && this.object.options.display_until != '') {
                    let display_until = DateTime.fromFormat(this.object.options.display_until, 'yyyy-MM-dd TT')
                    if (DateTime.local() >= display_until) {
                        return this.object.options.notice
                    }
                }
            }

            return null
        },

        disabled() {
            if (this.object.options.enabled == false) {
                window.EventBus.$emit(this.form + '_enabled', this.object.options.name, false)
                return true
            }

            if (this.readonly) {
                window.EventBus.$emit(this.form + '_enabled', this.object.options.name, false)
                return true
            }

            if (typeof this.object.options.has_default !== 'undefined' && this.object.options.has_default && typeof this.object.options.default_compulsory !== 'undefined' && this.object.options.default_compulsory) {
                window.EventBus.$emit(this.form + '_enabled', this.object.options.name, false)
                return true
            }

            if (this.object.type == 'toggle' && this.object.parents.length !== 0) {
                let selected = []
                let siblings = []

                let parent = this.object.parents[this.object.parents.length - 1]
                for (let i = 0; i < this.objects[parent].children.length; i++) {
                    let object = this.objects[this.objects[parent].children[i]]
                    if (object.type == 'toggle') {
                        if (this.values[object.options.name].value == true) {
                            selected.push(object.internal)
                        }

                        if (selected.includes(object.internal) === false) {
                            siblings.push(object.internal)
                        }
                    }
                }

                let group_rule = this.objects[parent].options.group_rule

                if ((group_rule == 'one' || group_rule == 'lte_one') && selected.length === 1 && siblings.includes(this.object.internal)) {
                    if (siblings.includes(this.object.internal)) {
                        window.EventBus.$emit(this.form + '_value', this.object.options.name, this.object.options.name, this.object.options.has_default == true ? this.object.options.default_value : false)
                        window.EventBus.$emit(this.form + '_enabled', this.object.options.name, false)
                        return true
                    }
                }
            }

            window.EventBus.$emit(this.form + '_enabled', this.object.options.name, true)
            return false
        }
    }
}
