<template>
    <div>
        <div>
            <data-table-component :key="refresh" :url="`/api/events/${event}/talks/datatable`" :columns="columns" :keyword="keyword">
                <template slot="filters">
                    <a :href="`/admin/events/${event}/talks/create`" class="button is-primary ml-auto">
                        <i class="fas fa-plus mr-3"></i> New
                    </a>
                </template>
                <template slot-scope="{ row }" slot="actions">
                    <td class="text-right">
                        <a :href="`/admin/events/${row.event_id}/talks/${row.id}/edit`" class="button is-small is-tertiary inline-block">
                            <i class="fas fa-edit"></i>
                        </a>
                        <button @click.prevent="() => handleDelete(row.id)" class="button is-small is-danger inline-block">
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </template>
            </data-table-component>
        </div>
        <div v-if="this.delete !== null" class="fixed inset-0 z-50 overflow-auto bg-smoke-light flex items-center justify-center">
            <div class="field bg-white rounded shadow rounded border-red border-t-4 border-solid p-8 animated faster slideInUp">
                <h2 class="text-2xl capitalize">Deleting Talk</h2>
                <p class="text-md">Are you sure you want to delete this talk?</p>
                <div class="flex mt-4">
                    <a href="" @click.prevent="handleConfirmDelete" class="button is-danger">Yes, Delete</a>
                    <a href="" @click.prevent="handleCancelDelete" class="button is-tertiary ml-4">Cancel</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DataTableComponent from '../DataTableComponent';

    export default {
        components: {
            DataTableComponent
        },

        props: {
            event: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                delete: null,
                refresh: 0,

                columns: [
                    {
                        key: 'title',
                        label: 'Title',
                    },
                    {
                        key: 'names',
                        label: 'Speakers',
                    }
                ],

                keyword: '',

                currentRow: {}
            }
        },

        methods: {
            handleDelete(talk) {
                this.delete = talk
            },

            handleConfirmDelete() {
                axios.delete('/api/events/' + this.event + '/talks/' + this.delete + '/delete').then(() => {
                    this.delete = null
                    this.refresh += 1
                });
            },
            handleCancelDelete() {
                this.delete = null
            }
        }
    }
</script>
