<template>
    <div v-click-outside="hide">
        <input type="text" :name="name" :id="name"
               :class="[ selectClass, isActive ? 'rounded-b-none border-b-0 outline-none' : '' ]"
               :value="value"
               :placeholder="placeholder"
               @focus="getResults"
               @keydown="onKeyDown"
               autocomplete="off">
        <transition name="slide">
            <ul class="border rounded rounded-t-none h-40 overflow-auto z-50" v-show="isActive">
                <li class="flex flex-col lg:flex-row p-2 text-sm px-4 cursor-pointer"
                    :class="[ (currentIndex != -1 && key === currentIndex) || (currentIndex == -1 && item.code == value) ? 'bg-gray-300' : 'even:bg-gray-100' ]"
                    v-for="(item, key) in filteredResults" v-bind:key="key" @click.prevent="addItem(item, key)">
                    <span class="w-1/3 font-bold">{{ item.code }}</span>
                    <span class="w-2/3">{{ item.description }}</span>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
    export default {
        props: {
            selectClass: {
                type: String,
                default: 'form-control'
            },

            options: {
                type: Array,
                required: true
            },

            name: {
                type: String,
                required: true,
            },

            placeholder: {
                type: String,
                default: 'Search'
            },

            value: {
                type: Number|String,
            }
        },

        data() {
            return {
                search: this.value,
                isActive: false,

                currentIndex: -1
            }
        },

        methods: {
            hide() {
                this.isActive = false;
            },

            getResults() {
                this.isActive = true;
            },

            onKeyDown(e) {
                if (this.isActive) {
                    if (e.key === 'ArrowDown') {
                        this.onPressArrowDown();
                    }

                    if (e.key === 'ArrowUp') {
                        this.onPressArrowUp();
                    }

                    if (e.key === 'Enter') {
                        this.onPressEnter();
                    }
                }
            },

            onPressArrowDown() {
                if ((this.currentIndex + 1) !== this.options.length) {
                    return this.currentIndex++;
                }
            },

            onPressArrowUp() {
                if (this.currentIndex > 0) {
                    return this.currentIndex--;
                }
            },

            onPressEnter() {
                let item = this.options[this.currentIndex];

                return this.addItem(item);
            },

            addItem(item, index) {
                this.currentIndex = index;

                this.$emit('addSelectOption', item);

                this.isActive = false;
            },
        },

        computed: {
            filteredResults() {
                return this.options;
            }
        }
    }
</script>
