<template>
    <div>
        <a href="#" role="button" class="button is-text" @click.prevent="show">Forgotten Password</a>

        <modal :is-visible="isResetPasswordModalVisible" @hide="isResetPasswordModalVisible = false">
            <template slot="modal-title">Forgotten Password</template>
            <div slot="modal-body">
                <input type="text" name="email" id="email" class="form-control" placeholder="Your username, email address or membership number" v-model="email" @change="emailChanged">
                <span class="invalid-input" v-if="error.has('email')">{{ error.get('email') }}</span>
                <span class="valid-input" v-if="isResetPasswordEmailSent">Forgotten password email sent</span>
            </div>
            <div slot="modal-footer" class="flex justify-end">
                <button type="button" class="button is-secondary" @click="isResetPasswordModalVisible = false">
                    <i class="fas fa-times-circle mr-3"></i> Close
                </button>
                <button type="button" class="button is-primary ml-3" v-if="!isResetPasswordEmailSent" @click="reset">
                    <i class="fas fa-lock mr-3"></i> Reset Password
                </button>
            </div>
        </modal>
    </div>
</template>


<script>
    import Modal from './Modal';
    import Error from '../Error';

    export default {

        components: {
            Modal
        },

        data() {
            return {
                email: '',

                isResetPasswordModalVisible: false,
                isResetPasswordEmailSent: false,

                error: new Error()
            }
        },

        methods: {
            emailChanged() {
                if (this.isResetPasswordEmailSent) {
                    this.isResetPasswordEmailSent = false;
                }
            },

            show() {
                this.email = '';

                this.isResetPasswordEmailSent = false;
                this.isResetPasswordModalVisible = true;
            },

            reset() {
                axios.post('/api/account/password/forgot', {
                    email: this.email,
                }).then(() => {
                    this.isResetPasswordEmailSent = true;
                }).catch(error => {
                    this.error.errors = error.response.data.errors;
                })
            }
        }
    }
</script>
