<template>
    <div>
        <input type="hidden" :name="name" :value="json">
        <keep-alive v-for="(child, index) in meta.layout" :key="index">
            <component
                :is="meta.objects[child.internal].component"
                :field="child"

                :form="name"

                :user="user"
                :event="event"
                :booking="booking"
                :bookingValues="bookingValues"

                :map="meta.map"
                :object="meta.objects[child.internal]"
                :layout="meta.layout"
                :objects="meta.objects"
                :values="meta.values"
            ></component>
        </keep-alive>

        <div class="flex justify-end mt-4">
            <button type="submit" class="button is-primary" @click.prevent="handleSubmit"><i class="fas fa-save mr-3"></i> Submit Evaluation</button>
        </div>
    </div>
</template>

<script>
    import GroupField from './Fields/Group'
    import InputField from './Fields/Input'
    import TextField from './Fields/Text'
    import ToggleField from './Fields/Toggle'
    import RatingField from './Fields/Rating'
    import ListField from './Fields/List'

    export default {
        name: 'EvaluationForm',

        components: {
            GroupField,
            InputField,
            TextField,
            ToggleField,
            RatingField,
            ListField
        },

        props: {
            name: {
                type: String,
                required: true,
            },

            user: {
                type: Object,
                required: false
            },
            event: {
                type: Object,
                required: true
            },
            booking: {
                type: Object,
                required: true
            },
            bookingValues: {
                type: Object,
                default: () => {
                    return {}
                }
            },

            layout: {
                type: Array,
                required: true
            },
            objects: {
                type: Array,
                required: true
            },
            values: {
                type: Object,
                required: true
            },
        },

        data() {
            return {
                meta: {
                    map: {},
                    flipped: {},

                    objects: this.objects,
                    layout: this.layout,
                    values: this.values
                }
            }
        },

        created() {
            window.EventBus.$on(this.name + '_value', (field, name, value) => {
                this.handleChange(field, name, value)
            })

            window.EventBus.$on(this.name + '_visible', (field, state) => {
                this.handleVisibleChange(field, state)
            })

            window.EventBus.$on(this.name + '_enabled', (field, state) => {
                this.handleEnabledChange(field, state)
            })
        },

        mounted() {
            this.init()
        },

        methods: {
            init() {
                let parents = {}
                let children = {}

                let recurse = (tree, parent) => {
                    for (let i = 0; i < tree.length; i++) {
                        parents[tree[i].internal] = []
                        if (parent !== null) {
                            parents[tree[i].internal] = [
                                parent,
                                ...parents[parent]
                            ]
                        }

                        children[tree[i].internal] = []
                        if (parent !== null) {
                            children[parent] = [
                                tree[i].internal,
                                ...children[parent]
                            ]
                        }

                        if (tree[i].children.length !== 0) {
                            recurse(tree[i].children, tree[i].internal)
                        }
                    }
                }

                recurse(this.layout, null)

                for (let i = 0; i < this.meta.objects.length; i++) {
                    this.$set(this.meta.map, this.meta.objects[i].options.name, this.meta.objects[i].internal)

                    if (typeof this.meta.values[this.meta.objects[i].options.name] === 'undefined') {
                        this.$set(this.meta.values, this.meta.objects[i].options.name, {
                            value: null,
                            visible: null,
                            enabled: null
                        })
                    }

                    if (typeof this.meta.objects[i].options.has_default !== 'undefined' && this.meta.objects[i].options.has_default) {
                        if ((typeof this.meta.objects[i].options.default_compulsory !== 'undefined' && this.meta.objects[i].options.default_compulsory) || typeof this.meta.values[this.meta.objects[i].options.name] === 'undefined' || this.meta.values[this.meta.objects[i].options.name].value === null) {
                            this.$set(this.meta.values[this.meta.objects[i].options.name], 'value', this.meta.objects[i].options.default_value)
                        }
                    }

                    this.$set(this.meta.objects[i], 'touched', [])
                    this.$set(this.meta.objects[i], 'visible', null)

                    this.$set(this.meta.objects[i], 'fields', [
                        this.meta.objects[i].options.name
                    ])

                    this.$set(this.meta.objects[i], 'rules', {})
                    this.$set(this.meta.objects[i], 'errors', {})
                    this.$set(this.meta.objects[i], 'parents', parents[this.meta.objects[i].internal])
                    this.$set(this.meta.objects[i], 'children', children[this.meta.objects[i].internal])

                    switch (this.meta.objects[i].type) {
                        case 'list':
                            this.$set(this.meta.objects[i], 'component', 'ListField')

                            this.$set(this.meta.objects[i].rules, 'default', [
                                (value) => {
                                    let total = 0

                                    let check = this.meta.objects.filter(object => object.parents.includes(this.meta.objects[i].internal))
                                    for (let e = 0; e < check.length; e++) {
                                        if (check[e].type == 'toggle') {
                                            if (this.meta.values[check[e].options.name].value == true) {
                                                total++
                                            }
                                        }
                                    }

                                    if (this.meta.objects[i].options.group_rule == 'one' && total !== 1) {
                                        return 'Please choose exactly one option'
                                    } else if (this.meta.objects[i].options.group_rule == 'gte_one' && total < 1) {
                                        return 'Please choose at least one option'
                                    } else if (this.meta.objects[i].options.group_rule == 'lte_one' && total > 1) {
                                        return 'Please choose at most one option'
                                    }

                                    return true
                                }
                            ])

                            break
                        case 'group':
                            this.$set(this.meta.objects[i], 'component', 'GroupField')
                            break
                        case 'input':
                            this.$set(this.meta.objects[i], 'component', 'InputField')

                            this.$set(this.meta.objects[i].rules, 'default', [
                                (value) => {
                                    if (this.meta.objects[i].options.required && (value == null || value.trim() == '')) {
                                        return 'This field is required'
                                    }

                                    return true
                                }
                            ])

                            break
                        case 'rating':
                            this.$set(this.meta.objects[i], 'component', 'RatingField')

                            this.$set(this.meta.objects[i].rules, 'default', [
                                (value) => {
                                    if (this.meta.objects[i].options.required && (value == null || value.trim() == '')) {
                                        return 'This field requires a rating'
                                    }

                                    return true
                                }
                            ])

                            break
                        case 'text':
                            this.$set(this.meta.objects[i], 'component', 'TextField')
                            break
                        case 'toggle':
                            this.$set(this.meta.objects[i], 'component', 'ToggleField')

                            this.meta.objects[i].fields.push('additional')

                            this.$set(this.meta.objects[i].rules, 'additional', [
                                (value) => {
                                    if (this.meta.values[this.meta.objects[i].options.name].value === true && this.meta.objects[i].options.request_additional && this.meta.objects[i].options.additional_mandatory && (value == null || value.trim() == '')) {
                                        return 'This field is required'
                                    }

                                    return true
                                }
                            ])

                            break
                    }
                }
            },

            handleChange(field, name, value) {
                let child = null
                if (name.indexOf('::') !== -1) {
                    child = name.split('::', 2)[1]
                }

                name = name.replace('::', '_')

                if (typeof this.meta.values[name] == 'undefined') {
                    this.$set(this.meta.values, name, {
                        'value': value,
                        'visible': null,
                        'enabled': null,
                    })
                } else {
                    this.$set(this.meta.values[name], 'value', value)
                }

                if (this.meta.objects[this.meta.map[field]].touched.includes(child !== null ? child : 'default') === false) {
                    this.meta.objects[this.meta.map[field]].touched.push(child !== null ? child : 'default')
                }

                let parents = this.meta.objects[this.meta.map[field]].parents
                if (parents.length !== 0) {
                    let parent = parents[0]

                    if (this.meta.objects[parent].type == 'list') {
                        if (this.meta.objects[parent].touched.includes('default') === false) {
                            this.meta.objects[parent].touched.push('default')
                        }

                        this.handleValidate(this.meta.objects[parent].options.name, 'default', null)
                    }
                }

                this.handleValidate(field, child !== null ? child : 'default', value)
            },

            handleVisibleChange(field, state) {
                if (typeof this.meta.values[field] == 'undefined') {
                    this.$set(this.meta.values, field, {
                        'value': null,
                        'visible': state,
                        'enabled': null,
                    })
                } else {
                    this.$set(this.meta.values[field], 'visible', state)
                }
            },
            handleEnabledChange(field, state) {
                if (typeof this.meta.values[field] == 'undefined') {
                    this.$set(this.meta.values, field, {
                        'value': null,
                        'visible': null,
                        'enabled': state,
                    })
                } else {
                    this.$set(this.meta.values[field], 'enabled', state)
                }
            },

            handleValidate(field, name, value) {
                this.$set(this.meta.objects[this.meta.map[field]].errors, name, [])

                if (this.meta.objects[this.meta.map[field]].touched.includes(name) && typeof this.meta.objects[this.meta.map[field]].rules !== 'undefined' && typeof this.meta.objects[this.meta.map[field]].rules[name] !== 'undefined') {
                    for (let i = 0; i < this.meta.objects[this.meta.map[field]].rules[name].length; i++) {
                        let result = this.meta.objects[this.meta.map[field]].rules[name][i](value)
                        if (result !== true) {
                            this.meta.objects[this.meta.map[field]].errors[name].push(result)
                        }
                    }
                }
            },

            validateForm() {
                let ok = true

                for (let i = 0; i < this.meta.objects.length; i++) {
                    if (this.meta.objects[i].visible === true && Object.keys(this.meta.objects[i].rules).length !== 0) {
                        for (let key in this.meta.objects[i].rules) {
                            this.$set(this.meta.objects[i].errors, key, [])

                            for (let j = 0; j < this.meta.objects[i].rules[key].length; j++) {
                                let field = this.meta.objects[i].options.name
                                if (key !== 'default') {
                                    field += '_' + key
                                }

                                let result = this.meta.objects[i].rules[key][j](typeof this.meta.values[field] !== 'undefined' ? this.meta.values[field].value : null)
                                if (result !== true) {
                                    this.meta.objects[i].errors[key].push(result)

                                    ok = false
                                }
                            }
                        }
                    }
                }

                return ok;
            },

            handleSubmit() {
                if (this.validateForm()) {
                    if (this.$el.parentElement.localName == 'form') {
                        return this.$el.parentElement.submit()
                    }
                }

                return
            }
        },

        computed: {
            json() {
                let clone = Object.assign({}, this.meta.values)
                if (typeof clone.null !== 'undefined') {
                    delete clone.null
                }
                return JSON.stringify(clone)
            }
        }
    }
</script>
