<template>
    <div>
        <div class="bg-white mt-10">
            <div class="container mx-auto p-10">
                <div class="flex">
                    <div class="mr-4">
                        <h2 class="font-bold text-3xl">£{{ total.toFixed(2) }}</h2>
                    </div>
                    <div class="ml-auto flex items-center">
                        <button @click.prevent="handlePrevious" class="button is-tertiary" v-if="this.canPrevious">Previous</button>
                        <button @click.prevent="handleNext" class="button is-primary ml-2" v-if="this.canNext && this.meta.page != 'welcome'">Next</button>
                    </div>
                </div>
                <div class="flex items-center justify-between mt-4">
                    <div class="w-full">
                        <span class="text-lg cursor-default" :class="{ 'text-green': this.pageLessEqual('welcome') }">Welcome</span>
                        <i class="fas fa-chevron-right px-2 text-xs text-gray-700"></i>
                        <span class="text-lg cursor-default" :class="{ 'text-green': this.pageLessEqual('personal') }">Personal Details</span>
                        <i class="fas fa-chevron-right px-2 text-xs text-gray-700"></i>
                        <span class="text-lg cursor-default" :class="{ 'text-green': this.pageLessEqual('booking') }">Booking Details</span>
                        <i class="fas fa-chevron-right px-2 text-xs text-gray-700"></i>
                        <span class="text-lg cursor-default" :class="{ 'text-green': this.pageLessEqual('summary') }">Summary</span>
                        <i class="fas fa-chevron-right px-2 text-xs text-gray-700"></i>
                        <span class="text-lg cursor-default" :class="{ 'text-green': this.pageLessEqual('payment') }">Payment</span>
                        <i class="fas fa-chevron-right px-2 text-xs text-gray-700"></i>
                        <span class="text-lg cursor-default">Complete</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container p-10 pb-0 mx-auto">
            <slot></slot>

            <div v-if="meta.error && meta.error.show" class="flex bg-white border-2 border-red shadow-lg py-4 px-6 mb-10 relative text-lg">
                <div class="flex-1 flex items-center">
                    <p>{{ meta.error.message }}</p>
                </div>
                <div class="flex-0 flex items-center ml-4">
                    <a href="" class="button is-secondary p-2" @click.prevent="meta.error.show = false">&times;</a>
                </div>
            </div>

            <div class="bg-white shadow-lg p-10 relative">
                <div v-if="meta.loading" class="absolute z-50 inset-0 bg-smoke-lightest flex items-center justify-center">
                    <div>
                        <i class="fas fa-spinner fa-spin text-6xl text-gray-600"></i>
                    </div>
                </div>

                <div :class="[ this.meta.page == 'welcome' ? 'visible' : 'hidden' ]">
                    <h2 class="font-bold text-2xl">Welcome</h2>
                    <p>Thank you for choosing to book a BSP event. If you are booking on behalf of someone else, please use the Guest function on the right, otherwise we will record you as attending the event and your colleague will be unable to collect their CPD certificate. If you have any difficulty completing your booking, please contact our Executive General Manager, Paula Dunn, who will be happy to help you: <a href="mailto:admin@bsperio.org.uk">admin@bsperio.org.uk</a> Tel <a href="tel:+448443351915">0844 335 1915</a></p>

                    <div class="flex mt-2 -m-2">
                        <div class="flex-1 w-1/2 p-2">
                            <h3 class="font-bold text-2xl">I want to attend as a member</h3>
                            <p>You will need your username and password to complete this booking.</p>

                            <div class="mt-4" v-if="this.user && this.user.active_subscription_id === null">
                                <div class="mb-4 border p-4">
                                    <h4 class="text-lg font-bold">Welcome back, {{ this.user.full_name }}</h4>
                                    <p>Unfortunately, as you don't have an active membership, you are not eligible for this event.<span v-if="!this.event.restrict_to_members"> If you are booking on behalf of someone else, you can still make a guest booking by choosing that option.</span></p>

                                    <div class="mt-4">
                                        Not you? <a class="text-blue underline" :href="[ '/events/' + this.event.id + '/booking/logout' ]">Log out</a>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-4" v-if="this.user && this.user.active_subscription_id !== null">
                                <div class="mb-4 border p-4">
                                    <h4 class="text-lg font-bold">Welcome back, {{ this.user.full_name }}</h4>
                                    <p>Your membership entitles you to attend this event as a member.<span v-if="!this.event.restrict_to_members"> If you are booking on behalf of someone else, you can still make a guest booking by choosing that option.</span></p>

                                    <div class="mt-4">
                                        Not you? <a class="text-blue underline" :href="[ '/events/' + this.event.id + '/booking/logout' ]">Log out</a>
                                    </div>
                                </div>
                                <button class="button is-primary inline-block" @click.prevent="handleStartMember">Book as a member</button>
                            </div>

                            <div class="mt-4" v-if="!this.user">
                                <a class="button is-primary inline-block" :href="[ '/events/' + this.event.id + '/booking/login' ]">Login to your account</a>
                                <h4 class="font-bold text-xl mt-4">Not yet a member of the BSP?</h4>
                                <p>Membership of the BSP has a huge number of benefits. If you would like more information or wish to join, please click the link below.</p>
                                <a class="button is-tertiary inline-block mt-4" href="#">Join now</a>
                            </div>
                        </div>
                        <div class="flex-1 w-1/2 p-2" v-if="!this.event.restrict_to_members">
                            <h3 class="font-bold text-2xl">I want to attend as a guest</h3>
                            <p>Please ensure that you keep a record of your booking reference number, you will need this to complete your evaluation and CPD.</p>
                            <button class="button is-primary inline-block mt-4" @click.prevent="handleStartGuest" v-if="!this.user">Continue as a guest</button>
                            <button class="button is-primary inline-block mt-4" @click.prevent="handleStartGuest" v-if="this.user">Book as a guest</button>
                        </div>
                    </div>
                </div>

                <div :class="[ this.meta.page == 'personal' ? 'visible' : 'hidden' ]">
                    <h2 class="font-bold text-2xl">Personal Details</h2>
                    <p>Please provide your personal details. Fields marked with an asterisk are mandatory.</p>

                    <div class="mt-4">
                        <div class="md:flex -mt-2 -mx-2">
                            <div class="w-full md:w-1/4 p-2">
                                <label class="form-label is-required">Title</label>
                                <GenericSelectComponent :options="titles" :value="getValue('title')" @change="event => setValue('title', event)"></GenericSelectComponent>
                                <span class="invalid-input" v-if="hasError('title')">{{ getError('title') }}</span>
                            </div>
                            <div class="md:flex w-full md:w-3/4">
                                <div class="w-full md:w-1/2 p-2">
                                    <label class="form-label is-required">First Name</label>
                                    <input type="text" class="form-control" :value="getValue('first_name')" @keyup="event => setValue('first_name', event.target.value)">
                                    <span class="invalid-input" v-if="hasError('first_name')">{{ getError('first_name') }}</span>
                                </div>
                                <div class="w-full md:w-1/2 p-2">
                                    <label class="form-label is-required">Last Name</label>
                                    <input type="text" class="form-control" :value="getValue('last_name')" @keyup="event => setValue('last_name', event.target.value)">
                                    <span class="invalid-input" v-if="hasError('last_name')">{{ getError('last_name') }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="md:flex -mx-2">
                            <div class="w-full md:w-1/2 p-2">
                                <label class="form-label">GDC Registration Number</label>
                                <input type="text" class="form-control" :value="getValue('gdc_reg_no')" @keyup="event => setValue('gdc_reg_no', event.target.value)">
                                <span class="invalid-input" v-if="hasError('gdc_reg_no')">{{ getError('gdc_reg_no') }}</span>
                            </div>
                            <div class="w-full md:w-1/2 p-2">
                                <label class="form-label">Trainee Number</label>
                                <input type="text" class="form-control" :value="getValue('trainee_no')" @keyup="event => setValue('trainee_no', event.target.value)">
                                <span class="invalid-input" v-if="hasError('trainee_no')">{{ getError('trainee_no') }}</span>
                            </div>
                        </div>
                        <div class="md:flex -mx-2">
                            <div class="w-full md:w-1/2 p-2">
                            <label class="form-label">Department</label>
                            <input type="text" class="form-control" :value="getValue('department')" @keyup="event => setValue('department', event.target.value)">
                            <span class="invalid-input" v-if="hasError('department')">{{ getError('department') }}</span>
                            </div>
                            <div class="w-full md:w-1/2 p-2">
                                <label class="form-label">House Number</label>
                                <input type="text" class="form-control" :value="getValue('house_number')" @keyup="event => setValue('house_number', event.target.value)">
                                <span class="invalid-input" v-if="hasError('house_number')">{{ getError('house_number') }}</span>
                            </div>
                        </div>
                        <div class="md:flex -mx-2">
                            <div class="w-full md:w-1/2 p-2">
                                <label class="form-label is-required">Address</label>
                                <input type="text" class="form-control" :value="getValue('address_1')" @keyup="event => setValue('address_1', event.target.value)">
                                <span class="invalid-input" v-if="hasError('address_1')">{{ getError('address_1') }}</span>
                            </div>
                            <div class="w-full md:w-1/2 p-2">
                                <label class="form-label">Locality</label>
                                <input type="text" class="form-control" :value="getValue('locality')" @keyup="event => setValue('locality', event.target.value)">
                                <span class="invalid-input" v-if="hasError('locality')">{{ getError('locality') }}</span>
                            </div>
                        </div>
                        <div class="md:flex -mx-2">
                            <div class="w-full md:w-1/2 p-2">
                                <label class="form-label is-required">Town</label>
                                <input type="text" class="form-control" :value="getValue('town')" @keyup="event => setValue('town', event.target.value)">
                                <span class="invalid-input" v-if="hasError('town')">{{ getError('town') }}</span>
                            </div>
                            <div class="w-full md:w-1/2 p-2">
                                <label class="form-label is-required">County</label>
                                <input type="text" class="form-control" :value="getValue('county')" @keyup="event => setValue('county', event.target.value)">
                                <span class="invalid-input" v-if="hasError('county')">{{ getError('county') }}</span>
                            </div>
                        </div>
                        <div class="md:flex -mx-2">
                            <div class="w-full md:w-1/2 p-2">
                                <label class="form-label is-required">Postcode</label>
                                <input type="text" class="form-control" :value="getValue('postcode')" @keyup="event => setValue('postcode', event.target.value)">
                                <span class="invalid-input" v-if="hasError('postcode')">{{ getError('postcode') }}</span>
                            </div>
                            <div class="w-full md:w-1/2 p-2">
                                <label class="form-label is-required">Country</label>
                                <GenericSelectComponent :options="countries" :value="getValue('country')" keyColumn="code" valueColumn="name" @change="event => setValue('country', event)"></GenericSelectComponent>
                                <span class="invalid-input" v-if="hasError('country')">{{ getError('country') }}</span>
                            </div>
                        </div>
                        <div class="md:flex -mx-2 -mb-2">
                            <div class="w-full md:w-1/2 p-2">
                                <label class="form-label is-required">Email Address</label>
                                <input type="text" class="form-control" :value="getValue('email')" @keyup="event => setValue('email', event.target.value)">
                                <span class="invalid-input" v-if="hasError('email')">{{ getError('email') }}</span>
                            </div>
                            <div class="w-full md:w-1/2 p-2">
                                <label class="form-label">Telephone</label>
                                <input type="text" class="form-control" :value="getValue('telephone')" @keyup="event => setValue('telephone', event.target.value)">
                                <span class="invalid-input" v-if="hasError('telephone')">{{ getError('telephone') }}</span>
                            </div>
                        </div>
                        <div class="py-2">
                            <label class="form-label">Website</label>
                            <input type="text" class="form-control" :value="getValue('website')" @keyup="event => setValue('website', event.target.value)">
                            <span class="invalid-input" v-if="hasError('website')">{{ getError('website') }}</span>
                        </div>
                    </div>

                    <div class="mt-4">
                        <button @click.prevent="handleNext" class="button is-primary ml-auto">Next</button>
                    </div>
                </div>

                <div :class="[ this.meta.page == 'booking' ? 'visible' : 'hidden' ]">
                    <h2 class="font-bold text-2xl" id="summary-top">Booking Details</h2>
                    <p>Please provide your booking details. Fields marked with an asterisk are mandatory.</p>

                    <keep-alive v-for="(child, index) in meta.layout" :key="index">
                        <component
                            :is="meta.objects[child.internal].component"
                            :field="child"

                            :form="name"

                            :user="meta.guest ? null : user"
                            :user-group="userGroup"
                            :event="event"

                            :map="meta.map"
                            :object="meta.objects[child.internal]"
                            :layout="meta.layout"
                            :objects="meta.objects"
                            :values="meta.values.custom"
                        ></component>
                    </keep-alive>

                    <div class="mt-4">
                        <button @click.prevent="handleNext" class="button is-primary ml-auto">Next</button>
                    </div>
                </div>

                <div v-if="this.meta.page == 'summary'" class="booking-summary">
                    <h2 class="font-bold text-2xl">Summary</h2>
                    <p>Please review your summary before continuing with your booking.</p>

                    <div class="mt-5 pt-5 border-solid border-t border-gray-300">
                        <div class="flex">
                            <h3 class="font-bold text-xl">Personal Details</h3>
                            <a class="ml-auto" href="" @click.prevent="handleGoto('personal')">Edit Personal Details</a>
                        </div>
                        <p>The personal details you have provided as part of the booking process.</p>

                        <div class="md:flex w-full -m-2 mt-2">
                            <div class="w-full md:w-1/2 p-2">
                                <div class="block mb-2">
                                    <label class="form-label mb-0">Attendance</label>
                                    <div v-if="meta.guest">Guest</div>
                                    <div v-if="!meta.guest">
                                        <div>Member</div>
                                        <div class="text-sm" v-if="user && user.member_id">{{ user.member_id }}</div>
                                    </div>
                                </div>
                                <div class="block mb-2">
                                    <label class="form-label mb-0">Full Name</label>
                                    <div>{{ getSummaryValue('full_name') }}</div>
                                </div>
                                <div class="block mb-2">
                                    <label class="form-label mb-0">GDC Registration Number</label>
                                    <div>{{ getSummaryValue('gdc_reg_no') }}</div>
                                </div>
                                <div class="block mb-2">
                                    <label class="form-label mb-0">Trainee Number</label>
                                    <div>{{ getSummaryValue('trainee_no') }}</div>
                                </div>
                                <div class="block mb-2">
                                    <label class="form-label mb-0">Email Address</label>
                                    <div>{{ getSummaryValue('email') }}</div>
                                </div>
                                <div class="block mb-2">
                                    <label class="form-label mb-0">Website</label>
                                    <div>{{ getSummaryValue('website') }}</div>
                                </div>
                            </div>
                            <div class="w-full md:w-1/2 p-2">
                                <div class="block mb-2">
                                    <label class="form-label mb-0">Department</label>
                                    <div>{{ getSummaryValue('department') }}</div>
                                </div>
                                <div class="block mb-2">
                                    <label class="form-label mb-0">Address</label>
                                    <div>{{ getSummaryValue('address_1') }}</div>
                                </div>
                                <div class="block mb-2">
                                    <label class="form-label mb-0">Locality</label>
                                    <div>{{ getSummaryValue('locality') }}</div>
                                </div>
                                <div class="block mb-2">
                                    <label class="form-label mb-0">Town</label>
                                    <div>{{ getSummaryValue('town') }}</div>
                                </div>
                                <div class="block mb-2">
                                    <label class="form-label mb-0">County</label>
                                    <div>{{ getSummaryValue('county') }}</div>
                                </div>
                                <div class="block mb-2">
                                    <label class="form-label mb-0">Postcode</label>
                                    <div>{{ getSummaryValue('postcode') }}</div>
                                </div>
                                <div class="block">
                                    <label class="form-label">Country</label>
                                    <div>{{ getSummaryValue('country') }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-5 pt-5 border-solid border-t border-gray-300">
                            <div class="flex">
                                <h3 class="font-bold text-xl">Booking Details</h3>
                                <a class="ml-auto" href="" @click.prevent="handleGoto('booking')">Edit Booking Details</a>
                            </div>
                            <p>The selections you have made for your booking.</p>

                            <div v-for="(child, index) in meta.layout" :key="index">
                                <component
                                    :is="meta.objects[child.internal].component"
                                    :field="child"

                                    :form="name"
                                    :readonly="true"

                                    :user="meta.guest ? null : user"
                                    :user-group="userGroup"
                                    :event="event"

                                    :map="meta.map"
                                    :object="meta.objects[child.internal]"
                                    :layout="meta.layout"
                                    :objects="meta.objects"
                                    :values="meta.values.custom"
                                ></component>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 pt-5 border-solid border-t border-gray-300 text-right">
                        <h2 class="font-bold text-3xl">£{{ total.toFixed(2) }}</h2>
                        <!-- <form v-if="total != 0" :action="'https://portal.bsp.local/api/stripe_payment/create'" method="post">
                            <input type="hidden" name="testMode" :value="this.meta.payment.testmode ? '100' : '0'">
                            <input type="hidden" name="accId1" :value="this.meta.payment.merchant">
                            <input type="hidden" name="instId" :value="this.meta.payment.installation">
                            <input type="hidden" name="cartId" :value="this.meta.payment.id">
                            <input type="hidden" name="amount" :value="this.meta.payment.amount">
                            <input type="hidden" name="currency" :value="this.meta.payment.currency.code">
                            <input type="hidden" name="desc" :value="this.meta.payment.description">
                            <input type="hidden" name="signature" :value="this.meta.payment.signature">

                            <input type="hidden" name="MC_callback" :value="this.meta.payment.callback">
                            <input type="hidden" name="MC_successurl" :value="this.meta.payment.successurl">
                            <input type="hidden" name="MC_failureurl" :value="this.meta.payment.failureurl">

                            <input type="hidden" name="lang" value="en-GB">
                            <input type="hidden" name="noLanguageMenu" value="1">
                            <input type="hidden" name="fixContact" value="1">
                            <input type="hidden" name="hideCurrency" value="1">

                            <input type="hidden" name="name" :value="this.fullmetaname">
                            <input type="hidden" name="address1" :value="this.meta.values.address_1">
                            <input type="hidden" name="address2" :value="this.meta.values.locality">
                            <input type="hidden" name="town" :value="this.meta.values.town">
                            <input type="hidden" name="region" :value="this.meta.values.county">
                            <input type="hidden" name="postcode" :value="this.meta.values.postcode">
                            <input type="hidden" name="country" :value="this.meta.values.country">
                            <input type="hidden" name="tel" :value="this.meta.values.telephone">
                            <input type="hidden" name="email" :value="this.meta.values.email">

                            <button type="submit" class="button is-primary ml-auto mt-5">Pay Now</button>
                        </form> -->
                        <form v-if="total == 0" class="clearfix">
                            <a :href="this.meta.payment.finishurl" class="button is-primary ml-auto float-right mt-5">Finish</a>
                        </form>
                        <div v-if="total != 0" class="mt-4">
                            <button @click.prevent="handleNext" class="button is-primary ml-auto">Next</button>
                        </div>
                    </div>
                </div>

                <!-- <div v-if="this.meta.page == 'payment'" :class="[ this.meta.page == 'payment' ? 'visible' : 'hidden' ]"> -->
                    <keep-alive>
                        <booking-form-payment
                            v-if="this.meta.page == 'payment'" :class="[ this.meta.page == 'payment' ? 'visible' : 'hidden' ]"
                            :stripeParams="stripeParams"
                            :event="event"
                            :meta="meta"
                            :values="values"
                            :booking="booking"
                        />
                    </keep-alive>
                <!-- </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import GenericSelectComponent from '../../GenericSelectComponent'

    import GroupField from './Fields/Group'
    import InputField from './Fields/Input'
    import TextField from './Fields/Text'
    import ListField from './Fields/List'
    import ToggleField from './Fields/Toggle'

    import BookingFormPayment from './BookingFormPayment'

    export default {
        name: 'BookingForm',

        components: {
            GenericSelectComponent,

            GroupField,
            InputField,
            TextField,
            ToggleField,
            ListField,

            BookingFormPayment
        },

        props: {
            name: {
                type: String,
                required: true,
            },

            user: {
                type: Object,
                required: false
            },
            userGroup: {
                type: String,
                required: false
            },
            event: {
                type: Object,
                required: true
            },
            booking: {
                type: Object,
                required: true
            },
            payment: {
                type: Object|null,
                required: false
            },

            titles: {
                type: Array,
                default: () => {
                    return {}
                }
            },
            countries: {
                type: Array,
                default: () => {
                    return {}
                }
            },

            layout: {
                type: Array,
                required: true
            },
            objects: {
                type: Array,
                required: true
            },
            values: {
                type: Object,
                required: true
            },

            stripeParams: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                meta: {
                    guest: this.booking.guest == 1 ? true : false,
                    loading: false,

                    page: this.booking.page,
                    pages: [
                        'welcome',
                        'personal',
                        'booking',
                        'summary',
                        'payment'
                    ],

                    personal: {
                        errors: {},

                        rules: {
                            title: () => {
                                if (typeof this.meta.values.title !== 'undefined' && this.meta.values.title !== null && this.meta.values.title.trim() == '') {
                                    return 'Please select a title.'
                                }

                                return true
                            },
                            first_name: () => {
                                if (typeof this.meta.values.first_name !== 'undefined' && this.meta.values.first_name !== null && this.meta.values.first_name.trim() == '') {
                                    return 'Please enter a valid firstname.'
                                }

                                return true
                            },
                            last_name: () => {
                                if (typeof this.meta.values.last_name !== 'undefined' && this.meta.values.last_name !== null && this.meta.values.last_name.trim() == '') {
                                    return 'Please enter a valid last name.'
                                }

                                return true
                            },
                            address_1: () => {
                                if (typeof this.meta.values.address_1 !== 'undefined' && this.meta.values.address_1 !== null && this.meta.values.address_1.trim() == '') {
                                    return 'Please enter a valid address.'
                                }

                                return true
                            },
                            town: () => {
                                if (typeof this.meta.values.town !== 'undefined' && this.meta.values.town !== null && this.meta.values.town.trim() == '') {
                                    return 'Please enter a valid town.'
                                }

                                return true
                            },
                            county: () => {
                                if (typeof this.meta.values.county !== 'undefined' && this.meta.values.county !== null && this.meta.values.county.trim() == '') {
                                    return 'Please enter a valid county.'
                                }

                                return true
                            },
                            postcode: () => {
                                if (typeof this.meta.values.postcode !== 'undefined' && this.meta.values.postcode !== null && this.meta.values.postcode.trim() == '') {
                                    return 'Please enter a valid postcode.'
                                }

                                return true
                            },
                            country: () => {
                                if (typeof this.meta.values.country !== 'undefined' && this.meta.values.country !== null && this.meta.values.country.trim() == '') {
                                    return 'Please select a country.'
                                }

                                return true
                            },
                            email: () => {
                                if (typeof this.meta.values.email !== 'undefined' && this.meta.values.email !== null && this.meta.values.email.trim() == '') {
                                    return 'Please enter a valid email address.'
                                }

                                let valid = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(this.meta.values.email)

                                if (!valid) {
                                    return 'Please enter a valid email address.'
                                }

                                return true
                            }
                        }
                    },

                    map: {},
                    flipped: {},

                    payment: this.payment,

                    objects: this.objects,
                    layout: this.layout,
                    values: this.values
                }
            }
        },

        created() {
            window.EventBus.$on(this.name + '_value', (field, name, value) => {
                this.handleChange(field, name, value)
            })

            window.EventBus.$on(this.name + '_visible', (field, state) => {
                this.handleVisibleChange(field, state)
            })

            window.EventBus.$on(this.name + '_enabled', (field, state) => {
                this.handleEnabledChange(field, state)
            })
        },

        mounted() {
            this.init()
        },

        methods: {
            init() {
                let parents = {}
                let children = {}

                let recurse = (tree, parent) => {
                    for (let i = 0; i < tree.length; i++) {
                        parents[tree[i].internal] = []
                        if (parent !== null) {
                            parents[tree[i].internal] = [
                                parent,
                                ...parents[parent]
                            ]
                        }

                        children[tree[i].internal] = []
                        if (parent !== null) {
                            children[parent] = [
                                tree[i].internal,
                                ...children[parent]
                            ]
                        }

                        if (tree[i].children.length !== 0) {
                            recurse(tree[i].children, tree[i].internal)
                        }
                    }
                }

                recurse(this.layout, null)

                for (let i = 0; i < this.meta.objects.length; i++) {
                    this.$set(this.meta.map, this.meta.objects[i].options.name, this.meta.objects[i].internal)

                    if (typeof this.meta.values.custom[this.meta.objects[i].options.name] === 'undefined') {
                        this.$set(this.meta.values.custom, this.meta.objects[i].options.name, {
                            value: null,
                            visible: null,
                            enabled: null
                        })
                    }

                    if (typeof this.meta.objects[i].options.has_default !== 'undefined' && this.meta.objects[i].options.has_default) {
                        if ((typeof this.meta.objects[i].options.default_compulsory !== 'undefined' && this.meta.objects[i].options.default_compulsory) || typeof this.meta.values.custom[this.meta.objects[i].options.name] === 'undefined' || this.meta.values.custom[this.meta.objects[i].options.name].value === null) {
                            this.$set(this.meta.values.custom[this.meta.objects[i].options.name], 'value', this.meta.objects[i].options.default_value)
                        }
                    }

                    this.$set(this.meta.objects[i], 'touched', [])
                    this.$set(this.meta.objects[i], 'visible', null)

                    this.$set(this.meta.objects[i], 'fields', [
                        this.meta.objects[i].options.name
                    ])

                    this.$set(this.meta.objects[i], 'rules', {})
                    this.$set(this.meta.objects[i], 'errors', {})
                    this.$set(this.meta.objects[i], 'parents', parents[this.meta.objects[i].internal])
                    this.$set(this.meta.objects[i], 'children', children[this.meta.objects[i].internal])

                    switch (this.meta.objects[i].type) {
                        case 'list':
                            this.$set(this.meta.objects[i], 'component', 'ListField')

                            this.$set(this.meta.objects[i].rules, 'default', [
                                (value) => {
                                    let total = 0

                                    let check = this.meta.objects.filter(object => object.parents.includes(this.meta.objects[i].internal))
                                    for (let e = 0; e < check.length; e++) {
                                        if (check[e].type == 'toggle') {
                                            if (this.meta.values.custom[check[e].options.name].value == true) {
                                                total++
                                            }
                                        }
                                    }

                                    if (this.meta.objects[i].options.group_rule == 'one' && total !== 1) {
                                        return 'Please choose exactly one option'
                                    } else if (this.meta.objects[i].options.group_rule == 'gte_one' && total < 1) {
                                        return 'Please choose at least one option'
                                    } else if (this.meta.objects[i].options.group_rule == 'lte_one' && total > 1) {
                                        return 'Please choose at most one option'
                                    }

                                    return true
                                }
                            ])

                            break
                        case 'group':
                            this.$set(this.meta.objects[i], 'component', 'GroupField')
                            break
                        case 'input':
                            this.$set(this.meta.objects[i], 'component', 'InputField')

                            this.$set(this.meta.objects[i].rules, 'default', [
                                (value) => {
                                    if (this.meta.objects[i].options.required && (value == null || value.trim() == '')) {
                                        return 'This field is required'
                                    }

                                    return true
                                }
                            ])

                            break
                        case 'text':
                            this.$set(this.meta.objects[i], 'component', 'TextField')
                            break
                        case 'toggle':
                            this.$set(this.meta.objects[i], 'component', 'ToggleField')

                            this.meta.objects[i].fields.push('additional')

                            this.$set(this.meta.objects[i].rules, 'additional', [
                                (value) => {
                                    if (this.meta.values.custom[this.meta.objects[i].options.name].value === true && this.meta.objects[i].options.request_additional && this.meta.objects[i].options.additional_mandatory && (value == null || value.trim() == '')) {
                                        return 'This field is required'
                                    }

                                    return true
                                }
                            ])

                            break
                    }
                }
            },

            handleChange(field, name, value) {
                let child = null
                if (name.indexOf('::') !== -1) {
                    child = name.split('::', 2)[1]
                }

                name = name.replace('::', '_')

                if (typeof this.meta.values.custom[name] == 'undefined') {
                    this.$set(this.meta.values.custom, name, {
                        'value': value,
                        'visible': null,
                        'enabled': null,
                    })
                } else {
                    this.$set(this.meta.values.custom[name], 'value', value)
                }

                // Handle changes for other fields dependent on this one
                for( let i = 0; i < this.meta.objects.length; i++ ) {
                    if( typeof this.meta.objects[i].options.filter_fields !== 'undefined' && this.meta.objects[i].options.filter_fields.length > 0 ) {
                        for( let j = 0; j < this.meta.objects[i].options.filter_fields.length; j++ ) {
                            let tmp = this.meta.objects[i].options.filter_fields[j]

                            if( tmp.name == name && value == false ) {
                                this.$set(this.meta.values.custom[name], 'visible', false)
                                this.$set(this.meta.values.custom[name], 'value', null)

                                // Fields match, set all children to false
                                let children = this.meta.objects[i].children

                                for( let k = 0; k < children.length; k++ ) {
                                    let childName = this.meta.objects[children[k]].options.name

                                    if (typeof this.meta.values.custom[childName] == 'undefined') {
                                        this.$set(this.meta.values.custom, childName, {
                                            'value': null,
                                            'visible': null,
                                            'enabled': null,
                                        })
                                    } else {
                                        this.$set(this.meta.values.custom[childName], 'value', null)
                                    }
                                }
                            }
                        }
                    }
                }

                if (this.meta.objects[this.meta.map[field]].touched.includes(child !== null ? child : 'default') === false) {
                    this.meta.objects[this.meta.map[field]].touched.push(child !== null ? child : 'default')
                }

                let parents = this.meta.objects[this.meta.map[field]].parents
                if (parents.length !== 0) {
                    let parent = parents[0]

                    if (this.meta.objects[parent].type == 'list') {
                        if (this.meta.objects[parent].touched.includes('default') === false) {
                            this.meta.objects[parent].touched.push('default')
                        }

                        this.handleValidate(this.meta.objects[parent].options.name, 'default', null)
                    }
                }

                this.handleValidate(field, child !== null ? child : 'default', value)
            },

            handleVisibleChange(field, state) {
                if (typeof this.meta.values.custom[field] == 'undefined') {
                    this.$set(this.meta.values.custom, field, {
                        'value': null,
                        'visible': state,
                        'enabled': null,
                    })
                } else {
                    this.$set(this.meta.values.custom[field], 'visible', state)
                }
            },
            handleEnabledChange(field, state) {
                let object = this.meta.objects[this.meta.map[field]]
                if (typeof object.options.default_compulsory !== 'undefined' && object.options.default_compulsory === true) {
                    if (object.options.default_value === this.meta.values.custom[field].value) {
                        state = true
                    }
                }

                if (typeof this.meta.values.custom[field] == 'undefined') {
                    this.$set(this.meta.values.custom, field, {
                        'value': null,
                        'visible': null,
                        'enabled': state,
                    })
                } else {
                    this.$set(this.meta.values.custom[field], 'enabled', state)
                }
            },

            handleValidate(field, name, value) {
                this.$set(this.meta.objects[this.meta.map[field]].errors, name, [])

                if (this.meta.objects[this.meta.map[field]].touched.includes(name) && typeof this.meta.objects[this.meta.map[field]].rules !== 'undefined' && typeof this.meta.objects[this.meta.map[field]].rules[name] !== 'undefined') {
                    for (let i = 0; i < this.meta.objects[this.meta.map[field]].rules[name].length; i++) {
                        let result = this.meta.objects[this.meta.map[field]].rules[name][i](value)
                        if (result !== true) {
                            this.meta.objects[this.meta.map[field]].errors[name].push(result)
                        }
                    }
                }
            },

            validateForm() {
                let ok = true

                for (let i = 0; i < this.meta.objects.length; i++) {
                    if (this.meta.objects[i].visible === true && Object.keys(this.meta.objects[i].rules).length !== 0) {
                        for (let key in this.meta.objects[i].rules) {
                            this.$set(this.meta.objects[i].errors, key, [])

                            for (let j = 0; j < this.meta.objects[i].rules[key].length; j++) {
                                let field = this.meta.objects[i].options.name
                                if (key !== 'default') {
                                    field += '_' + key
                                }

                                let result = this.meta.objects[i].rules[key][j](typeof this.meta.values.custom[field] !== 'undefined' ? this.meta.values.custom[field].value : null)
                                if (result !== true) {
                                    this.meta.objects[i].errors[key].push(result)

                                    ok = false
                                }
                            }
                        }
                    }
                }

                return ok;
            },

            pageLessEqual(page) {
                return this.meta.pages.indexOf(page) <= this.meta.pages.indexOf(this.meta.page)
            },

            getValue(key) {
                if (key == 'full_name') {
                    let fields = [
                        this.getValue('title'),
                        this.getValue('first_name'),
                        this.getValue('last_name')
                    ]

                    return fields.filter(e => e != null).join(' ')
                }

                if (typeof this.meta.values[key] !== 'undefined') {
                    return this.meta.values[key]
                }

                return null
            },
            setValue(key, value) {
                this.$set(this.meta.values, key, value)

                this.validate(this.meta.page, key)
            },

            getSummaryValue(key) {
                if (key == 'full_name') {
                    let fields = [
                        this.getValue('title'),
                        this.getValue('first_name'),
                        this.getValue('last_name')
                    ]

                    return fields.filter(e => e != null).join(' ')
                } else if (key == 'country') {
                    let values = this.countries.filter(country => country.code == this.getValue('country'))

                    if (typeof values[0] !== 'undefined') {
                        return values[0].name
                    }

                    return '–';
                }

                let value = this.getValue(key)
                if (value == null || value.trim() == '') {
                    return '–'
                }

                return value
            },

            validate(page, field) {
                let status = true

                if (page === 'booking') {
                    return this.validateForm()
                } else if (page === 'personal') {
                    for (let rule in this.meta.personal.rules) {
                        if (typeof field === 'undefined' || field === null || field === rule) {
                            if (Object.prototype.hasOwnProperty.call(this.meta.personal.rules, rule)) {
                                let valid = this.meta.personal.rules[rule]()
                                if (valid !== true) {
                                    this.$set(this.meta.personal.errors, rule, valid)

                                    status = false
                                } else {
                                    this.$delete(this.meta.personal.errors, rule)
                                }
                            }
                        }
                    }
                }

                return status
            },
            hasError(key) {
                return typeof this.meta.personal.errors[key] !== 'undefined'
            },
            getError(key) {
                if (this.hasError(key)) {
                    return this.meta.personal.errors[key]
                }

                return null
            },

            handleStartMember() {
                this.$set(this.meta, 'guest', false)

                if (this.user !== null) {
                    this.$set(this.meta.values, 'title', this.user.title)
                    this.$set(this.meta.values, 'first_name', this.user.first_name)
                    this.$set(this.meta.values, 'last_name', this.user.last_name)
                    this.$set(this.meta.values, 'gdc_reg_no', this.user.dentist_gdc_reg)
                    this.$set(this.meta.values, 'email', this.user.email)
                    this.$set(this.meta.values, 'website', this.user.website)

                    if (this.user.addresses.length !== 0) {
                        let address = this.user.addresses.filter(a => a.is_primary == 1)
                        if (address === null) {
                            address = this.user.addresses.filter(a => a.is_primary == 0)
                        }

                        if (address.length !== 0) {
                            address = address[0]

                            this.$set(this.meta.values, 'department', address !== null ? address.department : null)
                            this.$set(this.meta.values, 'address_1', address !== null ? address.address_1 : null)
                            this.$set(this.meta.values, 'locality', address !== null ? address.locality : null)
                            this.$set(this.meta.values, 'town', address !== null ? address.town : null)
                            this.$set(this.meta.values, 'county', address !== null ? address.county : null)
                            this.$set(this.meta.values, 'postcode', address !== null ? address.postcode : null)
                            this.$set(this.meta.values, 'country', address !== null && typeof address.country !== 'undefined' && address.country !== null ? address.country.code : null)
                            this.$set(this.meta.values, 'telephone', address !== null ? address.primary_telephone : null)
                        }
                    }
                }

                this.handleNext()
            },
            handleStartGuest() {
                this.$set(this.meta, 'guest', true)

                this.handleNext()
            },

            handleNext() {
                if (this.canNext && this.validate(this.meta.page)) {
                    this.meta.loading = true

                    axios.post('/events/' + this.event.id + '/booking', {
                        forwards: true,
                        guest: this.meta.guest,
                        values: this.meta.values,
                    }).then((response) => {
                        if (typeof response.data.continue !== 'undefined' && response.data.continue === true) {
                            const nextPage = this.meta.pages[this.meta.pages.indexOf(this.meta.page) + 1];

                            if (nextPage === "summary"){
                                const element = document.getElementById("summary-top");
                                const y = element.getBoundingClientRect().top + window.scrollY - 75;

                                window.scrollTo({top: y, behavior: 'smooth'});
                            }

                            this.$set(this.meta, 'page', nextPage);
                        } else if (typeof response.data.error !== 'undefined') {
                            this.$set(this.meta, 'error', {
                                show: true,
                                message: response.data.error
                            });
                        }

                        if (typeof response.data.payment !== 'undefined') {
                            this.$set(this.meta, 'payment', response.data.payment)
                        }

                        this.meta.loading = false
                    })
                }
            },
            handlePrevious() {
                if (this.canPrevious) {
                    this.meta.loading = true

                    axios.post('/events/' + this.event.id + '/booking', {
                        backwards: true
                    }).then((response) => {
                        if (typeof response.data.continue !== 'undefined' && response.data.continue === true) {
                            this.$set(this.meta, 'page', this.meta.pages[this.meta.pages.indexOf(this.meta.page) - 1])
                        } else if (typeof response.data.error !== 'undefined') {
                            this.$set(this.meta, 'error', {
                                show: true,
                                message: response.data.error
                            });
                        }

                        if (typeof response.data.payment !== 'undefined') {
                            this.$set(this.meta, 'payment', response.data.payment)
                        }

                        this.meta.loading = false
                    })
                }
            },
            handleGoto(page) {
                if (this.meta.pages.includes(page)) {
                    let oldIndex = this.meta.pages.indexOf(this.meta.page)
                    let newIndex = this.meta.pages.indexOf(page)
                    if (oldIndex > newIndex) {
                        this.meta.loading = true

                        axios.post('/events/' + this.event.id + '/booking', {
                            goto: page
                        }).then((response) => {
                            if (typeof response.data.continue !== 'undefined' && response.data.continue === true) {
                                this.$set(this.meta, 'page', page)
                            } else if (typeof response.data.error !== 'undefined') {
                                this.$set(this.meta, 'error', {
                                    show: true,
                                    message: response.data.error
                                });
                            }

                            if (typeof response.data.payment !== 'undefined') {
                                this.$set(this.meta, 'payment', response.data.payment)
                            }

                            this.meta.loading = false
                        })
                    }
                }
            },
        },

        computed: {
            canNext() {
                return this.meta.pages.indexOf(this.meta.page) < (this.meta.pages.length - 1)
            },
            canPrevious() {
                return this.meta.pages.indexOf(this.meta.page) > 0
            },

            total() {
                let total = 0

                for (let i = 0; i < this.meta.objects.length; i++) {
                    if (this.meta.objects[i].visible && typeof this.meta.values.custom[this.meta.objects[i].options.name] !== 'undefined') {
                        let subtotal = 0

                        if (typeof this.meta.objects[i].options.price !== 'undefined') {
                            subtotal += this.meta.objects[i].options.price
                        }

                        if (typeof this.meta.objects[i].options.discount !== 'undefined') {
                            subtotal -= this.meta.objects[i].options.discount
                        }

                        if (this.meta.values.custom[this.meta.objects[i].options.name].value === true || this.meta.values.custom[this.meta.objects[i].options.name].value == 1) {
                            total += subtotal
                        }
                    }
                }

                if (total < 0) {
                    return 0
                }

                return total
            },

            fullmetaname() {
                let name = [
                    this.meta.values.title,
                    this.meta.values.first_name,
                    this.meta.values.last_name
                ]

                return name.filter(n => n).join(' ')
            }
        }
    }
</script>
