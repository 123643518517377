<script>
    import DateTimeComponent from "./DateTimeComponent";
    import ToggleComponent from "./ToggleComponent";
    import GenericSelectComponent from "./GenericSelectComponent";
    import GenericSelectMultipleComponent from "./GenericSelectMultipleComponent";

    import XLSX from 'xlsx'
    import { saveAs } from 'file-saver'
    import Blob from 'blobjs'

    export default {
        components: {
            DateTimeComponent,
            ToggleComponent,
            GenericSelectComponent,
            GenericSelectMultipleComponent
        },

        props: {
            filter: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                showFilter: this.filter
            }
        },

        methods: {
            download() {
                let rows = [];

                let table = this.$refs.table;

                let row = [];
                table.querySelectorAll('thead th').forEach(function(th) {
                    row.push(th.innerText);
                });
                rows.push(row);

                table.querySelectorAll('tbody tr').forEach(function(tr) {
                    let row = [];
                    tr.querySelectorAll('td').forEach(function(td) {
                        row.push(td.innerText);
                    });
                    rows.push(row);
                });

                let workbook = XLSX.utils.book_new()
                workbook.SheetNames.push('Members')

                let worksheet = XLSX.utils.aoa_to_sheet(rows)
                workbook.Sheets['Members'] = worksheet

                let stream = XLSX.write(workbook, {
                    bookType:'xlsx',
                    type: 'binary'
                })

                let buffer = new ArrayBuffer(stream.length)
                let viewer = new Uint8Array(buffer)
                for (let i = 0; i < stream.length; i++) {
                    viewer[i] = stream.charCodeAt(i) & 0xFF
                }

                saveAs(new Blob([buffer], {
                    type: 'application/octet-stream'
                }), 'members.xlsx')
            }
        }
    }
</script>
