<style>
</style>

<template>
    <Field @change="(key, value) => handleChange(key, value)" @duplicate="(object) => this.$emit('duplicate', object)" @delete="(object) => this.$emit('delete', object)" @error="(element, valid) => this.$emit('error', object, element, valid)" :objects="objects" :object="object" :definition="definition">
        <div class="flex flex-wrap text-gray-700 -m-2">
            <div class="w-full p-2">
                <label for="caption" class="font-bold inline-block mb-2">Caption</label>
                <input type="text" id="caption" class="form-control" :value="options.caption" @keyup="(event) => handleChange('caption', event.target.value)">
            </div>
            <div class="w-full p-2" v-if="!this.object.options.toggled">
                <label for="description" class="font-bold inline-block mb-2">Description</label>
                <textarea id="description" class="form-control" :value="options.description" @keyup="(event) => handleChange('description', event.target.value)"></textarea>
            </div>
        </div>
        <div class="-m-4 mt-4" v-if="!this.object.options.toggled">
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'booking'">
                <h4 class="font-bold text-gray-700">Session</h4>
                <p class="text-gray-700 mb-4">Specify the session and slot associated with this option.</p>

                <div class="flex flex-wrap -m-2">
                    <div class="w-1/2 p-2">
                        <label class="form-label">Session</label>
                        <div class="relative">
                            <div class="bg-smoke-light absolute rounded inset-0 z-50" v-if="sessionsLoading">
                                <div class="flex items-center pr-2 absolute inset-y-0 right-0">
                                    <i class="fa fa-spinner fa-spin"></i>
                                </div>
                            </div>
                            <SelectComponent :options="sessions" :value="options.session" placeholder="Search Sessions..." keyColumn="id" valueColumn="title" descriptionColumn="description" :loading="sessionsLoading" @change="(value) => handleChange('session', value)"></SelectComponent>
                        </div>
                    </div>
                    <div class="w-1/2 p-2">
                        <label class="form-label">Slot</label>
                        <div class="relative">
                            <div class="bg-smoke-light absolute rounded inset-0 z-50" v-if="slotsLoading">
                                <div class="flex items-center pr-2 absolute inset-y-0 right-0">
                                    <i class="fa fa-spinner fa-spin"></i>
                                </div>
                            </div>
                            <SelectComponent :options="slots" :value="options.slot" placeholder="Search Slots..." keyColumn="id" valueColumn="title" descriptionColumn="description" :disabled="options.session == null" :loading="slotsLoading" @change="(value) => handleChange('slot', value)"></SelectComponent>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300">
                <FilterMixin type="toggle" :options="this.options" :object="this.object" :objects="this.objects" @change="(key, value) => handleChange(key, value)"></FilterMixin>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300">
                <MemberType type="toggle" :options="this.options" :object="this.object" :objects="this.objects" @change="(key, value) => handleChange(key, value)"></MemberType>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'booking'">
                <Cost :options="this.options" @change="(key, value) => handleChange(key, value)"></Cost>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300">
                <DefaultValue type="toggle" :options="this.options" @change="(key, value) => handleChange(key, value)"></DefaultValue>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'survey'">
                <FieldLimit :options="this.options" :event="this.event" @change="(key, value) => handleChange(key, value)"></FieldLimit>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'survey'">
                <DidNotAttend :options="this.options" @change="(key, value) => handleChange(key, value)"></DidNotAttend>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'survey'">
                <AppendCpd :options="this.options" @change="(key, value) => handleChange(key, value)"></AppendCpd>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'booking'">
                <MemberLimit :options="this.options" @change="(key, value) => handleChange(key, value)"></MemberLimit>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'booking'">
                <CapacityLimit :options="this.options" @change="(key, value) => handleChange(key, value)"></CapacityLimit>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300" v-if="type == 'booking'">
                <DateLimit :options="this.options" @change="(key, value) => handleChange(key, value)"></DateLimit>
            </div>
            <div class="w-full p-4 border-solid border-t border-gray-300">
                <AdditionalInformation :options="this.options" @change="(key, value) => handleChange(key, value)"></AdditionalInformation>
            </div>
        </div>
    </Field>
</template>

<script>
    import {debounce} from 'lodash'

    import Field from '../Field'

    import Cost from './Mixins/Cost'
    import FilterMixin from './Mixins/Filter'
    import DateLimit from './Mixins/DateLimit'
    import MemberLimit from './Mixins/MemberLimit'
    import MemberType from './Mixins/MemberType'
    import CapacityLimit from './Mixins/CapacityLimit'
    import AdditionalInformation from './Mixins/AdditionalInformation'
    import DefaultValue from './Mixins/DefaultValue'
    import AppendCpd from './Mixins/AppendCpd'
    import DidNotAttend from './Mixins/DidNotAttend'
    import FieldLimit from './Mixins/FieldLimit'

    import SelectComponent from '../../GenericSelectComponent'
    import Toggle from "../../GenericToggleComponent"

    export default {
        components: {
            Field,

            Cost,
            FilterMixin,
            DateLimit,
            MemberLimit,
            MemberType,
            CapacityLimit,
            AdditionalInformation,
            DefaultValue,
            AppendCpd,
            DidNotAttend,
            FieldLimit,

            SelectComponent,
            Toggle
        },

        props: {
            type: {
                type: String,
                required: true
            },
            objects: {
                type: Array,
                default: () => {
                    return []
                }
            },
            object: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            event: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        created() {
            debounce(() => {
                this.sessionsLoading = true

                axios.get('/api/events/' + this.event.id + '/sessions/select').then(response => {
                    this.sessions = response.data
                    this.sessionsLoading = false
                })
            }, 200)()
        },

        data() {
            return {
                definition: Definition,

                sessionsLoading: false,
                sessions: [],

                slotsLoading: false,
                slots: []
            }
        },

        methods: {
            handleChange(key, value) {
                if (key == 'session') {
                    if (value == null) {
                        this.slots = []
                    } else {
                        debounce(() => {
                            this.slotsLoading = true

                            axios.get('/api/events/' + this.event.id + '/sessions/' + value + '/slots/select').then(response => {
                                this.slots = response.data
                                this.slotsLoading = false
                            })
                        }, 200)()
                    }
                }

                this.$emit('change', this.object.internal, key, value)
            }
        },

        computed: {
            options() {
                const defaults = {
                    caption: '',
                    description: '',
                    allow_did_not_attend: true,
                    groups: {
                        member: true,
                        non_member: true
                    },
                }

                return Object.assign({}, defaults, this.object.options)
            }
        }
    }

    export const Definition = {
        name: 'toggle',
        title: 'Option',
        icon: 'toggle-on'
    }
</script>
