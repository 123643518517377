<template>
    <div class="flex justify-center">

        <button class="button text-gray-700 text-sm border px-2 py-1 mr-1" @click="onClickFirstPage" :disabled="isFirstPage">First</button>
        <button class="button text-gray-700 text-sm border px-2 py-1 mr-1" @click="onClickPreviousPage" :disabled="isFirstPage">Previous
        </button>

        <button class="is-paginated"
                v-for="page in pages"
                :key="page.name"
                :disabled="page.isDisabled"
                :class="{ 'is-active' : currentPage === page.name }"
                @click="onClickPageNumber(page.name)">{{ page.name }}
        </button>


        <button class="button text-gray-700 text-sm border px-2 py-1 mr-1" @click="onClickNextPage" :disabled="isLastPage">Next</button>
        <button class="button text-gray-700 text-sm border px-2 py-1" @click="onClickLastPage" :disabled="isLastPage">Last</button>
    </div>
</template>

<script>
    export default {
        props: {
            currentPage: {
                type: Number,
                required: true,
            },
            perPage: {
                type: Number,
                default: 10
            },
            total: {
                type: Number,
                required: true,
            },
            maxVisibleButtons: {
                type: Number,
                default: 3
            }
        },

        methods: {
            onClickFirstPage() {
                this.$emit('pagechange', 1);
            },

            onClickPreviousPage() {
                this.$emit('pagechange', this.currentPage - 1);
            },

            onClickNextPage() {
                this.$emit('pagechange', this.currentPage + 1);
            },

            onClickLastPage() {
                this.$emit('pagechange', this.totalPages);
            },

            onClickPageNumber(page) {
                this.$emit('pagechange', page);
            }
        },

        computed: {
            totalPages() {
              return Math.ceil(this.total / this.perPage);
            },

            startPage() {
                if (this.currentPage === 1) {
                    return 1;
                }

                if (this.currentPage === this.totalPages) {
                    return this.totalPages - (this.maxVisibleButtons - 1);
                }

                return this.currentPage - 1;
            },

            pages() {
                const range = [];

                for (let i = this.startPage; i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages); i++) {
                    if (i > 0) {
                        range.push({name: i, isDisabled: i === this.currentPage});
                    }
                }

                return range;
            },

            isFirstPage() {
                return this.currentPage === 1;
            },

            isLastPage() {
                return this.currentPage === this.totalPages;
            },
        }
    }

</script>
