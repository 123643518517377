<style>
</style>

<template>
    <div>
        <h4 class="font-bold text-gray-700">Additional Information</h4>
        <p class="text-gray-700 mb-4">When this field is used, you can request additional information from the user, optionally making it mandatory to respond.</p>

        <div class="flex flex-wrap -m-2">
            <div class="p-2">
                <label class="flex items-center">
                    <Toggle :value="options.request_additional" @change="(event) => handleChange('request_additional', event)"></Toggle>
                    <div class="ml-2 mb-0 form-label">Request additional information</div>
                </label>
            </div>

            <div class="w-full p-2" v-if="typeof options.request_additional !== 'undefined' && options.request_additional != ''">
                <label class="block mb-4 text-gray-700 w-full">
                    <label for="additional_prompt" class="font-bold inline-block mb-2">Prompt for information</label>
                    <input type="text" id="additional_prompt" class="form-control" :value="options.additional_prompt" @keyup="(event) => handleChange('additional_prompt', event.target.value)">
                </label>
                <div class="flex">
                    <label class="flex items-center">
                        <Toggle :value="options.additional_mandatory" @change="(event) => handleChange('additional_mandatory', event)"></Toggle>
                        <div class="ml-2 mb-0 form-label">Require a response</div>
                    </label>
                    <label class="flex items-center">
                        <Toggle :value="options.additional_multiline" @change="(event) => handleChange('additional_multiline', event)"></Toggle>
                        <div class="ml-2 mb-0 form-label">Accept multiline text</div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Toggle from "../../../GenericToggleComponent"

    export default {
        components: {
            Toggle
        },

        props: {
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        methods: {
            handleChange(key, value) {
                this.$emit('change', key, value)
            }
        }
    }
</script>
