<style>
</style>

<template>
    <div>
        <a class="button is-tertiary w-full mb-4" :href="`/admin/events/` + event.id + `/edit`"><i class="fas fa-sign-out-alt mr-2"></i> Go Back</a>

        <div class="flex mb-4">
            <a @click.prevent="() => handleToggleAll(true)" href="" class="button is-primary flex-1 mr-2"><i class="fas fa-arrow-down mr-3"></i> <span class="text-center w-full">Expand</span></a>
            <a @click.prevent="() => handleToggleAll(false)" href="" class="button is-primary flex-1 ml-2"><i class="fas fa-arrow-up mr-3"></i> <span class="text-center w-full">Collapse</span></a>
        </div>

        <div class="wrapper templates shadow rounded overflow-hidden">
            <draggable :list="templates" :clone="clone" v-bind="options()" :move="onMove" @start="isDragging = true" @end="isDragging = false">
                <div v-for="(element, index) in templates" :key="element.name">
                    <div class="field bg-white font-bold text-gray-700 px-4 py-2" :class="[ index === 0 ? 'rounded-t' : 'border-t border-gray-200', index === Object.keys(templates).length - 1 ? 'rounded-b border-0' : '' ]">
                        <h4 class="font-bold"><i class="fas mr-2" :class="[ 'fa-' + element.icon ]"></i> {{ element.title }}</h4>
                    </div>
                </div>
            </draggable>
        </div>

        <button class="button is-primary w-full mt-4" @click="save" :disabled="saving || !canSave"><i class="fas mr-2" :class="[ saving ? 'fa-spinner fa-spin' : 'fa-save']"></i> {{ saving ? 'Saving...' : (canSave ? 'Save' : 'Errors Found') }}</button>
    </div>
</template>

<script>
    export default {
        props: {
            reference: {
                type: String,
                required: true
            },
            eventData: {
                type: Object,
                required: true
            },
            templates: {
                type: Array,
                default: () => {
                    return []
                }
            },
            clone: {
                type: Function,
                required: true
            },
            save: {
                type: Function,
                required: true
            },
            saving: {
                type: Boolean,
                default: false
            },
            canSave: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                isDragging: true,

                event: this.eventData
            }
        },

        methods: {
            options() {
                return {
                    group: {
                        name: this.reference,
                        pull: 'clone',
                        put: false
                    },
                    componentData: {
                        attrs: {
                            class: 'group templates'
                        }
                    }
                }
            },

            handleToggleAll(value) {
                this.$emit('toggleall', value)
            },

            onMove(event, originalEvent) {
                if (event.draggedContext.element.name == 'toggle') {
                    if (event.to.previousElementSibling !== null && !event.to.previousElementSibling.classList.contains('field-group') && !event.to.previousElementSibling.classList.contains('field-list')) {
                        return false;
                    }
                } else {
                    if (event.to.previousElementSibling !== null && !event.to.previousElementSibling.classList.contains('field-group')) {
                        return false
                    }
                }


                return event.from != event.to
            }
        }
    }
</script>
