<template>
    <div class="border border-gray-300 p-4 mt-4" v-if="visible || this.admin">
        <div v-if="this.admin && !visible" class="bg-red font-bold text-white px-4 py-2 -mt-4 -mx-4 mb-4">
            Only visible to administrators
        </div>

        <div class="border border-red px-4 py-3 mb-4" v-if="notice" v-html="notice"></div>

        <h1 class="text-xl leading-none" v-if="this.object.options.caption">{{ this.object.options.caption }}</h1>
        <p v-if="this.object.options.description" v-html="this.object.options.description"></p>

        <div class="mt-4 flex items-center">
            <Toggle :value="getValue()" @change="(value) => handleChange(value)" :disabled="disabled" :name="object.options.name"></Toggle>
            <div class="inline-block bg-gray-300 ml-2 rounded px-2 py-1 text-sm leading-none" v-if="cost">£{{ cost }}</div>
            <div class="inline-block bg-gray-300 ml-2 rounded px-2 py-1 text-sm leading-none" v-if="this.object.options.has_capacity && this.admin">{{ usedCapacity}} spaces booked</div>
        </div>

        <div class="mt-4" v-if="this.object.options.request_additional && getValue()">
            <label :class="[ 'form-label', this.object.options.additional_mandatory ? 'is-required' : null ]" v-text="this.object.options.additional_prompt"></label>
            <input v-if="!this.object.options.additional_multiline" type="text" class="form-control" :value="getChildValue('additional')" :placeholder="this.object.options.additional_prompt" @input="(event) => handleChildChange('additional', event.target.value)" :disabled="this.readonly">
            <textarea :ref="`textarea-${this.object.options.name}-additional`" v-if="this.object.options.additional_multiline" class="form-control" :value="getChildValue('additional')" :placeholder="this.object.options.additional_prompt" @input="(event) => handleChildChange('additional', event.target.value)" :disabled="this.readonly"></textarea>
            <span class="invalid-input" v-if="getChildError('additional')">{{ getChildError('additional') }}</span>
        </div>
    </div>
</template>

<script>
    import autosize from 'autosize'

    import Toggle from "../../../GenericToggleComponent"

    import { default as field } from '../Mixins/Field'

    export default {
        mixins: [
            field
        ],

        components: {
            Toggle
        },

        props: {
            userGroup: {
                type: String,
                required: false
            }
        },

        mounted() {
            if (this.object.options.additional_multiline) {
                autosize(this.$refs['textarea-' + this.object.options.name + '-additional'])
            }
        },

        updated() {
            if (this.object.options.additional_multiline) {
                autosize(this.$refs['textarea-' + this.object.options.name + '-additional'])
            }
        }
    }
</script>
