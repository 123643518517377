<template>
    <div>
        <div class="mb-5 flex items-center">
            <h2 class="font-bold text-2xl text-gray-700"><span class="font-normal">Total</span> £{{ total.toFixed(2) }}</h2>

            <div class="flex ml-auto mr-4">
                <span class="font-bold text-gray-700 mr-3">Guest</span>
                <GenericToggleComponent :value="meta.guest" @change="(value) => meta.guest = value"></GenericToggleComponent>
            </div>

            <div class="flex mr-4">
                <span class="font-bold text-gray-700 mr-3">Member</span>
                <GenericToggleComponent :value="meta.user.active_subscription_id !== null" @change="(value) => value ? meta.user.active_subscription_id = 1 : meta.user.active_subscription_id = null"></GenericToggleComponent>
            </div>

            <div class="flex">
                <span class="font-bold text-gray-700 mr-3">Admin</span>
                <GenericToggleComponent :value="meta.admin" @change="(value) => meta.admin = value"></GenericToggleComponent>
            </div>
        </div>

        <div class="mb-5 flex items-center" v-if="meta.user.active_subscription_id == 1">
            <div class="ml-auto">
                <span class="font-bold text-gray-700">Member Type</span>
                <GenericSelectComponent name="member_types" :value="meta.member_type.value" :options="meta.member_type.options" :loading="meta.member_type.loading" key-column="name" value-column="name" @change="handleSelectMemberType"></GenericSelectComponent>
            </div>
        </div>

        <keep-alive v-for="(child, index) in meta.layout" :key="index">
            <component
                :is="meta.objects[child.internal].component"
                :field="child"

                :form="name"

                :guest="meta.guest"
                :admin="meta.admin"
                :user="meta.guest ? null : meta.user"
                :event="event"

                :map="meta.map"
                :object="meta.objects[child.internal]"
                :layout="meta.layout"
                :objects="meta.objects"
                :values="meta.values.custom"
            ></component>
        </keep-alive>
    </div>
</template>

<script>
    import GenericToggleComponent from '../../GenericToggleComponent'
    import GenericSelectComponent from '../../GenericSelectComponent'

    import GroupField from './Fields/Group'
    import InputField from './Fields/Input'
    import TextField from './Fields/Text'
    import ToggleField from './Fields/Toggle'
    import ListField from './Fields/List'

    export default {
        name: 'BookingFormPreview',

        components: {
            GenericToggleComponent,
            GenericSelectComponent,

            GroupField,
            InputField,
            TextField,
            ToggleField,
            ListField
        },

        props: {
            name: {
                type: String,
                required: true,
            },

            event: {
                type: Object,
                required: true
            },
            layout: {
                type: Array,
                required: true
            },
            objects: {
                type: Array,
                required: true
            },
            membertypes: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                meta: {
                    guest: false,
                    admin: false,
                    user: {
                        active_subscription_id: 1
                    },

                    map: {},
                    flipped: {},

                    objects: this.objects,
                    layout: this.layout,
                    values: {
                        custom: {}
                    },
                    member_type: {
                        value: 'Full - Dentist Member',
                        options: this.membertypes,
                        loading: false
                    }
                }
            }
        },

        created() {
            window.EventBus.$on(this.name + '_value', (field, name, value) => {
                this.handleChange(field, name, value)
            })

            window.EventBus.$on(this.name + '_visible', (field, state) => {
                this.handleVisibleChange(field, state)
            })

            window.EventBus.$on(this.name + '_enabled', (field, state) => {
                this.handleEnabledChange(field, state)
            })
        },

        mounted() {
            this.init()
        },

        methods: {
            init() {
                let parents = {}
                let children = {}

                let recurse = (tree, parent) => {
                    for (let i = 0; i < tree.length; i++) {
                        parents[tree[i].internal] = []
                        if (parent !== null) {
                            parents[tree[i].internal] = [
                                parent,
                                ...parents[parent]
                            ]
                        }

                        children[tree[i].internal] = []
                        if (parent !== null) {
                            children[parent] = [
                                tree[i].internal,
                                ...children[parent]
                            ]
                        }

                        if (tree[i].children.length !== 0) {
                            recurse(tree[i].children, tree[i].internal)
                        }
                    }
                }

                recurse(this.layout, null)

                for (let i = 0; i < this.meta.objects.length; i++) {
                    this.$set(this.meta.map, this.meta.objects[i].options.name, this.meta.objects[i].internal)

                    if (typeof this.meta.values.custom[this.meta.objects[i].options.name] === 'undefined') {
                        this.$set(this.meta.values.custom, this.meta.objects[i].options.name, {
                            value: null,
                            visible: null,
                            enabled: null
                        })
                    }

                    if (typeof this.meta.objects[i].options.has_default !== 'undefined' && this.meta.objects[i].options.has_default) {
                        if ((typeof this.meta.objects[i].options.default_compulsory !== 'undefined' && this.meta.objects[i].options.default_compulsory) || typeof this.meta.values.custom[this.meta.objects[i].options.name] === 'undefined' || this.meta.values.custom[this.meta.objects[i].options.name].value === null) {
                            this.$set(this.meta.values.custom[this.meta.objects[i].options.name], 'value', this.meta.objects[i].options.default_value)
                        }
                    }

                    this.$set(this.meta.objects[i], 'touched', [])
                    this.$set(this.meta.objects[i], 'visible', null)

                    this.$set(this.meta.objects[i], 'fields', [
                        this.meta.objects[i].options.name
                    ])

                    this.$set(this.meta.objects[i], 'rules', {})
                    this.$set(this.meta.objects[i], 'errors', {})
                    this.$set(this.meta.objects[i], 'parents', parents[this.meta.objects[i].internal])
                    this.$set(this.meta.objects[i], 'children', children[this.meta.objects[i].internal])

                    switch (this.meta.objects[i].type) {
                        case 'list':
                            this.$set(this.meta.objects[i], 'component', 'ListField')

                            this.$set(this.meta.objects[i].rules, 'default', [
                                (value) => {
                                    let total = 0

                                    let check = this.meta.objects.filter(object => object.parents.includes(this.meta.objects[i].internal))
                                    for (let e = 0; e < check.length; e++) {
                                        if (check[e].type == 'toggle') {
                                            if (this.meta.values.custom[check[e].options.name].value == true) {
                                                total++
                                            }
                                        }
                                    }

                                    if (this.meta.objects[i].options.group_rule == 'one' && total !== 1) {
                                        return 'Please choose exactly one option'
                                    } else if (this.meta.objects[i].options.group_rule == 'gte_one' && total < 1) {
                                        return 'Please choose at least one option'
                                    } else if (this.meta.objects[i].options.group_rule == 'lte_one' && total > 1) {
                                        return 'Please choose at most one option'
                                    }

                                    return true
                                }
                            ])

                            break
                        case 'group':
                            this.$set(this.meta.objects[i], 'component', 'GroupField')
                            break
                        case 'input':
                            this.$set(this.meta.objects[i], 'component', 'InputField')

                            this.$set(this.meta.objects[i].rules, 'default', [
                                (value) => {
                                    if (this.meta.objects[i].options.required && (value == null || value.trim() == '')) {
                                        return 'This field is required'
                                    }

                                    return true
                                }
                            ])

                            break
                        case 'text':
                            this.$set(this.meta.objects[i], 'component', 'TextField')
                            break
                        case 'toggle':
                            this.$set(this.meta.objects[i], 'component', 'ToggleField')

                            this.meta.objects[i].fields.push('additional')

                            this.$set(this.meta.objects[i].rules, 'additional', [
                                (value) => {
                                    if (this.meta.values.custom[this.meta.objects[i].options.name].value === true && this.meta.objects[i].options.request_additional && this.meta.objects[i].options.additional_mandatory && (value == null || value.trim() == '')) {
                                        return 'This field is required'
                                    }

                                    return true
                                }
                            ])

                            break
                    }
                }
            },

            handleSelectMemberType(value) {
                window.EventBus.$emit('preview_membertype', value);
            },

            handleChange(field, name, value) {
                let child = null
                if (name.indexOf('::') !== -1) {
                    child = name.split('::', 2)[1]
                }

                name = name.replace('::', '_')

                if (typeof this.meta.values.custom[name] == 'undefined') {
                    this.$set(this.meta.values.custom, name, {
                        'value': value,
                        'visible': null,
                        'enabled': null,
                    })
                } else {
                    this.$set(this.meta.values.custom[name], 'value', value)
                }

                if (this.meta.objects[this.meta.map[field]].touched.includes(child !== null ? child : 'default') === false) {
                    this.meta.objects[this.meta.map[field]].touched.push(child !== null ? child : 'default')
                }

                let parents = this.meta.objects[this.meta.map[field]].parents

                if (parents.length !== 0) {
                    for (let i = 0; i < parents.length; i++) {
                        if (this.meta.objects[parents[i]].type == 'list') {
                            if (this.meta.objects[parents[i]].touched.includes('default') === false) {
                                this.meta.objects[parents[i]].touched.push('default')
                            }

                            this.handleValidate(this.meta.objects[parents[i]].options.name, 'default', null)
                        }
                    }
                }

                this.handleValidate(field, child !== null ? child : 'default', value)
            },

            handleVisibleChange(field, state) {
                if (typeof this.meta.values.custom[field] == 'undefined') {
                    this.$set(this.meta.values.custom, field, {
                        'value': null,
                        'visible': state,
                        'enabled': null,
                    })
                } else {
                    window.EventBus.$emit(field + '_visible', state);
                    this.$set(this.meta.values.custom[field], 'visible', state)
                }
            },
            handleEnabledChange(field, state) {
                if (typeof this.meta.values.custom[field] == 'undefined') {
                    this.$set(this.meta.values.custom, field, {
                        'value': null,
                        'visible': null,
                        'enabled': state,
                    })
                } else {
                    this.$set(this.meta.values.custom[field], 'enabled', state)
                }
            },

            handleValidate(field, name, value) {
                this.$set(this.meta.objects[this.meta.map[field]].errors, name, [])

                if (this.meta.objects[this.meta.map[field]].touched.includes(name) && typeof this.meta.objects[this.meta.map[field]].rules !== 'undefined' && typeof this.meta.objects[this.meta.map[field]].rules[name] !== 'undefined') {
                    for (let i = 0; i < this.meta.objects[this.meta.map[field]].rules[name].length; i++) {
                        let result = this.meta.objects[this.meta.map[field]].rules[name][i](value)
                        if (result !== true) {
                            this.meta.objects[this.meta.map[field]].errors[name].push(result)
                        }
                    }
                }
            },

            validateForm() {
                let ok = true

                for (let i = 0; i < this.meta.objects.length; i++) {
                    if (this.meta.objects[i].visible === true && Object.keys(this.meta.objects[i].rules).length !== 0) {
                        for (let key in this.meta.objects[i].rules) {
                            this.$set(this.meta.objects[i].errors, key, [])

                            for (let j = 0; j < this.meta.objects[i].rules[key].length; j++) {
                                let field = this.meta.objects[i].options.name
                                if (key !== 'default') {
                                    field += '_' + key
                                }

                                let result = this.meta.objects[i].rules[key][j](typeof this.meta.values.custom[field] !== 'undefined' ? this.meta.values.custom[field].value : null)
                                if (result !== true) {
                                    this.meta.objects[i].errors[key].push(result)

                                    ok = false
                                }
                            }
                        }
                    }
                }

                return ok;
            },

            pageLessEqual(page) {
                return this.meta.pages.indexOf(page) <= this.meta.pages.indexOf(this.meta.page)
            },

            getValue(key) {
                if (typeof this.meta.values[key] !== 'undefined') {
                    return this.meta.values[key]
                }

                return null
            },
            setValue(key, value) {
                this.$set(this.meta.values, key, value)

                this.validate(this.meta.page, key)
            },

            validate(page, field) {
                return this.validateForm()
            },
            hasError(key) {
                return typeof this.meta.personal.errors[key] !== 'undefined'
            },
            getError(key) {
                if (this.hasError(key)) {
                    return this.meta.personal.errors[key]
                }

                return null
            }
        },

        computed: {
            total() {
                let total = 0

                for (let i = 0; i < this.meta.objects.length; i++) {
                    if (this.meta.objects[i].visible && typeof this.meta.values.custom[this.meta.objects[i].options.name] !== 'undefined') {
                        let subtotal = 0

                        if (typeof this.meta.objects[i].options.price !== 'undefined') {
                            subtotal += this.meta.objects[i].options.price
                        }

                        if (typeof this.meta.objects[i].options.discount !== 'undefined') {
                            subtotal -= this.meta.objects[i].options.discount
                        }

                        if (this.meta.values.custom[this.meta.objects[i].options.name].value === true || this.meta.values.custom[this.meta.objects[i].options.name].value == 1) {
                            total += subtotal
                        }
                    }
                }

                if (total < 0) {
                    return 0
                }

                return total
            }
        }
    }
</script>
