<template>
    <div class="relative">
        <div class="dropdown" :class="dropdown" @click.prevent="isOpen = !isOpen">
            <slot name="trigger"></slot>
        </div>

        <div class="dropdown-menu" v-show="isOpen" :class="dropdownMenu">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['dropdown', 'dropdownMenu'],

        data() {
            return {
                isOpen: false
            }
        },

        watch: {
            isOpen(isOpen) {
                if (isOpen) {
                    document.addEventListener('click', this.closeIfClickedOutside);
                }
            }
        },

        methods: {
            closeIfClickedOutside(event) {
                if (!event.target.closest('.dropdown')) {
                    this.isOpen = false;
                    document.removeEventListener('click', this.closeIfClickedOutside);
                }
            }
        }
    }
</script>

