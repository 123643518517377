<template>
    <div>
        <vue-hcaptcha ref="captcha" sitekey="2c0a8df8-37dd-4192-aee2-d4af924f298b" @verify="onVerify"></vue-hcaptcha>
        <a href="" @click.prevent="() => reload()">Reload Captcha</a>
    </div>
</template>

<script>
	import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

	export default {
		name: 'Captcha',

		components: {
			VueHcaptcha
		},

		prop: [
			'value'
		],

		data () {
			return {
				output: this.value
			}
        },

		methods: {
			handleInput (e) {
				this.$emit('input', this.output)
            },
            reload () {
                this.$refs.captcha.reset();
            },
			onVerify (response) {
				this.output = response

				this.$emit('input', this.output)
			}
		}
	}
</script>
