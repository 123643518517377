<template>
    <div>
        <slot :event="eventProp" :booking="bookingProp" :customCertificate="customCertificateInternal" :customCertificateChange="customCertificateChange" :eventBookingChange="eventBookingChange" name="primary"></slot>
        <slot :event="eventProp" :booking="bookingProp" :customCertificate="customCertificateInternal" :customCertificateChange="customCertificateChange" :eventBookingChange="eventBookingChange" name="secondary"></slot>
    </div>
</template>

<script>
    export default {
        props: {
            customCertificate: {
                type: Boolean|Number,
                default: true
            },

            event: {
                type: Number,
                required: false
            },
            user: {
                type: Number,
                required: false
            }
        },

        methods: {
            customCertificateChange(event) {
                this.$set(this, 'customCertificateInternal', event)
            },
            eventBookingChange(event) {
                this.$set(this, 'eventProp', event.event)
                this.$set(this, 'bookingProp', event.booking)
            }
        },

        data() {
            return {
                customCertificateInternal: typeof this.customCertificate === 'number' ? (this.customCertificate == '1' ? true : false) : this.customCertificate,
                eventProp: null,
                bookingProp: null,
            }
        }
    }
</script>
