<template>
    <div class="relative h-full">
        <div class="absolute w-full h-full z-10 flex justify-center items-center" v-if="loading">
            <div class="fa-2x">
                <i class="fas fa-spinner fa-spin text-gray-500"></i>
            </div>
        </div>
        <div class="mt-2 pb-10">
            <table class="-my-2 table data-table w-full">
                <thead>
                    <th class="pl-10 pr-10 text-sm">Booking</th>
                    <th class="pl-10 pr-10"></th>
                </thead>
                <tbody>
                    <tr v-for="(booking, key) in bookings" :key="key">
                        <td class="pl-10 pr-5 w-full align-middle">
                            <h5 class="leading-none text-sm">
                                <a :href="[ '/admin/events/' + booking.event.id + '/edit' ]">{{ booking.event.name }}</a>
                            </h5>
                            <h4 class="leading-none mt-2 font-bold text-l my-0">{{ booking.name }}</h4>
                            <span v-if="booking.approved" class="leading-none mt-2 inline-block bg-green-lighter rounded px-2 py-1 text-xs">Approved</span>
                            <span v-if="!booking.approved" class="leading-none mt-2 inline-block bg-orange-lighter rounded px-2 py-1 text-xs">Pending</span>
                            <span v-if="booking.cancelled" class="leading-none mt-2 inline-block bg-red-lighter rounded px-2 py-1 text-xs">Cancelled</span>
                        </td>
                        <td class="pl-5 pr-10 align-middle text-center">
                            <a :href="[ '/admin/events/bookings/' + booking.id + '/edit' ]" class="button is-small is-tertiary inline-block"><i class="fas fa-edit"></i></a>
                        </td>
                    </tr>
                    <tr v-if="bookings.length == 0">
                        <td class="px-10 text-center" style="padding-top:1.25rem" colspan="2">
                            <div>Sorry, no results were returned from this table</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        data() {
            return {
                loading: true,
                bookings: []
            }
        },

        created() {
            this.fetch();
        },

        methods: {
			fetch() {
                this.loading = true;

                axios.get('/api/events/bookings/dashboard', {
                    params: Object.assign({
                        limit: 5
                    }, this.params)
                }).then(response => {
                    this.bookings = []

                    response.data.forEach((data) => {
                        this.bookings.push({
                            id: data.id,
                            event: {
                                id: data.event.id,
                                name: data.event.title
                            },
                            name: data.fullname,
                            approved: data.approved,
                            cancelled: data.cancelled,
                        })
                    });

                    this.loading = false;
                })
            }
        }
    }
</script>
