<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield;
    }
</style>

<template>
    <div class="flex w-full">
        <div class="relative flex-1">
            <input type="number" :class="[ 'form-control', !disabled ? 'rounded-r-none' : '' ]" :name="name" :value="number" :min="min" :max="max" :step="step" :disabled="disabled" @input="handleInput">
            <div v-if="nullable && !disabled && number != null" class="absolute inset-y-0 right-0 flex items-center">
                <button class="cursor-pointer px-4" @click.prevent="handleClear">&times;</button>
            </div>
        </div>
        <button v-if="!disabled" @click.prevent="handleIncrement" class="button is-tertiary border-l-none rounded-none"><i class="fa fa-plus"></i></button>
        <button v-if="!disabled" @click.prevent="handleDecrement" class="button is-tertiary rounded-l-none"><i class="fa fa-minus"></i></button>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                required: false
            },
            value: {
                type: Number,
                required: false
            },
            min: {
                type: String|Number,
                required: false
            },
            max: {
                type: String|Number,
                required: false
            },
            step: {
                type: String|Number,
                required: false
            },
            nullable: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                number: this.value == '' ? null : this.value
            }
        },

        methods: {
            handleIncrement() {
                if (this.number == null || this.number < 0) {
                    this.number = 0
                } else {
                    this.number++
                }
            },

            handleDecrement() {
                if (this.number == null || this.number <= 0) {
                    this.number = 0
                } else {
                    this.number--
                }
            },

            handleClear() {
                this.number = null
            },

            handleInput(event) {
                this.number = event.target.value.replace(/[^\d.-]/g, '')
            }
        }
    }
</script>
