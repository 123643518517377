<template>
    <generic-select-component :id="id" :name="name" :options="options" :value="value" keyColumn="code" valueColumn="code" descriptionColumn="name" @change="handleChange"></generic-select-component>
</template>

<script>
    import SelectComponent from "./SelectComponent";
    import GenericSelectComponent from "./GenericSelectComponent";

    export default {
        components: {
            SelectComponent,
            GenericSelectComponent
        },

        props: {
            options: {
                type: Array,
                required: true
            },
            id: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: false
            },
            value: {
                type: Number|String,
                required: false
            }
        },

        methods: {
            handleChange(e) {
                this.$emit('addSubscriptionCodeItem', e);
            }
        }
    }
</script>
