<template>
    <div :class="[ 'border p-4 mt-4 transition duration-500 ease-in-out', getError() ? 'border-red' : 'border-gray-300' ]" v-if="visible || this.admin">
        <div v-if="this.admin && !visible" class="bg-red font-bold text-white px-4 py-2 -mt-4 -mx-4 mb-4">
            Only visible to administrators
        </div>

        <div class="border border-red px-4 py-3 mb-4" v-if="notice" v-html="notice"></div>

        <h1 class="text-xl leading-none" v-if="this.object.options.caption">{{ this.object.options.caption }}</h1>
        <p v-if="this.object.options.description" v-html="this.object.options.description"></p>

        <span class="invalid-input" v-if="getError()">{{ getError() }}</span>

        <div class="children" v-if="field.children.length !== 0">
            <keep-alive v-for="(child, index) in field.children" :key="index">
                <component
                    :is="objects[child.internal].component"
                    :field="child"

                    :form="form"
                    :readonly="readonly"
                    :admin="admin"

                    :user="user"
                    :event="event"
                    :bookingValues="bookingValues"

                    :map="map"
                    :object="objects[child.internal]"
                    :layout="layout"
                    :objects="objects"
                    :values="values"
                ></component>
            </keep-alive>
        </div>
    </div>
</template>

<script>
    import GroupField from './Group'
    import InputField from './Input'
    import TextField from './Text'
    import ToggleField from './Toggle'
    import RatingField from './Rating'

    import { default as field } from '../Mixins/Field'

    export default {
        mixins: [
            field
        ],

        name: 'ListField',

        components: {
            GroupField,
            InputField,
            TextField,
            ToggleField,
            RatingField,
        }
    }
</script>
