<style>
</style>

<template>
    <div>
        <h4 class="font-bold text-gray-700">Cost</h4>
        <p class="text-gray-700 mb-4">Enter the details of the cost of this option.</p>

        <div class="flex flex-wrap -mx-2 md:flex-no-wrap">
            <div class="w-full px-2 md:w-1/2">
                <label class="form-label">Price</label>
                <div class="relative w-full">
                    <Currency :price="typeof options.price !== 'undefined' ? options.price : 0" @onChange="(event) => handleChange('price', event)"></Currency>
                    <span class="absolute top-0 left-4 h-full flex items-center text-gray-400">£</span>
                </div>
            </div>
            <div class="w-full px-2 md:w-1/2">
                <label class="form-label">Discount</label>
                <div class="relative w-full">
                    <Currency :price="typeof options.discount !== 'undefined' ? options.discount : 0" @onChange="(event) => handleChange('discount', event)"></Currency>
                    <span class="absolute top-0 left-4 h-full flex items-center text-gray-400">£</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Currency from "../../../CurrencyComponent"

    export default {
        components: {
            Currency
        },

        props: {
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        methods: {
            handleChange(key, value) {
                this.$emit('change', key, value)
            }
        }
    }
</script>
