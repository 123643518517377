<template>
    <div class="container mx-auto mb-10" v-if="isVisible">
        <div class="toast card p-5 text-gray-700 flex justify-between items-center" v-bind:class="{ 'is-danger': this.type == 'error', 'is-primary': this.type == 'success' }">
            <div>
                <h5 class="p-0 m-0 mb-1 font-bold" v-if="this.title"><i class="fas" v-if="this.type" v-bind:class="{ 'fa-exclamation-triangle': this.type == 'error', 'fa-check-circle': this.type == 'success' }"></i> {{ this.title }}</h5>
                <p class="p-0 m-0"><i class="fas" v-if="this.type && !this.title" v-bind:class="{ 'fa-exclamation-triangle': this.type == 'error', 'fa-check-circle': this.type == 'success' }"></i> <slot></slot></p>
            </div>
            <button class="remove-card appearance-none" @click="isVisible = false">&times;</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String
            },
            title: {
                type: String
            }
        },

        data() {
            return {
                isVisible: true
            }
        }
    }
</script>
