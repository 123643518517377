<template>
    <div>
        <table class="w-full border-collapse">
            <thead>
                <tr>
                    <th class="text-left form-label table-cell is-required">Price</th>
                    <th class="text-left form-label table-cell is-required">Year</th>
                    <th v-if="showDelete" class="w-px"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in data" :key="row.key">
                    <td>
                        <div class="relative w-full lg:mb-0 lg:w-auto">
                            <currency-component :price="row.net_price" v-on:onChange="setRowPrice(index, $event)"></currency-component>
                            <i class="fas fa-pound-sign text-sm absolute top-0 left-0 h-full flex items-center px-4 text-gray-400"></i>
                        </div>
                    </td>
                    <td>
                        <div class="inline-block relative w-full">
                            <select class="form-control" :value="row.year" v-on:change="setRowYear(index, $event)">
                                <option v-for="(year, key) in getYears" :key="key">{{ year }}</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg class="fill-current h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                </svg>
                            </div>
                        </div>
                    </td>
                    <td v-if="showDelete">
                        <button class="button is-secondary" @click.prevent="remove(index)">&times;</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="mt-5">
            <button class="button is-primary" @click.prevent="add(0, year)"><i class="fas fa-plus mr-3"></i> Add</button>
        </div>
        
        <template v-for="(row, index) in data">
            <div v-bind:key="row.key">
                <input type="hidden" :name="'prices[' + index + '][net_price]'" :value="row.net_price">
                <input type="hidden" :name="'prices[' + index + '][year]'" :value="row.year">
            </div>
        </template>
    </div>
</template>

<script>
    import CurrencyComponent from "./CurrencyComponent";

    export default {
        components: {
            CurrencyComponent
        },

        props: {
            prices: {
                type: Array,
                required: false
            }
        },

        data() {
            let date = new Date()
            let year = date.getFullYear()

            let years = []
            let startYear = year + 10
            while (startYear >= year - 10) {
                years.push(startYear--)
            }

            return {
                counter: 0,
                data: [],
                year: year,
                years: years
            }
        },

        mounted() {
            if (this.prices.length > 0) {
                for (let i = 0; i < this.prices.length; i++) {
                    this.add(this.prices[i].net_price, this.prices[i].year)
                }
            } else {
                this.add(0, this.year)
            }
        },

        methods: {
            add(net_price, year) {
                this.counter += 1

                this.data.push({
                    'key': this.counter,
                    'net_price': net_price,
                    'year': year
                })
            },
            remove(index) {
                if (this.data.length > 1) {
                    this.data.splice(index, 1)
                }
            },

            setRowPrice(row, price) {
                Vue.set(this.data, row, {
                    'key': this.data[row].key,
                    'net_price': price,
                    'year': this.data[row].year,
                })
            },
            setRowYear(row, year) {
                Vue.set(this.data, row, {
                    'key': this.data[row].key,
                    'net_price': this.data[row].net_price,
                    'year': parseInt(year.target.value),
                })
            }
        },

        computed: {
            showDelete() {
                return this.data.length > 1
            },
            getYears() {
                return this.years
            }
        }
    };
</script>
