<template>
    <div class="relative h-full">
        <div class="flex flex-row justify-between mb-5">
            <div>
                <h3 class="font-bold uppercase m-0 text-gray-700">Events</h3>
                <h5 class="text-xs uppercase m-0 text-green">Upcoming and Historical Events</h5>
            </div>
            <div class="flex flex-row items-center">
                <div class="block text-xl mr-5"><span class="font-bold">{{ this.date.format('MMMM') }}</span> {{ this.date.format('YYYY') }}</div>
                <button class="block cursor-pointer bg-gray-200 hover:bg-gray-300 px-3 py-1 border border-r-0 border-gray-300 border-solid rounded-l" @click.prevent="previousMonth"><i class="fas fa-chevron-left"></i></button>
                <button class="block cursor-pointer bg-gray-200 hover:bg-gray-300 px-3 py-1 border border-gray-300 border-solid" @click.prevent="currentMonth">Today</button>
                <button class="block cursor-pointer bg-gray-200 hover:bg-gray-300 px-3 py-1 border border-l-0 border-gray-300 border-solid rounded-r" @click.prevent="nextMonth"><i class="fas fa-chevron-right"></i></button>
            </div>
        </div>
        <div>
            <div class="absolute w-full h-full z-10 top-0 flex justify-center items-center" v-if="loading">
                <div class="fa-2x">
                    <i class="fas fa-spinner fa-spin text-gray-500"></i>
                </div>
            </div>
            <calendar-view :show-date="datetime" class="theme-default" :events="events" @click-event="onClickItem"></calendar-view>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'

    export default {
        components: {
            CalendarView,
            CalendarViewHeader
        },

        data() {
            return {
                date: moment(),
                datetime: new Date(),
                loading: true,
                events: []
            }
        },

        created() {
            this.fetch();
        },

        methods: {
			fetch() {
                this.loading = true;

                axios.get('/api/events/calendar', {
                    params: Object.assign({
                        year: this.date.year(),
                        month: this.date.month() + 1,
                    }, this.params)
                }).then(response => {
                    this.events = []

                    response.data.forEach((data) => {
                        this.events.push({
                            id: data.id,
                            startDate: data.start_at,
                            endDate: data.end_at,
                            title: data.title,
                            url: '/admin/events/' + data.id + '/edit'
                        })
                    });

                    this.datetime = this.date.toDate();

                    this.loading = false;
                })
            },

            previousMonth() {
                this.date = this.date.subtract(1, 'month');

                this.fetch();
            },
            currentMonth() {
                this.date = moment();

                this.fetch();
            },
            nextMonth() {
                this.date = this.date.add(1, 'month');

                this.fetch();
            },

            onClickItem(e) {
                window.open(e.originalEvent.url, '_blank');
            }
        }
    }
</script>
