<style>
</style>

<template>
    <div class="transition duration-500 ease-in-out field bg-white rounded shadow rounded" :class="[ object.error ? 'border border-red' : '', 'field-' + definition.name ]">
        <div class="px-4 py-2 border-b flex flex-row items-center text-gray-700">
            <h4 class="font-bold mr-4 whitespace-no-wrap"><i class="fas mr-2" :class="[ 'fa-' + definition.icon ]"></i> {{ definition.title }}</h4>
            <div class="flex flex-row items-center ml-auto">
                <div class="flex flex-row items-center">
                    <h4 class="font-bold mr-4">Enabled</h4>
                    <Toggle :value="object.options.enabled" @change="(event) => this.$emit('change', 'enabled', event)"></Toggle>
                </div>

                <div class="flex flex-row items-center ml-4">
                    <h4 class="font-bold mr-4">Name</h4>
                    <input type="text" class="form-control py-1 px-2" :value="object.options.name" @keyup="(event) => this.$emit('change', 'name', event.target.value)" :class="[ !validation.name ? 'text-red border-red' : null ]">
                </div>

                <a href="" class="ml-4 hover:text-gray-800" @click.prevent="handleToggle"><i class="fas" :class="[ !object.options.toggled ? 'fa-arrow-up' : 'fa-arrow-down' ]"></i></a>
                <a href="" class="ml-4 hover:text-gray-800" @click.prevent="handleLocked"><i class="fas" :class="[ object.options.locked ? 'fa-lock' : 'fa-lock-open' ]"></i></a>
                <a href="" class="ml-4 hover:text-gray-800" @click.prevent="handleDuplicate"><i class="fas fa-copy"></i></a>
                <a href="" class="ml-4 hover:text-gray-800" @click.prevent="handleDelete"><i class="fas fa-trash"></i></a>
            </div>
        </div>
        <div class="p-4 pt-2">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import Toggle from "../GenericToggleComponent"

    export default {
        components: {
            Toggle
        },

        props: {
            definition: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            objects: {
                type: Array,
                default: () => {
                    return []
                }
            },
            object: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        methods: {
            handleLocked() {
                this.$emit('change', 'locked', !this.object.options.locked)
            },
            handleToggle() {
                this.$emit('change', 'toggled', !this.object.options.toggled)
            },
            handleDuplicate() {
                this.$emit('duplicate', this.object)
            },
            handleDelete() {
                this.$emit('delete', this.object)
            },
            validate(element, params) {
                let rules = {
                    name: (params) => {
                        if (params.name.trim() == '') {
                            return false
                        }

                        let match = this.objects.find(element => {
                            if (params.enabled == false || element.internal === this.object.internal || element.options.enabled == false) {
                                return false;
                            }

                            return element.options.name == params.name
                        })

                        if (match) {
                            return false
                        }

                        return true
                    }
                }

                let valid = true
                if (element in rules) {
                    valid = rules[element](params)
                }

                this.$emit('error', element, valid)

                return valid
            }
        },

        computed: {
            validation() {
                return {
                    name: this.validate('name', {
                        name: this.object.options.name,
                        enabled: this.object.options.enabled
                    })
                }
            }
        }
    }
</script>
