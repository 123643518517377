<template>
    <div>
        <data-table-component url="/api/events/bookings/datatable" :columns="columns" :keyword="keyword" :order-by="orderBy">
            <template slot="filters">
                <a href="/admin/events/bookings/create" class="button is-primary ml-auto">
                    <i class="fas fa-plus mr-3"></i> Create New Booking
                </a>
            </template>
            <template slot-scope="{ row }" slot="actions">
                <td class="text-right">
                    <a :href="`/admin/events/bookings/${row.id}/payments`" class="button is-small is-secondary inline-block">
                        <i class="fas fa-list"></i> Payments
                    </a>
                    <a :href="`/admin/events/bookings/${row.id}/edit`" class="button is-small is-tertiary inline-block">
                        <i class="fas fa-edit"></i>
                    </a>
                </td>
            </template>
        </data-table-component>
    </div>
</template>

<script>
    import DataTableComponent from '../DataTableComponent';

    export default {
        components: {
            DataTableComponent
        },

        data() {
            return {
                columns: [
                    {
                        key: 'booking_id',
                        label: 'Booking',
                    },
                    {
                        key: 'booking_created',
                        label: 'Date',
                    },
                    {
                        key: 'event_name',
                        label: 'Event',
                    },
                    {
                        key: 'fullname',
                        label: 'Full Name'
                    },
                    {
                        key: 'email',
                        label: 'Email',
                    },
                    {
                        key: 'paid',
                        label: 'Paid',
                        html: function (x, y) {
                            if (y == 1) {
                                return '<i class="fas fa-check text-green"></i>';
                            }
                            return '<i class="fas fa-times text-red"></i>';
                        }
                    },
                    {
                        key: 'approved',
                        label: 'Approved',
                        html: function (x, y) {
                            if (y == 1) {
                                return '<i class="fas fa-check text-green"></i>';
                            }
                            return '<i class="fas fa-times text-red"></i>';
                        }
                    },
                    {
                        key: 'cancelled',
                        label: 'Cancelled',
                        html: function (x, y) {
                            if (y == 1) {
                                return '<i class="fas fa-check text-green"></i>';
                            }
                            return '<i class="fas fa-times text-red"></i>';
                        }
                    }
                ],
                orderBy: '1'
            }
        }
    }
</script>
