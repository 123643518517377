<template>
    <div class="border border-gray-300 p-4 mt-4" v-if="visible || this.admin">
        <div v-if="this.admin && !visible" class="bg-red font-bold text-white px-4 py-2 -mt-4 -mx-4 mb-4">
            Only visible to administrators
        </div>

        <div class="border border-red px-4 py-3 mb-4" v-if="notice" v-html="notice"></div>

        <h1 class="text-xl leading-none" v-if="this.object.options.caption">{{ this.object.options.caption }}</h1>
        <p v-if="this.object.options.description" v-html="this.object.options.description"></p>
    </div>
</template>

<script>
    import { default as field } from '../Mixins/Field'

    export default {
        mixins: [
            field
        ]
    }
</script>
