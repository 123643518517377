<template>
    <div class="w-full">
        <div class="flex">
            <GenericToggleComponent :name="name" :value="status" @change="handleToggle"></GenericToggleComponent>
            <label class="form-label ml-3 mb-0" v-if="title" v-html="title"></label>
        </div>

        <div v-if="status">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import GenericToggleComponent from './GenericToggleComponent'

    export default {
        components: {
            GenericToggleComponent
        },

        props: {
            title: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: false
            },
            value: {
                type: Boolean|Number,
                default: false
            }
        },

        data() {
            return {
                status: this.value instanceof Number ? !!(this.value == '1' ? true : false) : !!this.value
            }
        },

        methods: {
            handleToggle(event) {
                this.status = event
            }
        }
    }
</script>

