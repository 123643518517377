<template>
    <nav class="bg-white relative block mb-10">
        <div class="shadow px-0">
            <div class="flex flex-wrap w-full container mx-auto md:flex-no-wrap md:justify-between md:items-center">
                <ul class="flex flex-col w-full md:flex-row md:py-0">
                    <MegaMenuItemComponent v-for="(route, index) in this.routes" :key="index" :name="route.name" :url="typeof route.url !== 'undefined' ? route.url : null" :className="typeof route.class !== 'undefined' ? route.class : null" :icon="typeof route.icon !== 'undefined' ? route.icon : null" :routes="typeof route.children !== 'undefined' ? route.children : []"></MegaMenuItemComponent>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    import MegaMenuItemComponent from './MegaMenuItemComponent'

    export default {
        name: 'MegaMenuComponent',

        components: {
            MegaMenuItemComponent
        },

        props: {
            external: {
                type: String,
                required: false
            }
        },

        data() {
            return {
                routes: [
                    {
                        name: 'Home',
                        url: '/admin',
                        icon: 'fas fa-home'
                    },
                    {
                        name: 'Membership',
                        icon: 'fas fa-users',
                        children: [
                            {
                                name: 'Users',
                                url: '/admin/users',
                            },
                            {
                                name: 'Subscriptions',
                                url: '/admin/subscriptions'
                            },
                            {
                                name: 'Renewals',
                                children: [
                                    {
                                        icon: 'fas fa-list-alt',
                                        name: 'Bulk Renewals',
                                        url: '/admin/renewals/bulk'
                                    },
                                    {
                                        icon: 'fas fa-list-alt',
                                        name: 'Bulk Payments',
                                        url: '/admin/payments/bulk'
                                    }
                                ]
                            },
                            {
                                name: 'Council Members',
                                url: '/admin/councillors',
                            },
                            {
                                name: 'Honoraries',
                                url: '/admin/honoraries',
                            }
                        ]
                    },
                    {
                        name: 'Events',
                        icon: 'fas fa-calendar-alt',
                        children: [
                            {
                                name: 'Events',
                                url: '/admin/events'
                            },
                            {
                                name: 'Bookings',
                                url: '/admin/events/bookings'
                            },
                            {
                                name: 'Certificates',
                                url: '/admin/events/certificates'
                            }
                        ]
                    },
                    {
                        name: 'Jobs',
                        icon: 'fas fa-briefcase',
                        url: '/admin/jobs'
                    },
                    {
                        name: 'Reporting',
                        icon: 'fas fa-chart-bar',
                        children: [
                            {
                                name: 'Payments',
                                url: '/admin/reports/payment'
                            },
                            {
                                name: 'Members',
                                url: '/admin/reports/member'
                            },
                            {
                                name: 'Events',
                                url: '/admin/reports/event'
                            },
                        ]
                    },
                    {
                        name: 'View Site',
                        icon: 'fas fa-eye',
                        url: (this.external ? this.external : '/'),
                        class: 'md:ml-auto'
                    },
                    {
                        name: 'Settings',
                        icon: 'fas fa-cogs',
                        children: [
                            {
                                name: 'Administrators',
                                url: '/admin/admins'
                            },
                            {
                                name: 'Campaign Monitor',
                                url: '/admin/campaignmonitor'
                            },
                            {
                                name: 'Settings',
                                url: '/admin/settings'
                            }
                        ]
                    }
                ]
            }
        }
    }
</script>
