<template>
    <div class="mb-4">
        <div class="flex flex-row flex-wrap -m-2">
            <div class="flex-1 bg-white px-10 py-6 shadow rounded h-full flex flex-col m-2">
                <h3 class="font-bold uppercase m-0 text-gray-700">{{ this.current.title}}</h3>
                <h5 class="text-xs uppercase m-0 text-green">Bookings</h5>
                <div class="flex items-center mt-2 h-full">
                    <span class="text-3xl font-bold">
                        <CountComponent :to="this.totals.current"></CountComponent>
                    </span>
                </div>
                <div class="mt-2">
                    <GenericSelectComponent placeholder="Compare against another event..." :compact="true" :value="this.compared !== null ? this.compared.id : null" :options="this.events" value-column="title" description-column="description" @change="handleSelect"></GenericSelectComponent>
                </div>
            </div>

            <div class="flex-1 bg-white px-10 py-6 shadow rounded h-full flex flex-col m-2" v-if="this.compared && !this.calculating">
                <h3 class="font-bold uppercase m-0 text-gray-700">{{ this.compared.title}}</h3>
                <h5 class="text-xs uppercase m-0 text-green">Bookings</h5>
                <div class="flex items-center mt-2 h-full">
                    <span class="text-3xl font-bold">
                       {{ this.totals.compared }}
                    </span>
                </div>
                <div class="flex items-center mt-2 h-full">
                    <span class="text-xl font-bold text-green" v-if="this.totals.current < this.totals.compared">
                        Up by {{ Math.abs(this.totals.current - this.totals.compared) }}
                    </span>
                    <span class="text-xl font-bold text-red" v-if="this.totals.current > this.totals.compared">
                        Down by {{ Math.abs(this.totals.current - this.totals.compared) }}
                    </span>
                    <span class="text-xl font-bold text-gray-500" v-if="this.totals.current == this.totals.compared">
                        Unchanged
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CountComponent from '../CountComponent'
    import GenericSelectComponent from '../GenericSelectComponent'

    export default {
        components: {
            CountComponent,
            GenericSelectComponent
        },

        props: {
            event: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                calculating: false,
                events: [],
                totals: {
                    current: 0,
                    compared: 0,
                },
                current: this.event,
                compared: null
            }
        },

        mounted() {
            this.reload()

            axios.get('/api/events/select').then(response => {
                this.events = []
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].id != this.current.id) {
                        this.events.push(response.data[i])
                    }
                }
            })
        },

        methods: {
            reload() {
                axios.get('/api/events/' + this.current.id + '/statistics').then(response => {
                    this.totals.current = response.data.bookings
                })

                if (this.compared !== null && this.compared.id !== null) {
                    this.calculating = true
                    axios.get('/api/events/' + this.compared.id + '/statistics').then(response => {
                        this.totals.compared = response.data.bookings
                        this.calculating = false
                    })
                }
            },

            handleSelect(event) {
                if (event == null || event == '') {
                    this.totals.compared = 0
                    this.compared = null
                } else if (this.compared == null || event != this.compared.id) {
                    axios.get('/api/events/' + event).then(response => {
                        this.compared = response.data
                        this.reload()
                    })
                }
            }
        }
    }
</script>
