<style>
</style>

<template>
    <div>
        <h4 class="font-bold text-gray-700">Filter</h4>
        <p class="text-gray-700 mb-4">Hide the field unless a specific series of rules are met.</p>

        <div class="flex items-center border rounded border-gray-400 mb-2 p-3" v-for="(item, index) in values" :key="index">
            <div class="flex-1">
                <h4 class="font-bold text-gray-700 leading-none">{{ item.name }}</h4>
                <p class="leading-none text-sm text-gray-700">{{ item.type }}</p>
            </div>
            <div class="mx-4">
                <label class="flex">
                    <div class="mr-2 font-bold text-gray-700">Show if Empty</div>
                    <Toggle :value="item.filled" @change="(value) => handleFilledChange(index, value)"></Toggle>
                    <div class="ml-2 font-bold text-gray-700">Filled</div>
                </label>
            </div>
            <button class="button is-danger text-sm ml-auto" @click.prevent="() => removeSelected(index)"><i class="fa fa-trash"></i></button>
        </div>
        <div class="mb-2">
            <Select value="" :options="fields" placeholder="Search Fields..." keyColumn="name" valueColumn="name" descriptionColumn="type" @change="handleSelect"></Select>
        </div>
    </div>
</template>

<script>
    import Toggle from '../../../GenericToggleComponent'
    import Select from '../../../GenericSelectComponent'
    import Rating from '../../../GenericRatingComponent'

    export default {
        components: {
            Toggle,
            Select,
            Rating
        },

        props: {
            type: {
                type: String,
                default: 'text'
            },
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            object: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            objects: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },

        data() {
            let fields = this.objects.filter(field => (field.type == 'toggle' || field.type == 'input' || field.type == 'rating')).map(field => field.name)

            let selections = []

            if (typeof this.options.filter_fields !== 'undefined' && this.options.filter_fields !== null) {
                for (let i = 0; i < this.options.filter_fields.length; i++) {
                    if (fields.includes(this.options.filter_fields[i].name)) {
                        selections.push(this.options.filter_fields[i])
                    }
                }
            }

            return {
                selections: selections
            }
        },

        methods: {
            getFields(exclude, selected) {
                return this.objects.filter(object => (this.selections !== null ? !this.selections.map(field => field.name).includes(object.options.name) : true) && (object.type == 'toggle' || object.type == 'input' || object.type == 'rating')).map(object => {
                    return {
                        name: object.options.name,
                        type: object.type.charAt(0).toUpperCase() + object.type.slice(1)
                    }
                })
            },

            handleChange(key, value) {
                this.$emit('change', key, value)
            },

            handleFilledChange(index, value) {
                this.selections[index].filled = value

                this.handleChange('filter_fields', this.selections)
            },

            handleSelect(value) {
                this.selections.push({
                    name: value,
                    filled: true
                })

                this.handleChange('filter_fields', this.selections)
            },
            removeSelected(index) {
                this.selections.splice(index, 1)

                this.handleChange('filter_fields', this.selections)
            }
        },

        computed: {
            fields() {
                return this.getFields(this.object.name, this.selections)
            },

            values() {
                let fields = {}
                for (let i = 0; i < this.objects.length; i++) {
                    fields[this.objects[i].options.name] = {
                        name: this.objects[i].options.name,
                        type: this.objects[i].type
                    }
                }

                let response = []
                for (let i = 0; i < this.selections.length; i++) {
                    if (typeof this.selections[i].name !== 'undefined') {
                        response.push({
                            filled: this.selections[i].filled,
                            name: fields[this.selections[i].name].name,
                            type: fields[this.selections[i].name].type
                        })
                    }
                }

                return response
            }
        },

        watch: {
            objects: {
                handler() {
                    let fields = this.objects.filter(field => (field.type == 'toggle' || field.type == 'input' || field.type == 'rating')).map(field => field.options.name)

                    let selections = []

                    if (typeof this.options.filter_fields !== 'undefined' && this.options.filter_fields !== null) {
                        for (let i = 0; i < this.options.filter_fields.length; i++) {
                            if (fields.includes(this.options.filter_fields[i].name)) {
                                selections.push(this.options.filter_fields[i])
                            }
                        }
                    }

                    if (JSON.stringify(selections.map(field => field.name)) !== JSON.stringify(this.selections.map(field => field.name))) {
                        this.handleChange('filter_fields', selections)
                    }

                    this.selections = selections
                },
                deep: true
            }
        }
    }
</script>
