<template>
    <div>
        <slot :existingMember="existingMemberInternal" :existingMemberChange="existingMemberChange" :guestBooking="guestBookingInternal" :guestBookingChange="guestBookingChange" :event="eventInternal" :eventChange="eventChange" :user="userInternal" :userChange="userChange" name="primary"></slot>
        <slot :existingMember="existingMemberInternal" :existingMemberChange="existingMemberChange" :guestBooking="guestBookingInternal" :guestBookingChange="guestBookingChange" :event="eventInternal" :eventChange="eventChange" :user="userInternal" :userChange="userChange" name="secondary"></slot>
        <slot :existingMember="existingMemberInternal" :existingMemberChange="existingMemberChange" :guestBooking="guestBookingInternal" :guestBookingChange="guestBookingChange" :event="eventInternal" :eventChange="eventChange" :user="userInternal" :userChange="userChange" name="tertiary" :editingBooking="editingBooking" :editingBookingChange="editingBookingChange"></slot>
    </div>
</template>

<script>
    export default {
        props: {
            existingMember: {
                type: Boolean|Number,
                default: true
            },
            guestBooking: {
                type: Boolean|Number,
                default: true
            },

            event: {
                type: Number,
                required: false
            },
            user: {
                type: Number,
                required: false
            }
        },

        methods: {
            existingMemberChange(event) {
                this.$set(this, 'existingMemberInternal', event)
            },
            guestBookingChange(event) {
                this.$set(this, 'guestBookingInternal', event)
            },

            eventChange(event) {
                this.$set(this, 'eventInternal', event)
            },
            userChange(event) {
                this.$set(this, 'userInternal', event)
            },

            editingBookingChange(event) {
                this.$set(this, 'editingBooking', event)
            }
        },

        data() {
            return {
                existingMemberInternal: typeof this.existingMember === 'number' ? (this.existingMember == '1' ? true : false) : this.existingMember,
                guestBookingInternal: typeof this.guestBooking === 'number' ? (this.guestBooking == '1' ? true : false) : this.guestBooking,

                eventInternal: this.event,
                userInternal: this.user,

                editingBooking: false,
            }
        }
    }
</script>
