<template>
    <div class="relative h-full">
        <div class="absolute w-full h-full z-10 flex justify-center items-center bg-smoke-light rounded" v-if="loading > 0">
            <div class="fa-2x">
                <i class="fas fa-spinner fa-spin text-gray-200"></i>
            </div>
        </div>

        <div class="flex flex-wrap mb-5">
            <div class="flex items-center w-full">
                <Select :value="event" :options="events" value-column="title" description-column="subtitle" date-column="start_date" placeholder="Search Events..." @change="handleEventChange"></Select>
                <div class="flex ml-auto">
                    <Toggle :value="showFieldNames" @change="(value) => showFieldNames = value"></Toggle>
                    <label class="ml-3 font-bold text-gray-600">Show field names</label>
                </div>
            </div>
        </div>

        <div class="mb-5" v-if="typeof event.id !== 'undefined'">
            <div class="flex flex-wrap items-stretch -m-2">
                <a :href="'/admin/reports/event/' + event.id + '/delegate-list'" class="m-2 block button is-primary flex-auto"><i class="fas fa-download mr-3"></i> Delegate List</a>
                <div class="m-2 relative block flex-auto">
                    <div>
                        <button type="button" class="flex button justify-between is-primary w-full text-center" id="names-addresses" aria-haspopup="true" :aria-expanded="showNamesAddressesDropdown ? 'true' : 'false'" @click.prevent="toggleNamesAddressesDropdown">
                            Names and Addresses
                            <svg class="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                            </svg>
                        </button>
                    </div>

                    <div v-click-outside="hideNamesAddressesDropdown" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg" v-if="showNamesAddressesDropdown">
                        <div class="rounded-md bg-white shadow-xs">
                            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="names-addresses">
                                <a :href="'/admin/reports/event/' + event.id + '/names-addresses'" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem"><i class="fas fa-download mr-3"></i> All</a>
                                <a :href="'/admin/reports/event/' + event.id + '/names-addresses/members'" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem"><i class="fas fa-download mr-3"></i> Members</a>
                                <a :href="'/admin/reports/event/' + event.id + '/names-addresses/non-members'" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem"><i class="fas fa-download mr-3"></i> Non Members</a>
                            </div>
                        </div>
                    </div>
                </div>
                <a :href="'/admin/reports/event/' + event.id + '/booking-list'" class="m-2 block button is-primary flex-auto"><i class="fas fa-download mr-3"></i> Booking List</a>
                <div class="m-2 relative block flex-auto">
                    <div>
                        <button type="button" class="flex button justify-between is-primary w-full text-center" id="badge-list" aria-haspopup="true" :aria-expanded="showBadgeListDropdown ? 'true' : 'false'" @click.prevent="toggleBadgeListDropdown">
                            Badge List
                            <svg class="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                            </svg>
                        </button>
                    </div>

                    <div v-click-outside="hideBadgeListDropdown" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg" v-if="showBadgeListDropdown">
                        <div class="rounded-md bg-white shadow-xs">
                            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="badge-list">
                                <a :href="'/admin/reports/event/' + event.id + '/badge-list'" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem"><i class="fas fa-download mr-3"></i> All</a>
                                <a :href="'/admin/reports/event/' + event.id + '/badge-list/members'" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem"><i class="fas fa-download mr-3"></i> Members</a>
                                <a :href="'/admin/reports/event/' + event.id + '/badge-list/non-members'" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem"><i class="fas fa-download mr-3"></i> Non Members</a>
                            </div>
                        </div>
                    </div>
                </div>
                <a :href="'/admin/reports/event/' + event.id + '/accounts'" class="m-2 block button is-primary flex-auto"><i class="fas fa-download mr-3"></i> Accounts</a>
                <div class="m-2 relative block flex-auto">
                    <div>
                        <button type="button" class="flex button justify-between is-primary w-full text-center" id="email-list" aria-haspopup="true" :aria-expanded="showEmailListDropdown ? 'true' : 'false'" @click.prevent="toggleEmailListDropdown">
                            Email List
                            <svg class="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                            </svg>
                        </button>
                    </div>

                    <div v-click-outside="hideEmailListDropdown" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg" v-if="showEmailListDropdown">
                        <div class="rounded-md bg-white shadow-xs">
                            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="email-list">
                                <a :href="'/admin/reports/event/' + event.id + '/email-list'" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem"><i class="fas fa-download mr-3"></i> All</a>
                                <a :href="'/admin/reports/event/' + event.id + '/email-list/members'" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem"><i class="fas fa-download mr-3"></i> Members</a>
                                <a :href="'/admin/reports/event/' + event.id + '/email-list/non-members'" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem"><i class="fas fa-download mr-3"></i> Non Members</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-wrap -mx-2 p-6 md:p-0 md:flex-no-wrap">
            <div class="w-full px-2 mb-5 md:mb-0">
                <h3 class="text-xl text-gray-600 mb-2">Attendance</h3>

                <div class="card is-primary p-5 overflow-x-auto" ref="container">
                    <table class="generic-table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Paid</th>
                                <th>Unpaid</th>
                                <th>Approved</th>
                                <th>Unapproved</th>
                                <th>Price</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="report.attendance && report.attendance.length > 0">
                                <template v-for="(row, index) in report.attendance">
                                    <template v-if="row.type === 'toggle'">
                                        <tr :key="`${index}-1`" class="cursor-pointer" @click.prevent="() => handleToggleExpand('attendance', index)">
                                            <td class="w-full">
                                                <div>
                                                    <i class="fas text-green mr-2" :class="[ typeof row.expanded !== 'undefined' && row.expanded === true ? 'fa-caret-square-up' : 'fa-caret-square-down']"></i>
                                                    {{ row.title }}
                                                </div>
                                                <div v-if="showFieldNames">
                                                    <small class="text-grey">{{ row.subtitle }}</small>
                                                </div>
                                            </td>
                                            <td class="text-center">{{ row.paid }}</td>
                                            <td class="text-center">{{ row.unpaid }}</td>
                                            <td class="text-center">{{ row.approved }}</td>
                                            <td class="text-center">{{ row.unapproved }}</td>
                                            <td>£{{ row.price }}</td>
                                            <td>£{{ row.total }}</td>
                                        </tr>
                                        <tr :key="`${index}-2`" v-if="typeof row.expanded !== 'undefined' && row.expanded === true">
                                            <td colspan="100%">
                                                <div class="overflow-x-auto" :style="`width:${width}px`">
                                                    <BookingsTable :context="{event: event, field: row.subtitle}" :event="event" :restrict="row.bookings" :notes="row.notes.length == 0 ? {} : row.notes" @loading="() => setLoading(true)" @loaded="() => setLoading(false)"></BookingsTable>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-if="row.type !== 'toggle'">
                                        <tr :key="`${index}-1`">
                                            <th class="w-full text-left" colspan="100%">
                                                <div>
                                                    {{ row.title }}
                                                </div>
                                                <div v-if="showFieldNames">
                                                    <small class="text-grey">{{ row.subtitle }}</small>
                                                </div>
                                            </th>
                                        </tr>
                                    </template>
                                </template>
                            </template>

                            <template v-if="!report.attendance || report.attendance.length === 0">
                                <tr>
                                    <td class="text-center" colspan="100%">No Results</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>

                    <table class="ml-auto mt-5" v-if="report.attendance && report.attendance.length > 0">
                        <tr>
                            <td class="p-2">
                                <h4 class="font-bold text-lg">Theoretical Income</h4>
                                <p class="text-sm">No complimentary bookings, all full price</p>
                            </td>
                            <td class="p-2">
                                <h2 class="text-2xl font-bold">£{{ report.theoretical_total }}</h2>
                            </td>
                        </tr>
                        <tr>
                            <td class="border-t border-gray-300 p-2">
                                <h4 class="font-bold text-lg">Actual Income</h4>
                                <p class="text-sm">Sum paid excluding complimentary, manual and test bookings</p>
                            </td>
                            <td class="border-t border-gray-300 p-2">
                                <h2 class="text-2xl font-bold">£{{ report.actual_total }}</h2>
                            </td>
                        </tr>
                        <tr>
                            <td class="border-t border-gray-300 p-2">
                                <h4 class="font-bold text-lg">Complimentary Bookings</h4>
                            </td>
                            <td class="border-t border-gray-300 p-2">
                                <h2 class="text-2xl font-bold">£{{ report.complimentary_total }}</h2>
                            </td>
                        </tr>
                        <tr v-for="(total, name) in report.payment_method_total">
                            <td class="border-t border-gray-300 p-2">
                                <h4 class="font-bold text-lg">{{ name }} Bookings</h4>
                            </td>
                            <td class="border-t border-gray-300 p-2">
                                <h2 class="text-2xl font-bold">£{{ total }}</h2>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="flex flex-wrap -mx-2 p-6 md:p-0 md:flex-no-wrap mt-5">
            <div class="w-full px-2 mb-5 md:mb-0">
                <h3 class="text-xl text-gray-600 mb-2">Evaluation</h3>

                <div class="card is-primary p-5 overflow-x-auto" ref="container">
                    <div class="flex items-center mb-5">
                        <div class="mr-4">
                            <Select :value="group" :options="groups" value-column="name" placeholder="Group..." @change="handleGroupChange"></Select>
                        </div>
                        <div class="mr-4">
                            <Select :value="region" :options="regions" value-column="name" placeholder="Region..." @change="handleRegionChange"></Select>
                        </div>
                        <div class="flex items-center mr-4" v-if="report.survey_replies">
                            <h5 class="mt-0 mb-0 text-md text-gray-600">{{ report.survey_replies }} Result{{ report.survey_replies !== 1 ? 's' : '' }}</h5>
                        </div>

                        <button class="button is-primary ml-auto mr-2" @click.prevent="handleCommentsDownload"><i class="fas fa-download mr-3"></i> Download Comments</button>

                        <button class="button is-primary" @click.prevent="handleSurveyDownload"><i class="fas fa-download mr-3"></i> Download</button>
                    </div>

                    <table class="generic-table">
                        <thead>
                            <tr>
                                <th width="100%">Question</th>
                                <th>Skipped</th>
                                <th>Ratings</th>
                                <th>Average</th>
                                <th>Strongly Disagree</th>
                                <th>Disagree</th>
                                <th>Neutral</th>
                                <th>Agree</th>
                                <th>Strongly Agree</th>
                                <th>Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="report.survey && report.survey.length > 0">
                                <template v-for="(row, index) in report.survey">
                                    <template v-if="row.type === 'group'">
                                        <tr :key="`${index}-5`">
                                            <th class="w-full text-left" colspan="100%">
                                                <div>
                                                    {{ row.title }}
                                                </div>
                                                <div v-if="showFieldNames">
                                                    <small class="text-grey">{{ row.subtitle }}</small>
                                                </div>
                                            </th>
                                        </tr>
                                    </template>
                                    <template v-if="row.type !== 'group'">
                                        <tr :key="`${index}-3`" class="cursor-pointer" @click.prevent="() => handleToggleExpand('survey', index)">
                                            <td class="w-full" nowrap>
                                                <div>
                                                    <i class="fas text-green mr-2" :class="[ typeof row.expanded !== 'undefined' && row.expanded === true ? 'fa-caret-square-up' : 'fa-caret-square-down']"></i>
                                                    {{ row.title }}
                                                </div>
                                                <div v-if="showFieldNames">
                                                    <small class="text-grey">{{ row.subtitle }}</small>
                                                </div>
                                            </td>
                                            <td class="text-center">{{ row.skipped }}</td>
                                            <td class="text-center">{{ row.ratings != null ? row.ratings : '–' }}</td>
                                            <td class="text-center">{{ row.average != null ? row.average : '–' }}</td>
                                            <td class="text-center">{{ row.awful != null ? row.awful : '0' }}</td>
                                            <td class="text-center">{{ row.bad != null ? row.bad : '0' }}</td>
                                            <td class="text-center">{{ row.neutral != null ? row.neutral : '0' }}</td>
                                            <td class="text-center">{{ row.good != null ? row.good : '0' }}</td>
                                            <td class="text-center">{{ row.great != null ? row.great : '0' }}</td>
                                            <td class="text-center">{{ row.comments }}</td>
                                        </tr>
                                        <tr :key="`${index}-4`" v-if="typeof row.expanded !== 'undefined' && row.expanded === true">
                                            <td colspan="100%">
                                                <div class="overflow-x-auto" :style="`width:${width}px`">
                                                    <div class="card is-primary p-5">
                                                        <div class="pr-5 overflow-x-scroll">
                                                            <table class="generic-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="1">Reference</th>
                                                                        <th>Name</th>
                                                                        <th>Email</th>
                                                                        <th>Group</th>
                                                                        <th>Region</th>
                                                                        <th>Skipped</th>
                                                                        <th v-if="row.type != 'input'">{{ row.type == 'rating' ? 'Rating' : 'Answer' }}</th>
                                                                        <th>Comments</th>
                                                                        <th>Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(answer, idx) in row.answers" :key="idx">
                                                                        <td><a :href="'/admin/events/bookings/' + answer.booking + '/edit'">{{ answer.booking_id }}</a></td>
                                                                        <td nowrap>{{ answer.full_name }}</td>
                                                                        <td><a :href="'mailto:' + answer.email">{{ answer.email }}</a></td>
                                                                        <td nowrap>{{ answer.member_type != null ? answer.member_type : '–' }}</td>
                                                                        <td nowrap>{{ answer.member_region != null ? answer.member_region : '–' }}</td>
                                                                        <td :class="[ 'text-lg', !answer.attended ? 'text-green' : 'text-red' ]">{{ !answer.attended ? '&#10004;' : '&times;' }}</td>
                                                                        <td v-if="answer.type == 'toggle'">{{ answer.answer ? 'Yes' : 'No' }}</td>
                                                                        <td v-if="answer.type != 'toggle' && answer.type != 'input'">{{ answer.answer != '' ? answer.answer : '–' }}</td>
                                                                        <td v-if="answer.type != 'input'" nowrap>{{ answer.details != null && answer.details != '' ? answer.details : '–' }}</td>
                                                                        <td v-if="answer.type == 'input'" nowrap>{{ answer.answer != null && answer.answer != '' ? answer.answer : '–' }}</td>
                                                                        <td nowrap>{{ answer.answered_at }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                            </template>

                            <template v-if="!report.survey || report.survey.length === 0">
                                <tr>
                                    <td class="text-center" colspan="100%">No Results</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="flex flex-wrap -mx-2 p-6 md:p-0 md:flex-no-wrap mt-5">
            <div class="w-full px-2 mb-5 md:mb-0">
                <h3 class="text-xl text-gray-600 mb-2">Bookings</h3>
                <BookingsTable :context="{event: event}" :event="event" @loading="() => setLoading(true)" @loaded="() => setLoading(false)"></BookingsTable>
            </div>
        </div>
    </div>
</template>

<script>
    import Select from '../GenericSelectComponent'
    import Toggle from '../GenericToggleComponent'

    import BookingsTable from './EventsReport/BookingsTable'

    import XLSX from 'xlsx'
    import { saveAs } from 'file-saver'
    import Blob from 'blobjs'
    import moment from 'moment'

    export default {
        components: {
            Select,
            Toggle,

            BookingsTable
        },

        props: {
            groups: {
                type: Array,
                default: () => {
                    return []
                }
            },
            regions: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },

        data() {
            return {
                loading: 0,

                showFieldNames: false,

                group: null,
                region: null,

                event: {},
                name: null,
                events: [],

                report: {},

                width: 640,

                showNamesAddressesDropdown: false,
                showBadgeListDropdown: false,
                showEmailListDropdown: false,
            }
        },

        mounted() {
            this.fetchEvents()
        },

        created() {
            this.$nextTick(function() {
                window.addEventListener('resize', this.handleResize)
            })
        },

        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },

        methods: {
            toggleNamesAddressesDropdown() {
                this.showNamesAddressesDropdown = !this.showNamesAddressesDropdown
            },
            toggleBadgeListDropdown() {
                this.showBadgeListDropdown = !this.showBadgeListDropdown
            },
            toggleEmailListDropdown() {
                this.showEmailListDropdown = !this.showEmailListDropdown
            },

            hideNamesAddressesDropdown() {
                this.showNamesAddressesDropdown = false
            },
            hideBadgeListDropdown() {
                this.showBadgeListDropdown = false
            },
            hideEmailListDropdown() {
                this.showEmailListDropdown = false
            },
            handleCommentsDownload() {
                var slugify = require('slugify')

                var filename = moment().format('YYYY-MM-DD_HH-mm')
                if (typeof this.event !== 'undefined' && this.event !== null) {
                    filename = filename + '_' + slugify(this.name.toLowerCase(), '-')
                }

                if (this.group !== null && this.group !== '') {
                    let group = this.groups.find(element => element.id === this.group).name
                    filename = filename + '_' + slugify(group.toLowerCase(), '-')
                }

                if (this.region !== null && this.region !== '') {
                    let region = this.regions.find(element => element.id == this.region).name
                    filename = filename + '_' + slugify(region.toLowerCase(), '-')
                }

                let rows = []

                for (let s in this.report.survey) {
                    let survey = this.report.survey[s]
                    
                    if(survey.comments == 0) continue;

                    if (survey.type == 'rating') {
                        rows.push(['Title', 'Comments'])
                        rows.push([survey.title, survey.comments])
                    } else {
                        rows.push(['Title', 'Comments'])
                        rows.push([survey.title, survey.comments])
                    }

                    if (survey.type == 'input') {
                        rows.push(['Reference', 'Name', 'Comments', 'Date'])
                    } else {
                        rows.push(['Reference', 'Name', 'Comments', 'Date'])
                    }

                    for (let a in survey.answers) {
                        let answer = this.report.survey[s].answers[a]

                        if (survey.type == 'input') {
                            if(answer.answer == '' || !answer.answer) continue;

                            rows.push([
                                answer.booking_id,
                                answer.full_name,
                                answer.answer,
                                answer.answered_at
                            ])
                        } else {
                            if(answer.details == '' || !answer.details) continue;

                            rows.push([
                                answer.booking_id,
                                answer.full_name,
                                answer.details,
                                answer.answered_at
                            ])
                        }
                    }

                    rows.push([])
                }

                let workbook = XLSX.utils.book_new()
                workbook.SheetNames.push('Survey')

                let worksheet = XLSX.utils.aoa_to_sheet(rows)
                workbook.Sheets['Survey'] = worksheet

                let stream = XLSX.write(workbook, {
                    bookType:'xlsx',
                    type: 'binary'
                })

                let buffer = new ArrayBuffer(stream.length)
                let viewer = new Uint8Array(buffer)
                for (let i = 0; i < stream.length; i++) {
                    viewer[i] = stream.charCodeAt(i) & 0xFF
                }

                saveAs(new Blob([buffer], {
                    type: 'application/octet-stream'
                }), filename + '.xlsx')
            },
            handleSurveyDownload() {
                var slugify = require('slugify')

                var filename = moment().format('YYYY-MM-DD_HH-mm')
                if (typeof this.event !== 'undefined' && this.event !== null) {
                    filename = filename + '_' + slugify(this.name.toLowerCase(), '-')
                }

                if (this.group !== null && this.group !== '') {
                    let group = this.groups.find(element => element.id === this.group).name
                    filename = filename + '_' + slugify(group.toLowerCase(), '-')
                }

                if (this.region !== null && this.region !== '') {
                    let region = this.regions.find(element => element.id == this.region).name
                    filename = filename + '_' + slugify(region.toLowerCase(), '-')
                }

                let rows = []

                for (let s in this.report.survey) {
                    let survey = this.report.survey[s]

                    if (survey.type == 'rating') {
                        rows.push(['Title', 'Name', 'Skipped', 'Ratings', 'Average', 'Strongly Disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly Agree', 'Comments'])
                        rows.push([survey.title, survey.subtitle, survey.skipped, survey.ratings, survey.average, survey.awful, survey.bad, survey.neutral, survey.good, survey.great, survey.comments])
                    } else {
                        rows.push(['Title', 'Name', 'Skipped', 'Comments'])
                        rows.push([survey.title, survey.subtitle, survey.skipped, survey.comments])
                    }

                    if (survey.type == 'input') {
                        rows.push(['Reference', 'Name', 'Email', 'Group', 'Region', 'Skipped', 'Comments', 'Date'])
                    } else {
                        rows.push(['Reference', 'Name', 'Email', 'Group', 'Region', 'Skipped', 'Answer', 'Comments', 'Date'])
                    }

                    for (let a in survey.answers) {
                        let answer = this.report.survey[s].answers[a]

                        if (survey.type == 'input') {
                            rows.push([
                                answer.booking_id,
                                answer.full_name,
                                answer.email,
                                answer.member_type,
                                answer.member_region,
                                !answer.attended ? 'Yes' : 'No',
                                answer.answer,
                                answer.answered_at
                            ])
                        } else {
                            rows.push([
                                answer.booking_id,
                                answer.full_name,
                                answer.email,
                                answer.member_type,
                                answer.member_region,
                                !answer.attended ? 'Yes' : 'No',
                                answer.type == 'toggle' ? (answer.answer ? 'Yes' : 'No') : answer.answer,
                                answer.details,
                                answer.answered_at
                            ])
                        }
                    }

                    rows.push([])
                }

                let workbook = XLSX.utils.book_new()
                workbook.SheetNames.push('Survey')

                let worksheet = XLSX.utils.aoa_to_sheet(rows)
                workbook.Sheets['Survey'] = worksheet

                let stream = XLSX.write(workbook, {
                    bookType:'xlsx',
                    type: 'binary'
                })

                let buffer = new ArrayBuffer(stream.length)
                let viewer = new Uint8Array(buffer)
                for (let i = 0; i < stream.length; i++) {
                    viewer[i] = stream.charCodeAt(i) & 0xFF
                }

                saveAs(new Blob([buffer], {
                    type: 'application/octet-stream'
                }), filename + '.xlsx')
            },

            handleGroupChange(event) {
                if (event !== null && event !== '') {
                    this.group = event
                } else {
                    this.group = null
                }

                this.fetchReport()
            },

            handleRegionChange(event) {
                if (event !== null && event !== '') {
                    this.region = event
                } else {
                    this.region = null
                }

                this.fetchReport()
            },

            handleResize() {
                var computed = getComputedStyle(this.$refs.container)

                var width = this.$refs.container.clientWidth
                width -= parseFloat(computed.paddingLeft) + parseFloat(computed.paddingRight)

                this.width = width
            },

            setLoading(bool) {
                if (bool) {
                    this.loading++
                } else {
                    this.loading--

                    if (this.loading < 0) {
                        this.loading = 0
                    }
                }
            },

            handleToggleExpand(type, event) {
                if (type == 'attendance') {
                    if (typeof this.report.attendance[event].expanded === 'undefined' || this.report.attendance[event].expanded === false) {
                        this.$set(this.report.attendance[event], 'expanded', true)
                        this.handleResize()
                    } else {
                        this.$set(this.report.attendance[event], 'expanded', false)
                    }
                } else {
                    if (typeof this.report.survey[event].expanded === 'undefined' || this.report.survey[event].expanded === false) {
                        this.$set(this.report.survey[event], 'expanded', true)
                        this.handleResize()
                    } else {
                        this.$set(this.report.survey[event], 'expanded', false)
                    }
                }
            },

            handleEventChange(event) {
                //this.reset();

                if (event !== null && event !== '') {
                    event = this.events.find(object => {
                        return object.id == event
                    })

                    this.setLoading(true)

                    axios.get('/api/events/' + event.id, {
                        params: {
                            fields: true
                        }
                    }).then(response => {
                        this.$set(this, 'event', response.data)
                        this.$set(this, 'name', response.data.title)

                        this.setLoading(false)

                        this.fetchReport()
                    })
                }
            },

            reset() {
                this.$set(this, 'event', {})
                this.$set(this, 'name', null)
                this.$set(this, 'report', {})
            },

            fetchEvents() {
                this.setLoading(true)

                axios.get('/api/events/select').then(response => {
                    this.$set(this, 'events', response.data)

                    this.setLoading(false)
                })
            },

            fetchReport() {
                if (this.event !== null) {
                    this.setLoading(true)

                    axios.get('/api/events/' + this.event.id + '/report', {
                        params: {
                            group: this.group,
                            region: this.region,
                        }
                    }).then(response => {
                        this.$set(this, 'report', response.data.report)

                        this.setLoading(false)
                    })
                }
            }
        }
    }
</script>
