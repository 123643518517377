<style>
</style>

<template>
    <div>
        <h4 class="font-bold text-gray-700">Limit Members</h4>
        <p class="text-gray-700 mb-4">Choose whether to limit this field to members, non members or administrators.</p>

        <div class="flex flex-wrap -m-2">
            <div class="p-2">
                <label class="flex items-center">
                    <Toggle :value="options.groups && options.groups.member" @change="(event) => handleChange('groups.member', event)"></Toggle>
                    <div class="ml-2 mb-0 form-label">Members</div>
                </label>
            </div>
            <div class="p-2">
                <label class="flex items-center">
                    <Toggle :value="options.groups && options.groups.non_member" @change="(event) => handleChange('groups.non_member', event)"></Toggle>
                    <div class="ml-2 mb-0 form-label">Non Members</div>
                </label>
            </div>
            <div class="p-2">
                <label class="flex items-center">
                    <Toggle :value="true" :disabled="true"></Toggle>
                    <div class="ml-2 mb-0 form-label">Administrators</div>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import Toggle from "../../../GenericToggleComponent"

    export default {
        components: {
            Toggle
        },

        props: {
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        methods: {
            handleChange(key, value) {
                this.$emit('change', key, value)
            }
        }
    }
</script>
