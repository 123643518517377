<template>
    <div class="fixed inset-0 z-50 overflow-auto bg-smoke-light flex items-center justify-center">
        <div class="field bg-white rounded shadow rounded border-red border-t-4 border-solid p-8 animated faster slideInUp">
            <h2 class="text-2xl">Are you sure?</h2>
            <p class="text-md">Deleting this group will also delete all child elements!</p>
            <div class="flex mt-4">
                <a href="" @click.prevent="handleConfirm" class="button is-danger">Yes, Delete</a>
                <a href="" @click.prevent="handleCancel" class="button is-tertiary ml-4">Cancel</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            handleConfirm() {
                this.$emit('confirm')
            },
            handleCancel() {
                this.$emit('cancel')
            }
        }
    }
</script>
