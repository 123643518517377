<template>
    <div class="relative">
        <datetime :class="className" :type="type" :value="date" :format="format" :valueFormat="output" :hidden-name="name" :input-id="id" v-on:input="onInput"></datetime>
        <a v-if="this.date" class="absolute inset-y-0 right-0 py-2 px-3" href="" @click.prevent="clear">&times;</a>
    </div>
</template>

<script>
    import moment from 'moment';
    import Datetime from './Datetime';
    import { DateTime as LuxonDateTime } from 'luxon'

    export default {
        props: {
            type: {
                type: String,
                default: 'datetime'
            },
            className: {
                type: String,
                default: 'form-control'
            },
            format: {
                type: String,
                default: function () {
                    if (this.type === 'datetime') {
                        return 'yyyy-MM-dd HH:mm'
                    } else if (this.type === 'time') {
                        return 'HH:mm'
                    }
                    return 'yyyy-MM-dd'
                }
            },
            output: {
                type: String,
                default: function () {
                    if (this.type === 'datetime') {
                        return 'yyyy-MM-dd HH:mm:ss'
                    } else if (this.type === 'time') {
                        return 'HH:mm:ss'
                    }
                    return 'yyyy-MM-dd'
                }
            },
            datetime: {
                type: String|Date|moment,
                required: false,
            },
            name: {
                type: String,
                required: false,
            },
            id: {
                type: String,
                required: false,
            }
        },

        data() {
            let datetime = this.datetime

            if (moment.isMoment(datetime)) {
                datetime = datetime.format('YYYY-MM-DD HH:mm:ss')
            } else if (datetime instanceof Date) {
                datetime = moment(datetime).format('YYYY-MM-DD HH:mm:ss')
            } else if (datetime instanceof LuxonDateTime) {
                datetime = datetime.toFormat('yyyy-MM-dd HH:mm:ss')
            } else if (datetime === null) {
                datetime = ''
            }

            return {
                date: datetime
            }
        },

        watch: {
            datetime: {
                handler(nv, ov) {
                    this.updateDateFromDatetime()
                }
            }
        },

        methods: {
            updateDateFromDatetime() {
                if (moment.isMoment(this.datetime)) {
                    this.date = this.datetime.format('YYYY-MM-DD HH:mm:ss')
                } else if (this.datetime instanceof Date) {
                    this.date = moment(this.datetime).format('YYYY-MM-DD HH:mm:ss')
                } else if (this.datetime instanceof LuxonDateTime) {
                    this.date = this.datetime.toFormat('yyyy-MM-dd HH:mm:ss')
                } else if (this.datetime === null) {
                    this.date = ''
                } else {
                    this.date = this.datetime
                }
            },
            clear() {
                this.date = ''

                this.$emit('input', '');
            },
            onInput(payload) {
                this.date = payload

                if (payload.length) {
                    this.$emit('input', payload);
                } else {
                    this.$emit('input', '');
                }
            }
        },
    };
</script>
