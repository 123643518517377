<template>
    <div class="relative">
        <datetime :class="className" :type="type" :value="datetime" :format="format" valueFormat="yyyy-MM-dd HH:mm:ss" @input="handleChange"></datetime>
        <a v-if="this.datetime" class="absolute inset-y-0 right-0 py-2 px-3" href="" @click.prevent="handleClear">&times;</a>
    </div>
</template>

<script>
    import moment from 'moment';

    import { DateTime } from 'luxon'

    export default {
        props: {
            type: {
                type: String,
                default: 'datetime'
            },
            className: {
                type: String,
                default: 'form-control'
            },
            format: {
                type: String,
                default: function () {
                    if (this.type == 'datetime') {
                        return 'yyyy-MM-dd HH:mm'
                    } else if (this.type == 'time') {
                        return 'HH:mm'
                    }
                    return 'yyyy-MM-dd'
                }
            },
            value: {
                type: DateTime,
                required: false,
            }
        },

        data() {
            return {
                datetime: this.value !== null ? this.value.toFormat('yyyy-MM-dd HH:mm:ss') : ''
            }
        },

        methods: {
            handleClear() {
                this.datetime = ''

                this.$emit('change', null);
            },
            handleChange(value) {
                if (value.length) {
                    this.$emit('change', DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss'));
                } else {
                    this.$emit('change', null);
                }
            }
        }
    };
</script>
