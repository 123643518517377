<template>
    <div>
        <div class="mb-4">
            <label class="form-label">Title</label>
            <input type="text" class="form-control" :value="slot.title" @keyup="(event) => handleChange('title', event.target.value)">
        </div>

        <div>
            <label class="form-label">Description</label>
            <Editor :value="slot.description" @change="(event) => handleChange('description', event)"></Editor>
        </div>
    </div>
</template>

<script>
    import Editor from '../../builder/Fields/Mixins/Editor'

    export default {
        components: {
            Editor
        },

        props: {
            data: {
                type: Object,
                required: true
            }
        },

        data() {
            const initial = {
                description: ''
            }

            return {
                slot: Object.assign({}, initial, this.data)
            }
        },

        methods: {
            handleChange(name, value) {
                this.$emit('change', name, value)
            }
        },

        computed: {}
    }
</script>
