<template>
    <div>
        <div class="mb-5">
            <form>
                <div class="flex flex-col lg:flex-row">
                    <div class="flex flex-col lg:flex-row lg:justify-between flex-grow">
                        <div class="relative w-full mb-5 mr-5 lg:mb-0 lg:w-auto" v-if="showTable">
                            <input type="search" name="keyword" id="keyword" class="border rounded px-3 py-2 w-full pl-10 lg:w-auto lg:min-w-300" placeholder="Search" v-model="keyword">
                            <i class="fas fa-search absolute top-0 left-0 h-full flex items-center px-4 text-gray-400"></i>
                        </div>
                        <a href="/admin/events/bookings/create" class="button is-primary ml-auto">
                            <i class="fas fa-plus mr-3"></i> New Manual Booking
                        </a>
                    </div>
                </div>
            </form>
        </div>
        <div>
            <div v-if="showTable">
                <data-table-component :url="`/api/bookings/${booking}/bookings/datatable`" :has-toolbar="false" :columns="columns" :keyword="keyword">
                    <template slot-scope="{ row }" slot="actions">
                        <td class="text-right">
                            <a :href="`/admin/events/${row.id}/`" class="button is-small is-tertiary inline-block">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </template>
                </data-table-component>
            </div>
            <div v-if="showCalendar">
                <calendar-view :show-date="showDate" class="theme-default" :events="events">
                    <calendar-view-header
                        slot="header"
                        slot-scope="t"
                        :header-props="t.headerProps"
                        @input="setShowDate" />
                </calendar-view>
            </div>
        </div>
    </div>
</template> 
 
<script>
    import DataTableComponent from '../DataTableComponent';
    import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar';
    import moment from 'moment';

    export default {
        components: {
            DataTableComponent,
            CalendarView,
            CalendarViewHeader
        },

         props: {
            booking: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                showTable: true,
                showCalendar: false,

                columns: [
                    {
                        key: 'id',
                        label: 'Wrong table',
                    },
                    {
                        key: 'name',
                        label: 'Wrong table',
                    }
                ],

                keyword: '',

                currentRow: {},

                showDate: new Date(),

                events: [
                    {
                        id: "e0",
                        startDate: "2019-11-11",
                    },
                    {
                        id: "e1",
                        startDate: "2019-11-22",
                    },
                    {
                        id: "e2",
                        startDate: "2019-11-18",
                        title: "Single-day event with a long title",
                    },
                    {
                        id: "e3",
                        startDate: "2019-11-05",
                        endDate: "2019-11-12",
                        title: "Multi-day event with a long title and times",
                    }
                ]
            }
        },

        methods: {
			setShowDate(d) {
				this.showDate = d;
			}
		}
    }
</script>
