<template>
    <div>
        <div class="mb-5" v-if="hasToolbar">
            <div class="flex flex-col lg:flex-row lg:justify-between">
                <slot name="prepend"></slot>

                <div class="relative w-full mb-5 mr-10 lg:mb-0 lg:w-auto" v-if="isSearchable">
                    <input type="search" name="keyword" id="keyword"
                            class="border rounded px-3 py-2 w-full pl-10 lg:w-auto lg:min-w-300" placeholder="Search"
                            v-model="keyword">
                    <i class="fas fa-search absolute top-0 left-0 h-full flex items-center px-4 text-gray-400"></i>
                </div>

                <slot name="filters"></slot>
            </div>
        </div>

        <div :class="tableContainerClass">
            <table :class="[ hasRows ? 'mb-10' : '', tableClass ]">
                <thead>
                <tr>
                    <th class="cursor-pointer" v-for="(column, key) in columns" :key="key" @click="onSort(key)" :class="[ typeof column.wrap !== 'undefined' && !column.wrap ? 'whitespace-no-wrap' : '']">
                        {{ column.label }} <i class="fas"
                                              :class="[ sort === 'asc' ? 'fa-angle-down' : 'fa-angle-up' ]"
                                              v-show="key == (order ? order : defaultOrderBy())"></i>
                    </th>
                </tr>
                </thead>
                <tbody v-if="isLoading">
                <tr>
                    <td class="text-center h-20" :colspan="columns.length">
                        <div class="fa-2x">
                            <i class="fas fa-spinner fa-spin text-gray-500"></i>
                        </div>
                    </td>
                </tr>
                </tbody>
                <tbody v-if="hasRows === false && isLoading === false">
                <tr>
                    <td class="text-center text-gray-600 h-20" :colspan="columns.length">Sorry, no results were returned
                        from this table
                    </td>
                </tr>
                </tbody>
                <transition name="fade-slow">
                    <tbody v-if="hasRows === true">
                        <tr v-for="(row, key) in getRows" :key="key">
                            <td class="text-sm" v-for="(column, key) in getRowColumns(row)" :key="key" v-html="html(key, column, row)"></td>
                            <slot name="actions" :row="row"></slot>
                        </tr>
                    </tbody>
                </transition>
            </table>

            <div class="flex flex-col items-center justify-between md:flex-row" v-if="hasRows">
                <data-table-pagination :currentPage="meta.current_page"
                                       :perPage="meta.per_page"
                                       :total="meta.total"
                                       @pagechange="onPageChange">
                </data-table-pagination>
                <p class="text-gray-700 text-sm mt-5 lg:mt-0">Showing {{ getStatsFrom }} to {{ getStatsTo }} of {{ getStatsTotal }} entries</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {debounce} from 'lodash';
    import DataTablePagination from "./DataTablePagination";

    export default {
        components: {
            DataTablePagination
        },

        props: {
            url: {
                type: String,
                required: true
            },

            orderBy: {
                type: String,
                default: null
            },

            sortBy: {
                type: String,
                default: 'desc'
            },

            columns: {
                type: Array,
                required: true
            },

            tableContainerClass: {
                type: String,
                default: 'bg-white shadow rounded p-10'
            },

            tableClass: {
                type: String,
                default: 'data-table'
            },

            isSearchable: {
                type: Boolean,
                default: true
            },

            hasToolbar: {
                type: Boolean,
                default: true
            },

            params: {
                type: Object,
            }
        },

        created() {
            this.fetch();
        },

        mounted() {
            window.EventBus.$on('onTableRefresh', () => this.fetch());
        },

        data() {
            return {
                data: {},
                isLoading: true,

                order: this.orderBy,
                sort: this.sortBy,

                meta: {
                    current_page: 1,
                    total: 0,
                    per_page: 1,
                },

                keyword: '',
                showX: [
                    10, 20, 50
                ]
            }
        },

        methods: {
            fetch: debounce(function () {
                axios.get(this.url, {
                    params: Object.assign({
                        keyword: this.keyword,
                        page: this.meta.current_page,
                        order_by: this.columns[(this.order ? this.order : this.defaultOrderBy())].key,
                        sort: this.sort,
                    }, this.params)
                }).then(response => {
                    this.meta = response.data;
                    this.data = this.meta.data;

                    delete this.meta.data;

                    this.isLoading = false;
                })
            }, 200),

            onSort(key) {
                if (key == this.order) {
                    this.sort = this.sort == 'asc' ? 'desc' : 'asc';
                }

                this.order = key;
            },

            onPageChange(page) {
                this.meta.current_page = page;
                this.fetch();
            },

            // @deprecated
            isColumnVisible(column) {
                let item = this.columns.find(o => o.key === column);

                if (typeof item !== 'undefined') {
                    if (!item.hasOwnProperty('visible')) {
                        return true;
                    }

                    return item.visible;
                }

                return false;
            },

            html(key, value, row) {
                let column = this.columns[key];

                if (column.hasOwnProperty('html')) {
                    return column.html(column.key, value, row);
                }

                return row[column.key];
            },


            getRowColumns(row) {
                var rows = [];
                this.columns.forEach(element => {
                    rows.push(row[element.key])
                });

                return rows;
            },

            defaultOrderBy() {
                return Object.keys(this.columns)[0];
            }

        },

        computed: {
            getRows() {
                return this.data;
            },

            hasRows() {
                if (typeof this.data.length === 'undefined') {
                    return false;
                }

                if (this.data.length) {
                    return true;
                }

                return false;
            },

            getStatsFrom() {
                return this.meta.from || 0;
            },

            getStatsTo() {
                return this.meta.to || 0;
            },

            getStatsTotal() {
                return this.meta.total || 0;
            }
        },

        watch: {
            sort: function () {
                this.fetch();
            },

            order: function () {
                this.fetch();
            },

            keyword: function () {
                this.fetch();
            }
        }
    }
</script>
