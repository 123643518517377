<template>
    <div class="card is-primary p-5">
        <div class="flex items-center mb-5">
            <Select :value="level" :options="levels" value-column="name" placeholder="Filter Types..." @change="handleLevelChange"></Select>

            <label class="ml-5 flex items-center">
                <div class="mr-2 text-sm text-gray-600">Paid</div>
                <Toggle :value="paid" @change="handlePaidChange"></Toggle>
            </label>

            <label class="ml-5 flex items-center">
                <div class="mr-2 text-sm text-gray-600">Approved</div>
                <Toggle :value="approved" @change="handleApprovedChange"></Toggle>
            </label>

            <label class="ml-5 flex items-center">
                <div class="mr-2 text-sm text-gray-600">Complimentary</div>
                <Toggle :value="complimentary" @change="handleComplimentaryChange"></Toggle>
            </label>

            <label class="ml-5 flex items-center">
                <div class="mr-2 text-sm text-gray-600">Cancelled</div>
                <Toggle :value="cancelled" @change="handleCancelledChange"></Toggle>
            </label>

            <div class="flex items-center ml-5">
                <h5 class="mt-0 mb-0 text-md text-gray-600">{{ count }} Results</h5>
            </div>

            <button class="button is-primary ml-auto" @click.prevent="handleDownload"><i class="fas fa-download mr-3"></i> Download</button>
        </div>

        <div class="pr-5 overflow-x-scroll">
            <table class="generic-table">
                <thead>
                    <tr>
                        <th>Reference</th>
                        <th>Paid</th>
                        <th>Approved</th>
                        <th>Complimentary</th>
                        <th>Cancelled</th>
                        <th>Price</th>
                        <th>CPD</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>GDC&nbsp;Reg</th>
                        <th>Address</th>
                        <th>Email</th>
                        <th>Date</th>
                        <th nowrap v-for="(field, key) in fields" :key="key">{{ field }}</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="booking in bookings">
                        <tr :key="`${booking.id}-1`">
                            <td><a :href="'/admin/events/bookings/' + booking.id + '/edit'">{{ booking.booking_id }}</a></td>
                            <td :class="[ 'text-center text-lg', booking.paid ? 'text-green' : 'text-red' ]">{{ booking.paid ? '&#10004;' : '&times;' }}</td>
                            <td :class="[ 'text-center text-xl', booking.approved ? 'text-green' : 'text-red' ]">{{ booking.approved ? '&#10004;' : '&times;' }}</td>
                            <td :class="[ 'text-center text-xl', booking.complimentary ? 'text-green' : 'text-red' ]">{{ booking.complimentary ? '&#10004;' : '&times;' }}</td>
                            <td :class="[ 'text-center text-xl', booking.cancelled ? 'text-red' : 'text-green' ]">{{ booking.cancelled ? '&#10004;' : '&times;' }}</td>
                            <td class="text-center">&pound;{{ booking.amount }}</td>
                            <td class="text-center">{{ booking.evaluated_at !== null ? booking.cpd : 0 }}</td>
                            <td nowrap>{{ (booking.title !== '' ? booking.title + ' ' : '') + booking.fullname }}</td>
                            <td nowrap>{{ booking.guest || booking.user_id === '' ? 'Non Member' : 'Member' }}</td>
                            <td nowrap>{{ booking.gdc_reg_no }}</td>
                            <td nowrap>{{ booking.address }}</td>
                            <td><a :href="'mailto:' + booking.email">{{ booking.email }}</a></td>
                            <td nowrap>{{ booking.created_at }}</td>
                            <td nowrap v-for="(field, key) in fields" :key="key">
                                <template v-if="types[key] === 'toggle'">
                                    <template v-if="typeof booking.values[key] !== 'undefined'">
                                        <span :class="[ 'text-center text-lg', (booking.values[key].value === 1 || booking.values[key].value === '1') ? 'text-green' : 'text-red' ]">{{ (booking.values[key].value === 1 || booking.values[key].value === '1') ? '&#10004;' : '&times;' }}</span>
                                    </template>
                                    <template v-if="typeof booking.values[key] === 'undefined'">
                                        <span class="text-center text-lg text-red">&times;</span>
                                    </template>
                                </template>
                                <template v-if="types[key] === 'input'">{{ typeof booking.values[key] !== 'undefined' ? booking.values[key].value : '–' }}</template>
                                <template v-if="types[key] === 'additional'">{{ typeof booking.values[key] !== 'undefined' ? booking.values[key].value : '–' }}</template>
                            </td>
                        </tr>
                        <tr :key="`${booking.id}-2`" v-if="typeof notes[booking.id] !== 'undefined'">
                            <td colspan="100%">
                                <div class="px-2 text-grey">Note: {{ notes[booking.id] }}</div>
                            </td>
                        </tr>
                    </template>

                    <tr v-if="count === 0">
                        <td class="text-center" colspan="100%">No Results</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import Select from '../../GenericSelectComponent'
    import Toggle from '../../GenericToggleComponent'

    import XLSX from 'xlsx'
    import {saveAs} from 'file-saver'
    import Blob from 'blobjs'
    import moment from 'moment'

    export default {
        components: {
            Select,
            Toggle
        },

        props: {
            event: {
                type: Object|null,
                default: null
            },

            context: {
                type: Object,
                default: () => {
                    return {}
                }
            },

            restrict: {
                type: Array|null,
                default: null
            },

            notes: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        data() {
            return {
                count: 0,

                level: null,
                levels: [
                    {
                        id: 'member',
                        name: 'Member'
                    },
                    {
                        id: 'non_member',
                        name: 'Non Member'
                    }
                ],

                approved: true,
                paid: true,
                complimentary: false,
                cancelled: false,

                bookings: {},
                fields: {},
                types: {}
            }
        },

        methods: {
            handleDownload() {
                var slugify = require('slugify')

                var filename = moment().format('YYYY-MM-DD_HH-mm')
                if (typeof this.context.event !== 'undefined' && this.context.event !== null) {
                    filename = filename + '_' + slugify(this.context.event.title.toLowerCase(), '-')
                }
                if (typeof this.context.field !== 'undefined' && this.context.field !== null) {
                    filename = filename + '_' + slugify(this.context.field.toLowerCase(), '-')
                }

                if (typeof this.type !== 'undefined' && this.type !== null && this.type !== '') {
                    filename = filename + '_' + slugify(this.type, '-')
                }

                if (this.approved) {
                    filename = filename + '_approved'
                } else {
                    filename = filename + '_unapproved'
                }

                if (this.paid) {
                    filename = filename + '_paid'
                } else {
                    filename = filename + '_unpaid'
                }

                if (this.complimentary) {
                    filename = filename + '_complimentary'
                }

                if (this.cancelled) {
                    filename = filename + '_cancelled'
                }

                let header = ['Reference', 'Paid', 'Approved', 'Complimentary', 'Cancelled', 'Price', 'CPD', 'Name', 'Type', 'GDC Reg', 'Address', 'Email', 'Date', 'Notes']
                for (let field in this.fields) {
                    field = this.fields[field]

                    header.push(field)
                }

                let data = [header]

                for (let booking in this.bookings) {
                    booking = this.bookings[booking]

                    let row = [
                        booking.booking_id,
                        booking.paid === 1 ? 'yes' : 'no',
                        booking.approved === 1 ? 'yes' : 'no',
                        booking.complimentary === 1 ? 'yes' : 'no',
                        booking.cancelled === 1 ? 'yes' : 'no',
                        '£' + booking.amount,
                        booking.evaluated_at !== null ? booking.cpd : 0,
                        (booking.title !== '' ? booking.title + ' ' : '') + booking.fullname,
                        booking.guest || booking.user_id === '' ? 'Non Member' : 'Member',
                        booking.gdc_reg_no,
                        booking.address,
                        booking.email,
                        booking.created_at,
                        typeof this.notes[booking.id] !== 'undefined' ? this.notes[booking.id] : ''
                    ]

                    const keys = Object.keys(this.fields)
                    for (const key of keys) {
                        if (typeof this.types[key] !== 'undefined') {
                            if (this.types[key] === 'toggle') {
                                if (typeof booking.values[key] !== 'undefined') {
                                    row.push(booking.values[key].value === '1' || booking.values[key].value === 1 ? 'yes' : 'no')
                                } else {
                                    row.push('no')
                                }
                            } else if (this.types[key] === 'input') {
                                row.push(booking.values[key].value)
                            }
                        }
                    }

                    data.push(row)
                }

                let workbook = XLSX.utils.book_new()
                workbook.SheetNames.push('Bookings')

                workbook.Sheets['Bookings'] = XLSX.utils.aoa_to_sheet(data)

                let stream = XLSX.write(workbook, {
                    bookType:'xlsx',
                    type: 'binary'
                })

                let buffer = new ArrayBuffer(stream.length)
                let viewer = new Uint8Array(buffer)
                for (let i = 0; i < stream.length; i++) {
                    viewer[i] = stream.charCodeAt(i) & 0xFF
                }

                saveAs(new Blob([buffer], {
                    type: 'application/octet-stream'
                }), filename + '.xlsx')
            },

            handleLevelChange(level) {
                if (level !== null && level !== '') {
                    this.level = level
                } else {
                    this.level = null
                }

                this.fetchBookings()
            },

            handleComplimentaryChange(event) {
                this.complimentary = !!event;

                this.fetchBookings()
            },

            handleApprovedChange(event) {
                this.approved = !!event;

                this.fetchBookings()
            },

            handleCancelledChange(event) {
                this.cancelled = !!event;

                this.fetchBookings()
            },

            handlePaidChange(event) {
                this.paid = !!event;

                this.fetchBookings()
            },

            fetchBookings() {
                if (this.event !== null && Object.keys(this.event).length !== 0) {
                    this.$emit('loading')

                    axios.get('/api/events/' + this.event.id + '/bookings', {
                        params: {
                            type: this.level,
                            approved: this.approved,
                            cancelled: this.cancelled,
                            complimentary: this.complimentary,
                            paid: this.paid,
                            strict: true,
                            values: true,
                            restrict: this.restrict !== null ? JSON.stringify(this.restrict) : null
                        }
                    }).then(response => {
                        this.$set(this, 'count', response.data.count)

                        this.$set(this, 'bookings', response.data.bookings)

                        this.$emit('loaded')
                    })
                } else {
                    this.$set(this, 'count', 0)
                    this.$set(this, 'bookings', {})

                    this.$set(this, 'approved', true)
                    this.$set(this, 'paid', true)
                    this.$set(this, 'complimentary', false)
                }
            }
        },

        mounted() {
            this.$set(this, 'types', {})
            this.$set(this, 'fields', {})
            if (typeof this.event.booking !== 'undefined') {
                for (let i = 0; i < this.event.booking.fields.length; i++) {
                    if (this.event.booking.fields[i].enabled === true && (this.event.booking.fields[i].type === 'toggle' || this.event.booking.fields[i].type === 'input')) {
                        this.fields[this.event.booking.fields[i].name] = this.event.booking.fields[i].options.caption
                        this.types[this.event.booking.fields[i].name] = this.event.booking.fields[i].type

                        if (typeof this.event.booking.fields[i].options['request_additional'] !== 'undefined' && this.event.booking.fields[i].options['request_additional'] === true) {
                            this.fields[this.event.booking.fields[i].name + '_additional'] = (typeof this.event.booking.fields[i].options['additional_prompt'] !== 'undefined' && this.event.booking.fields[i].options['additional_prompt'] !== '' ? this.event.booking.fields[i].options['additional_prompt'] : this.event.booking.fields[i].options.caption + ' Additional Information')
                            this.types[this.event.booking.fields[i].name + '_additional'] = 'additional'
                        }
                    }
                }
            }

            this.fetchBookings()
        },

        watch: {
            event: {
                handler() {
                    this.$set(this, 'types', {})
                    this.$set(this, 'fields', {})
                    if (typeof this.event.booking !== 'undefined') {
                        for (let i = 0; i < this.event.booking.fields.length; i++) {
                            if (this.event.booking.fields[i].enabled === true && (this.event.booking.fields[i].type === 'toggle' || this.event.booking.fields[i].type === 'input')) {
                                this.fields[this.event.booking.fields[i].name] = this.event.booking.fields[i].options.caption
                                this.types[this.event.booking.fields[i].name] = this.event.booking.fields[i].type

                                if (typeof this.event.booking.fields[i].options['request_additional'] !== 'undefined' && this.event.booking.fields[i].options['request_additional'] === true) {
                                    this.fields[this.event.booking.fields[i].name + '_additional'] = (typeof this.event.booking.fields[i].options['additional_prompt'] !== 'undefined' && this.event.booking.fields[i].options['additional_prompt'] !== '' ? this.event.booking.fields[i].options['additional_prompt'] : this.event.booking.fields[i].options.caption + ' Additional Information')
                                    this.types[this.event.booking.fields[i].name + '_additional'] = 'additional'
                                }
                            }
                        }
                    }

                    this.fetchBookings()
                }
            }
        }
    }
</script>
