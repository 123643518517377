<template>
    <div>
        <div class="flex justify-between mb-3">
            <h3 class="mb-4 font-admin-title text-lg font-bold text-gray-700">Notes</h3>
            <a href="#" class="font-bold text-green" @click.prevent="isAddNotesModalVisible = true">
                <i class="fas fa-sticky-note mr-3"></i> Add a Note
            </a>
        </div>

        <div class="flex -mx-1 flex-wrap">
            <div class="w-1/2 px-1" v-if="notes.length === 0">
                <p class="text-gray-600">Be the first to add a note</p>
            </div>
            <div class="w-1/2 px-1" v-for="(note, key) in notes" :key="key">
                <a href="#" class="flex-1 flex flex-col py-6 mb-3 px-6 border rounded mr-3 hover:bg-yellow-lighter cursor-pointer" @click.prevent="open(key)">
                    <div class="flex flex-col mb-2">
                        <p class="font-bold uppercase m-0 text-gray-700">{{ note.admin.name }}</p>
                        <p class="text-xs uppercase m-0 text-green">{{ note.created_at | date }}</p>
                    </div>
                    <div>
                        <p class="text-sm">{{ note.message | trim }}</p>
                    </div>
                </a>
            </div>
        </div>

        <modal :is-visible="isNotesModalVisible" @hide="isNotesModalVisible = false"
               v-if="isNotesModalVisible">
            <template slot="modal-title">Note</template>
            <div slot="modal-body">
                <div class="flex justify-between mb-2">
                    <p class="font-bold">{{ active.note.created_at }}</p>
                    <p class="font-bold">{{ active.note.admin.name }}</p>
                </div>
                <p style="white-space: pre-line;">{{ active.note.message }}</p>
            </div>
            <div slot="modal-footer" class="flex justify-end">
                <button type="button" class="button is-secondary mr-3" @click="isNotesModalVisible = false">
                    <i class="fas fa-times-circle mr-3"></i> Close
                </button>
                <button type="button" class="button is-danger" @click="remove">
                    <i class="fas fa-trash mr-3"></i> Delete
                </button>
            </div>
        </modal>

        <modal :is-visible="isAddNotesModalVisible" @hide="close">
            <template slot="modal-title">Add a Note</template>
            <div slot="modal-body">
                <textarea name="message" id="message" cols="30" rows="10" class="form-control resize-none"
                          v-model="message"></textarea>
                <span class="invalid-input" v-if="error.has('message')">{{ error.get('message') }}</span>
            </div>
            <div slot="modal-footer" class="flex justify-end">
                <button type="button" class="button is-secondary mr-3" @click="close">
                    <i class="fas fa-times-circle mr-3"></i> Close
                </button>
                <button type="button" class="button is-primary" @click="save">
                    <i class="fas fa-save mr-3"></i> Save
                </button>
            </div>
        </modal>
    </div>
</template>


<script>
    import moment from 'moment';
    import Modal from './Modal';
    import Error from '../Error';

    export default {

        components: {
            Modal
        },

        props: {
            user: {
                type: Object
            },
        },

        created() {
            this.fetch();
        },

        data() {
            return {
                message: '',
                notes: [],
                active: {},
                isNotesModalVisible: false,
                isAddNotesModalVisible: false,

                error: new Error()
            }
        },

        methods: {
            fetch() {
                axios.get('/api/users/' + this.user.id + '/notes').then(response => {
                    this.notes = response.data;
                })
            },

            remove() {
                axios.delete('/api/users/' + this.user.id + '/notes/' + this.active.note.id).then(() => {
                    this.isNotesModalVisible = false;
                    this.active = {};

                    this.fetch();
                });
            },

            close() {
                this.error = new Error();
                this.message = '';
                this.isAddNotesModalVisible = false;
            },

            open(id) {
                this.active = {id: id, note: this.notes[id]};
                this.isNotesModalVisible = true;
            },

            save() {
                axios.post('/api/users/' + this.user.id + '/notes', {
                    message: this.message
                }).then(() => {
                    this.close();
                    this.fetch();
                }).catch(error => {
                    this.error.errors = error.response.data.errors;
                })
            }
        },

        filters: {
            trim: function (value) {
                return value.substring(0, 30) + '..';
            },

            date: function (value) {
                return moment(value).format('MMMM Do YYYY, h:mm A')
            }
        }
    }
</script>
