<template>
    <span v-text="formatted" type="type"></span>
</template>

<script>
    import accounting from "accounting";

    export default {
        props: {
            to: {
                type: Number,
                required: true
            },
            type: {
                type: String,
                default: "string"
            }
        },

        data() {
            return {
                count: 0,
                interval: null
            };
        },
        computed: {
            increment() {
                return Math.ceil(this.to / 30);
            },
            formatted() {
                switch (this.type) {
                    case "money":
                        return accounting.formatMoney(this.count, '');
                    default:
                        return this.count;
                }
            }
        },
        mounted() {
            this.interval = setInterval(this.tick, 40);
        },
        methods: {
            tick() {
                if (this.count + this.increment >= this.to) {
                    this.count = this.to;
                    return clearInterval(this.interval);
                }
                this.count += this.increment;
            }
        },
        watch: {
            to: {
                handler() {
                    this.count = 0
                    this.interval = setInterval(this.tick, 40)
                }
            }
        }
    };
</script>
