<style>
</style>

<template>
    <div>
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
            <div class="flex mb-2">
                <button class="button justify-center text-sm p-0 w-8 h-8 text-center bg-gray-600 hover:bg-gray-700" :class="{ 'bg-gray-700': isActive.bold() }" @click.prevent="commands.bold">
                    <i class="fas fa-bold"></i>
                </button>
                <button class="button justify-center text-sm p-0 w-8 h-8 text-center bg-gray-600 hover:bg-gray-700 ml-2" :class="{ 'bg-gray-700': isActive.italic() }" @click.prevent="commands.italic">
                    <i class="fas fa-italic"></i>
                </button>
                <button class="button justify-center text-sm p-0 w-8 h-8 text-center bg-gray-600 hover:bg-gray-700 ml-2" :class="{ 'bg-gray-700': isActive.underline() }" @click.prevent="commands.underline">
                    <i class="fas fa-underline"></i>
                </button>
                <button class="button justify-center text-sm p-0 w-8 h-8 text-center bg-gray-600 hover:bg-gray-700 ml-2" :class="{ 'bg-gray-700': isActive.strike() }" @click.prevent="commands.strike">
                    <i class="fas fa-strikethrough"></i>
                </button>
                <button class="button justify-center text-sm p-0 w-8 h-8 text-center bg-gray-600 hover:bg-gray-700 ml-2" :class="{ 'bg-gray-700': isActive.paragraph() }" @click.prevent="commands.paragraph">
                    <i class="fas fa-paragraph"></i>
                </button>
                <button class="button justify-center text-sm p-0 w-8 h-8 text-center bg-gray-600 hover:bg-gray-700 ml-2" :class="{ 'bg-gray-700': isActive.bullet_list() }" @click.prevent="commands.bullet_list">
                    <i class="fas fa-list-ul"></i>
                </button>
                <button class="button justify-center text-sm p-0 w-8 h-8 text-center bg-gray-600 hover:bg-gray-700 ml-2" :class="{ 'bg-gray-700': isActive.ordered_list() }" @click.prevent="commands.ordered_list">
                    <i class="fas fa-list-ol"></i>
                </button>
                <button class="button justify-center text-sm p-0 w-8 h-8 text-center bg-gray-600 hover:bg-gray-700 ml-2 mr-2" :class="{ 'bg-gray-700': isActive.blockquote() }" @click.prevent="commands.blockquote">
                    <i class="fas fa-quote-left"></i>
                </button>
                <button class="button justify-center text-sm p-0 w-8 h-8 text-center bg-gray-600 hover:bg-gray-700 ml-auto" @click.prevent="commands.undo">
                    <i class="fas fa-undo"></i>
                </button>
                <button class="button justify-center text-sm p-0 w-8 h-8 text-center bg-gray-600 hover:bg-gray-700 ml-2" @click.prevent="commands.redo">
                    <i class="fas fa-redo"></i>
                </button>
            </div>
        </editor-menu-bar>
        <editor-content class="border border-gray-400 bg-white rounded px-4 py-2" :editor="editor" v-if="editor !== null" />
        <input v-if="this.name !== null" type="hidden" :name="this.name" :value="content">
    </div>
</template>

<script>
    import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
    import {
        Blockquote,
        OrderedList,
        BulletList,
        ListItem,
        Bold,
        Code,
        Italic,
        Link,
        Strike,
        Underline,
        History,
    } from 'tiptap-extensions'

    export default {
        components: {
            EditorContent,
            EditorMenuBar
        },

        props: {
            name: {
                type: String,
                required: false
            },
            value: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                editor: null,

                content: this.value
            }
        },

        methods: {
            handleUpdate(element) {
                this.content = element.getHTML()
                this.$emit('change', this.content)
            }
        },

        beforeDestroy() {
            if (this.editor !== null) {
                this.editor.destroy()
            }
        },

        mounted() {
            this.editor = new Editor({
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new Link(),
                    new Bold(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                ],
                content: this.content,
                onUpdate: this.handleUpdate
            })
        },
    }
</script>
