<template>
    <div>
        <label class="form-label">Email Address</label>
        <input type="text" class="form-control" :value="emailAddress" :disabled="this.isChecked" @keyup="onInput">
        <input type="hidden" name="marketing_email" :value="emailAddress">

        <label for="use_standard_email_as_marketing" class="form-label font-normal mt-2">
            <input type="checkbox" name="use_standard_email_as_marketing" id="use_standard_email_as_marketing" value="1" :checked="isChecked" @change="onChange">
            <span class="text-base">Use same email address as account login</span>
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            email: {
                type: String,
                required: false
            },
            default: {
                type: String,
                required: false
            },
            checked: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                old: this.email,

                emailAddress: this.email,
                isChecked: this.checked === 1
            }
        },

        methods: {
            onInput(event) {
                this.emailAddress = event.target.value
            },
            onChange() {
                this.isChecked = !this.isChecked

                if (this.isChecked) {
                    this.emailAddress = this.default
                } else {
                    this.emailAddress = this.old
                }
            }
        }
    }
</script>
