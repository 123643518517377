<script>
    import Error from "../Error";

    import FormStepComponent from "./FormStepComponent";

    export default {
        components: {
            FormStepComponent,
        },

        props: {
            url: {
                type: String,
                required: true
            },
            steps: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                currentStep: 1,
                data: [],

                isSuccess: false,
                isError: false,

                error: new Error()
            }
        },

        methods: {
            hasStep(step) {
                return step >= 1 && step <= this.steps
            },

            nextStep() {
                if (this.hasStep(this.currentStep + 1)) {
                    return this.currentStep++;
                }
            },

            prevStep() {
                if (this.hasStep(this.currentStep - 1)) {
                    return this.currentStep--;
                }
            },

            tryStep(step) {
                if (this.hasStep(step) && this.data[step] !== undefined) {
                    this.currentStep = step;
                }
            },

            setPayload(step, payload) {
                this.data[step] = payload
            },

            tryAgain() {
                this.isError = false;
                this.isSuccess = false;
            },

            submit() {
                let properties = {};

                const data = this.data.filter(x => x != null);
                for (let i in data) {
                    Object.keys(data[i]).map(x => {
                        if (x !== 'error') {
                            properties[x] = data[i][x];
                        }
                    })
                }

                axios.post(this.url, properties).then(response => {
                    this.isSuccess = true;
                    this.isError = false;
                }).catch(errors => {
                    this.error.errors = errors.response.data.errors;
                    this.isError = true;
                    this.isSuccess = false;
                })
            },
        }
    }
</script>
