<style>
</style>

<template>
    <div>
        <h4 class="font-bold text-gray-700">Did Not Attend</h4>
        <p class="text-gray-700 mb-4">Allow the user to specify that they did not attend the relevant session.</p>

        <div class="flex flex-wrap -m-2">
            <div class="p-2">
                <label class="flex items-center">
                    <Toggle :value="options.allow_did_not_attend" @change="(event) => handleChange('allow_did_not_attend', event)"></Toggle>
                    <div class="ml-2 mb-0 form-label">Allow user to specify that they did not attend</div>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import Toggle from "../../../GenericToggleComponent"

    export default {
        components: {
            Toggle
        },

        props: {
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        methods: {
            handleChange(key, value) {
                this.$emit('change', key, value)
            }
        }
    }
</script>
