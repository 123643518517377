<template>
    <div>
        <data-table-component url="/api/subscriptions" :columns="columns" :params="params">
            <template slot-scope="{ row }" slot="filters">
                <slot v-bind="row"></slot>
            </template>
            <template slot-scope="{ row }" slot="actions">
                <td class="text-right">
                    <a :href="`/admin/subscriptions/${row.id}/variants`" class="button is-small is-secondary inline-block">
                        <i class="fas fa-th-list"></i> Manage Variants
                    </a>
                    <a :href="`/admin/subscriptions/${row.id}/edit`" class="button is-small is-tertiary inline-block">
                        <i class="fas fa-edit"></i>
                    </a>
                    <a :href="`/admin/subscriptions/${row.id}/duplicate`" class="button is-small is-tertiary inline-block">
                        <i class="fas fa-copy"></i>
                    </a>
                </td>
            </template>
        </data-table-component>
    </div>
</template>

<script>
    import DataTableComponent from '../DataTableComponent';

    export default {
        components: {
            DataTableComponent,
        },

        data() {
            return {
                columns: [
                    {
                        key: 'code',
                        label: 'Code',
                    },
                    {
                        key: 'name',
                        label: 'Name',
                    },
                ],

                currentRow: {},
                isDeleteModalVisible: false,


                // Custom filters
                params: {},
            }
        },

        methods: {

        }
    }
</script>
