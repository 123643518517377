<template>
    <div>
        <data-table-component url="/api/honoraries" :columns="columns">
            <template slot-scope="{ row }" slot="filters">
                <slot v-bind="row"></slot>
            </template>
            <template slot-scope="{ row }" slot="actions">
                <td class="text-right">
                    <a :href="`/admin/honoraries/${row.id}/edit`" class="button is-small is-tertiary inline-block">
                        <i class="fas fa-edit"></i>
                    </a>
                    <a :href="`/admin/users/${row.user_id}/edit`" class="button is-small is-secondary inline-block">
                        <i class="fas fa-user-edit"></i>
                    </a>
                    <a :href="`/admin/users/honorary/${row.id}/edit`" class="button is-small is-danger inline-block" @click.prevent="showDeleteModal(row)">
                        <i class="fas fa-trash"></i>
                    </a>
                </td>
            </template>
        </data-table-component>

        <modal :is-visible="isDeleteModalVisible" @hide="isDeleteModalVisible = !isDeleteModalVisible">
            <template slot="modal-title">
                Confirm Delete
            </template>
            <div slot="modal-body">
                <p>Are you sure you wish to delete <span class="font-bold">{{ getName }}</span>?</p>
            </div>
            <div slot="modal-footer" class="flex justify-end">
                <button type="button" class="button is-secondary mr-2" @click.prevent="isDeleteModalVisible = false">
                    Cancel
                </button>
               <button type="button" class="button is-danger" @click="onDelete">
                    <i class="fas fa-trash mr-3"></i> Delete
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
    import DataTableComponent from '../DataTableComponent';
    import Modal from '../../components/Modal';

    export default {
        components: {
            DataTableComponent,
            Modal
        },

        data() {
            return {
                columns: [
                    {
                        key: 'first_name',
                        label: 'First Name'
                    },
                    {
                        key: 'last_name',
                        label: 'Last Name'
                    },
                    {
                        key: 'year',
                        label: 'Year'
                    },
                ],

                currentRow: {},
                isDeleteModalVisible: false,
            }
        },

        methods: {
            showDeleteModal(row) {
                this.currentRow = row;
                this.isDeleteModalVisible = true;
            },

            onDelete() {
                axios.delete('/admin/honoraries/' + this.currentRow.id).then(response => {
                    if (response.status === 200) {
                        this.isDeleteModalVisible = false;
                    }
                });

                window.EventBus.$emit('onTableRefresh');
            }
        },

        computed: {
            getName() {
                return this.currentRow.first_name + ' ' + this.currentRow.last_name;
            }
        }
    }
</script>
