<template>
    <div class="session card p-8 mb-4">
        <div class="flex justify-between items-center">
            <div class="flex-1">
                <h1 class="prevent text-xl">{{ session.title }}</h1>
                <span class="prevent" v-html="information"></span>
            </div>
            <div class="flex">
                <button class="button is-primary" @click="() => handleSessionChange('toggled', !session.toggled)"><i class="fa" :class="session.toggled ? 'fa-angle-double-up' : 'fa-angle-double-down'"></i></button>
            </div>
        </div>

        <div class="mt-4" :class="{ 'hidden': !session.toggled }">
            <div class="mb-4">
                <div class="mb-4">
                    <label class="form-label">Title</label>
                    <input type="text" class="form-control prevent" :value="session.title" @keyup="(event) => handleSessionChange('title', event.target.value)">
                </div>

                <div class="mb-4">
                    <label class="form-label">Subtitle</label>
                    <input type="text" class="form-control prevent" :value="session.subtitle" @keyup="(event) => handleSessionChange('subtitle', event.target.value)">
                </div>

                <div class="mb-4">
                    <label class="form-label">Date</label>
                    <LuxonDateTime type="date" className="form-control prevent" :value="session.date" @change="(event) => handleSessionChange('date', event)"></LuxonDateTime>
                </div>

                <div class="mb-4">
                    <label class="form-label">Linked Session</label>
                    <GenericSelectComponent class="prevent" :compact="true" valueColumn="title" :options="sessions" :value="session.linked_session" @change="(value) => handleSessionChange('linked_session', value)"></GenericSelectComponent>
                </div>

                <div>
                    <label class="form-label">Max CPD Hours</label>
                    <p class="mb-4">Maximum number of CPD hours allowed for this session on the Evaluation form. If this sessions has a Linked Session set, the allowed maximum will be the higher of the two sessions' Max CPD Hours</p>
                    <input type="number" class="form-control prevent" :value="session.max_cpd_hours" @keyup="(event) => handleSessionChange('max_cpd_hours', event.target.value)">
                </div>
            </div>

            <label class="form-label">Slots</label>

            <div class="slots">
                <Draggable class="sortable-group-table mb-4" tag="table" :list="session.slots" group="slots" draggable="tbody" filter=".prevent" :prevent-on-filter="false" @end="handleEnd">
                    <SessionSlot :talks="talks" :talksLoading="talksLoading" :session="session" :data="slot" :types="types" :event="event" v-for="slot in iterableSlots" :key="slot.ulid" @change="(key, value) => handleSlotChange(slot.ulid, key, value)" @remove="() => handleSlotRemove(slot.ulid)"></SessionSlot>

                    <tbody class="prevent" v-if="iterableSlots.length === 0">
                        <td colspan="100%" class="p-2 border-t text-center text-gray-500">No Slots</td>
                    </tbody>

                    <thead class="" slot="header">
                        <th class="border-r">Time</th>
                        <th>Type</th>
                        <th>Duration</th>
                        <th>CPD</th>
                        <th></th>
                    </thead>

                    <tfoot class="" slot="footer">
                        <td class="border-t" colspan="100%">
                            <table class="ml-auto border-l">
                                <tr>
                                    <th class="text-right pl-4">Total</th>
                                    <td class="pl-2 py-2 pr-4" v-html="this.minutes != null ? this.minutes + ' minutes' : '–'"></td>
                                </tr>
                                <tr>
                                    <th class="text-right border-t pl-4">CPD</th>
                                    <td class="pl-2 py-2 border-t pr-4" v-html="this.cpd != null ? this.cpd + ' minutes' : '–'"></td>
                                </tr>
                            </table>
                        </td>
                    </tfoot>
                </Draggable>

                <div class="flex">
                    <button class="button is-primary" @click="addSlot"><i class="fa fa-plus mr-2"></i> Add Slot</button>
                    <button class="button is-danger ml-auto" @click="handleSessionRemove"><i class="fa fa-trash mr-2"></i> Remove</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Draggable from 'vuedraggable'
    import { DateTime } from 'luxon'

    import GenericSelectComponent from '../GenericSelectComponent'

    import SessionSlot from './SessionSlot'
    import LuxonDateTime from './LuxonDateTime'

    export default {
        components: {
            Draggable,
            GenericSelectComponent,
            SessionSlot,
            LuxonDateTime
        },

        props: {
            event: {
                type: Object,
                required: true
            },
            data: {
                type: Object,
                required: true
            },
            types: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            talks: {
                type: Array,
                default: () => {
                    return []
                }
            },
            talksLoading: {
                type: Boolean,
                default: false
            },
            sessions: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                session: this.data
            }
        },

        mounted() {
            this.calculateDurations()
        },

        methods: {
            calculateDurations() {
                for (let i = 0; i < this.session.slots.length; i++) {
                    let next = typeof this.session.slots[i + 1] !== 'undefined' ? this.session.slots[i + 1] : null
                    if (next !== null && this.session.slots[i].time !== null && next.time !== null) {
                        let diff = next.time.diff(this.session.slots[i].time, ['minutes']).toObject()
                        this.session.slots[i].duration = diff.minutes
                    } else {
                        this.session.slots[i].duration = null
                    }
                }
            },

            addSlot() {
                this.$emit('addSlot')
                this.calculateDurations()
            },

            handleSessionChange(key, value) {
                this.$emit('sessionChange', key, value)
                this.calculateDurations()
            },

            handleSlotChange(slot, key, value) {
                this.$emit('slotChange', slot, key, value)
                this.calculateDurations()
            },

            handleEnd(event) {
                event.item.style.transform = 'none'
                this.calculateDurations()
            },

            handleSessionRemove() {
                this.$emit('sessionRemove')
                this.calculateDurations()
            },
            handleSlotRemove(ulid) {
                this.$emit('slotRemove', ulid)
                this.calculateDurations()
            }
        },

        computed: {
            minutes() {
                return this.session.slots.reduce((a, b) => a + (b.duration ? b.duration : 0), 0)
            },
            cpd() {
                return this.session.slots.reduce((a, b) => a + (b.duration && b.cpd && (b.type == 'parallel' || b.type == 'talk') ? b.duration : 0), 0)
            },
            information() {
                let information = []

                if (this.session.date !== null) {
                    information.push(this.session.date.toFormat('yyyy-MM-dd'))
                }

                information.push(this.minutes + ' minutes')

                if (this.session.subtitle !== null && this.session.subtitle !== '') {
                    information.push(this.session.subtitle)
                }

                return information.length === 0 ? null : information.join('&nbsp;&middot;&nbsp;')
            },

            iterableSlots() {
                return this.session.slots.filter((slot) => {
                    return slot.deleted == false
                })
            }
        }
    }
</script>
