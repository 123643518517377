/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// Core Components
import AdminNotesComponent from "./components/AdminNotesComponent";
import UserNotesComponent from "./components/UserNotesComponent";
import CountComponent from "./components/CountComponent";
import CurrencyComponent from "./components/CurrencyComponent";
import DataTableComponent from "./components/DataTableComponent";
import DropdownComponent from "./components/DropdownComponent";
import FormComponent from "./components/FormComponent";
import MegaMenuComponent from "./components/MegaMenuComponent";
import NavigationComponent from "./components/NavigationComponent";
import ResetPasswordComponent from "./components/ResetPasswordComponent";
import SelectComponent from "./components/SelectComponent";
import GenericSelectComponent from "./components/GenericSelectComponent";
import Tooltip from "./components/Tooltip";
import UserPaymentSelectComponent from "./components/UserPaymentSelectComponent";
import UserSelectComponent from "./components/UserSelectComponent";
import UserPaymentFormComponent from "./components/UserPaymentFormComponent";
import DateTimeComponent from "./components/DateTimeComponent";
import UserPaymentTypeComponent from "./components/UserPaymentTypeComponent";
import VariantPricesComponent from "./components/VariantPricesComponent";
import SubscriptionVariantYearComponent from "./components/SubscriptionVariantYearComponent";
import UserSubscriptionCodeSelectComponent from "./components/UserSubscriptionCodeSelectComponent";
import YearSelectComponent from "./components/YearSelectComponent";
import GrowthChart from "./components/GrowthChart";
import ReportTableComponent from "./components/ReportTableComponent";
import RegistrationLookupComponent from "./components/RegistrationLookupComponent";
import ToastComponent from "./components/Toast";
import ToggleComponent from "./components/ToggleComponent";
import GenericToggleComponent from "./components/GenericToggleComponent";
import MarketingEmailComponent from "./components/MarketingEmailComponent";
import GenericSelectMultipleComponent from "./components/GenericSelectMultipleComponent";
import EditorComponent from "./components/builder/Fields/Mixins/Editor";
import SpeakersSelectComponent from "./components/SpeakersSelectComponent";
import SpeakersTable from "./components/events/SpeakersTable";
import QuickSearchComponent from "./components/QuickSearchComponent";
import ToggleContentComponent from "./components/ToggleContentComponent";
import NumberComponent from "./components/NumberComponent";
import CpdComponent from "./components/CpdComponent";
import EventBookingComparisonComponent from "./components/events/EventBookingComparisonComponent";
import MemberSelectComponent from "./components/MemberSelectComponent";
import PdfViewer from "./components/PdfViewer";

// Events Components
import EventsTable from "./components/events/EventsTable";
import EventsCalendar from "./components/events/EventsCalendar";
import BookingsList from "./components/events/BookingsList";
import EventsList from "./components/events/EventsList";
import TalksTable from "./components/events/TalksTable";
import VideosTable from "./components/events/VideosTable";
import EventsBookingsDataTable from "./components/events/EventsBookingsDataTable";

// Builder Components
import Builder from "./components/builder/Builder";
import BookingForm from "./components/builder/Render/BookingForm"
import BookingFormPreview from "./components/builder/Render/BookingFormPreview"
import EvaluationForm from "./components/builder/render/Evaluation/EvaluationForm"
import EvaluationFormPreview from "./components/builder/render/Evaluation/EvaluationFormPreview"
import BookingFormEditorComponent from "./components/builder/Render/BookingFormEditor"
import EvaluationFormEditorComponent from "./components/builder/Render/Evaluation/EvaluationFormEditor"
import Sessions from "./components/sessions/Sessions";
import BookingEvaluationCombinationComponent from "./components/bookings/BookingEvaluationCombinationComponent"

// Bookings DataTables
import BookingsTable from "./components/bookings/BookingsTable";
import ProcessBookings from "./components/bookings/ProcessBookings";
import BookingPaymentsDataTable from "./components/bookings/BookingPaymentsDataTable";
import BookingPaymentsTypeComponent from "./components/bookings/BookingPaymentsTypeComponent";

//Booking Components
import BookingNotesComponent from "./components/bookings/BookingNotesComponent";
import UserBookingSelectComponent from "./components/bookings/UserBookingSelectComponent";
import BookingsExistingMemberComponent from "./components/bookings/BookingsExistingMemberComponent";
import EditUserBookingSelectComponent from "./components/bookings/EditUserBookingSelectComponent";
import EventBookingComponent from "./components/EventBookingComponent";

//Certificates Components
import CertificatesTable from "./components/certificates/CertificatesDataTable";
import CustomCertificateComponent from "./components/certificates/CustomCertificateComponent";

// User Components
import Captcha from "./components/users/Captcha";
import UserBookingsDataTable from "./components/users/UserBookingsDataTable";

// Data Tables
import CouncillorDataTable from "./components/datatables/CouncillorDataTable";
import HonoraryDataTable from "./components/datatables/HonoraryDataTable";
import JobsDataTable from "./components/datatables/JobsDataTable";
import SubscriptionsDataTable from "./components/datatables/SubscriptionsDataTable";
import SubscriptionVariantsDataTable from "./components/datatables/SubscriptionVariantsDataTable";
import UsersDataTable from "./components/datatables/UsersDataTable";
import UserPaymentsDataTable from "./components/datatables/UserPaymentsDataTable";
import UserSubscriptionsDataTable from "./components/datatables/UserSubscriptionsDataTable";
import AdminsDataTable from "./components/datatables/AdminsDataTable";

import EventsReport from "./components/reports/EventsReport";
import MembershipTypesComponent from "./components/MembershipTypesComponent";

import RenewalsLog from "./components/RenewalsLog";

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

window.EventBus = new Vue();

const app = new Vue({
    el: '#app',
    components: {
        PdfViewer,
        AdminNotesComponent,
        UserNotesComponent,
        CountComponent,
        CurrencyComponent,
        DataTableComponent,
        DropdownComponent,
        FormComponent,
        MegaMenuComponent,
        NavigationComponent,
        ResetPasswordComponent,
        SelectComponent,
        GenericSelectComponent,
        Tooltip,
        UserPaymentSelectComponent,
        UserSelectComponent,
        UserPaymentFormComponent,
        DateTimeComponent,
        UserPaymentTypeComponent,
        VariantPricesComponent,
        SubscriptionVariantYearComponent,
        UserSubscriptionCodeSelectComponent,
        YearSelectComponent,
        GrowthChart,
        ReportTableComponent,
        RegistrationLookupComponent,
        ToastComponent,
        ToggleComponent,
        GenericToggleComponent,
        MarketingEmailComponent,
        GenericSelectMultipleComponent,
        EditorComponent,
        SpeakersSelectComponent,
        SpeakersTable,
        QuickSearchComponent,
        ToggleContentComponent,
        NumberComponent,
        CpdComponent,
        EventBookingComparisonComponent,
        MembershipTypesComponent,
        MemberSelectComponent,

        EventsTable,
        EventsCalendar,
        BookingsList,
        EventsList,
        BookingsTable,
        ProcessBookings,
        BookingPaymentsDataTable,
        BookingNotesComponent,
        UserBookingSelectComponent,
        BookingsExistingMemberComponent,
        CertificatesTable,
        CustomCertificateComponent,
        VideosTable,

        Captcha,
        UserBookingsDataTable,

        EventsBookingsDataTable,
        EditUserBookingSelectComponent,
        BookingPaymentsTypeComponent,
        EventBookingComponent,
        BookingEvaluationCombinationComponent,

        Builder,
        BookingForm,
        BookingFormPreview,
        EvaluationForm,
        EvaluationFormPreview,
        BookingFormEditorComponent,
        EvaluationFormEditorComponent,

        Sessions,

        CouncillorDataTable,
        HonoraryDataTable,
        JobsDataTable,
        SubscriptionsDataTable,
        SubscriptionVariantsDataTable,
        UserPaymentsDataTable,
        UsersDataTable,
        UserSubscriptionsDataTable,
        AdminsDataTable,
        TalksTable,

        EventsReport,

        RenewalsLog
    }
});
