<template>
    <div class="flex -mx-2">
        <div class="w-1/2 px-2 mb-5">
            <label class="form-label is-required">Event</label>
            <GenericSelectComponent name="event_id" :value="events.value" :options="events.options" :loading="events.loading" key-column="id" value-column="title" description-column="description" @change="handleSelectEvent"></GenericSelectComponent>
        </div>
        <div class="w-1/2 px-2 mb-5">
            <label class="form-label is-required">Booking</label>
            <GenericSelectComponent name="booking_id" :value="bookings.value" :options="bookings.options" :loading="bookings.loading" :disabled="events.value === null" key-column="id" value-column="title" description-column="description" fullname-column="fullname" id-column="user_id" member-id-column="member_id" email-column="email" @change="handleSelectBooking"></GenericSelectComponent>
        </div>
    </div>
</template>

<script>
    import {debounce} from 'lodash'

    import GenericSelectComponent from "./GenericSelectComponent";

    export default {
        components: {
            GenericSelectComponent,
        },

        props: {
            event: {
                type: Number|String,
                required: false
            },
            booking: {
                type: Number|String,
                required: false
            }
        },

        data() {
            return {
                events: {
                    loading: true,
                    value: this.event == '' ? null : this.event,
                    options: []
                },
                bookings: {
                    loading: false,
                    value: this.booking == '' ? null : this.booking,
                    options: []
                },
            }
        },

        mounted() {
            this.loadEvents()

            if (this.events.value !== null && this.events.value !== '') {
                this.loadBookings()
            }

        },

        methods: {
            handleSelectEvent(event) {
                this.$set(this.events, 'value', event == '' ? null : event)
                if (this.events.value === null) {
                    this.$set(this.bookings, 'value', null)
                } else {
                    this.loadBookings()
                }

                this.$emit('change', {
                    event: this.events.options.find(item => {
                        return item.id === this.events.value
                    }),
                    booking: this.bookings.options.find(item => {
                        return item.id === this.bookings.value
                    }),
                });
            },
            handleSelectBooking(booking) {
                this.$set(this.bookings, 'value', booking == '' ? null : booking)

                this.$emit('change', {
                    event: this.events.options.find(item => {
                        return item.id === this.events.value
                    }),
                    booking: this.bookings.options.find(item => {
                        return item.id === this.bookings.value
                    }),
                });
            },

            loadEvents: debounce(function () {
                this.$set(this.events, 'loading', true)

                axios.get('/api/events/select').then(response => {
                    this.$set(this.events, 'options', response.data)
                    this.$set(this.events, 'loading', false)
                })
            }, 200),
            loadBookings: debounce(function () {
                this.$set(this.bookings, 'loading', true)

                axios.get('/api/events/' + this.events.value + '/bookings/select').then(response => {
                    this.$set(this.bookings, 'options', response.data)
                    this.$set(this.bookings, 'loading', false)
                })
            }, 200)
        }
    }
</script>
