<template>
    <div>
        <div class="mb-5">
            <label for="subscription_code" class="form-label is-required">Subscription Code</label>
            <user-subscription-code-select-component :options="variants" name="subscription_code" id="subscription_code" :value="selectedVariantCode" v-on:addSubscriptionCodeItem="selectVariantCode($event)"></user-subscription-code-select-component>
            <span class="invalid-input"></span>
        </div>
        <div>
            <label for="subscription_year" class="form-label is-required">Subscription Year</label>
            <year-select-component :years="selectedVariantYears" id="subscription_year" name="subscription_year" :year="selectedVariantYear" v-on:addYearItem="selectVariantYear($event)"></year-select-component>
            <span class="invalid-input"></span>
        </div>
    </div>
</template>

<script>
    import YearSelectComponent from "./YearSelectComponent";
    import UserSubscriptionCodeSelectComponent from "./UserSubscriptionCodeSelectComponent";
    import GenericSelectComponent from "./GenericSelectComponent";

    export default {
        components: {
            YearSelectComponent,
            UserSubscriptionCodeSelectComponent,
            GenericSelectComponent,
        },

        props: {
            variant: {
                type: Number|String
            },
            year: {
                type: Number
            },
            variants: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                selectedVariantCode: this.variant,
                selectedVariantYear: this.year,
            }
        },

        methods: {
            selectVariantCode: function(code) {
                this.selectedVariantCode = code
                this.selectedVariantYear = null
            },
            selectVariantYear: function(year) {
                this.selectedVariantYear = year
            }
        },

        computed: {
            selectedVariantYears: function() {
                for (let i = 0; i < this.variants.length; i++) {
                    if (this.variants[i].code === this.selectedVariantCode) {
                        let years = []
                        for (let j = 0; j < this.variants[i].prices.length; j++) {
                            years.push(this.variants[i].prices[j].year);
                        }
                        return years
                    }
                }

                return null
            }
        }
    };
</script>
