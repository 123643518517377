<template>
    <div class="sessions">
        <Draggable :list="sessions" group="sessions" filter=".prevent" :prevent-on-filter="false" @end="handleEnd">
            <Session :talks="talks" :talksLoading="talksLoading" :data="session" :types="types" :event="event" :sessions="sessions" v-for="session in iterableSessions" :key="session.ulid" @sessionChange="(key, value) => handleSessionChange(session.ulid, key, value)" @slotChange="(slot, key, value) => handleSlotChange(session.ulid, slot, key, value)" @addSlot="() => addSlot(session.ulid)" @sessionRemove="() => handleRemoveSession(session.ulid)" @slotRemove="(slot) => handleRemoveSlot(session.ulid, slot)"></Session>
        </Draggable>

        <div class="border rounded mb-4 p-4 text-center text-gray-500" v-if="iterableSessions.length === 0">No Sessions</div>

        <div class="flex">
            <button class="button is-primary" @click="addSession"><i class="fa fa-plus mr-2"></i> Add Session</button>
            <button class="button is-primary ml-auto" @click="saveChanges" :disabled="saving"><i class="fas mr-2" :class="[ saving ? 'fa-spinner fa-spin' : 'fa-save']"></i> {{ saving ? 'Saving Changes...' : 'Save Changes' }}</button>
        </div>

        <DeleteModal v-if="delete_slot !== null || delete_session !== null" :type="(delete_slot === null && delete_session !== null) ? 'session' : 'slot'"  @confirm="confirmDelete" @cancel="cancelDelete"></DeleteModal>
    </div>
</template>

<script>
    import {debounce} from 'lodash'

    import Draggable from 'vuedraggable'
    import { ulid } from 'ulid'
    import { Duration, DateTime } from 'luxon'

    import Session from './Session'
    import DeleteModal from './DeleteModal'

    export default {
        components: {
            Draggable,
            Session,
            DeleteModal
        },

        props: {
            event: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            data: {
                type: Array,
                default: () => {
                    return {}
                }
            },
            types: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        data() {
            return {
                talks: [],
                talksLoading: false,

                delete_session: null,
                delete_slot: null,

                saving: false,

                sessions: this.parseSessions(this.data)
            }
        },

        created() {
            this.fetchTalks()
        },

        methods: {
            fetchTalks: debounce(function () {
                this.talksLoading = true
                axios.get('/api/events/' + this.event.id + '/talks/select').then(response => {
                    this.talks = response.data
                    this.talksLoading = false
                })
            }, 200),

            parseSessions(data) {
                let sessions = []

                for (let i = 0; i < data.length; i++) {
                    let session = data[i]

                    session.ulid = ulid()
                    session.toggled = true
                    session.created = false
                    session.deleted = false

                    if (session.date !== null) {
                        let tmpDate = session.date
                        tmpDate = tmpDate.slice(0, -8)
                        session.date = tmpDate.replace('T', ' ')
                    }

                    session.date = session.date !== null ? DateTime.fromFormat(session.date, 'yyyy-MM-dd HH:mm:ss') : null

                    for (let j = 0; j < session.slots.length; j++) {
                        session.slots[j].ulid = ulid()
                        session.slots[j].toggled = true
                        session.slots[j].created = false
                        session.slots[j].deleted = false

                        session.slots[j].time = session.slots[j].time !== null ? DateTime.fromFormat(session.slots[j].time, 'HH:mm:ss') : null
                        session.slots[j].duration = session.slots[j].duration !== null ? Duration.fromObject({
                            minutes: session.slots[j].duration
                        }) : null
                    }

                    sessions.push(session)
                }

                return sessions
            },

            handleSessionChange(session, key, value) {
                session = this.sessions.findIndex(element => element.ulid == session)
                this.$set(this.sessions, session, Object.assign(this.sessions[session], {
                    [key]: value
                }))
            },

            handleSlotChange(session, slot, key, value) {
                session = this.sessions.findIndex(element => element.ulid == session)
                slot = this.sessions[session].slots.findIndex(element => element.ulid == slot)
                this.$set(this.sessions[session].slots, slot, Object.assign(this.sessions[session].slots[slot], {
                    [key]: value
                }))
            },

            addSession() {
               this.sessions.push({
                    ulid: ulid(),
                    toggled: true,
                    created: true,
                    deleted: false,

                    id: null,
                    title: 'Untitled Session',
                    subtitle: null,
                    date: null,

                    slots: [],

                    linked_session: null
                })
            },

            addSlot(session) {
                session = this.sessions.findIndex(element => element.ulid == session)
                this.$set(this.sessions[session], 'slots', [...this.sessions[session].slots, {
                    ulid: ulid(),
                    toggled: true,
                    created: true,
                    deleted: false,

                    id: null,
                    title: '',
                    type: null,
                    time: null,
                    duration: null,
                    cpd: false
                }])
            },

            handleEnd(event) {
                event.item.style.transform = 'none'
            },

            handleRemoveSession(session) {
                this.delete_session = session
            },
            handleRemoveSlot(session, slot) {
                this.delete_session = session
                this.delete_slot = slot
            },

            confirmDelete() {
                if (this.delete_session !== null && this.delete_slot !== null) {
                    let session = this.sessions.findIndex(element => element.ulid == this.delete_session)
                    let slot = this.sessions[session].slots.findIndex(element => element.ulid == this.delete_slot)
                    this.$set(this.sessions[session].slots[slot], 'deleted', true)
                } else if (this.delete_session !== null && this.delete_slot === null) {
                    let session = this.sessions.findIndex(element => element.ulid == this.delete_session)
                    this.$set(this.sessions[session], 'deleted', true)
                    for (let i = 0; i < this.sessions[session].slots.length; i++) {
                        this.$set(this.sessions[session].slots[i], 'deleted', true)
                    }
                }

                this.delete_session = null
                this.delete_slot = null
            },
            cancelDelete() {
                this.delete_session = null
                this.delete_slot = null
            },

            saveChanges() {
                this.saving = true;

                // for (let i = 0; i < this.sessions.length; i++) {
                //     // Resolve timestamp format between Vue and Laravel
                //     let tmpDate = this.sessions[i].date.toFormat('yyyy-MM-dd HH:mm:ss')
                //     // console.log(tmpDate)
                //     // tmpDate = tmpDate.slice(0, -6)
                //     // tmpDate += '000Z'
                //     // this.sessions[i].date = tmpDate
                //     this.$set(this.sessions[i], 'date', tmpDate)
                // }
                // console.log(this.sessions)

                axios.post('/api/events/' + this.event.id + '/sessions/update', {
                    sessions: this.sessions,
                }).then((response) => {
                    if (response.data.success === true) {
                        this.$set(this, 'sessions', this.parseSessions(response.data.data.sessions))
                    }

                    this.saving = false
                })
            }
        },

        computed: {
            iterableSessions() {
                return this.sessions.filter((session) => {
                    return session.deleted == false
                })
            }
        }
    }
</script>
