<template>
    <div class="relative">
        <div class="bg-smoke-light absolute rounded inset-0 z-50" v-if="loading"></div>

        <table class="border rounded w-full mb-4">
            <tbody>
                <tr v-if="speakers.length === 0">
                    <td class="p-2 text-center text-gray-500">No Speakers</td>
                </tr>
                <tr v-for="speaker in speakers" :key="speaker.id">
                    <td class="border-t p-2 px-4">{{ speaker.fullname }}</td>
                    <td class="border-t p-4" width="1">
                        <button class="button is-danger" @click.prevent="() => handleRemove(speaker.id)"><i class="fa fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="flex">
            <div class="flex-1">
                <GenericSelectComponent :options="options" :value="selected" placeholder="Search Speakers..." keyColumn="id" valueColumn="fullname" :loading="loading" @change="(value) => selected = value"></GenericSelectComponent>
            </div>
            <button class="button is-primary flex-0 ml-4" @click.prevent="handleSelect"><i class="fa fa-plus mr-2"></i> Add Speaker</button>
        </div>

        <input type="hidden" name="speakers[]" :value="speaker.id" v-for="speaker in speakers" :key="speaker.id">
    </div>
</template>

<script>
    import {debounce} from 'lodash'

    import GenericSelectComponent from './GenericSelectComponent'

    export default {
        components: {
            GenericSelectComponent
        },

        props: {
            event: {
                type: Object,
                required: true
            },
            data: {
                type: Array,
                default: () => {
                    return []
                }
            },
        },

        created() {
            this.fetch()
        },

        data() {
            return {
                speakers: this.data,
                loading: false,
                selected: null,
                options: this.data
            }
        },

        methods: {
            fetch: debounce(function () {
                this.loading = true

                axios.get('/api/events/' + this.event.id + '/speakers/select').then(response => {
                    this.options = response.data

                    this.loading = false
                })
            }, 200),

            handleRemove(id) {
                let index = this.speakers.findIndex(element => element.id == id)

                if (index > -1) {
                    this.speakers.splice(index, 1)
                }
            },
            handleSelect() {
                if (this.speakers.findIndex(element => element.id == this.selected) === -1) {
                    let index = this.options.findIndex(element => element.id == this.selected)
                    this.speakers.push(this.options[index])
                }

                this.selected = null
            }
        },
    }
</script>
