<template>
    <div :class="[ isActive ? 'flex flex-wrap lg:flex-no-wrap -mx-2' : 'flex flex-wrap lg:flex-no-wrap']">
        <div :class="[ isActive ? 'w-full lg:w-1/2 px-2' : 'w-full']">
            <label for="paymentable_type" class="form-label is-required">Type</label>
            <div class="inline-block relative w-full">
                <select class="form-control" name="paymentable_type" id="paymentable_type" @change="onTypeChange($event)">
                    <option v-for="(item, key) in typeResults" v-bind:key="key" :value="item.name" v-bind:selected="typeValue == item.name ? 'selected' : false">
                        {{ item.value }}
                    </option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg class="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                    </svg>
                </div>
            </div>
        </div>
        <div :class="[ isActive ? 'w-full lg:w-1/2 px-2' : 'hidden']">
            <label for="paymentable_id" class="form-label is-required">{{ getTitle }}</label>
            <div v-click-outside="hideItemResults">
                <div class="inline-block relative w-full">
                    <input type="hidden" name="paymentable_id" v-model="itemValue">
                    <div class="form-control" :class="[ showItemResults ? 'rounded-br-none rounded-bl-none' : '']" @click="toggleItemShow">{{ itemValue ? itemValue : (itemResults.length ? 'Select a ' + getTitle : 'No Results')}}</div>
                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg class="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                        </svg>
                    </div>
                </div>
                <transition name="slide">
                    <div class="border-l border-b border-r rounded-b" v-show="showItemResults">
                        <div class="py-2 flex items-center cursor-pointer hover:bg-gray-100"
                            v-for="(option, key) in itemResults" v-bind:key="key"
                            :class="[ key === itemResults.length - 1 ? 'border-b-none' : 'border-b', itemValue == option.id ? 'bg-gray-300 hover:bg-gray-300' : '' ]"
                            @click="onItemClick(option)">
                            <div class="flex items-center px-5">
                                <div class="pr-5 text-lg text-center w-16 inline-block">{{ option.id }}</div>
                                <div class="f-bold">
                                    <div>
                                        <span class="text-gray-500 w-10 inline-block">Year</span>
                                        <span>{{ option.year }}</span>
                                    </div>
                                    <div>
                                        <span class="text-gray-500 w-10 inline-block">Type</span>
                                        <span>{{ option.variant }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import Error from '../../Error';
    import {debounce} from 'lodash';

    export default {
        props: {
            types: {
                type: Array,
                required: true
            },
            type: {
                type: String
            },
            value: {
                type: String
            },
            booking: {
                type: Number,
                required: true
            }
        },

        created() {
            this.fetch();
        },

        data() {
            return {
                typeValue: this.type,
                itemValue: this.value,

                data: {},
                isLoading: true,
                showItemResults: false,

                error: new Error()
            }
        },

        methods: {
            hideItemResults() {
                this.showItemResults = false;
            },

            fetch: debounce(function () {
                if (this.isActive) {
                    this.isLoading = true;

                    axios.get(this.getUrl).then(response => {
                        this.data = response.data;

                        this.isLoading = false;
                    })
                }
            }, 200),

            onTypeChange(event) {
                this.typeValue = event.target.value;
                this.itemValue = '';

                this.fetch();
            },

            toggleItemShow() {
                this.showItemResults = !this.showItemResults;
            },

            onItemClick(option) {
                this.showItemResults = false;
                this.itemValue = option.id;
            }
        },

        computed: {
            isActive() {
                return this.typeValue != ''
            },
            getTitle() {
                return this.typeValue.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
            },
            getUrl() {
                switch (this.typeValue) {
                    case 'subscription':
                        return '/api/selects/user/' + this.user + '/subscriptions';
                        break;
                }

                return null;
            },
            typeResults() {
                let types = [
                    {
                        name: "",
                        value: "None"
                    }
                ];

                this.types.forEach(item => {
                    types.push({
                        name: item,
                        value: item.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
                    })
                });

                return types;
            },
            itemResults() {
                return this.data;
            }
        }
    }
</script>
