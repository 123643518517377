<template>
    <div>
        <input type="text" :name="name" :id="id" class="form-control" :value="reg" :placeholder="placeholder" @input="onInput" @keydown="onKeyDown">
        <button v-if="showLookup" class="button is-primary inline-block mt-2" @click.prevent="lookup"><i class="fas fa-external-link-square-alt mr-3"></i> Lookup</button>
    </div>
</template>

<script>
    String.prototype.isEmpty = function() {
        return (this.length === 0 || !this.trim())
    }

    export default {
        props: {
            type: {
                type: String,
                default: 'gdc'
            },
            name: {
                type: String,
                required: true
            },
            id: {
                type: String,
                required: true
            },
            value: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String
            }
        },

        data() {
            return {
                reg: this.value
            }
        },

        methods: {
            onInput(e) {
                this.reg = e.target.value

                this.$emit('input', this.reg)
            },
            onInput(e) {
                this.reg = e.target.value

                this.$emit('input', this.reg)
            },
            onKeyDown(e) {
                this.$emit('keydown', this.reg)
            },
            lookup() {
                switch (this.type) {
                    case 'gdc':
                    case 'bsdht':
                        window.open('https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=' + this.reg, '_blank')
                        return true
                }

                return false
            }
        },

        computed: {
            showLookup() {
                if (this.type == 'gdc' || this.type == 'bsdht') {
                    return !this.reg.isEmpty()
                }

                return false
            }
        }
    }
</script>
