<template>
    <div>
        <div>
            <div>
                <data-table-component :url="`/api/users/${user.member_id}/bookings?member_id=${user.member_id}`" :has-toolbar="false" :columns="columns">
                    <template slot-scope="{ row }" slot="actions">
                        <td class="text-right">
                            <a :href="`/admin/events/bookings/${row.id}/edit`" class="button is-small is-tertiary inline-block">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </template>
                </data-table-component>
            </div>
        </div>
    </div>
</template>

<script>
    import DataTableComponent from '../DataTableComponent'
    import moment from 'moment';

    export default {
        components: {
            DataTableComponent
        },

        props: {
            user: {
                type: Object
            },
        },

        data() {
            return {
                columns: [
                    {
                        key: 'id',
                        label: 'Booking ID',
                    },
                    {
                        key: 'event_title',
                        label: 'Title',
                    },
                    {
                        key: 'booking_fullname',
                        label: 'Member',
                    },
                    {
                        key: 'booking_created',
                        label: 'Created at',
                        html: (key, value, row) => {
                            return moment(row.booking_created).format('DD-MM-YYYY');
                        }
                    }
                ]
            }
        }
    }
</script>
