<template>
    <div v-click-outside="hide">
        <input type="hidden" :name="name" v-if="bookingData.user_id !== null" :value="bookingData.user_id">
        <input type="hidden" :name="name" v-else :value="resultId">
        <input type="text" class="form-control" v-bind:class="[ showResults ? 'rounded-br-none rounded-bl-none' : '']"
               placeholder="Start by typing a name, email or membership ID"
               autocomplete="off"
               v-model="search"
               @focus="onFocus"
               @keyup="onKeyUp">
        <transition name="slide">
            <div class="border-l border-b border-r rounded-b" v-show="showResults">
                <div class="py-2 flex items-center cursor-pointer hover:bg-gray-100"
                     v-for="(option, key) in filteredOptions"
                     :class="[ key === filteredOptions.length - 1 ? 'border-b-none' : 'border-b' ]"
                     @click="onClick(option)"
                     :key="key">
                    <div class="px-10 w-40 text-sm">{{ option.member_id }}</div>
                    <div class="flex-1 text-sm">
                        <span>{{ option.name }}</span>
                        <span class="block text-xs">{{ option.email }}</span>
                    </div>
                </div>
                <div class="py-2 flex items-center" v-if="filteredOptions.length == 0">
                    <div class="w-full text-center text-gray-500 block ext-sm">No Results</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                required: true,
            },
            options: {
                type: Array,
                required: true,
            },
            booking: {
                type: Object,
                required: true,
            }
        },

        data() {
            return {
                search: '',
                opts: this.options,
                showResults: false,
                resultId: null,
                bookingData: this.booking,
            }
        },

        methods: {
            hide() {
                this.showResults = false;
            },

            onFocus() {
                if (this.search.length === 0) {
                    this.showResults = false;
                    return;
                }

                this.showResults = true;
            },

            onKeyUp() {
                if (this.search.length === 0) {
                    this.showResults = false;
                    return;
                }

                this.showResults = true;
            },

            onClick(option) {
                this.bookingData.user_id = option.id;
                this.search = option.name;
                this.showResults = false;
            },

            setName: function () {
                this.search = booking.fullname;
            },

            mounted: function(){
                this.$nextTick(this.setName);
        },

        computed: {
            filteredOptions() {
                return this.opts.filter(x => {
                    return x.email.toUpperCase().includes(this.search.toUpperCase()) || x.name.toUpperCase().includes(this.search.toUpperCase()) || x.member_id === parseInt(this.search);
                }).splice(0, 5);
            }
        }
        }
    };
</script>
