<template>
    <div>
        <h2 class="font-bold text-2xl" id="summary-top">Subscription Payment</h2>
        <p>Please enter your payment details below.</p>


        <div id="card-element" :class="[ isLoading ? 'hidden' : 'visible' ]"></div>

        <div v-if="showErr" id="card-error" class="flex bg-white border-2 border-red shadow-lg py-4 px-6 mt-5 mb-10 relative text-lg">
            <div class="flex-1 flex items-center">
                <p>Error: {{ errText }}</p>
            </div>
            <div class="flex-0 flex items-center ml-4">
                <a href="" class="button is-secondary p-2" @click.prevent="showErr = false">&times;</a>
            </div>
        </div>

        <div id="card-loading" v-if="isLoading">
            <div class="loading-spinner"></div>
        </div>

        <div class="ml-auto flex items-center">
            <button class="button is-tertiary" @click="$emit('subGoBack')">Back</button>
            <button class="button is-primary ml-2" @click="submitPayment">Pay now</button>
        </div>
    </div>
</template>

<script>
    import { loadScript } from "vue-plugin-load-script"

    export default {
        name: 'SubscriptionPayment',

        emits: [
            'subGoBack'
        ],

        props: {
            page: {
                type: String,
                required: true
            },
            formValues: {
                type: Object,
                required: true
            },
            user: {
                type: Object
            },
            userAddress: {
                type: Object|Array
            },
            userCountry: {
                type: Object|Array
            },
            stripeParams: {
                type: Object,
                required: true
            },
            subYear: {
                type: String
            },
            address1: {
                type: String
            },
            address2: {
                type: String
            },
            town: {
                type: String
            },
            region: {
                type: String
            },
            postcode: {
                type: String
            },
            country: {
                type: String
            },
        },

        data() {
            return {
                isLoading: true,
                showErr: false,
                errText: '',
                stripe: null,
                elements: null,
                cardElement: null,
                paymentIntent: null,
                cardToken: null,
                userIp: null
            }
        },

        mounted() {
            this.loadStripe()
        },

        methods: {
            loadStripe() {
                // Get user's IP address
                fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({ ip }) => {
                    this.userIp = ip
                });

                // Create payment intent
                axios.post('/account/payment/payment_intent', {
                    amount: this.formValues.amount,
                    payment_type: 'subscription'
                }).then((response) => {
                    if (response.status != 200 || !response.data.payment_intent) {
                        this.errText = 'Something went wrong. Please try again later.'
                        this.showErr = true
                        return
                    }

                    this.paymentIntent = response.data.payment_intent;

                    loadScript("https://js.stripe.com/v3")
                    .then(() => {
                        this.stripe = Stripe(this.stripeParams.pk)
                        this.elements = this.stripe.elements()

                        this.cardElement = this.elements.create('card', {
                            hidePostalCode: true,
                            style: {
                                base: {
                                    fontSize: '16px'
                                }
                            }
                        })

                        this.cardElement.mount('#card-element')

                        this.showErr = false
                        this.errText = ''
                        this.isLoading = false
                    })
                    .catch((err) => {
                        console.error(err)
                        this.errText = 'Something went wrong. Please try again later.'
                        this.showErr = true
                        this.isLoading = false
                    });
                })
            },
            tokenCreated (token) {
                this.cardToken = token
            },
            submitPayment() {
                this.showErr = false
                this.errText = ''
                this.isLoading = true

                this.stripe.confirmCardPayment(this.paymentIntent.client_secret, {
                    payment_method: {
                        card: this.cardElement,
                        billing_details: {
                            "address": {
                                "city": this.town,
                                "country": this.country,
                                "line1": this.address1,
                                "line2": this.address2,
                                "postal_code": this.postcode,
                                "state": this.region
                            },
                            name: this.user.full_name,
                        }
                    },
                    receipt_email: this.user.email
                })
                .then((response) => {
                    if ('error' in response) {
                        console.error(response)
                        this.errText = response.error.message
                        this.showErr = true
                        this.isLoading = false
                    } else {
                        axios.post('/account/payment/complete_payment', {
                            payment: this.formValues.reference,
                            payment_intent: this.paymentIntent,
                            status: response.paymentIntent.status,
                            user_ip: this.userIp,
                            sub_year: this.subYear ? this.subYear : false
                        }).then((response) => {
                            console.log(response)
                            if (response.status == 200) {
                                window.location.href = response.data
                            } else {
                                console.error(response)
                            }
                        })
                    }
                })
                .catch((err) => {
                    console.error(err)
                    this.errText = 'Something went wrong. Please try again later.'
                    this.showErr = true
                    this.isLoading = false
                })
            }
        },

        computed: {
            stripeTokenData() {
                return {
                    address_line1: this.userAddress.address_1,
                    address_city: this.userAddress.town,
                    address_country: this.userCountry.code,
                    address_zip: this.userAddress.postcode
                }
            }
        }
    }
</script>

<style>
    #card-element {
        padding: 10px 10px;
        border: 1px solid #e2e8f0;
        border-radius: 5px;
        margin-top: 15px;
        margin-bottom: 30px;
    }

    .loading-spinner {
        border: 5px solid #53AF31;
        border-top: 5px solid transparent;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1.2s linear infinite;
        margin-top: 15px;
        margin-bottom: 30px;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
