<template>
    <div>
        <div class="card is-primary mb-5" v-if="this.type == 'both' || this.type == 'booking'">
            <div class="mb-5">
                <h2 class="font-bold text-2xl text-gray-700">Booking Details</h2>
                <p class="text-muted">
                    <span class="text-red is-required"></span> These fields are required
                </p>
            </div>

            <BookingFormEditor
                ref="booking-form"
                :guest="guest"
                :user="user"
                :event="event"
                :values="bookingValues"
                :force-amount="forceAmount"
                :forced-amount="forcedAmount"
            ></BookingFormEditor>

            <div class="flex justify-end" v-if="this.type == 'booking'">
                <button type="submit" class="button is-primary" @click.prevent="handleSubmit">Update</button>
            </div>
        </div>

        <div class="card is-primary" v-if="this.type == 'both' || this.type == 'evaluation'">
            <div class="mb-5">
                <h2 class="font-bold text-2xl text-gray-700">Evaluation Details</h2>
                <p class="text-muted">
                    <span class="text-red is-required"></span> These fields are required
                </p>
            </div>

            <EvaluationFormEditor
                ref="evaluation-form"
                :user="user"
                :event="event"
                :values="evaluationValues"
                :bookingValues="bookingValues"
                :force-cpd="forceCpd"
                :forced-cpd="forcedCpd"
            ></EvaluationFormEditor>

            <label class="flex items-center">
                <Toggle name="completed" :value="complete" @change="(value) => complete = value"></Toggle>
                <div class="ml-3 mb-0 form-label">Mark as completed</div>
            </label>

            <div class="flex justify-end">
                <button type="submit" class="button is-primary" @click.prevent="handleSubmit">Update</button>
            </div>
        </div>
    </div>
</template>

<script>
    import BookingFormEditor from '../builder/Render/BookingFormEditor'
    import EvaluationFormEditor from '../builder/Render/Evaluation/EvaluationFormEditor'
    import Toggle from '../GenericToggleComponent'

    export default {
        components: {
            BookingFormEditor,
            EvaluationFormEditor,
            Toggle
        },

        props: {
            type: {
                type: String,
                default: 'both'
            },

            user: {
                type: Number|String,
                required: false
            },
            event: {
                type: Number|String,
                required: false
            },
            guest: {
                type: Boolean,
                default: false
            },

            completed: {
                type: Boolean,
                default: false,
            },

            forceAmount: {
                type: Boolean,
                default: false
            },
            forcedAmount: {
                type: String,
                default: ''
            },

            forceCpd: {
                type: Boolean,
                default: false
            },
            forcedCpd: {
                type: String,
                default: ''
            },

            bookingValues: {
                type: Object,
                required: false,
                default: () => {
                    return {}
                }
            },
            evaluationValues: {
                type: Object,
                required: false,
                default: () => {
                    return {}
                }
            },
        },

        data() {
            return {
                complete: this.completed
            }
        },

        methods: {
            handleSubmit() {
                if ((this.type == 'both' || this.type == 'booking') && !this.$refs['booking-form'].validateForm()) {
                    return false
                }

                if ((this.type == 'both' || this.type == 'evaluation') && !this.$refs['evaluation-form'].validateForm()) {
                    return false
                }

                if (this.$el.parentElement.parentElement.localName == 'form') {
                    return this.$el.parentElement.parentElement.submit()
                } else if (this.$el.parentElement.localName == 'form') {
                    return this.$el.parentElement.submit()
                }
            }
        }
    }
</script>
