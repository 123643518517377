<template>
    <div class="border border-gray-300 p-4 mt-4" v-if="visible || this.admin">
        <div v-if="this.admin && !visible" class="bg-red font-bold text-white px-4 py-2 -mt-4 -mx-4 mb-4">
            Only visible to administrators
        </div>

        <div class="border border-red px-4 py-3 mb-4" v-if="notice" v-html="notice"></div>

        <h1 class="text-xl font-normal text-black leading-none m-0 block" v-if="this.object.options.caption" :class="[ 'form-label', this.object.options.required ? 'is-required' : null ]">{{ this.object.options.caption }}</h1>
        <p v-if="this.object.options.description" v-html="this.object.options.description"></p>

        <div class="mt-4">
            <input v-if="!this.object.options.multiline" type="text" class="form-control" :value="getValue()" :placeholder="this.object.options.placeholder" @input="(event) => handleChange(event.target.value)" :disabled="disabled">
            <textarea :ref="`textarea-${this.object.options.name}`" v-if="this.object.options.multiline" class="form-control resize-none" :value="getValue()" :placeholder="this.object.options.placeholder" @input="(event) => handleChange(event.target.value)" :disabled="disabled"></textarea>
            <span class="invalid-input" v-if="getError()">{{ getError() }}</span>
        </div>
    </div>
</template>

<script>
    import autosize from 'autosize'

    import { default as field } from '../Mixins/Field'

    export default {
        mixins: [
            field
        ],

        mounted() {
            if (this.object.options.multiline) {
                autosize(this.$refs['textarea-' + this.object.options.name])
            }
        },

        updated() {
            if (this.object.options.multiline) {
                autosize(this.$refs['textarea-' + this.object.options.name])
            }
        },
    }
</script>
