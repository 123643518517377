<template>
    <div>
        <input type="hidden" :name="name" :value="cpd" :disabled="disabled">
        <DatetimePopup v-if="open" type="time" :datetime="datetime" title="Select the CPD amount" @confirm="handleConfirm" @cancel="handleCancel"></DatetimePopup>
        <div class="relative w-full">
            <button class="w-full justify-center text-left" :class="{ 'bg-white border border-gray-400 px-4 py-2 rounded pr-8': cpd, 'button is-primary px-4 py-2': !cpd }" style="height:42px" @click.prevent="handleTrigger" v-html="cpd != null ? cpd : 'Select'"></button>
            <button class="absolute inset-y-0 right-0 py-1 px-3" @click.prevent="handleClear" v-if="datetime != null">&times;</button>
        </div>
    </div>
</template>

<script>
    import { Duration, DateTime } from 'luxon'

    import DatetimePopup from './datetime/DatetimePopup'

    export default {
        components: {
            DatetimePopup
        },

        props: {
            name: {
                type: String,
                required: false
            },
            value: {
                type: Number|String,
                required: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },

        data() {
            let datetime = null
            let duration = null

            if (this.value !== null && this.value !== '') {
                duration = Duration.fromObject({
                    minutes: this.value
                })

                let format = duration.toFormat("h m").split(' ')

                datetime = DateTime.fromObject({
                    hour: parseInt(format[0]),
                    minute: parseInt(format[1])
                })
            }

            return {
                open: false,

                backup: null,

                duration: duration,
                datetime: datetime
            }
        },

        methods: {
            handleTrigger() {
                this.backup = this.duration

                if (this.datetime == null) {
                    this.datetime = DateTime.fromObject({
                        hour: 0,
                        minute: 0
                    })
                }

                this.open = true
            },

            handleConfirm(value) {
                this.duration = Duration.fromObject({
                    hours: value.hour,
                    minutes: value.minute
                })

                this.datetime = DateTime.fromObject({
                    hour: this.duration.hours,
                    minute: this.duration.minutes
                })

                this.open = false
            },

            handleCancel() {
                this.duration = this.backup
                this.backup = null
                if (this.duration != null) {
                    this.datetime = DateTime.fromObject({
                        hour: this.duration.hours,
                        minute: this.duration.minutes
                    })
                } else {
                    this.datetime = null
                }
                this.open = false
            },

            handleClear() {
                if (!this.open) {
                    this.datetime = null
                    this.duration = null
                }
            },
        },

        computed: {
            cpd() {
                return this.duration != null ? this.duration.toFormat('m') : null
            }
        },

        watch: {
            value: {
                handler: function(n, o) {
                    if (n != o) {
                        let datetime = null
                        let duration = null

                        if (n !== null && n !== '') {
                            duration = Duration.fromObject({
                                minutes: n
                            })

                            let format = duration.toFormat("h m").split(' ')

                            datetime = DateTime.fromObject({
                                hour: parseInt(format[0]),
                                minute: parseInt(format[1])
                            })
                        }

                        this.duration = duration
                        this.datetime = datetime
                    }
                }
            }
        }
    }
</script>
