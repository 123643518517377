<style lang="css" scoped>
    .slot > td > .edit {
        display: none;
    }
    .slot > td:hover > .edit {
        display: block;
    }
</style>

<template>
    <tbody>
        <tr class="slot">
            <td width="1" class="border-r">
                <span v-if="!slot.toggled" v-html="time ? time : '&mdash;'"></span>
                <div class="flex items-center prevent" v-if="slot.toggled">
                    <DatetimePopup v-if="timeOpen" type="time" :datetime="timeLocal" title="Select the time" @confirm="changeTime" @cancel="cancelTime"></DatetimePopup>
                    <div class="relative w-full">
                        <button class="w-full justify-center text-left" :class="{ 'bg-white border border-gray-400 px-3 py-1 rounded pr-8': slot.time, 'button is-primary px-3 py-1': !slot.time }" @click="triggerTime" v-html="time ? time : 'Select'"></button>
                        <button class="absolute inset-y-0 right-0 py-1 px-3" @click="clearTime" v-if="slot.time">&times;</button>
                    </div>
                </div>
            </td>
            <td>
                <span class="capitalize" v-if="!slot.toggled" v-html="slot.type ? slot.type : '&mdash;'"></span>
                <GenericSelectComponent class="prevent" :compact="true" v-if="slot.toggled" :options="types" :value="slot.type" @change="(value) => handleChange('type', value)"></GenericSelectComponent>
            </td>
            <td>
                <span v-html="slot.duration ? slot.duration + 'm' : '&mdash;'"></span>
            </td>
            <td>
                <span v-if="!slot.toggled" v-html="slot.cpd ? 'Yes' : 'No'"></span>
                <div class="prevent" v-if="slot.toggled">
                    <GenericToggleComponent :value="slot.type == 'parallel' || slot.type == 'talk' ? slot.cpd : false" @change="(value) => handleChange('cpd', value)" :disabled="slot.type != 'parallel' && slot.type != 'talk'"></GenericToggleComponent>
                </div>
            </td>
            <td width="1">
                <div class="flex justify-end">
                    <button class="button is-danger mr-2" v-if="slot.toggled" @click="handleRemove"><i class="fa fa-trash"></i></button>
                    <button class="button is-primary" @click="() => handleChange('toggled', !slot.toggled)"><i class="fa" :class="slot.toggled ? 'fa-angle-double-up' : 'fa-angle-double-down'"></i></button>
                </div>
            </td>
        </tr>
        <tr class="content" :class="{ 'hidden': !slot.toggled }" v-if="slot.type !== null && slot.type !== ''">
            <td class="border-r"></td>
            <td class="prevent" colspan="100%">
                <div class="p-4">
                    <AdminSlot v-if="slot.type == 'admin' || slot.type == 'information'" :data="slot" @change="(name, value) => handleChange(name, value)"></AdminSlot>
                    <ParallelSlot v-if="slot.type == 'parallel'" :talks="talks" :talksLoading="talksLoading" :data="slot" :event="event" @change="(name, value) => handleChange(name, value)"></ParallelSlot>
                    <BreakSlot v-if="slot.type == 'break'" :data="slot" @change="(name, value) => handleChange(name, value)"></BreakSlot>
                    <TalkSlot v-if="slot.type == 'talk'" :talks="talks" :talksLoading="talksLoading" :data="slot" :event="event" @change="(name, value) => handleChange(name, value)"></TalkSlot>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
    import { Duration, DateTime } from 'luxon'

    import GenericSelectComponent from '../GenericSelectComponent'
    import GenericToggleComponent from '../GenericToggleComponent'
    import DatetimePopup from '../datetime/DatetimePopup'

    import AdminSlot from './Slots/AdminSlot'
    import ParallelSlot from './Slots/ParallelSlot'
    import BreakSlot from './Slots/BreakSlot'
    import TalkSlot from './Slots/TalkSlot'

    export default {
        components: {
            GenericSelectComponent,
            GenericToggleComponent,
            DatetimePopup,

            AdminSlot,
            ParallelSlot,
            BreakSlot,
            TalkSlot,
        },

        props: {
            event: {
                type: Object,
                required: true
            },
            session: {
                type: Object,
                required: true
            },
            data: {
                type: Object,
                required: true
            },
            types: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            talks: {
                type: Array,
                default: () => {
                    return []
                }
            },
            talksLoading: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                slot: this.data,

                deleted: this.data.deleted,

                timeOpen: false,
                timeTemp: null,
                timeLocal: this.data.time,
            }
        },

        methods: {
            triggerTime() {
                this.timeTemp = this.timeLocal
                if (!this.timeLocal) {
                    this.timeLocal = DateTime.fromObject({
                        hour: 12,
                        minute: 30
                    })
                }
                this.timeOpen = true
            },
            changeTime(value) {
                this.timeLocal = value

                this.$emit('change', 'time', this.timeLocal)

                this.timeOpen = false
                this.timeTemp = null
            },
            cancelTime() {
                this.timeLocal = this.timeTemp

                this.timeOpen = false
                this.timeTemp = null
            },
            clearTime() {
                if (!this.timeOpen) {
                    this.timeLocal = null
                    this.$emit('change', 'time', null)
                }
            },

            handleChange(key, value) {
                this.$emit('change', key, value)
            },
            handleRemove() {
                this.$emit('remove')
            }
        },

        computed: {
            time() {
                return this.slot.time ? this.slot.time.toLocaleString(DateTime.TIME_24_SIMPLE) : null
            }
        }
    }
</script>
