<style>
</style>

<template>
    <Draggable :list="layout" ghost-class="ghost" filter=".form-control" :prevent-on-filter="false" :group="getGroup()" :component-data="getComponentData()" :move="onMove" @start="isDragging = true" @end="isDragging = false">
        <div class="list" v-for="element in layout" :key="element.internal">
            <GroupField v-if="map[element.internal].type == 'group'" :type="type" :objects="objects" :object="map[element.internal]" :event="event" @change="handleChange" @duplicate="handleDuplicate" @delete="handleDelete" @error="handleError"></GroupField>
            <TextField v-if="map[element.internal].type == 'text'" :type="type" :objects="objects" :object="map[element.internal]" :event="event" @change="handleChange" @duplicate="handleDuplicate" @delete="handleDelete" @error="handleError"></TextField>
            <InputField v-if="map[element.internal].type == 'input'" :type="type" :objects="objects" :object="map[element.internal]" :event="event" @change="handleChange" @duplicate="handleDuplicate" @delete="handleDelete" @error="handleError"></InputField>
            <ToggleField v-if="map[element.internal].type == 'toggle'" :type="type" :objects="objects" :object="map[element.internal]" :event="event" @change="handleChange" @duplicate="handleDuplicate" @delete="handleDelete" @error="handleError"></ToggleField>
            <RatingField v-if="map[element.internal].type == 'rating'" :type="type" :objects="objects" :object="map[element.internal]" :event="event" @change="handleChange" @duplicate="handleDuplicate" @delete="handleDelete" @error="handleError"></RatingField>
            <ListField v-if="map[element.internal].type == 'list'" :type="type" :objects="objects" :object="map[element.internal]" :event="event" @change="handleChange" @duplicate="handleDuplicate" @delete="handleDelete" @error="handleError"></ListField>

            <Nested v-if="element.children" :type="type" :reference="reference" :layout="element.children" :objects="objects" :event="event" @change="handleChange" @duplicate="handleDuplicate" @delete="handleDelete" @error="handleError"></Nested>
        </div>
    </Draggable>
</template>

<script>
    import Draggable from 'vuedraggable'

    import GroupField from './Fields/Group'
    import TextField from './Fields/Text'
    import InputField from './Fields/Input'
    import ToggleField from './Fields/Toggle'
    import RatingField from './Fields/Rating'
    import ListField from './Fields/List'

    export default {
        name: 'Nested',

        components: {
            Draggable,

            GroupField,
            TextField,
            InputField,
            ToggleField,
            RatingField,
            ListField
        },

        props: {
            type: {
                type: String,
                required: true
            },
            reference: {
                type: String,
                required: true
            },
            layout: {
                type: Array,
                default: () => {
                    return []
                }
            },
            objects: {
                type: Array,
                default: () => {
                    return []
                }
            },
            event: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        data() {
            return {
                isDragging: false,
                delayedDragging: false
            }
        },

        methods: {
            handleChange(object, name, value) {
                this.$emit('change', object, name, value)
            },
            handleDuplicate(object) {
                this.$emit('duplicate', object)
            },
            handleDelete(object) {
                this.$emit('delete', object)
            },
            handleError(field, element, valid) {
                this.$emit('error', field, element, valid)
            },
            getGroup() {
                return {
                    name: this.reference
                }
            },
            getComponentData() {
                return {
                    attrs: {
                        class: 'group elements'
                    }
                }
            },
            onMove(event) {
                if (event.dragged.classList.contains('field-toggle') || (typeof event.dragged.firstChild.classList !== 'undefined' && event.dragged.firstChild.classList.contains('field-toggle'))) {
                    if (event.to.previousElementSibling !== null && !event.to.previousElementSibling.classList.contains('field-group') && !event.to.previousElementSibling.classList.contains('field-list')) {
                        return false;
                    }
                } else if (event.dragged.classList.contains('field-list') || (typeof event.dragged.firstChild.classList !== 'undefined' && event.dragged.firstChild.classList.contains('field-list'))) {
                    if (event.to.previousElementSibling !== null && !event.to.previousElementSibling.classList.contains('field-group')) {
                        return false
                    }
                } else {
                    if (event.to.previousElementSibling !== null && !event.to.previousElementSibling.classList.contains('field-group') && !event.to.previousElementSibling.classList.contains('field-list')) {
                        return false
                    }
                }

                return (
                    (!event.relatedContext.element || !this.map[event.relatedContext.element.internal].options.locked) && !this.map[event.draggedContext.element.internal].options.locked
                )
            }
        },

        computed: {
            map() {
                let map = []

                for (let i = 0, len = this.objects.length; i < len; i++) {
                    map[this.objects[i].internal] = this.objects[i]
                }

                return map
            }
        },

        watch: {
            isDragging(value) {
                if (value) {
                    this.delayedDragging = true

                    return
                }

                this.$nextTick(() => {
                    this.delayedDragging = false
                })
            }
        }
    }
</script>
