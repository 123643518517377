<script>
    import Error from "../Error";

    import DateTimeComponent from "./DateTimeComponent";
    import RegistrationLookupComponent from "./RegistrationLookupComponent";
    import Captcha from "./users/Captcha";

    export default {
        components: {
            DateTimeComponent,
            RegistrationLookupComponent,
            Captcha,
        },

        props: {
            url: {
                type: String,
            },

            step: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                properties: {},

                isLoading: false,
                error: new Error()
            }
        },

        methods: {
            nextStep() {
                if (this.url) {
                    this.isLoading = true;
                    axios.post(this.url, this.properties).then(response => {
                        this.setPayload(this.step, this.properties);
                        this.$emit('next-step');
                        this.isLoading = false;
                    }).catch(errors => {
                        this.error.errors = errors.response.data.errors;
                        this.isLoading = false;
                    });
                } else {
                    this.setPayload(this.step, this.properties);
                    this.$emit('next-step');
                }
            },

            inPropertyArray: function(item, property) {
                if (this.properties[property] == null) {
                    this.$set(this.properties, property, []);
                }

                let length = this.properties[property].length;

                for (let i = 0; i < length; i++) {
                    if (this.properties[property][i] == item) {
                        return true;
                    }
                }

                return false;
            },

            togglePropertyArray: function(item, property) {
                if (this.properties[property] == null) {
                    this.$set(this.properties, property, []);
                }

                if (this.inPropertyArray(item, property)) {
                    this.$delete(this.properties[property], this.properties[property].indexOf(item));

                    return true;
                }

                this.$set(this.properties, property, [...this.properties[property], item]);

                return true;
            },

            prevStep() {
                this.$emit('prev-step');
            },

            setPayload(step, properties) {
                this.$emit('set-payload', step, properties);
            },

            submit() {
                if (this.properties.captcha) {
                    if (typeof this.error.errors['captcha'] !== 'undefined') {
                        this.$delete(this.error.errors, 'captcha');
                    }

                    if (this.url) {
                        this.isLoading = true;
                        axios.post(this.url, this.properties).then(response => {
                            this.setPayload(this.step, this.properties);
                            this.$emit('submit');
                            this.isLoading = false;
                        }).catch(errors => {
                            this.error.errors = errors.response.data.errors;
                            this.isLoading = false;
                        });
                    } else {
                        this.setPayload(this.step, this.properties);
                        this.$emit('submit');
                    }
                } else {
                    this.$set(this.error.errors, 'captcha', [
                        'Please confirm you are human to continue'
                    ]);
                }
            }
        }
    }
</script>
