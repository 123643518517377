<template>
    <div>
        <data-table-component url="/api/admin/admins/datatable" :columns="columns">
            <template slot-scope="{ row }" slot="filters">
                <slot v-bind="row"></slot>
            </template>
            <template slot-scope="{ row }" slot="actions">
                <td class="text-right">
                    <a :href="`/admin/admins/${row.id}/edit`" class="button is-small is-tertiary inline-block">
                        <i class="fas fa-edit"></i>
                    </a>
                </td>
            </template>
        </data-table-component>
    </div>
</template>

<script>
    import DataTableComponent from '../DataTableComponent';

    export default {
        components: {
            DataTableComponent
        },

        data() {
            return {
                columns: [
                    {
                        key: 'name',
                        label: 'Name'
                    },
                    {
                        key: 'email',
                        label: 'Username or Email Address'
                    }
                ]
            }
        }
    }
</script>
