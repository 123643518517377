<template>
    <div>
        <pdf
            v-for="i in numPages"
            :key="i"
            :src="path"
            :page="i"
            class="block w-full"
        ></pdf>

        <div v-if="this.loading">
            <div class="fa-2x flex text-gray-500 justify-center">
                <i class="fas fa-spinner fa-spin" />
            </div>
        </div>
    </div>
</template>

<script>
    import pdf from 'vue-pdf';

    export default {
        components: {
            pdf
        },
        props: {
            src: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                path: pdf.createLoadingTask(this.src, {
                    onProgress: status => {
                        if (status.loaded === status.total) {
                            this.loading = false;
                        }
                    }
                }),
                loading: true,
                numPages: undefined
            }
        },
        mounted() {
            this.path.promise.then(pdf => {
                this.numPages = pdf.numPages;
            });
        }
    };
</script>
