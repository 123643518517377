<template>
    <div class="relative" v-click-outside="handleOutside">
        <div class="relative">
            <input ref="query" :class="[ 'form-control outline-none p-4 pr-12', open ? 'rounded-b-none' : '' ]" type="text" v-model.lazy="query" @input="handleQuery" @focus="handleFocus" placeholder="Enter a search query...">
            <div class="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600" v-if="loading">
                <i class="fas fa-spinner fa-spin"></i>
            </div>
        </div>

        <div v-if="open" :class="[ 'absolute bg-white border border-t-0 rounded rounded-t-none overflow-auto w-full max-h-80 z-50' ]">
            <div class="p-4 text-gray-600 text-center uppercase text-sm" v-if="results.length === 0">No Results</div>
            <ul v-if="results.length !== 0">
                <li v-for="(result, key) in results" :key="key">
                    <a :href="result.url" target="_blank" class="block hover:bg-gray-100 p-4 cursor-pointer" :class="[ key != results.length - 1 ? 'border-b' : '' ]">
                        <h3 class="text-sm text-gray-600 uppercase leading-none">{{ result.type }}</h3>
                        <div class="mt-2">
                            <h4 class="text-lg font-bold leading-none">{{ result.name }}</h4>
                            <p class="leading-none" v-if="result.description !== null" v-html="result.description"></p>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash'

    export default {
        data() {
            return {
                query: '',

                results: [],

                loading: false,
                open: false
            }
        },

        mounted() {
            this.$nextTick(() => this.$refs.query.focus())
        },

        methods: {
            handleQuery(event) {
                this.query = event.target.value

                this.performSearch()
            },

            performSearch: debounce(function () {
                if (this.query.trim() != '') {
                    this.open = true
                    this.loading = true

                    axios.post('/api/search', {
                        query: this.query.trim()
                    }).then(response => {
                        this.results = response.data
                        this.loading = false
                    })
                } else {
                    this.open = false
                }
            }, 200),

            handleOutside() {
                this.open = false
            },

            handleFocus() {
                if (this.query != '') {
                    this.open = true
                }
            }
        }
    }
</script>
