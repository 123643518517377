<template>
    <div class="generic-toggle-component" :class="className">
        <input type="hidden" :name="name" :value="toggled ? 1 : 0" :disabled="disabled" />
        <div class="toggle" @click="toggle"></div>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                required: false
            },
            value: {
                type: Boolean|Number,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                toggled: this.value instanceof Number ? !!(this.value == '1' ? true : false) : !!this.value
            }
        },

        created() {
            window.EventBus.$on(this.name + '_visible', (state) => {
                if( state == false && this.toggled ) {
                    this.toggle();
                }
            });
        },

        methods: {
            toggle() {
                if (!this.disabled) {
                    const toggled = !this.toggled

                    this.toggled = toggled

                    this.$emit('input', toggled)
                    this.$emit('change', toggled)
                }
            }
        },

        computed: {
            className() {
                let { toggled, disabled } = this

                return ['generic-toggle-component', {
                    toggled, disabled
                }]
            },
            inputValue() {
                return this.toggled ? 1 : 0
            }
        },

        watch: {
            value (value) {
                this.toggled = value instanceof Number ? !!(value == '1' ? true : false) : !!value
            }
        }
    };
</script>
