<style>
</style>

<template>
    <div>
        <h4 class="font-bold text-gray-700">Member Type(s)</h4>
        <p class="text-gray-700 mb-4">Only show the field if the logged-in user matches one or more of these types.</p>

        <div class="flex items-center border rounded border-gray-400 mb-2 p-3" v-for="(item, index) in selections" :key="index">
            <div class="flex-1">
                <h4 class="font-bold text-gray-700 leading-none">{{ item.name }}</h4>
            </div>
            <button class="button is-danger text-sm ml-auto" @click.prevent="() => removeSelected(index)"><i class="fa fa-trash"></i></button>
        </div>
        <div class="mb-2">
            <Select value="" :options="selectMemberTypes" placeholder="Search Member Types..." keyColumn="name" valueColumn="name" descriptionColumn="type" @change="handleSelect"></Select>
        </div>
    </div>
</template>

<script>
    import Select from '../../../GenericSelectComponent'

    export default {
        components: {
            Select
        },

        props: {
            type: {
                type: String,
                default: 'text'
            },
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            object: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            objects: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },

        data() {
            return {
                selections: [],
                memberTypes: []
            }
        },

        created() {
            axios.get('/api/users/groups/select').then(response => {
                this.memberTypes = response.data;

                let fields = this.memberTypes

                let selections = []

                if (typeof this.options.filter_member_types !== 'undefined' && this.options.filter_member_types !== null) {
                    for (let i = 0; i < this.options.filter_member_types.length; i++) {
                        // if (fields.includes(this.options.filter_member_types[i].name)) {
                        //     selections.push(this.options.filter_member_types[i])
                        // }

                        for( let j = 0; j < fields.length; j++ ) {
                            if( fields[j].name == this.options.filter_member_types[i].name ) {
                                selections.push(this.options.filter_member_types[i])
                            }
                        }
                    }
                }

                this.selections = selections
            });
        },

        methods: {
            getMemberTypes(selections) {
                var out = []
                var excluded = []

                for( var key in this.memberTypes ) {
                    for( var k in selections ) {
                        if( selections[k].name == this.memberTypes[key].name ) {
                            excluded.push(this.memberTypes[key])
                        }
                    }
                }

                for( var key in this.memberTypes ) {
                    if( !excluded.includes( this.memberTypes[key] ) ) {
                        out.push( {
                            name: this.memberTypes[key].name
                        } )
                    }
                }

                return out
            },

            handleChange(key, value) {
                this.$emit('change', key, value)
            },

            handleSelect(value) {
                this.selections.push({
                    name: value
                })

                this.handleChange('filter_member_types', this.selections)
            },
            removeSelected(index) {
                this.selections.splice(index, 1)

                this.handleChange('filter_member_types', this.selections)
            }
        },

        computed: {
            selectMemberTypes() {
                return this.getMemberTypes(this.selections);
            }
        },

        watch: {
            // objects: {
            //     handler() {
            //         let fields = this.objects.filter(field => (field.type == 'toggle' || field.type == 'input' || field.type == 'rating')).map(field => field.options.name)

            //         let selections = []

            //         if (typeof this.options.filter_member_types !== 'undefined' && this.options.filter_member_types !== null) {
            //             for (let i = 0; i < this.options.filter_member_types.length; i++) {
            //                 if (fields.includes(this.options.filter_member_types[i].name)) {
            //                     selections.push(this.options.filter_member_types[i])
            //                 }
            //             }
            //         }

            //         if (JSON.stringify(selections.map(field => field.name)) !== JSON.stringify(this.selections.map(field => field.name))) {
            //             this.handleChange('filter_member_types', selections)
            //         }

            //         this.selections = selections
            //     },
            //     deep: true
            // }
        }
    }
</script>
