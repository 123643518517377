<template>
    <div class="relative">
        <div class="bg-smoke-light absolute rounded inset-0 z-50" v-if="meta.loading"></div>

        <input type="hidden" name="evaluation_form_values" :value="outputValues">

        <div class="border border-red p-4" v-if="meta.layout.length === 0">
            <h1 class="text-lg text-red font-bold">Form Not Configured</h1>
            <p class="leading-tight text-red">This form has not yet been configured. Bookings can still be created, but they will have no specific evaluation information.</p>
        </div>

        <keep-alive v-for="(child, index) in meta.layout" :key="index">
            <component
                :is="meta.objects[child.internal].component"
                :field="child"

                form="evaluation_form_editor"
                :admin="true"

                :user="meta.user"
                :event="meta.event"
                :bookingValues="meta.bookingValues"

                :map="meta.map"
                :object="meta.objects[child.internal]"
                :layout="meta.layout"
                :objects="meta.objects"
                :values="meta.values"
            ></component>
        </keep-alive>

        <div class="mt-4">
            <div class="w-full mb-5">
                <label class="form-label">CPD</label>
                <CpdComponent name="cpd" :value="meta.forceCpd === true ? meta.forcedCpd : cpd" @change="(event) => meta.forcedCpd = event"></CpdComponent>
            </div>

            <div class="w-full mb-5">
                <div class="flex">
                    <GenericToggleComponent name="force_cpd" :value="meta.forceCpd" @change="(event) => meta.forceCpd = event"></GenericToggleComponent>
                    <label class="form-label ml-3 mb-0">Lock CPD to this value</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ListField from './Fields/List'
    import GroupField from './Fields/Group'
    import InputField from './Fields/Input'
    import TextField from './Fields/Text'
    import ToggleField from './Fields/Toggle'
    import RatingField from './Fields/Rating'

    import CpdComponent from '../../../CpdComponent'
    import GenericToggleComponent from '../../../GenericToggleComponent'

    export default {
        components: {
            ListField,
            GroupField,
            InputField,
            TextField,
            ToggleField,
            RatingField,

            CpdComponent,
            GenericToggleComponent
        },

        props: {
            user: {
                type: Number|String,
                required: false
            },
            event: {
                type: Number|String,
                required: false
            },
            bookingValues: {
                type: Object,
                required: true
            },

            forceCpd: {
                type: Boolean,
                default: false
            },
            forcedCpd: {
                type: String,
                default: ''
            },

            values: {
                type: Object,
                required: false,
                default: () => {
                    return {}
                }
            },
        },

        data() {
            return {
                meta: {
                    map: {},
                    flipped: {},

                    user: null,
                    event: null,
                    bookingValues: this.bookingValues,

                    loading: true,

                    forceCpd: this.forceCpd,
                    forcedCpd: this.forcedCpd,

                    objects: [],
                    layout: [],
                    values: this.values
                }
            }
        },

        created() {
            window.EventBus.$on('evaluation_form_editor_value', (field, name, value) => {
                this.handleChange(field, name, value)
            })

            window.EventBus.$on('evaluation_form_editor_visible', (field, state) => {
                this.handleVisibleChange(field, state)
            })

            window.EventBus.$on('evaluation_form_editor_enabled', (field, state) => {
                this.handleEnabledChange(field, state)
            })
        },

        mounted() {
            this.init()
        },

        methods: {
            init() {
                this.$set(this.meta, 'loading', true)

                let required = 0
                let complete = 0

                if (this.user !== null && ((this.meta.user === null || typeof this.meta.user.id === 'undefined') || (this.meta.user !== null && typeof this.meta.user.id !== 'undefined' && this.user !== this.meta.user.id))) {
                    required++

                    axios.get('/api/users/' + this.user).then(response => {
                        complete++

                        if (response.data.success === true) {
                            this.$set(this.meta, 'user', response.data.user)
                        }

                        if (required == complete) {
                            this.$set(this.meta, 'loading', false)
                        }
                    })
                } else if (this.user === null) {
                    this.$set(this.meta, 'user', null)
                }

                if (this.event !== null && ((this.meta.event === null || typeof this.meta.event.id === 'undefined') || (this.meta.event !== null && typeof this.meta.event.id !== 'undefined' && this.event !== this.meta.event.id))) {
                    required++

                    axios.get('/api/events/' + this.event + '/survey').then(response => {
                        complete++

                        if (response.data.success === true) {
                            this.$set(this.meta, 'map', {})
                            this.$set(this.meta, 'flipped', {})
                            this.$set(this.meta, 'event', response.data.event)
                            this.$set(this.meta, 'objects', response.data.objects)
                            this.$set(this.meta, 'layout', response.data.layout)

                            this.process()
                        }

                        if (required == complete) {
                            this.$set(this.meta, 'loading', false)
                        }
                    })
                } else if (this.event === null) {
                    this.$set(this.meta, 'event', null)
                    this.$set(this.meta, 'objects', [])
                    this.$set(this.meta, 'layout', [])
                    this.$set(this.meta, 'map', {})
                    this.$set(this.meta, 'flipped', {})
                }

                return true
            },

            process() {
                let parents = {}
                let children = {}

                let recurse = (tree, parent) => {
                    for (let i = 0; i < tree.length; i++) {
                        parents[tree[i].internal] = []
                        if (parent !== null) {
                            parents[tree[i].internal] = [
                                parent,
                                ...parents[parent]
                            ]
                        }

                        children[tree[i].internal] = []
                        if (parent !== null) {
                            children[parent] = [
                                tree[i].internal,
                                ...children[parent]
                            ]
                        }

                        if (tree[i].children.length !== 0) {
                            recurse(tree[i].children, tree[i].internal)
                        }
                    }
                }

                recurse(this.meta.layout, null)

                for (let i = 0; i < this.meta.objects.length; i++) {
                    this.$set(this.meta.map, this.meta.objects[i].options.name, this.meta.objects[i].internal)

                    if (typeof this.meta.values[this.meta.objects[i].options.name] === 'undefined') {
                        this.$set(this.meta.values, this.meta.objects[i].options.name, {
                            value: null,
                            visible: null,
                            enabled: null
                        })
                    }

                    if (typeof this.meta.objects[i].options.has_default !== 'undefined' && this.meta.objects[i].options.has_default) {
                        if ((typeof this.meta.objects[i].options.default_compulsory !== 'undefined' && this.meta.objects[i].options.default_compulsory) || typeof this.meta.values[this.meta.objects[i].options.name] === 'undefined' || this.meta.values[this.meta.objects[i].options.name].value === null) {
                            this.$set(this.meta.values[this.meta.objects[i].options.name], 'value', this.meta.objects[i].options.default_value)
                        }
                    }

                    this.$set(this.meta.objects[i], 'touched', [])
                    this.$set(this.meta.objects[i], 'visible', null)

                    this.$set(this.meta.objects[i], 'fields', [
                        this.meta.objects[i].options.name
                    ])

                    this.$set(this.meta.objects[i], 'rules', {})
                    this.$set(this.meta.objects[i], 'hooks', {})
                    this.$set(this.meta.objects[i], 'errors', {})
                    this.$set(this.meta.objects[i], 'parents', parents[this.meta.objects[i].internal])
                    this.$set(this.meta.objects[i], 'children', children[this.meta.objects[i].internal])

                    switch (this.meta.objects[i].type) {
                        case 'group':
                            this.$set(this.meta.objects[i], 'component', 'GroupField')
                            break
                        case 'list':
                            this.$set(this.meta.objects[i], 'component', 'ListField')

                            this.$set(this.meta.objects[i].rules, 'default', [
                                (value) => {
                                    let total = 0

                                    let check = this.meta.objects.filter(object => object.parents.includes(this.meta.objects[i].internal))
                                    for (let e = 0; e < check.length; e++) {
                                        if (check[e].type == 'toggle') {
                                            if (this.meta.values[check[e].options.name].value == true) {
                                                total++
                                            }
                                        }
                                    }

                                    if (this.meta.objects[i].options.group_rule == 'one' && total !== 1) {
                                        return 'Please choose exactly one option'
                                    } else if (this.meta.objects[i].options.group_rule == 'gte_one' && total < 1) {
                                        return 'Please choose at least one option'
                                    } else if (this.meta.objects[i].options.group_rule == 'lte_one' && total > 1) {
                                        return 'Please choose at most one option'
                                    }

                                    return true
                                }
                            ])

                            break
                        case 'input':
                            this.$set(this.meta.objects[i], 'component', 'InputField')

                            this.$set(this.meta.objects[i].rules, 'default', [
                                (value) => {
                                    if (this.meta.objects[i].options.required && (value == null || value.trim() == '')) {
                                        return 'This field is required'
                                    }

                                    return true
                                }
                            ])

                            break
                        case 'rating':
                            this.$set(this.meta.objects[i], 'component', 'RatingField')

                            this.$set(this.meta.objects[i].rules, 'default', [
                                (value) => {
                                    if (this.meta.objects[i].options.required && (value == null || value.trim() == '')) {
                                        return 'This field requires a rating'
                                    }

                                    return true
                                }
                            ])

                            break
                        case 'text':
                            this.$set(this.meta.objects[i], 'component', 'TextField')
                            break
                        case 'toggle':
                            this.$set(this.meta.objects[i], 'component', 'ToggleField')

                            this.meta.objects[i].fields.push('additional')

                            this.$set(this.meta.objects[i].rules, 'additional', [
                                (value) => {
                                    if (this.meta.values[this.meta.objects[i].options.name].value === true && this.meta.objects[i].options.request_additional && this.meta.objects[i].options.additional_mandatory && (value == null || value.trim() == '')) {
                                        return 'This field is required'
                                    }

                                    return true
                                }
                            ])

                            break
                    }
                }
            },

            handleChange(field, name, value) {
                let child = null
                if (name.indexOf('::') !== -1) {
                    child = name.split('::', 2)[1]
                }

                name = name.replace('::', '_')

                if (typeof this.meta.values[name] == 'undefined') {
                    this.$set(this.meta.values, name, {
                        'value': value,
                        'visible': null,
                        'enabled': null,
                    })
                } else {
                    this.$set(this.meta.values[name], 'value', value)
                }

                if (this.meta.objects[this.meta.map[field]].touched.includes(child !== null ? child : 'default') === false) {
                    this.meta.objects[this.meta.map[field]].touched.push(child !== null ? child : 'default')
                }

                let parents = this.meta.objects[this.meta.map[field]].parents
                if (parents.length !== 0) {
                    let parent = parents[0]

                    if (this.meta.objects[parent].type == 'list') {
                        if (this.meta.objects[parent].touched.includes('default') === false) {
                            this.meta.objects[parent].touched.push('default')
                        }

                        this.handleValidate(this.meta.objects[parent].options.name, 'default', null)
                    }
                }

                this.handleValidate(field, child !== null ? child : 'default', value)
            },

            handleVisibleChange(field, state) {
                if (typeof this.meta.values[field] == 'undefined') {
                    this.$set(this.meta.values, field, {
                        'value': null,
                        'visible': state,
                        'enabled': null,
                    })
                } else {
                    this.$set(this.meta.values[field], 'visible', state)
                }
            },

            handleEnabledChange(field, state) {
                if (typeof this.meta.values[field] == 'undefined') {
                    this.$set(this.meta.values, field, {
                        'value': null,
                        'visible': null,
                        'enabled': state,
                    })
                } else {
                    this.$set(this.meta.values[field], 'enabled', state)
                }
            },

            handleValidate(field, name, value) {
                this.$set(this.meta.objects[this.meta.map[field]].errors, name, [])

                if (this.meta.objects[this.meta.map[field]].touched.includes(name) && typeof this.meta.objects[this.meta.map[field]].rules !== 'undefined' && typeof this.meta.objects[this.meta.map[field]].rules[name] !== 'undefined') {
                    for (let i = 0; i < this.meta.objects[this.meta.map[field]].rules[name].length; i++) {
                        let result = this.meta.objects[this.meta.map[field]].rules[name][i](value)
                        if (result !== true) {
                            this.meta.objects[this.meta.map[field]].errors[name].push(result)
                        }
                    }
                }
            },

            validateForm() {
                let ok = true

                for (let i = 0; i < this.meta.objects.length; i++) {
                    if (this.meta.objects[i].visible === true && Object.keys(this.meta.objects[i].rules).length !== 0) {
                        for (let key in this.meta.objects[i].rules) {
                            this.$set(this.meta.objects[i].errors, key, [])

                            for (let j = 0; j < this.meta.objects[i].rules[key].length; j++) {
                                let field = this.meta.objects[i].options.name
                                if (key !== 'default') {
                                    field += '_' + key
                                }

                                let result = this.meta.objects[i].rules[key][j](typeof this.meta.values[field] !== 'undefined' ? this.meta.values[field].value : null)
                                if (result !== true) {
                                    this.meta.objects[i].errors[key].push(result)

                                    ok = false
                                }
                            }
                        }
                    }
                }

                return ok;
            }
        },

        watch: {
            event: (newEvent, oldEvent) => {
                if (newEvent !== oldEvent) {
                    this.init()
                }
            },
            user: (newUser, oldUser) => {
                if (newUser !== oldUser) {
                    this.init()
                }
            },
        },

        computed: {
            outputValues() {
                return JSON.stringify(this.meta.values)
            },

            cpd() {
                let cpd = 0
                if (this.meta.event !== null && typeof this.meta.event.initial_cpd !== 'undefined' && this.meta.event.initial_cpd != null) {
                    cpd = this.meta.event.initial_cpd
                }

                for (let i = 0; i < this.meta.objects.length; i++) {
                    if (this.meta.objects[i].visible && typeof this.meta.objects[i].options.cpd !== 'undefined' && this.meta.objects[i].options.cpd > 0) {
                        if ((typeof this.meta.objects[i].options.force_cpd !== 'undefined' && (this.meta.objects[i].options.force_cpd == true || this.meta.objects[i].options.force_cpd == 1)) || (typeof this.meta.values[this.meta.objects[i].options.name] !== 'undefined' && ((this.meta.objects[i].type == 'input' || this.meta.objects[i].type == 'rating') && this.meta.values[this.meta.objects[i].options.name].value != null) || (this.meta.objects[i].type == 'toggle' && (this.meta.values[this.meta.objects[i].options.name].value == 1 || this.meta.values[this.meta.objects[i].options.name].value === true)))) {
                            cpd += this.meta.objects[i].options.cpd
                        }
                    }
                }

                if (this.meta.event !== null && typeof this.meta.event.maximum_cpd !== 'undefined' && this.meta.event.maximum_cpd != null && cpd > this.meta.event.maximum_cpd) {
                    cpd = this.meta.event.maximum_cpd
                }

                if (this.meta.forceCpd !== true) {
                    this.meta.forcedCpd = (cpd < 0 ? 0 : cpd)
                }

                if (cpd < 0) {
                    return 0
                }

                return cpd
            }
        }
    }
</script>
