<template>
    <div class="flex flex-wrap border border-solid rounded border-gray-400 p-1">
        <div v-for="(value, key) in selections" :key="key" class="flex items-center justify-between flex-auto border-gray-300 bg-gray-200 border border-solid rounded m-1 px-2 py-1 text-sm text-gray-600">
            <input type="hidden" :name="[ name + '[]' ]" :value="key">
            <span>{{ value }}</span>
            <a href="" @click.prevent="() => removeSelection(key)" class="ml-2">&times;</a>
        </div>

        <div class="m-1 relative flex-auto" v-click-outside="collapse">
            <input type="text"
                :class="[ 'form-control py-1 px-2', isExpanded ? 'rounded-b-none outline-none' : '' ]"
                :value="selection"
                :placeholder="placeholder"
                @focus="expand"
                @keyup="onKeyUp"
                @keydown="onKeyDown"
                autocomplete="off">

            <div style="border-color:#cbd5e0" class="absolute bg-white border border-t-0 rounded rounded-t-none overflow-auto w-full max-h-40 z-50" v-show="isExpanded">
                <ul>
                    <li v-show="!hasResults">
                        <div class="p-2 text-sm px-4 cursor-pointer w-full">
                            <span class="block">No Results</span>
                        </div>
                    </li>
                    <li class="generic-select-result" v-show="hasResults" v-for="(item, key) in filtered" v-bind:key="key" @click.prevent="select(item, key)">
                        <div class="p-2 text-sm px-4 cursor-pointer w-full" :class="[ (currentIndex != -1 && key === currentIndex) || (currentIndex == -1 && item[keyColumn] === choice) ? 'bg-gray-300' : '' ]">
                            <span class="block" :class="[ descriptionColumn ? 'font-bold' : '' ]">{{ item[valueColumn] }}</span>
                            <span class="block" v-if="descriptionColumn">{{ item[descriptionColumn] }}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import GenericSelectComponent from './GenericSelectComponent'

    export default {
        components: {
            GenericSelectComponent
        },

        props: {
            name: {
                type: String,
                required: true,
            },

            options: {
                type: Array,
                required: true
            },

            values: {
                type: Array|Object,
                default: () => {
                    return {}
                }
            },

            placeholder: {
                type: String,
                default: "Search..."
            },

            keyColumn: {
                type: String,
                default: 'id'
            },

            valueColumn: {
                type: String,
                default: 'name'
            },

            descriptionColumn: {
                type: String
            }
        },

        data() {
            return {
                selection: '',
                selections: this.values,

                choice: '',
                currentIndex: -1,
                hasResults: false,
                isExpanded: false,
                edited: false,
            }
        },

        methods: {
            collapse() {
                this.isExpanded = false;
            },

            expand() {
                this.isExpanded = true;
            },

            select(item, index) {
                this.$set(this.selections, item[this.keyColumn], item[this.valueColumn])

                this.selection = '';

                this.collapse();
            },

            onKeyDown(event) {
                if (this.isExpanded) {
                    if (event.key === 'ArrowDown') {
                        if ((this.currentIndex + 1) !== this.options.length) {
                            return this.currentIndex++;
                        }
                    } else if (event.key === 'ArrowUp') {
                        if (this.currentIndex > 0) {
                            return this.currentIndex--;
                        }
                    } else if (event.key === 'Enter') {
                        event.preventDefault();

                        let item = this.options[this.currentIndex];

                        return this.select(item, this.currentIndex);
                    }
                } else {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                    }

                    return this.expand();
                }
            },

            onKeyUp(event) {
                let old = this.selection;
                this.selection = event.target.value;

                if (this.selection != old) {
                    this.edited = true;
                }
            },

            removeSelection(key) {
                this.$delete(this.selections, key)
            }
        },

        computed: {
            filtered() {
                if (this.edited) {
                    let valueColumn = this.valueColumn;
                    let descriptionColumn = this.descriptionColumn;
                    let selection = this.selection;

                    let results = this.options.filter(function (element) {
                        let valueFilter = element[valueColumn].trim().toLowerCase().includes(selection.toLowerCase().trim());

                        if (descriptionColumn) {
                            let descriptionFilter = element[descriptionColumn].trim().toLowerCase().includes(selection.toLowerCase().trim());

                            return valueFilter || descriptionFilter;
                        }

                        return valueFilter;
                    });

                    if (results.length > 0) {
                        this.hasResults = true;
                    } else {
                        this.hasResults = false;
                    }

                    return results;
                } else {
                    if (this.options.length > 0) {
                        this.hasResults = true;
                    } else {
                        this.hasResults = false;
                    }

                    return this.options;
                }
            }
        }
    }
</script>
