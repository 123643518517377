<style>
    .wrapper.templates {
        margin: 0;
    }
    .group.templates {
        display: flex;
        flex-direction: column;
        margin: 0;
    }
    .group.templates > div {
        margin: 0;
    }
    .group.templates .field {
        white-space: nowrap;
    }
    .wrapper.elements {
        margin: -1rem 0 -1rem -1rem;
    }
    .group.elements {
        padding: 1rem 0 0 1rem;
    }
    .group.elements .list {
        padding: 1rem 0 0 1rem;
        margin: -1rem 0 0 -1rem;
    }
    .group.elements:empty {
        padding-bottom: 2rem;
        margin-top: -2rem;
    }
    .group.elements .ghost {
        opacity: 0.5;
    }
    .group.elements .ghost:not(.list) {
        margin-bottom: 1rem;
    }
</style>

<template>
    <div class="flex flex-wrap lg:flex-no-wrap -mx-2">
        <div class="w-full px-6 mb-5 lg:w-1/4 lg:px-2 lg:mb-0 sticky self-start top-4">
            <Toolbox reference="builder" :event-data="event" :templates="templates" :clone="handleClone" :save="handleSave" :saving="saving" :can-save="!hasError" @toggleall="handleToggleAll"></Toolbox>
        </div>
        <div class="w-full px-6 lg:w-3/4 lg:px-2">
            <div class="mb-4" v-if="this.layout === undefined || this.layout.length === 0">
                <h2 class="font-bold text-2xl -mt-2 text-gray-500">No Fields</h2>
                <p class="text-gray-500">Drag and drop fields from the left to start building your form, and click Save to save your changes.</p>
                <div class="w-full mt-4 p-4 bg-white shadow rounded">
                    <div class="flex w-full">
                        <GenericSelectComponent class="flex-1" name="clone" :options="cloneable" placeholder="Select an event to clone..." keyColumn="id" valueColumn="title" descriptionColumn="subtitle" :value="cloneEvent" @change="handleFormCloneChange"></GenericSelectComponent>
                        <button class="button is-primary ml-4" @click="handleFormClone" :disabled="this.cloneEvent == null"><i class="fas fa-clone mr-2"></i> Clone</button>
                    </div>
                </div>
            </div>
            <div class="wrapper elements">
                <Nested reference="builder" :type="type" :layout="layout" :objects="objects" :event="event" @change="handleChange" @duplicate="handleDuplicate" @delete="handleDelete" @error="handleError"></Nested>
            </div>
        </div>
        <DeleteModal v-if="deleting != null" @confirm="handleDeleteConfirm" @cancel="handleDeleteCancel"></DeleteModal>
    </div>
</template>

<script>
    import * as DeepSet from 'vue-deepset'

    import GenericSelectComponent from '../GenericSelectComponent'

    import Toolbox from './Toolbox'
    import Nested from './Nested'

    import DeleteModal from './Modals/Delete'

    import { Definition as GroupFieldDefinition } from './Fields/Group'
    import { Definition as TextFieldDefinition } from './Fields/Text'
    import { Definition as InputFieldDefinition } from './Fields/Input'
    import { Definition as ToggleFieldDefinition } from './Fields/Toggle'
    import { Definition as RatingFieldDefinition } from './Fields/Rating'
    import { Definition as ListFieldDefinition } from './Fields/List'

    export default {
        components: {
            GenericSelectComponent,

            Toolbox,
            Nested,

            DeleteModal
        },

        props: {
            type: {
                type: String,
                required: true
            },
            cloneableData: {
                type: Array,
                required: true
            },
            eventData: {
                type: Object,
                required: true
            },
            layoutData: {
                type: Array,
                required: true
            },
            objectsData: {
                type: Array,
                required: true
            }
        },

        data() {
            let templates = [
                GroupFieldDefinition,
                TextFieldDefinition,
                ToggleFieldDefinition,
                ListFieldDefinition,
                InputFieldDefinition,
            ]

            if (this.type == 'survey') {
                templates.push(RatingFieldDefinition)
            }

            return {
                cloneable: this.cloneableData,
                cloneEvent: null,

                event: this.eventData,
                layout: this.layoutData,
                objects: this.objectsData,

                internal: this.objectsData.length > 0 ? Math.max.apply(Math, this.objectsData.map(item => item.internal)) : 0,

                templates: templates,

                saving: false,
                deleting: null
            }
        },

        computed: {
            hasError() {
                return this.objects.filter(item => item.error == true).length !== 0
            }
        },

        methods: {
            handleToggleAll(option) {
                for (let i = 0; i < this.objects.length; i++) {
                    this.$set(this.objects[i].options, 'toggled', !option)
                }
            },
            handleFormCloneChange(event) {
                this.cloneEvent = event
            },
            handleFormClone() {
                if (this.cloneEvent !== null) {
                    axios.get('/api/events/' + this.cloneEvent + '/' + (this.type == 'survey' ? 'survey' : 'booking')).then((response) => {
                        if (response.data.success === true) {
                            this.$set(this, 'layout', response.data.layout)
                            this.$set(this, 'objects', response.data.objects)
                        }
                    })
                }
            },
            handleError(field, element, valid) {
                const index = this.objects.findIndex(object => object.internal == field.internal)

                this.$set(this.objects[index], 'error', !valid)
            },
            handleChange(internal, key, value) {
                const index = this.objects.findIndex(object => object.internal == internal)

                DeepSet.vueSet(this.objects[index].options, key, value)
            },
            handleDuplicate(object) {
                let replicate = (layout, target, parent) => {
                    let nodes = []

                    for (let i = 0; i < layout.length; i++) {
                        if (target === null || layout[i].internal === target) {
                            this.internal++

                            let node = {
                                internal: this.internal,
                                children: []
                            }

                            let clone = JSON.parse(
                                JSON.stringify(
                                    this.objects.find(object => object.internal === layout[i].internal)
                                )
                            )

                            clone.options.name = ''

                            this.objects.push({
                                internal: node.internal,
                                type: clone.type,
                                options: clone.options
                            })

                            if (layout[i].children.length !== 0) {
                                node.children = replicate(layout[i].children, null, layout[i].internal)
                            }

                            nodes.push(node)
                        } else {
                            nodes = nodes.concat(replicate(layout[i].children, target, layout[i].internal))
                        }
                    }

                    return nodes
                }

                let insert = (layout, replica, after) => {
                    if (replica.length !== 0) {
                        for (let i = 0; i < layout.length; i++) {
                            if (layout[i].internal == after) {
                                layout.splice(i + 1, 0, replica[0])
                            }

                            if (layout[i].children.length !== 0) {
                                insert(layout[i].children, replica, after)
                            }
                        }
                    }
                }

                insert(this.layout, replicate(this.layout, object.internal), object.internal)
            },
            handleDelete(object) {
                this.deleting = object
            },
            handleDeleteConfirm() {
                const target = this.deleting.internal

                let affected = (layout, target) => {
                    let response = []

                    for (let i = 0; i < layout.length; i++) {
                        if (target === false) {
                            response.push(layout[i].internal)

                            if (layout[i].children) {
                                response = response.concat(affected(layout[i].children, false))
                            }
                        } else {
                            if (layout[i].internal == target) {
                                response.push(layout[i].internal)

                                if (layout[i].children) {
                                    response = response.concat(affected(layout[i].children, false))
                                }
                            } else {
                                if (layout[i].children) {
                                    response = response.concat(affected(layout[i].children, target))
                                }
                            }
                        }
                    }

                    return response
                }

                let filter = (layout, affected) => {
                    let response = []

                    for (let i = 0; i < layout.length; i++) {
                        if (!affected.includes(layout[i].internal)) {
                            let field = {
                                id: layout[i].id,
                                internal: layout[i].internal,
                                children: []
                            }

                            if (layout[i].children) {
                                field.children = filter(layout[i].children, affected)
                            }

                            response.push(field)
                        }
                    }

                    return response
                }

                let result = affected(this.layout, target)

                this.$set(this, 'layout', filter(this.layout, result))

                for (let i = 0; i < result.length; i++) {
                    this.$delete(this.objects, this.objects.findIndex(object => object.internal == result[i]))
                }

                this.deleting = null
            },
            handleDeleteCancel() {
                this.deleting = null
            },
            handleClone(object) {
                this.internal++

                this.objects.push({
                    internal: this.internal,
                    type: object.name,
                    options: {
                        enabled: true,
                        name: ''
                    }
                })

                return {
                    internal: this.internal,
                    children: []
                }
            },
            cleanLayout(chunk) {
                let output = []

                for (let i = 0; i < chunk.length; i++) {
                    if (typeof this.objects[i] !== 'undefined') {
                        output[i] = chunk[i]

                        if (chunk[i].children.length !== 0) {
                            output[i].children = this.cleanLayout(chunk[i].children)
                        }
                    }
                }

                return output
            },
            handleSave() {
                if (!this.hasError) {
                    this.saving = true;

                    this.$set(this, 'layout', this.cleanLayout(this.layout))

                    axios.post('/api/events/' + this.event.id + '/' + this.type + '/update', {
                        layout: this.layout,
                        objects: this.objects
                    }).then((response) => {
                        if (response.data.success === true) {
                            for (const property in response.data.changes) {
                                const index = this.objects.findIndex(object => object.internal == property)
                                this.$set(this.objects[index], 'id', response.data.changes[property])
                            }
                        }

                        this.saving = false
                    })
                }
            }
        }
    }
</script>
