<template>
    <div>
        <data-table-component url="/api/users" :columns="columns">
            <template slot-scope="{ row }" slot="filters">
                <slot v-bind="row"></slot>
            </template>
            <template slot-scope="{ row }" slot="actions">
                <td class="text-right">
                    <a :href="`/admin/users/${row.id}/payments`" class="button is-small is-secondary inline-block">
                        <i class="fas fa-list"></i> Payments
                    </a>
                    <a :href="`/admin/users/${row.id}/edit`" class="button is-small is-tertiary inline-block">
                        <i class="fas fa-edit"></i>
                    </a>
                </td>
            </template>
        </data-table-component>
    </div>
</template>

<script>
    import DataTableComponent from '../DataTableComponent';
    import Modal from '../../components/Modal';

    export default {
        components: {
            DataTableComponent,
            Modal
        },

        data() {
            return {
                columns: [
                    { key: 'member_id', label: 'Member' },
                    { key: 'full_name', label: 'Name' },
                    { key: 'email', label: 'Email' },
                    {
                        key: 'username',
                        label: 'Username',
                        html: function (x, y, z) {
                            if (z.email == y) {
                                return '&mdash;';
                            }

                            return y;
                        }
                    },
                    { key: 'membership', label: 'Type' },
                    {
                        key: 'is_subscribed',
                        label: 'Subscriber',
                        html: function (x, y) {
                            if (y == 1) {
                                return '<i class="fas fa-check text-green"></i>';
                            }

                            return '<i class="fas fa-times text-red"></i>';
                        }
                    },
                    {
                        key: 'is_paid',
                        label: 'Paid',
                        html: function (x, y) {
                            if (y == 1) {
                                return '<i class="fas fa-check text-green"></i>';
                            }

                            return '<i class="fas fa-times text-red"></i>';
                        }
                    },
                    {
                        key: 'is_active',
                        label: 'Active',
                        html: function (x, y) {
                            if (y == 1) {
                                return '<i class="fas fa-check text-green"></i>';
                            }

                            return '<i class="fas fa-times text-red"></i>';
                        }
                    },
                ],

                currentRow: {},
                isDeleteModalVisible: false,
            }
        }
    }
</script>
