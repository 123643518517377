<template>
    <div class="fixed z-50 left-0 top-0 right-0 bottom-0 bg-black h-screen" style="background-color: rgba(0,0,0,0.75)"
         @click="hide" v-show="isVisible">
        <div class="container flex mx-auto justify-center items-center h-full">
            <div class="w-3/5">
                <transition name="fade-fast">
                    <div class="bg-white rounded-lg" v-if="isVisible" @click.stop>
                        <div
                            class="flex justify-between items-center px-6 py-5 text-lg font-bold text-gray-700 bg-gray-100 rounded-t-lg border-b">
                            <slot name="modal-title"></slot>
                            <button @click.prevent="hide">
                                <i class="fas fa-times-circle"></i>
                            </button>
                        </div>
                        <div class="p-6">
                            <slot name="modal-body"></slot>
                        </div>

                        <div class="px-6 py-3 bg-gray-200 rounded-b-lg">
                            <slot name="modal-footer"></slot>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            isVisible: {
                type: Boolean,
                required: true
            }
        },

        methods: {
            hide() {
                this.$emit('hide');
            }
        },

        watch: {
            isVisible: function (n, o) {
                const b = document.querySelector('body');
                if (n) {
                    b.classList.add('overflow-hidden');
                } else {
                    b.classList.remove('overflow-hidden');
                }
            }
        }
    }
</script>
