<template>
    <div>
        <div class="mb-5">
            <div class="flex flex-col lg:flex-row">
                <div class="flex flex-col lg:flex-row mr-5">
                    <a href="" class="button rounded-r-none" :class="[type === 'all' ? 'is-primary' : 'is-tertiary']" @click.prevent="type = 'all'">
                        <i class="fas fa-list mr-3"></i> All
                    </a>
                    <a href="" class="button rounded-none" :class="[type === 'active' ? 'is-primary' : 'is-tertiary']" @click.prevent="type = 'active'">
                        <i class="fas fa-list mr-3"></i> Active
                    </a>
                    <a href="" class="button rounded-none" :class="[type === 'inactive' ? 'is-primary' : 'is-tertiary']" @click.prevent="type = 'inactive'">
                        <i class="fas fa-list mr-3"></i> Inactive
                    </a>
                    <a href="" class="button rounded-l-none" :class="[type === 'pending' ? 'is-primary' : 'is-tertiary']" @click.prevent="type = 'pending'">
                        <i class="fas fa-list mr-3"></i> Pending
                    </a>
                </div>
                <div class="flex flex-col lg:flex-row lg:justify-between flex-grow">
                    <a href="/admin/jobs/create" class="button is-primary ml-auto">
                        <i class="fas fa-plus mr-3"></i> Add
                    </a>
                </div>
            </div>
        </div>

        <div v-if="type == 'all'">
            <data-table-component url="/api/jobs" :columns="columns" :params="params">
                <template slot-scope="{ row }" slot="filters">
                    <slot v-bind="row"></slot>
                </template>
                <template slot-scope="{ row }" slot="actions">
                    <td class="text-right whitespace-no-wrap">
                        <a :href="`/admin/jobs/${row.id}/edit`" class="button is-small is-tertiary inline-block">
                            <i class="fas fa-edit"></i>
                        </a>
                        <a href="#" class="button is-small is-danger inline-block" @click.prevent="showDeleteModal(row)">
                            <i class="fas fa-trash"></i>
                        </a>
                    </td>
                </template>
            </data-table-component>
        </div>

        <div v-if="type == 'active'">
            <data-table-component url="/api/jobs/active" :columns="columns" :params="params">
                <template slot-scope="{ row }" slot="filters">
                    <slot v-bind="row"></slot>
                </template>
                <template slot-scope="{ row }" slot="actions">
                    <td class="text-right whitespace-no-wrap">
                        <a :href="`/admin/jobs/${row.id}/edit`" class="button is-small is-tertiary inline-block">
                            <i class="fas fa-edit"></i>
                        </a>
                        <a href="#" class="button is-small is-danger inline-block" @click.prevent="showDeleteModal(row)">
                            <i class="fas fa-trash"></i>
                        </a>
                    </td>
                </template>
            </data-table-component>
        </div>

        <div v-if="type == 'inactive'">
            <data-table-component url="/api/jobs/inactive" :columns="columns" :params="params">
                <template slot-scope="{ row }" slot="filters">
                    <slot v-bind="row"></slot>
                </template>
                <template slot-scope="{ row }" slot="actions">
                    <td class="text-right whitespace-no-wrap">
                        <a :href="`/admin/jobs/${row.id}/edit`" class="button is-small is-tertiary inline-block">
                            <i class="fas fa-edit"></i>
                        </a>
                        <a href="#" class="button is-small is-danger inline-block" @click.prevent="showDeleteModal(row)">
                            <i class="fas fa-trash"></i>
                        </a>
                    </td>
                </template>
            </data-table-component>
        </div>

        <div v-if="type == 'pending'">
            <data-table-component url="/api/jobs/pending" :columns="columns" :params="params">
                <template slot-scope="{ row }" slot="filters">
                    <slot v-bind="row"></slot>
                </template>
                <template slot-scope="{ row }" slot="actions">
                    <td class="text-right whitespace-no-wrap">
                        <a :href="`/admin/jobs/${row.id}/edit`" class="button is-small is-tertiary inline-block">
                            <i class="fas fa-edit"></i>
                        </a>
                        <a href="#" class="button is-small is-danger inline-block" @click.prevent="showDeleteModal(row)">
                            <i class="fas fa-trash"></i>
                        </a>
                    </td>
                </template>
            </data-table-component>
        </div>

        <modal :is-visible="isDeleteModalVisible" @hide="isDeleteModalVisible = !isDeleteModalVisible">
            <template slot="modal-title">
                Confirm Delete
            </template>
            <div slot="modal-body">
                <p>Are you sure you wish to delete <span class="font-bold">{{ currentRow.job_title }}</span>?</p>
            </div>
            <div slot="modal-footer" class="flex justify-end">
                <button type="button" class="button is-secondary mr-2" @click.prevent="isDeleteModalVisible = false">
                    Cancel
                </button>
                <button type="button" class="button is-danger" @click="onDelete">
                    <i class="fas fa-trash mr-3"></i> Delete
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
    import DataTableComponent from '../DataTableComponent';
    import Modal from '../../components/Modal';
    import moment from 'moment';

    export default {
        components: {
            DataTableComponent,
            Modal
        },

        data() {
            return {
                type: 'all',

                keyword: '',

                columns: [
                    {
                        key: 'job_title',
                        label: 'Title',
                    },
                    {
                        key: 'is_active',
                        label: 'Approved',
                        html: function (x, y) {
                            if (y === 1) {
                                return '<i class="fas fa-check text-green"></i>';
                            }

                            return '<i class="fas fa-times text-red"></i>';
                        }
                    },
                    {
                        key: 'start_date',
                        label: 'Start Date',
                        html: (key, value, row) => {
                            return moment(row.start_date).format('DD-MM-YYYY');
                        }
                    },
                    {
                        key: 'end_date',
                        label: 'End Date',
                        html: (key, value, row) => {
                            return moment(row.end_date).format('DD-MM-YYYY');
                        }
                    },
                    {
                        key: 'region',
                        label: 'Region',
                        html: (key, value, row) => {
                            return (row.region !== '' && row.region != null) ? row.region : '–';
                        }
                    },
                ],

                currentRow: {},
                isDeleteModalVisible: false,

                params: {},
                approvedFilter: 2,
            }
        },

        methods: {
            showDeleteModal(row) {
                this.currentRow = row;
                this.isDeleteModalVisible = true;
            },

            onDelete() {
                axios.delete('/admin/jobs/' + this.currentRow.id).then(() => {
                    window.EventBus.$emit('onTableRefresh');
                });

                this.isDeleteModalVisible = false;
            }
        },

        watch: {
            approvedFilter(value) {
                this.params.approved = value;

                window.EventBus.$emit('onTableRefresh');
            },
            type(value) {
                window.EventBus.$emit('onTableRefresh');
            }
        }
    }
</script>
