<style>
</style>

<template>
    <div>
        <h4 class="font-bold text-gray-700">Linked Session</h4>
        <p class="text-gray-700 mb-4">If this field is linked to a session, that session will be used to calculate max CPD hours. Only set this on one rating field per speaker, or the max CPD hours may be calculated incorrectly</p>

        <div class="flex flex-wrap -m-2 mb-4">
            <div class="w-full p-2">
                <label class="form-label">Session</label>
                <div class="relative">
                    <div class="bg-smoke-light absolute rounded inset-0 z-50" v-if="loading">
                        <div class="flex items-center pr-2 absolute inset-y-0 right-0">
                            <i class="fa fa-spinner fa-spin"></i>
                        </div>
                    </div>
                    <Select :options="sessions" :value="options.session" placeholder="Search Sessions..." keyColumn="id" valueColumn="title" descriptionColumn="description" :loading="loading" @change="(value) => handleChange('session', value)"></Select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {debounce} from 'lodash'

    import Select from '../../../GenericSelectComponent'

    export default {
        components: {
            Select
        },

        props: {
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            event: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        data() {
            return {
                loading: false,
                sessions: []
            }
        },

        created() {
            debounce(() => {
                this.loading = true

                axios.get('/api/events/' + this.event.id + '/sessions/select').then(response => {
                    this.sessions = response.data
                    this.loading = false
                })
            }, 200)()
        },

        methods: {
            handleChange(key, value) {
                this.$emit('change', key, value)
            }
        }
    }
</script>
