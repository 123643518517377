<style>
</style>

<template>
    <div>
        <h4 class="font-bold text-gray-700">Required</h4>
        <p class="text-gray-700 mb-4">Choose whether to force users to enter a value in this field.</p>

        <label class="flex items-center">
            <toggle :value="options.required" @change="(event) => handleChange('required', event)"></toggle>
            <div class="ml-2 mb-0 form-label">Answer required</div>
        </label>
    </div>
</template>

<script>
    import Toggle from "../../../GenericToggleComponent"

    export default {
        components: {
            Toggle
        },

        props: {
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        methods: {
            handleChange(key, value) {
                this.$emit('change', key, value)
            }
        }
    }
</script>
