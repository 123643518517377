<template>
    <div>
        <data-table-component :url="`/api/users/${userId}/payments`" :columns="columns" :is-searchable="false" table-container-class="data-table shadow-none">
            <template slot-scope="{ row }" slot="filters">
                <slot v-bind="row"></slot>
            </template>
            <template slot-scope="{ row }" slot="actions">
                <td class="text-right">
                    <a :href="`/admin/users/${userId}/payments/${row.id}/print`" target="_blank" class="button is-small is-tertiary inline-block">
                        <i class="fas fa-print"></i>
                    </a>
                    <a :href="`/admin/users/${userId}/payments/${row.id}/edit`" class="button is-small is-tertiary inline-block">
                        <i class="fas fa-edit"></i>
                    </a>
                </td>
            </template>
        </data-table-component>
    </div>
</template>

<script>
    import DataTableComponent from '../DataTableComponent';
    import moment from 'moment';

    export default {
        components: {
            DataTableComponent,
        },

        props: {
            user: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                columns: [
                    {
                        key: 'id',
                        label: 'ID',
                    },
                    {
                        key: 'paymentable_type',
                        label: 'Type',
                        html: (key, value, row) => {
                            if (row.paymentable_type) {
                                return row.paymentable_type.toLowerCase().replace(/^(.)|\s+(.)/g, function ($1) {
                                    return $1.toUpperCase()
                                });
                            }

                            return 'General';
                        }
                    },
                    {
                        key: 'method_id',
                        label: 'Method',
                        html: (key, value, row) => {
                            return row.method.name;
                        }
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        html: (key, value, row) => {
                            return row.status.toLowerCase().replace(/^(.)|\s+(.)/g, function ($1) {
                                return $1.toUpperCase()
                            });
                        }
                    },
                    {
                        key: 'amount',
                        label: 'Amount',
                        html: (key, value, row) => {
                            return row.currency.symbol + row.amount;
                        }
                    },
                    {
                        key: 'date',
                        label: 'Date',
                        html: (key, value, row) => {
                            return moment(row.date).format('DD-MM-YYYY HH:mm');
                        }
                    }
                ],

                userId: this.user,

                currentRow: {},
                isDeleteModalVisible: false,
            }
        }
    }
</script>
