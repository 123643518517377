<template>
    <select-component :options="options"
                      :id="id"
                      :name="name"
                      :value="search"
                      @addSelectOption="onAddSelectOption"></select-component>
</template>

<script>
    import SelectComponent from "./SelectComponent";

    export default {
        components: {
            SelectComponent
        },

        props: {
            options: {
                type: Array,
                required: true
            },
            id: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: false
            },
            value: {
                type: String,
                required: false
            }
        },

        data() {
            return {
                search: this.value
            }
        },

        methods: {
            onAddSelectOption(payload) {
                this.search = payload.code
            }
        }
    }
</script>
