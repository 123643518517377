<template>
    <div class="generic-select" v-click-outside="handleClickOutside">
        <input type="hidden" :name="name" :value="option">
        <div class="card relative is-primary text-gray-700 mb-4 p-4" v-if="object !== null">
            <span class="block">{{ object.member_id }}</span>
            <span class="block font-bold">{{ object.full_name }}</span>
            <span class="block">{{ object.email }}</span>

            <a href="" class="absolute inset-y-0 right-0 mt-1 mr-3 text-xl" @click.prevent="handleClear">&times;</a>
        </div>
        <input type="text" :class="[ 'form-control', open ? 'rounded-b-none outline-none' : '' ]" :id="id" v-model="query" :placeholder="placeholder" :disabled="disabled" @focus="handleFocus" @keydown="handleKeyDown" autocomplete="off">
        <div class="absolute inset-y-0 right-0 flex items-center px-4" v-show="nullable && !disabled && query !== ''">
            <span class="cursor-pointer" @click.prevent="handleClear">&times;</span>
        </div>
        <div style="border-color:#cbd5e0" class="absolute bg-white border border-t-0 rounded rounded-t-none overflow-auto w-full max-h-40 z-50" v-show="open">
            <ul>
                <li v-show="options.length === 0">
                    <div class="p-2 text-sm px-4 cursor-pointer w-full">
                        <span class="block">No Results</span>
                    </div>
                </li>
                <li class="generic-select-result" v-show="options.length !== 0" v-for="(item, key) in options" :key="key" :ref="'select-item-' + key" @click.prevent="handleSelect(item, key)">
                    <div class="text-sm cursor-pointer w-full" :class="[ (index !== -1 && key === index) || (index === -1 && item.id === option) ? 'bg-gray-300' : '', 'p-2 px-4' ]">
                        <span class="block">{{ item.member_id }}</span>
                        <span class="block font-bold">{{ item.full_name }}</span>
                        <span class="block">{{ item.email }}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>


<script>
    export default {
        props: {
            name: {
                type: String,
                required: true,
            },
            id: {
                type: String,
                required: false,
            },
            value: {
                type: String|Number,
                required: false,
            },
            nullable: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: "Search..."
            },
        },

        mounted() {
            this.loading = true

            axios.get('/api/search/members').then(response => {
                this.options = response.data

                if (this.value === '') {
                    this.loading = false
                }
            })

            if (this.value !== '') {
                this.loading = true

                axios.get('/api/users/' + this.value).then(response => {
                    this.object = {
                        email: response.data.user.email,
                        full_name: response.data.user.title ? (response.data.user.title + ' ' + response.data.user.full_name) : response.data.user.full_name,
                        id: response.data.user.id,
                        member_id: response.data.user.member_id,
                    }
                    this.loading = false
                })
            }
        },

        data() {
            return {
                open: false,
                loading: false,

                debouncedQuery: '',
                index: -1,
                timeout: null,

                object: null,

                option: this.value,
                options: [],
            }
        },

        watch: {
            query: {
                handler: function (val, oldVal) {
                    if (val !== oldVal) {
                        this.loading = true

                        axios.post('/api/search/members', {
                            query: val.trim()
                        }).then(response => {
                            this.options = response.data
                            this.loading = false
                        })
                    }
                }
            },
            option: {
                handler: function (val, oldVal) {
                    if (val !== '') {
                        this.index = this.options.findIndex(function (e) {
                            return e.id === val
                        })
                    }

                    this.$emit('change', val);
                }
            },
            options: {
                handler: function (val, oldVal) {
                    let option = this.option

                    if (option !== '') {
                        this.index = this.options.findIndex(function (e) {
                            return e.id === option
                        })
                    }
                }
            }
        },

        methods: {
            handleClickOutside() {
                this.open = false
            },
            handleClear() {
                this.open = false
                this.index = -1
                this.query = ''
                this.option = ''
                this.object = null;
            },
            handleSelect(e) {
                this.option = e.id
                this.object = e

                this.open = false
                this.index = -1
                this.query = ''

                this.$emit('change', e.id);
            },
            handleFocus() {
                this.open = true
            },
            handleKeyDown(e) {
                if (this.open) {
                    if (e.key === 'ArrowDown') {
                        if ((this.index + 1) !== this.options.length) {
                            this.index++
                            if (this.$refs['select-item-' + this.index].length === 1) {
                                let element = this.$refs['select-item-' + this.index][0]
                                element.parentNode.parentNode.scrollTop = element.offsetTop - element.parentNode.parentNode.offsetTop
                            }
                        }
                    } else if (e.key === 'ArrowUp') {
                        if (this.index > 0) {
                            this.index--
                            if (this.$refs['select-item-' + this.index].length === 1) {
                                let element = this.$refs['select-item-' + this.index][0]
                                element.parentNode.parentNode.scrollTop = element.offsetTop - element.parentNode.parentNode.offsetTop
                            }
                        }
                    } else if (e.key === 'Enter') {
                        e.preventDefault()
                        this.handleSelect(this.options[this.index])
                    }
                } else {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                    }

                    this.open = true
                }
            },
        },

        computed: {
            query: {
                get() {
                    return this.debouncedQuery
                },

                set(val) {
                    if (this.timeout) clearTimeout(this.timeout)

                    this.timeout = setTimeout(() => {
                        this.debouncedQuery = val
                    }, 250)
                }
            }
        }
    }
</script>
